import { CommonModule } from '@angular/common';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Directive,
    ElementRef,
    Input,
    OnDestroy,
    QueryList,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil, take } from 'rxjs/operators';
import { SlickCarouselStore } from './slick-carousel-store.service';
import {
    getAllSlidesTranslateDistance,
    getDotsArray,
    getNextDraggedSlide,
    getSlickListWidth,
    getSlideWidth,
    registerBreakpoints,
    transition,
} from './slick-carousel.fns';
import { AFFSlickCarouselSettings, RegisterBreakpoints, TranslateTrackParams } from './slick-carousel.model';

@Directive({
    standalone: true,
    selector: `slick-slide, aff-slick-slide, [aff-slick-slide], [affSlickSlide]`,
    host: {
        class: 'aff-slick-slide, slick-slide',
        '[attr.data-slick-index]': 'index',
        '[style.width.px]': 'width',
    },
})
export class AFFSlickSlideDirective {
    @Input() index = 0;

    private _width = 0;
    @Input()
    set width(w: number) {
        this._width = w;
        this.cd.detectChanges();
    }

    get width() {
        return this._width;
    }

    constructor(public cd: ChangeDetectorRef) { }
}

@Component({
    selector: 'slider, aff-slick-carousel',
    standalone: true,
    imports: [CommonModule, AFFSlickSlideDirective],
    providers: [SlickCarouselStore],
    templateUrl: './slick-carousel.component.html',
    styleUrls: ['./slick-carousel.component.scss', './slick-carousel.component.theme.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'aff-slick-carousel, slick-slider, slider',
    },
})
export class SlickCarouselComponent implements AfterContentInit, OnDestroy {
    private _onDestroy$: Subject<boolean> = new Subject();

    @ContentChildren(AFFSlickSlideDirective)
    $slides!: QueryList<AFFSlickSlideDirective>;
    @ViewChild('slickList', { static: true })
    $slickList!: ElementRef<HTMLElement>;
    @ViewChild('slickTrack', { static: true })
    $slickTrack!: ElementRef<HTMLElement>;

    @Input()
    set settings(settings: Partial<AFFSlickCarouselSettings>) {
        this.store.originalSettings = settings;
        this.store.next(settings);
    }

    private slideCount = 0;
    private _initialized = false;
    private _resizeObserver: ResizeObserver;
    private activeBreakpoint: number | null = null;
    private _triggerBreakpoint: number | null = null;
    private _dragging = false;
    private _dragStartClientX = 0;
    private _dragEndClientX = 0;
    private _dragEndClientY = 0;
    private _translateStartX = 0;

    targetBreakpoint: number | null = null;
    triggerBreakpoint: number | null = null;
    _options: AFFSlickCarouselSettings = <AFFSlickCarouselSettings>{};

    currentSlide$: BehaviorSubject<number> = new BehaviorSubject(0);
    slideWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    transition$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    resize$: ReplaySubject<DOMRectReadOnly> = new ReplaySubject<DOMRectReadOnly>(1);

    slickListWidth$: Observable<number> = this.resize$.pipe(map(getSlickListWidth));

    calculateTranslate$: Observable<number> = combineLatest([
        this.slideWidth$,
        this.currentSlide$,
        this.store.translateTrackParams$,
    ]).pipe(
        map(([slideWidth, currentSlide, translateParams]: [number, number, TranslateTrackParams]) => {
            const translateWidth = translateParams.slidesToScroll * slideWidth;
            return -(translateWidth * currentSlide);
        })
    );

    translateSlickTrackValue$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    translateSlickTrack$: Observable<string> = this.translateSlickTrackValue$.pipe(
        map((translateNumber: number) => {
            return `translate3d(${translateNumber}px, 0px, 0px)`;
        })
    );

    $dots: ReplaySubject<number[]> = new ReplaySubject(1);

    get prevDisabled() {
        return this.store.infinite ? false : this.currentSlide$.value <= 0;
    }

    get nextDisabled() {
        return this.store.infinite ? false : this.currentSlide$.value >= this.slideCount;
    }

    constructor(private el: ElementRef, public store: SlickCarouselStore, private cd: ChangeDetectorRef) {
        this._resizeObserver = new ResizeObserver((entries, observer) => {
            this.resize$.next(entries[0].contentRect);
        });

        this._resizeObserver.observe(this.el.nativeElement);

        this.calculateTranslate$.pipe(takeUntil(this._onDestroy$)).subscribe((value: number) => {
            this.translateSlickTrackValue$.next(value);
        });
    }

    ngAfterContentInit() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _ = this;

        if (!this._initialized && this.$slides?.length) {
            this._initialized = true;

            combineLatest([this.resize$, this.store.state$])
                .pipe(takeUntil(this._onDestroy$))
                .subscribe(([resize, state]: [DOMRectReadOnly, AFFSlickCarouselSettings]) => {
                    _.setPosition(resize, state);
                    const dots = getDotsArray(this.$slides.length, state);
                    this.slideCount = dots.length - 1;
                    this.$dots.next(dots);
                    this.transition$.next(transition(state.speed));
                    this.checkResponsive(resize, state, registerBreakpoints(state));
                });
        }
    }

    ngOnDestroy() {
        this._onDestroy$.next(true);
        this.store.destroy();
    }

    previousHandler() {
        if (this.currentSlide$.value > 0) {
            this.currentSlide$.next(this.currentSlide$.value - 1);
        } else if (this.store.infinite) {
            this.currentSlide$.next(this.slideCount);
        }
    }

    nextHandler() {
        if (this.currentSlide$.value < this.slideCount) {
            this.currentSlide$.next(this.currentSlide$.value + 1);
        } else if (this.store.infinite) {
            this.currentSlide$.next(0);
        }
    }

    setPosition(resize: DOMRectReadOnly, options: AFFSlickCarouselSettings) {
        this.setDimensions(resize, options);
    }

    setDimensions(resize: DOMRectReadOnly, options: AFFSlickCarouselSettings) {

        /**
         * @description Commented out code for future purpose.
        if (options.vertical === false) {
            if (options.centerMode === true) {
                // Add center padding to slideList element
            }
        } else {
            // Set height to slideList element
            if (options.centerMode === true) {
                // Add center padding to slideList element
            }
        }
        */

        /**
         * @description Commented out code for future purpose.
        if (options.vertical === false && options.variableWidth === false) {
            const slideWidth = Math.ceil(resize.width / options.slidesToShow);
        } else if (options.variableWidth === true) {
            // console.log('options.variableWidth', options.variableWidth);
        } else {
            // console.log('else');
        }
        */

        if (!options.variableWidth) {
            const slideWidth = getSlideWidth(resize, options);
            this.slideWidth$.next(slideWidth);
            this.$slides.forEach((slide: AFFSlickSlideDirective) => {
                slide.width = slideWidth;
            });
        }
    }

    /**
     *
     * @param resize
     * @param options
     * @param r - RegisterBreakpoints
     */
    checkResponsive(resize: DOMRectReadOnly, options: AFFSlickCarouselSettings, r: RegisterBreakpoints) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _ = this;
        const [sliderWidth, windowWidth] = [resize.width, window.innerWidth];

        this._options = <AFFSlickCarouselSettings>{};
        this.targetBreakpoint = null;
        this.triggerBreakpoint = null;

        const respondToWidth = this.getRespondToWidth(options, windowWidth, sliderWidth);

        if (options?.responsive?.length && options?.responsive !== null) {
            this.targetBreakpoint = this.getTargetBreakpoint(r, respondToWidth);

            if (this.targetBreakpoint !== null) {
                if (_.activeBreakpoint !== null) {
                    if (this.targetBreakpoint !== _.activeBreakpoint) {
                        _.activeBreakpoint = this.targetBreakpoint;
                        this._options = {
                            ..._.store.originalSettings,
                            ...r.breakpointSettings[this.targetBreakpoint],
                        };
                        this.triggerBreakpoint = this.targetBreakpoint;
                    }
                } else {
                    _.activeBreakpoint = this.targetBreakpoint;
                    this._options = {
                        ..._.store.originalSettings,
                        ...r.breakpointSettings[this.targetBreakpoint],
                    };
                    this.currentSlide$.next(options.initialSlide);
                    this.triggerBreakpoint = this.targetBreakpoint;
                }
            } else {
                this.handleNullTargetBreakpoint(options);
            }

            this.updateSettings(this._options, this.triggerBreakpoint);
        }
    }

    getRespondToWidth(options: AFFSlickCarouselSettings, windowWidth: any, sliderWidth: any): any {
        let respondToWidth = 0;
        if (options.respondTo === 'window') {
            respondToWidth = windowWidth;
        } else if (options.respondTo === 'slider') {
            respondToWidth = sliderWidth;
        } else if (options.respondTo === 'min') {
            respondToWidth = Math.min(windowWidth, sliderWidth);
        }

        return respondToWidth;
    }

    getTargetBreakpoint(r: any, respondToWidth: any): any {
        let breakpoint, targetBreakpoint = null;
        for (breakpoint in r.breakpoints) {
            // eslint-disable-next-line no-prototype-builtins
            if (r.breakpoints.hasOwnProperty(breakpoint)) {
                if (this.store.originalSettings.mobileFirst === false) {
                    if (respondToWidth < r.breakpoints[breakpoint]) {
                        targetBreakpoint = r.breakpoints[breakpoint];
                    }
                } else {
                    targetBreakpoint = (respondToWidth > r.breakpoints[breakpoint]) ? r.breakpoints[breakpoint] : null;
                }
            }
        }
        return targetBreakpoint;
    }

    updateSettings(_options: AFFSlickCarouselSettings, triggerBreakpoint: number | null) {
        if (triggerBreakpoint) {
            this.store.updateSettings.next({
                breakpoint: triggerBreakpoint,
                settings: _options,
            });
        }
    }

    handleNullTargetBreakpoint(options: AFFSlickCarouselSettings) {
        if (this.activeBreakpoint !== null) {
            this.activeBreakpoint = null;
            this._options = this.store.originalSettings;
            this.currentSlide$.next(options.initialSlide);
            this.triggerBreakpoint = this.targetBreakpoint;
            this.store.updateSettings.next({
                breakpoint: this.triggerBreakpoint,
                settings: this._options,
            });
        }
    }

    changeSlide(slideNumber: number) {
        this.currentSlide$.next(slideNumber);
        this.cd.detectChanges();
    }

    dragStart(event: MouseEvent) {
        if (this.store.draggable) {
            this.onDragStart(event);
        }
    }

    drag(event: MouseEvent) {
        if (this.store.draggable) {
            this.onDrag(event);
        }
    }

    dragEnd(event: MouseEvent) {
        if (!this.store.draggable) {
            return;
        }
        if (!this._dragging) {
            return;
        }
        this.onDragEnd(event);
    }

    touchStart(event: TouchEvent): void {
        const clientX = event.touches[0].clientX;
        const clientY = event.touches[0].clientY;
        this.dragStart(<MouseEvent>{ clientX, clientY });
    }

    touchMove(event: TouchEvent): void {
        const clientX = event.touches[0].clientX;
        const clientY = event.touches[0].clientY;
        this.drag(<MouseEvent>{ clientX, clientY });
    }

    touchEnd(event: TouchEvent): void {
        this.dragEnd(<MouseEvent>{
            clientX: this._dragEndClientX,
            clientY: this._dragEndClientY,
        });
    }

    onDragStart(event: MouseEvent) {
        this._dragging = true;
        this._translateStartX = this.translateSlickTrackValue$.value;
        this._dragStartClientX = event.clientX;
        this.transition$.next('');
    }

    onDrag(event: MouseEvent) {
        if (this._dragging) {
            this._dragEndClientX = event.clientX;
            this._dragEndClientY = event.clientY;
            this.translateSlickTrackValue$.next(this.calculateDragXDistance(event));
        }
    }

    onDragEnd(event: MouseEvent) {
        if (!this._dragging) {
            return;
        }
        const translateEndX: number = this.calculateDragXDistance(event);
        this._dragging = false;
        this.transition$.next(transition(this.store.speed));

        combineLatest([this.slideWidth$, this.store.translateTrackParams$, this.slickListWidth$])
            .pipe(take(1))
            .subscribe(([slideWidth, translateParams, slickListWidth]: [number, TranslateTrackParams, number]) => {
                const minSwipe: number = slickListWidth / translateParams.touchThreshold;

                if (Math.abs(event.clientX - this._dragStartClientX) >= minSwipe) {
                    const tsds = getAllSlidesTranslateDistance(this.slideCount + 1, slideWidth, translateParams);

                    let nextSlide = 0;
                    if (this._translateStartX < translateEndX) {
                        nextSlide = getNextDraggedSlide(translateEndX, tsds, 'lower');
                    } else {
                        nextSlide = getNextDraggedSlide(translateEndX, tsds, 'upper');
                    }
                    this.currentSlide$.next(nextSlide);
                } else {
                    this.currentSlide$.next(this.currentSlide$.value);
                }
            });
    }

    private calculateDragXDistance(event: MouseEvent) {
        return this._translateStartX + (event.clientX - this._dragStartClientX);
    }
}
