<ng-container *ngIf="merchantSearch">
    <janet-merchant-search-hero-banner [moduleData]="merchantSearch"></janet-merchant-search-hero-banner>
    <janet-merchant-search [merchantSearchStoreList]="merchantSearchStoreList"
        [merchantSearchNoOfRecords]="merchantSearchNoOfRecords" [merchantSearchRadius]="merchantSearchRadius"
        [categoriesData]="categoriesData" [contentModules]="contentModules" *ngIf="_browser"></janet-merchant-search>
</ng-container>

<ng-container *ngIf="shopOurPartners">
    <janet-shop-our-partners-container [locationsPageDetails]="locationsPageDetails" [locationsData]="moduleData"
        [dealerInfoData]="locationsDealerInfoData" [categoriesData]="categoriesData"
        [locationsLanguageLabelsObj]="locationsLanguageLabelsObj"></janet-shop-our-partners-container>
</ng-container>

<ng-container *ngFor="
        let module of contentModules;
        let moduleIndex = index;
        let moduleCount = count;
        let isFirstModule = first;
        let isLastModule = last
    ">
    <ng-container [ngSwitch]="module.fieldGroupName">
        <janet-hero-component class="module-spacing" *ngSwitchCase="heroGroupName" [moduleData]="module" [ngClass]="{
                'mt-3 mt-xxl-10': merchantSearch && isFirstModule,
                'mb-3 mb-xxl-10': merchantSearch && isLastModule
            }"></janet-hero-component>
        <janet-banner class="module-spacing" *ngSwitchCase="bannerGroupName" [moduleData]="module"
            [categoriesData]="categoriesData" [ngClass]="{
                'mt-3 mt-xxl-10': merchantSearch && isFirstModule,
                'mb-3 mb-xxl-10': merchantSearch && isLastModule
            }"></janet-banner>

        <shop-by-category class="module-spacing" *ngSwitchCase="shopCategoryGroupName" [moduleData]="module" [ngClass]="{
                'mt-3 mt-xxl-10': merchantSearch && isFirstModule,
                'mb-3 mb-xxl-10': merchantSearch && isLastModule
            }"></shop-by-category>
        <janet-full-width-content class="module-spacing" *ngSwitchCase="contentGroupName" [moduleData]="module"
            [ngClass]="{
                'mt-3 mt-xxl-10': merchantSearch && isFirstModule,
                'mb-3 mb-xxl-10': merchantSearch && isLastModule
            }"></janet-full-width-content>
        <janet-icons class="module-spacing" *ngSwitchCase="iconsGroupName" [iconsModuleData]="module" [ngClass]="{
                'mt-3 mt-xxl-10': merchantSearch && isFirstModule,
                'mb-3 mb-xxl-10': merchantSearch && isLastModule
            }"></janet-icons>
        <janet-merchant-form class="module-spacing" *ngSwitchCase="merchantSignupForm" [merchantSignUpFormData]="module"
            [ngClass]="{
                'mt-3 mt-xxl-10': merchantSearch && isFirstModule,
                'mb-3 mb-xxl-10': merchantSearch && isLastModule
            }">
        </janet-merchant-form>
        <janet-website-form class="module-spacing" *ngSwitchCase="websiteForm" [websiteFormData]="module" [ngClass]="{
                'mt-3 mt-xxl-10': merchantSearch && isFirstModule,
                'mb-3 mb-xxl-10': merchantSearch && isLastModule
            }">
        </janet-website-form>
        <janet-dsar-form *ngSwitchCase="dsarForm" [dsarFormData]="module" [ngClass]="{
                'mt-3 mt-xxl-10': merchantSearch && isFirstModule,
                'mb-3 mb-xxl-10': merchantSearch && isLastModule
            }">
        </janet-dsar-form>
        <janet-navite-forms class="module-spacing" *ngSwitchCase="nativeFormGroupName" [moduleData]="module"
            [moduleIndex]="moduleIndex" [ngClass]="{
                'mt-3 mt-xxl-10': merchantSearch && isFirstModule,
                'mb-3 mb-xxl-10': merchantSearch && isLastModule
            }"></janet-navite-forms>
        <ng-container *ngSwitchCase="testimonialGroupName">
            <ua2-aff-carousel class="module-spacing carousel-wrapper" [showNavigation]="false"
                [showIndicators]="(module?.testimonials).length > 1"
                [carouselContainer]="'carouselContainer_' + moduleIndex" [ngClass]="{
                    'mt-3 mt-xxl-10': merchantSearch && isFirstModule,
                    'mb-3 mb-xxl-10': merchantSearch && isLastModule
                }">
                <ng-container *ngFor="
                        let testimonial of module.testimonials;
                        let first = first;
                        let index = index;
                        let count = count
                    ">
                    <div class="carousel-item testimonials-wrapper" [class.active]="first" affCarouselSlide [id]="index"
                        data-bs-interval="8000">
                        <div class="row container mx-auto p-0" [ngClass]="{ 'mb-6': count > 1 }">
                            <div class="px-3 px-sm-3 px-md-7 px-xxl-33">
                                <aff-card [hoverable]="false" [borderless]="true" [shadow]="false"
                                    class="testimonials-card mb-2 px-3 py-8 p-md-8 p-xxl-10">
                                    <aff-card-body class="p-0">
                                        <janet-testimonial [testimonial]="testimonial"
                                            [showRating]="true"></janet-testimonial>
                                    </aff-card-body>
                                </aff-card>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ua2-aff-carousel>
        </ng-container>
    </ng-container>
</ng-container>

<!-- Footer -->
<footer class="mt-3 w-100" [ngStyle]="{ visibility: _browser ? 'visible' : 'hidden' }">
    <janet-social-media *ngIf="(!dealerInfo || dealerInfo?.bBrandedHeaderFooter)"></janet-social-media>
    <janet-footer-menu-container
        *ngIf="(!dealerInfo || dealerInfo?.bBrandedHeaderFooter)"></janet-footer-menu-container>
    <janet-customer-service-information [locationsDealerInfoData]="locationsDealerInfoData"
        [locationsBaseModuleData]="locationsBaseModuleData"></janet-customer-service-information>
    <janet-copy-rights *ngIf="(!dealerInfo || dealerInfo?.bBrandedHeaderFooter)"></janet-copy-rights>
</footer>
<!-- Footer -->