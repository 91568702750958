<div [ngClass]="[checkBoxContainerDefaultClass, checkBoxContainerCustomClass]">
    @if(useNgModel){
    <input [ngClass]="[checkBoxDefaultClass, checkBoxCustomClass]" type="checkbox" [id]="checkBoxId"
        [(ngModel)]="isChecked" (change)="onChange()" [disabled]="isDisabled" />
    }@else{
    <input [ngClass]="[checkBoxDefaultClass, checkBoxCustomClass]" type="checkbox" [id]="checkBoxId"
        [formControl]="control" (change)="onFormControlChange()" [disabled]="isDisabled" />
    }

    <label [ngClass]="[checkBoxLabelDefaultClass, checkBoxLabelCustomClass]" [for]="checkBoxId">
        {{ checkBoxLabel }}
    </label>
</div>