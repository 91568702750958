import { createAction, props } from '@ngrx/store';
import { NuxtState } from './nuxt.model';

/**
 * Do not use this action,
 * for internal use only
 */
export const _nuxtEmitAction = createAction('[Nuxt/API] Nuxt Emit', props<{ event: NuxtState }>());

/**
 * use as:
 * this.store.dispatch(nuxtEmit('eventName', value));
 *
 * Use this to emit a "nuxt" event similar to
 * $nuxt.$emit('event/name', value);
 *
 * @param eventName
 * @param value
 */
export const nuxtEmit = (eventName: string, value: any) => {
    return _nuxtEmitAction({
        event: {
            [eventName]: value,
        },
    });
};

export const _nuxtRemove = createAction('[Nuxt/API] Nuxt Remove', props<{ event: string }>());

export const nuxtRemove = (eventName: string) => {
    return _nuxtRemove({
        event: eventName,
    });
};
