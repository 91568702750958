import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GoogleApiService } from 'apps/corporate/corporate-website/src/app/services/google-api-service.service';
import { zsr } from 'apps/corporate/corporate-website/src/app/validators/zip-search-results.validator';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'ua2-aff-search',
    templateUrl: './aff-search.component.html',
})
export class AffSearchComponent implements OnInit {
    @Input() isShopCTA = false;
    @Input() buttonText: string | undefined;
    @Input() btnTarget? = '_self';
    @Input() affBtnId = '';
    @Input() enableBtnDarkTheme: string;
    @Input() searchType?: string = '';
    @Input() findStoreURL: string;
    @Input() simpleCtaUrl? = '';
    @Input() defaultCategory? = { label: '', value: '' };
    @Input() errorMessageClass? = '';
    affSearchForm: FormGroup;
    isButtonAnchor = false;
    buttonCustomClass =
        'px-3 py-25 p-xxl-3 d-inline-flex flex-row justify-content-center align-items-center aff-button-state aff-button form-button-properties';
    invalidChars = ['-', '+', 'e'];
    zipErrorMessages = {
        required: 'Provide a 5 digit zip code',
        minlength: 'Provide a 5 digit zip code',
        zsr: 'Provide a zip code that is in the US or Puerto Rico',
    };
    storenameErrorMessages = {
        required: 'Store Name is required',
        minlength: 'The Store Name atleast min of 3 character',
    };
    categoryErrorMessages = {
        required: 'Provide a category',
    };
    options = [];
    showCategoryErrorMessage = false;
    constructor(
        public formBuilder: FormBuilder,
        @Inject(DOCUMENT) private document: Document,
        private googleApiService: GoogleApiService,
        private http: HttpClient,
        public route: ActivatedRoute
    ) {}

    @Input()
    set selectOptions(data: any) {
        this.options = data;
    }

    ngOnInit(): void {
        this.route?.queryParams?.subscribe((params) => {
            this.route.url.subscribe((segments) => {
                const isShopPresent = segments.some((segment) => segment.path.toLowerCase() === 'shop');

                if (isShopPresent) {
                    if (
                        params?.filter &&
                        params?.filter?.toString()?.trim()?.length > 0 &&
                        this.options &&
                        this.options?.length > 0
                    ) {
                        const paramCategory: any = this.options?.find((category: any) => {
                            return (
                                category?.value?.toLowerCase() ===
                                params?.filter?.toString()?.trim()?.toLowerCase()
                            );
                        });
                        if (paramCategory) {
                            this.defaultCategory = paramCategory;
                        }
                    }
                }
            });
            let zip;
            if (this.document.defaultView?.sessionStorage) {
                zip = this.document.defaultView.sessionStorage.getItem('zipCode');
            }
            this.buildSearchForm(zip ?? '');
        });
    }

    get onlyZipClass() {
        let classes = '';
        classes += 'aff-search-input-zip';
        classes += !this.buttonText ? ' mb-0' : '';
        return classes;
    }

    get catZipButtonTextClass() {
        return !this.buttonText ? ' mb-0' : '';
    }

    get zipButtonTextClass() {
        return !this.buttonText ? 'aff-search-input-mb-0-md-xxl' : '';
    }

    handleEvent(object: any): void {
        if (object.event === 'input') {
            this.onInputChange(object.data);
        }
        if (object.event === 'keydown') {
            this.onKeyDown(object.data);
        }
    }

    onInputChange(event: any): void {
        const value = event.target.value.replace(/\D/g, '');
        event.target.value = value.slice(0, 5);
    }

    onKeyDown(event: KeyboardEvent): void {
        if (this.invalidChars.includes(event.key)) {
            event.preventDefault();
        }
    }

    onSubmit() {
        const zip = this.affSearchForm.controls['zipcode'];
        if (!this.affSearchForm.valid || !zip.value) {
            this.showCategoryErrorMessage = this.affSearchForm.controls['merchantcategory']?.value === null;
            if (!zip.value) {
                zip.setErrors({ required: true });
            }
        } else {
            const getSearchVal = this.affSearchForm.value;

            if (this.searchType === 'store_name') {
                this.document.location.href = `${this.findStoreURL}?search=${getSearchVal.storename}`;
            } else {
                const category =
                    getSearchVal.merchantcategory === this.defaultCategory?.label
                        ? this.defaultCategory?.value
                        : getSearchVal.merchantcategory;
                this.googleApiService.getGoogleApiResponse(
                    getSearchVal.zipcode,
                    this.searchType === 'cat_zip' ? category : ''
                );
            }
        }
    }

    buildSearchForm(zip: string) {
        const zipcode = { zipcode: [zip, [Validators.minLength(5)], [zsr(this.http)]] };
        const merchantcategory = { merchantcategory: [this.defaultCategory?.label, Validators.required] };
        const storename = { storename: ['', [Validators.minLength(3)]] };

        let formGroupObj = {};

        if (this.searchType === 'cat_zip') {
            formGroupObj = { ...zipcode, ...merchantcategory };
        } else if (this.searchType === 'store_name') {
            formGroupObj = { ...storename };
        } else {
            formGroupObj = { ...zipcode };
        }

        this.affSearchForm = this.formBuilder.group(formGroupObj);
    }
}
