<div [ngClass]="shopOurPartnersBodyMainContainer">
    <!--Commenting below code for future reference -->
    <!--<section [ngClass]="shopOurPartnersBannerContainer" class="d-flex align-items-center">
        <h3 class="m-0 px-0 mb-3 mb-md-0 shop-our-partners-stores-near-title col-12 col-md-6">
            Stores Near <span class="shop-our-partners-stores-near-zip-code"
                *ngIf="searchParamValue && isNumeric(searchParamValue)">{{searchParamValue}}</span>
        </h3>
        <ng-container *ngIf="_dealerInfoData && _dealerInfoData?.bBranded && _locationsData">
            <janet-shop-our-partners-dtc-banner class="col-12 col-md-6 px-0 ps-md-0 ps-xxl-41"
                [dtcBannerMapSection]="true" [locationsData]="_locationsData"></janet-shop-our-partners-dtc-banner>
        </ng-container>
    </section>-->

    <section
        class="shop-our-partners-body px-3 px-md-0 d-flex flex-column flex-md-row justify-content-md-between align-items-start align-items-md-center ps-3 ps-sm-3"
    >
        <h3
            [ngClass]="{
                'visible d-block': isSOPBodyContentDisplay,
                'invisible d-none d-md-block': !isSOPBodyContentDisplay
            }"
            class="m-0 mb-3 mb-md-0 shop-our-partners-stores-near-title col-12 col-md-6"
        >
            {{
                _dealerInfoData && _dealerInfoData?.bBranded
                    ? _locationsLanguageLabelsObj?.storeListLabel
                    : "Stores Near"
            }}
            <ng-container *ngIf="_dealerInfoData && _dealerInfoData?.bBranded; else nonBrandContent">
                <span
                    class="shop-our-partners-stores-near-zip-code"
                    *ngIf="
                        (searchParamValue && isNumeric(searchParamValue)) ||
                        (locationZipCode && locationZipCode?.toString()?.trim()?.length > 0)
                    "
                    >{{ searchParamValue ? searchParamValue : locationZipCode }}</span
                >
            </ng-container>
            <ng-template #nonBrandContent>
                <span
                    class="shop-our-partners-stores-near-zip-code"
                    *ngIf="
                        (searchParamValue && searchParamValue?.toString()?.trim()?.length > 0) ||
                        (locationZipCode && locationZipCode?.toString()?.trim()?.length > 0)
                    "
                    >{{ searchParamValue ? searchParamValue : locationZipCode }}</span
                >
            </ng-template>
        </h3>

        <janet-shop-our-partners-form
            class="col-12 col-md-6"
            [multiSelectOptions]="_options"
            [locationsPageDetails]="_locationsPageDetails"
            [locationsData]="_locationsData"
            [dealerInfoData]="_dealerInfoData"
            [locationsLanguageLabelsObj]="_locationsLanguageLabelsObj"
            (storeListErrMsg)="storeListErrMsg($event)"
        ></janet-shop-our-partners-form>
    </section>

    <ng-container *ngIf="isSOPBodyContentDisplay">
        <div
            *ngIf="_dealerInfoData && _dealerInfoData?.bBranded && _locationsData"
            class="shop-our-partners-body d-block d-md-none col-12 col-md-6 mt-3 px-0"
        >
            <janet-shop-our-partners-dtc-banner
                [dtcBannerMapSection]="true"
                [locationsData]="_locationsData"
                [dealerInfoData]="_dealerInfoData"
            ></janet-shop-our-partners-dtc-banner>
        </div>
        <section id="merchantShop" [ngClass]="shopOurPartnersBodyContainer">
            <div
                class="col-12 col-md-6 pe-md-3 pe-0 mobile-padding"
                [ngClass]="{ 'pointer-none': isLoaderDisplay }"
            >
                <janet-shop-our-partners-stores-list
                    class="px-0 col-12 col-md-12 col-xxl-6 d-none d-sm-none d-md-block d-lg-block"
                    [displayListSearchResults]="displayData"
                    [totalRecords]="totalRecords?.record"
                    [paginationClicked]="paginationClicked"
                    [class.invisible]="isLoaderDisplay"
                    [selectedMarkerIndex]="selectedMarkerIndex"
                    [storeListPaginationClicked]="storeListPaginationClicked"
                    [locationsData]="_locationsData"
                    [dealerInfoData]="_dealerInfoData"
                    [locationsLanguageLabelsObj]="_locationsLanguageLabelsObj"
                    [storeListErrorMessage]="_storeListErrorMessage"
                    (selectedMarkerIndexValEmitter)="onSelectedMarkerIndexValEmitted($event)"
                >
                </janet-shop-our-partners-stores-list>

                <janet-shop-our-partners-stores-list-mobile
                    [displayListSearchResults]="displayData"
                    [totalRecords]="totalRecords?.record"
                    [paginationClicked]="paginationClicked"
                    [googleMapUrl]="mapUrl"
                    [ngClass]="storeListMobileDisplayClass"
                    [storeListPaginationClicked]="storeListPaginationClicked"
                    [locationsData]="_locationsData"
                    [dealerInfoData]="_dealerInfoData"
                    [storeListErrorMessage]="_storeListErrorMessage"
                    [locationsLanguageLabelsObj]="_locationsLanguageLabelsObj"
                ></janet-shop-our-partners-stores-list-mobile>

                <ua2-stores-pagination
                    *ngIf="displayData"
                    class="ps-0"
                    [startIndex]="startIndex"
                    [endIndex]="endIndex"
                    [totalRecords]="totalRecords"
                    [paginationLabel]="
                        _dealerInfoData?.bBranded
                            ? _locationsLanguageLabelsObj?.paginationLabel
                            : 'Showing results'
                    "
                    (paginationUpdated)="handlePaginationUpdate($event)"
                    [ngClass]="paginationDisplayClass"
                ></ua2-stores-pagination>
            </div>

            <div
                class="px-0 col-12 col-md-6 col-xxl-6 d-none d-sm-none d-md-block d-lg-block overflow-hidden"
                [ngClass]="{ 'pointer-none': isLoaderDisplay }"
            >
                <ng-container *ngIf="_dealerInfoData && _dealerInfoData?.bBranded && _locationsData">
                    <janet-shop-our-partners-dtc-banner
                        class="d-block ms-xxl-8"
                        [dtcBannerMapSection]="true"
                        [locationsData]="_locationsData"
                    ></janet-shop-our-partners-dtc-banner>
                </ng-container>

                <ng-container *ngIf="displayData && display">
                    <ua2-map
                        [mapApiKey]="mapApiKey"
                        [googleMapUrl]="mapUrl"
                        [locationsData]="displayData"
                        [storeIndex]="storeIndex"
                        (selectedMarkerIndex)="onMarkerSelected($event)"
                        [clearMarker]="_clearMarker"
                        [sopLocationsData]="_locationsData"
                        [dealerInfoData]="_dealerInfoData"
                        [applyBtnLabel]="
                            _dealerInfoData?.bBranded
                                ? _locationsLanguageLabelsObj?.storeListApplyButtonLabel
                                : 'Apply'
                        "
                        [getDirectionBtnLabel]="
                            _dealerInfoData?.bBranded
                                ? _locationsLanguageLabelsObj?.getDirectionsLabel
                                : 'Get Directions'
                        "
                    ></ua2-map>
                </ng-container>
            </div>

            <ng-container *ngIf="getScreenWidth >= 768">
                <div
                    class="sop-storelist-overlay"
                    [class.visible-layout]="isLoaderDisplay"
                    [class.in-visible-layout]="!isLoaderDisplay"
                ></div>
                <div
                    class="sop-storelist-loader-gif d-flex justify-content-center align-items-center"
                    [class.gif-visible]="isLoaderDisplay"
                    [class.gif-in-visible]="!isLoaderDisplay"
                    [class.z-n1]="!isLoaderDisplay"
                    [class.z-1]="isLoaderDisplay"
                >
                    <img src="assets/gif/loader.gif" alt="Loader GIF" />
                </div>
            </ng-container>
            <div
                class="sop-storelist-loader-gif-mobile d-flex justify-content-center align-items-center"
                *ngIf="isLoaderDisplay && getScreenWidth < 768"
            >
                <img src="assets/gif/loader.gif" alt="Loader GIF" />
            </div>
        </section>
    </ng-container>
</div>
