import { trigger, transition, style, animate, AnimationTriggerMetadata } from '@angular/animations';

/**
 * Item starts invisible and fades in from the bottom up
 *
 * @export
 * @param {number} [startPosition=10]
 * @param {number} [time=150]
 * @return {*}  {AnimationTriggerMetadata}
 */
export function fadeSlideInAnimation(startPosition = 10, time = 150): AnimationTriggerMetadata {
    return trigger('fadeSlideIn', [
        transition(':enter', [
            style({ opacity: 0, transform: `translateY(${startPosition}px)` }),
            animate(`${time}ms`, style({ opacity: 1, transform: 'translateY(0)' })),
        ]),
    ]);
}
