import { createReducer, on } from '@ngrx/store';
import * as NuxtActions from './nuxt.actions';
import { NuxtState } from './nuxt.model';

export const nuxtsFeatureKey = 'nuxt';

export const initialNuxtState: NuxtState = {};

export const nuxtReducer = createReducer(
    initialNuxtState,
    on(NuxtActions._nuxtEmitAction, (state, { event }) => ({
        ...state,
        ...event,
    })),
    on(NuxtActions._nuxtRemove, (state, { event }) => ({
        ...state,
        [event]: undefined,
    }))
);
