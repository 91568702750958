<ng-container *ngIf="showRating">
    <div
        class="d-flex mb-3"
        [ngClass]="ratingContainerClasses()"
        *ngIf="testimonial?.rating && testimonial?.rating > 0"
    >
        <ua2-rating [max]="5" [rate]="testimonial.rating"></ua2-rating>
    </div>
    <figure [ngClass]="textAlignmentClasses()" class="m-0">
        <blockquote class="blockquote m-0">
            <h4 class="text-header text-center text-break">
                <em>“{{ testimonial.testimonialText }}”</em>
            </h4>
        </blockquote>
        <p class="m-0 mt-3" *ngIf="testimonial?.testimonialCustomerName">
            <span class="text-sub-header text-center text-break">
                - {{ testimonial.testimonialCustomerName }}
            </span>
        </p>
    </figure>
</ng-container>
