import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class SanitizeUrlService {
    constructor(private sanitizer: DomSanitizer) { }
    sanitize(url: string): SafeUrl {
        // Ensure that disabling Angular built-in sanitization is safe here.
        return this.sanitizer.bypassSecurityTrustUrl(url);  //NOSONAR
    }
}
