import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LanguageDataService {
    private selectedLanguageData = new BehaviorSubject<any>(null);
    selectedLanguageData$ = this.selectedLanguageData.asObservable();

    setSelectedLanguageData(data: any) {
        this.selectedLanguageData.next(data);
    }
}
