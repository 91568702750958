import { HttpClientModule } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, InjectionToken, NgModule, TransferState, makeStateKey } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InMemoryCache } from '@apollo/client/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { MenuDataService } from './services/menu-data.service';
import { RoutesService } from './services/routes.service';

import { LaunchDarklyConfig, LaunchDarklyConfigToken, WINDOW } from '@janet/shared/domain';
import { FeatureFlagToggleModule } from '@janet/shared/ui-common';
import { localFeatureFlags } from './local-feature-flags';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

export function redirectAppInitializer(routesService: RoutesService) {
    return () => routesService.appInitialize();
}

export function menuData(menuDataService: MenuDataService) {
    return () => menuDataService.getData();
}

const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo.state');
export const PAGE_DATA_STATE_KEY = makeStateKey<any>('page.state');
export const BRAND_IMAGE_STATE_KEY = makeStateKey<any>('page.brandImage');
export const MENU_STATE_KEY = makeStateKey<any>('menu.state');

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        SharedModule,
        ApolloModule,
        BrowserModule,
        BrowserAnimationsModule,
        GoogleTagManagerModule.forRoot({
            id: environment.GTM_ID,
        }),
        RouterModule.forRoot([]),
        FeatureFlagToggleModule,
    ],
    providers: [
        Title,
        { provide: APP_INITIALIZER, useFactory: redirectAppInitializer, deps: [RoutesService], multi: true },
        { provide: APP_ID, useValue: 'serverApp' },
        { provide: 'googleTagManagerId', useValue: environment.GTM_ID },
        { provide: APP_INITIALIZER, useFactory: menuData, deps: [MenuDataService], multi: true },
        { provide: APOLLO_CACHE, useValue: new InMemoryCache() },
        {
            provide: APOLLO_OPTIONS,
            useFactory(httpLink: HttpLink, cache: InMemoryCache, transferState: TransferState) {
                const isBrowser = transferState.hasKey<any>(STATE_KEY);

                if (isBrowser) {
                    const state = transferState.get<any>(STATE_KEY, null);
                    cache.restore(state);
                } else {
                    transferState.onSerialize(STATE_KEY, () => cache.extract());
                    cache.reset();
                }

                return {
                    link: httpLink.create({
                        uri: environment.graphQLURL,
                        includeExtensions: true,
                        extractFiles: (body): any => extractFiles(body, isExtractableFile),
                    }),
                    cache,
                    ssrMode: true,
                    ssrForceFetchDelay: 200,
                };
            },
            deps: [HttpLink, APOLLO_CACHE, TransferState],
        },
        {
            provide: LaunchDarklyConfigToken,
            useValue: <LaunchDarklyConfig>{
                clientID: environment.launchDarklyClientID,
                localFeatureFlags,
            },
        },
        { provide: WINDOW, useFactory: () => window },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
