import { createReducer, on } from '@ngrx/store';
import * as OFeatureFlagActions from './o-feature-flag.actions';
import { OFeatureFlagState } from './o-feature-flag.model';

export const oFeatureFlagsFeatureKey = 'oFeatureFlag';

export const initialOFeatureFlagState: OFeatureFlagState = {};

export const oFeatureFlagReducer = createReducer(
    initialOFeatureFlagState,
    on(OFeatureFlagActions.setFeatureList, (state, { payload }) => ({
        ...state,
        ...payload,
    }))
);
