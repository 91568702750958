@if(_siteKey && (showCaptcha$ | async)){ @if(!isCaptchaInvisible){
<ngx-recaptcha2 #captchaElem [siteKey]="_siteKey" (ready)="handleReady()" (load)="handleLoad()"
    (success)="handleSuccess($event)" (error)="handleError()" [useGlobalDomain]="true" [id]="captchaFieldId"
    [formControl]="control">
</ngx-recaptcha2>
}@else {
<ngx-invisible-recaptcha #invisiblecaptchaElem [siteKey]="_siteKey" (ready)="handleReady()" (load)="handleLoad()"
    (success)="handleSuccess($event)" (error)="handleError()" [useGlobalDomain]="true" [id]="captchaFieldId"
    [formControl]="control">
</ngx-invisible-recaptcha>
}

<ng-container *ngIf="control && control?.invalid && _showErrorMessage && !isCaptchaInvisible">
    <p *ngFor="let err of control?.errors | keyvalue" [ngClass]="[errorMessageClass]">
        {{ errorMessages[err.key] }}
    </p>
</ng-container>
}