import { map } from 'rxjs/operators';
import { Inject, Injectable, Injector, PLATFORM_ID, TransferState } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { PageDataService } from '../../services/page-data.service';
import { DOCUMENT, isPlatformServer, isPlatformBrowser } from '@angular/common';
import { SOP_BASE_URL_STATE_KEY } from '../../../constants/state-keys';

@Injectable({
    providedIn: 'root',
})
export class PageDataResolver {
    baseUrl: any = '';

    constructor(
        private _pageDataService: PageDataService,
        @Inject(DOCUMENT) private document: Document,
        private injector: Injector,
        @Inject(PLATFORM_ID) private platformId: any,
        private _transferState: TransferState
    ) {
        this.baseUrl = '';
        if (isPlatformServer(this.platformId)) {
            try {
                const host = this.injector?.get('host', null); //NOSONAR
                if (host) {
                    this.baseUrl = host;

                    this._transferState?.set(SOP_BASE_URL_STATE_KEY, this.baseUrl);
                } else {
                    console.log('Host provider not available during server-side rendering.');
                }
            } catch (error) {
                console.log('Error retrieving host from injector');
            }
        }

        if (isPlatformBrowser(this.platformId)) {
            const vanityUrls = [
                {
                    url: 'https://gardnerwhiteqa-apply.com',
                    pdno: 1199,
                },
                {
                    url: 'https://afreightqa-apply.com',
                    pdno: 1300,
                },
            ];

            const vanityURLData = vanityUrls.find((urlData) => urlData.url === this.baseUrl);

            if (vanityURLData) {
                const fullURL = `${vanityURLData.url}/locations/d/${vanityURLData.pdno}`;

                if (typeof window !== 'undefined' && this.baseUrl !== fullURL) {
                    window.location.href = fullURL;
                }
            }
            const baseUrl = this._transferState?.get(SOP_BASE_URL_STATE_KEY, null);
            console.log('sop page base url browser (PageDataResolver) --> ' + baseUrl);
        }
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let baseUrl = '';
        if (this.baseUrl) {
            baseUrl = this.baseUrl;
        }

        let slug = state.url.replace('/', '');
        slug = slug.replace(/\.$/, '');
        if (slug === '') {
            slug = '/';
        }

        //Recdirect for external URL's apart from angular routes
        if (slug.includes('www.')) {
            const externalURL = slug.replace(/www./g, '');
            window.location.href = `https://${externalURL.replace(/\/$/, '')}`;
            return EMPTY;
        }

        return this._pageDataService.getPageData(slug, baseUrl).pipe(map((response: any) => response?.data));
    }
}
