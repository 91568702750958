import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@janet/corporate/ui-corporate';
import { Subscription } from 'rxjs';
import { GoogleApiService } from '../../services/google-api-service.service';
import { zsr } from '../../validators/zip-search-results.validator';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'janet-zip-search-form',
    templateUrl: './zip-search-form.component.html',
})
export class ZipSearchFormComponent implements OnInit {
    zipSearchForm: FormGroup;
    isButtonAnchor = false;
    buttonCustomClass =
        'px-3 py-25 p-xxl-3 d-inline-flex flex-row justify-content-center align-items-center aff-button-state aff-button form-button-properties';
    invalidChars = ['-', '+', 'e'];
    errorMessages = {
        required: 'zip code is required',
        minlength: 'The zip code must be 5 digits',
        zsr: 'Provide a zip code that is in the US or Puerto Rico',
    };

    zipCode: string | number | null = '';
    subscription: Subscription;
    selectedCategoryData: any;

    constructor(
        public formBuilder: FormBuilder,
        private modalService: ModalService,
        private googleApiService: GoogleApiService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {
        this.subscription = this.modalService.action$.subscribe((affModalData: any) => {
            this.selectedCategoryData = affModalData.data;
        });

        if ('zipCode' in sessionStorage) {
            this.zipCode = sessionStorage.getItem('zipCode');
        }

        this.zipSearchForm = this.formBuilder.group({
            zipcode: [this.zipCode, [Validators.minLength(5)], [zsr(this.http)]],
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    handleEvent(object: any): void {
        if (object.event === 'input') {
            this.onInputChange(object.data);
        }
        if (object.event === 'keydown') {
            this.onKeyDown(object.data);
        }
    }

    onInputChange(event: any): void {
        const value = event.target.value.replace(/[e+-]/gi, '');
        event.target.value = value.slice(0, 5);
    }

    onKeyDown(event: KeyboardEvent): void {
        if (this.invalidChars.includes(event.key)) {
            event.preventDefault();
        }
    }

    get isdisabled() {
        const zipCode = this.zipSearchForm?.get('zipcode')?.value;
        if (zipCode.toString().trim().length >= 5) {
            return false;
        }
        return true;
    }

    onSubmit() {
        if (this.zipSearchForm.valid) {
            this.zipCode = this.zipSearchForm?.get('zipcode')?.value.slice(0, 5);
            sessionStorage.setItem('zipCode', (this.zipCode as string).trim());

            if (this.selectedCategoryData && this.selectedCategoryData.shopCategory) {
                this.googleApiService.getGoogleApiResponse(
                    this.zipCode as string,
                    this.selectedCategoryData.shopCategory.ShopCategoryAPIKey.apiKey
                );
            }
        }
    }
}
