import {
    OnInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    booleanAttribute,
    PLATFORM_ID,
    Inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { InvisibleReCaptchaComponent, ReCaptcha2Component } from 'ngx-captcha';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
    selector: 'ua2-corporate-form-recaptcha, aff-corporate-form-recaptcha',
    templateUrl: './corporate-form-recaptcha.component.html',
})
export class CorporateFormRecaptchaComponent implements OnInit {
    @Input() captchaFieldId?: string = '';
    @Input() control = new FormControl();
    @Input({ transform: booleanAttribute }) isCaptchaInvisible: boolean = false;
    @ViewChild('captchaElem', { static: false }) captchaElem!: ReCaptcha2Component;
    @ViewChild('invisiblecaptchaElem', { static: false }) invisiblecaptchaElem: InvisibleReCaptchaComponent;
    @Input() errorMessageClass?: string = 'text-start text-red m-0';
    @Input() errorMessages: any = {};
    @Output() inputChange: EventEmitter<any> = new EventEmitter();
    _siteKey = '';
    _showErrorMessage: any = true;
    recaptcha: any;
    showCaptcha$: Observable<any>;
    @Output() captchaReadyEventEmitter: EventEmitter<any> = new EventEmitter();

    constructor(private _changeDetectorRef: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId: any) { }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.showCaptcha$ = new Observable((observer: any) => {
                observer.next(true);
                this.execute();
            }).pipe(shareReplay());
        }
    }

    @Input()
    set siteKey(value: any) {
        this._siteKey = value;
    }

    @Input()
    set showErrorMessage(value: any) {
        this._showErrorMessage = value;
    }

    handleLoad(): void {
        this.emitChanges();
    }

    handleSuccess(captchaResponse: string): void {
        this.emitChanges();
    }

    handleReady(): void {
        this.emitChanges();
        this.captchaReadyEventEmitter.emit();
    }

    handleError(): void {
        this.emitChanges();
    }

    execute(): void {
        setTimeout(() => {
            this.invisiblecaptchaElem?.execute();
        }, 3 * 1000);
    }

    emitInputChange() {
        this.inputChange.emit();
    }

    detectChanges() {
        this._changeDetectorRef.detectChanges();
    }

    emitChanges() {
        this.emitInputChange();
        this.detectChanges();
    }
}
