import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ua2-aff-card-body, aff-card-body',
    templateUrl: './aff-card-body.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})
export class AffCardBodyComponent implements OnInit {
    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'card-body');
    }
}
