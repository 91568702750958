import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Inject, Injectable, Optional } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { combineLatest, fromEvent, interval, ReplaySubject } from 'rxjs';
import { filter, first, startWith, switchMap, tap } from 'rxjs/operators';
import {
    createServiceWorkerUpdateConfig,
    SERVICE_WORKER_UPDATE_CONFIG,
    ServiceWorkerUpdateConfig,
    UpdateAvailableEvent,
} from './service-worker-update.model';

@Injectable({
    providedIn: 'root',
})
export class ServiceWorkerUpdateService {
    updatesAvailable$: ReplaySubject<UpdateAvailableEvent> = new ReplaySubject<UpdateAvailableEvent>(1);
    unrecoverableUpdate$: ReplaySubject<string> = new ReplaySubject<string>(1);

    constructor(
        private appRef: ApplicationRef,
        private swUpdate: SwUpdate,
        @Inject(DOCUMENT) private document: Document,
        @Optional() @Inject(SERVICE_WORKER_UPDATE_CONFIG) private config: Partial<ServiceWorkerUpdateConfig>
    ) {
        this.config = {
            ...createServiceWorkerUpdateConfig(this.config),
        };

        swUpdate.versionUpdates
            .pipe(
                /**
                 * Log the update event.
                 * Of course, you want see this in the console unless you configure
                 * ServiceWorkerUpdateConfig promptUserUpdateAvailable to true
                 */
                tap((evt: VersionEvent) => {
                    switch (evt.type) {
                        case 'VERSION_DETECTED':
                            console.log(`Downloading new app version: ${evt.version.hash}`);
                            break;
                        case 'VERSION_READY':
                            console.log(`Current app version: ${evt.currentVersion.hash}`);
                            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
                            break;
                        case 'VERSION_INSTALLATION_FAILED':
                            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                            break;
                    }
                }),
                filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
            )
            .subscribe((evt) => {
                if (this.config.promptUserUpdateAvailable) {
                    this.updatesAvailable$.next(<UpdateAvailableEvent>{
                        type: 'UPDATE_AVAILABLE',
                        current: evt.currentVersion,
                        available: evt.latestVersion,
                    });
                } else {
                    // Reload the page to update to the latest version.
                    //
                    document.location.reload();
                }
            });
    }

    checkOnPageLoad() {

      // check if localhost
      if (this.document.location.hostname === 'localhost') {
        return;
      }

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;

        // Allow the app to stabilize first, before starting
        const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));

        appIsStable$.subscribe(async () => {
            this.checkForUpdate().then(() => {
                that.startInterval.call(that);
            });
        });
    }

    private startInterval() {
        const touchEnd$ = fromEvent(this.document, 'touchend').pipe(startWith('fake touchend'));
        const mouseUp$ = fromEvent(this.document, 'mousemove').pipe(startWith('fake mousemove'));

        combineLatest([touchEnd$, mouseUp$])
            .pipe(
                /**
                 * Only check for updates if the user is idle for this.config.intervalTime.
                 */
                switchMap(() => interval(this.config.intervalTime))
            )
            .subscribe(() => {
                console.log('check for update');
                this.swUpdate.checkForUpdate();
            });
    }

    private async checkForUpdate() {
        try {
            /**
             * This will trigger an immediate check for updates.
             * The event is listened on swUpdate.versionUpdates above.
             */
            const updateFound = await this.swUpdate.checkForUpdate();
            console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
        } catch (err) {
            console.error('Failed to check for updates:', err);
        }
    }
}
