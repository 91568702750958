import { Component, Input } from '@angular/core';
import { FooterMenu } from '../../types/menu-data.types';

@Component({
    selector: 'janet-footer-menu-desktop',
    templateUrl: './footer-menu-desktop.component.html',
    styleUrls: ['./footer-menu-desktop.component.scss'],
})
export class FooterMenuDesktopComponent {
    menuList: FooterMenu[];

    @Input()
    set menuListItems(value: FooterMenu[]) {
        this.menuList = value;
    }

    trackByFn(index: number, item: any): string {
        return item.node.id;
    }
}
