import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GLOBAL_COMPONENTS, GLOBAL_COMPONENTS_EXPORT, GLOBAL_PIPES, GLOBAL_PIPES_EXPORT } from '../../components';
import { GLOBAL_CONTAINER_COMPONENTS, GLOBAL_CONTAINER_COMPONENTS_EXPORT } from '../../containers';
import { CorporateUiCorporateModule } from '@janet/corporate/ui-corporate';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { GLOBAL_DIRECTIVES } from '../../directives';

@NgModule({
    declarations: [...GLOBAL_COMPONENTS, ...GLOBAL_CONTAINER_COMPONENTS, ...GLOBAL_PIPES, ...GLOBAL_DIRECTIVES],
    imports: [CommonModule, CorporateUiCorporateModule, NgxJsonViewerModule, FormsModule, ReactiveFormsModule],
    providers: [],
    exports: [
        NgxJsonViewerModule,
        ...GLOBAL_COMPONENTS,
        ...GLOBAL_CONTAINER_COMPONENTS,
        ...GLOBAL_DIRECTIVES,
        ...GLOBAL_CONTAINER_COMPONENTS_EXPORT,
        ...GLOBAL_PIPES_EXPORT,
        ...GLOBAL_COMPONENTS_EXPORT,
    ],
})
export class SharedModule { }
