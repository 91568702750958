import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as LocalStorageReducer from './local-storage.reducer';
import { Dictionary } from '@ngrx/entity';
import { LocalStorageItem, localStoragesFeatureKey, LocalStorageState } from './local-storage.model';

/**
 * DO NOT SELECT THIS
 */
export const selectLocalStorageEntityState =
    createFeatureSelector<LocalStorageReducer.localStorageState>(localStoragesFeatureKey);

const { selectIds, selectEntities, selectAll, selectTotal } =
    LocalStorageReducer.localStorageAdapter.getSelectors();

export const selectAllLocalStorages = createSelector(selectLocalStorageEntityState, selectAll);
export const selectLocalStorageEntities = createSelector(selectLocalStorageEntityState, selectEntities);

export const selectLocatStorageItem = <T>(key: string) =>
    createSelector(selectLocalStorageEntities, (entities: Dictionary<LocalStorageItem>): T | undefined => {
        return entities[key]?.value;
    });

/**
 * SELECT THIS TO GET ALL LOCALSTORAGE IN A DICTIONARY OBJECT
 */
export const selectLocoalStorageState = createSelector(
    selectAllLocalStorages,
    (l: LocalStorageItem[]): LocalStorageState => {
        return l.reduce((a: LocalStorageState, i: LocalStorageItem) => {
            a[i.key] = i.value;
            return a;
        }, {});
    }
);