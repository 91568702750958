import { Component, HostListener, Input } from '@angular/core';
import { ZipSearchComponent } from '../zip-search/zip-search.component';
import { FontAwesomeIconService, ModalService } from '@janet/corporate/ui-corporate';
import { ShopCategoryList, ShopModule } from '../../types/shop-by-category.types';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'janet-shop-by-category, shop-by-category',
    templateUrl: './shop-by-category.component.html',
})
export class ShopByCategoryComponent {
    shopByCategoryTitle = 'shop-by-category-title text-center m-0 mx-3 mx-md-0';
    shopByCategorySubTitle =
        'shop-by-category-sub-title mt-4 mb-0 mx-3 mx-xxl-4 text-center text-break text-wrap';
    shopByCategoryLists: any[];
    shopCategoryHeading: string;
    shopCategorySubheading: string;
    findStoreURL: string;
    queryParam = '/?filter=';
    public getScreenWidth: any;

    constructor(public modalService: ModalService, public _fontAwesomeIconService: FontAwesomeIconService) {
        this.findStoreURL = environment.findStoreURL;
        this.getScreenWidth = window.innerWidth;
        this.shopByCategoryLists = []; // Initialize as an empty array
    }

    @Input()
    set moduleData(data: ShopModule) {
        const moduleData = data;
        [this.shopByCategoryLists, this.shopCategoryHeading] = [
            moduleData.shopCategoryList,
            moduleData.shopCategoryHeading,
        ];
        if (moduleData.shopCategorySubheading && moduleData.shopCategorySubheading.trim().length > 0) {
            this.shopCategorySubheading = moduleData.shopCategorySubheading;
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
    }

    getFaIcon(iconStr: string | null | undefined): string {
        return this._fontAwesomeIconService.getFaIcon(iconStr);
    }

    onCategorySelect(list: ShopCategoryList): void {
        const modelObj = {
            component: [ZipSearchComponent],
            showModal: true,
            data: list,
        };
        this.modalService.modalAction(modelObj);
    }

    getStoreUrl(slug: string): string {
        return `${this.findStoreURL}${this.queryParam}${slug}`;
    }

    get getShopByCategoryContainerClass(): string {
        const shopByCategoryContainer = 'shop-by-category-container my-8 mt-md-8 mb-md-5 mx-0';

        return `${shopByCategoryContainer}`;
    }

    get getShopByCategoryTitleContainerClass(): string {
        if (this.getScreenWidth >= 360) {
            return `container`;
        }
        return '';
    }

    get getShopByCategoryIconsWrapperClass(): string {
        if (this.getScreenWidth >= 360) {
            if (this.getScreenWidth >= 768 && this.getScreenWidth <= 1439) {
                return `container container-width`;
            }
            return `container`;
        }
        return '';
    }

    get getShopByCategoryIconContainerClass(): string {
        const listLength: number = this.shopByCategoryLists.length;
        const [defaultClasses, contentCenter, mobileDesktopClasses] = [
            'text-center mt-4 mx-36 mx-sm-0',
            'justify-content-center',
            'd-flex flex-row flex-wrap flex-xxl-nowrap align-items-start shop-by-category-icon-column-gap',
        ];

        if (this.getScreenWidth >= 360 && this.getScreenWidth < 1440) {
            if (listLength === 3) {
                if (this.getScreenWidth >= 360 && this.getScreenWidth < 768) {
                    return `${mobileDesktopClasses} ${defaultClasses} justify-content-around`;
                }

                if (this.getScreenWidth >= 768) {
                    return `${defaultClasses} ${mobileDesktopClasses} ${contentCenter}`;
                }
            }

            if (listLength === 4 || listLength === 8 || (listLength > 4 && listLength < 8)) {
                return `${defaultClasses} ${contentCenter} row`;
            }
        }

        return `${mobileDesktopClasses} ${defaultClasses} ${contentCenter}`;
    }

    get getCategoryIconSelectorClass(): string {
        const listLength: number = this.shopByCategoryLists.length;
        let defaultClasses = 'd-flex justify-content-center';

        if (this.getScreenWidth >= 360 && this.getScreenWidth < 1440) {
            if (this.getScreenWidth >= 768 && this.getScreenWidth <= 1439) {
                defaultClasses += ' p-0';
            }

            if (listLength === 4 || listLength === 8 || (listLength > 4 && listLength < 8)) {
                return `${defaultClasses} col-3`;
            }

            return `${defaultClasses}`;
        }

        return '';
    }
}
