import { Injectable } from '@angular/core';
import { PAGE_DATA_QUERY, SHOP_PAGE_QUERY } from '../queries/data.query';
import { PREVIEW_QUERY } from '../queries/preview.query';
import { MERCHANT_SEARCH_QUERY } from '../queries/merchant-search.query';
import { LOCATIONS_QUERY } from '../queries';

@Injectable({
    providedIn: 'root',
})
export class FillterQueryService {
    getQueryDetails(slug: string, isPreview: string | null): any {
        let pageQuery;
        let shopQuerySlug;
        if (isPreview) {
            pageQuery = PREVIEW_QUERY;
        } else if (slug.includes('shop/')) {
            const [index_1, index_2] = [
                slug.split('/')[slug.split('/').length - 1],
                slug.split('/')[slug.split('/').length - 2],
            ];
            shopQuerySlug = index_1.trim().length > 0 ? index_1 : index_2;
            pageQuery = MERCHANT_SEARCH_QUERY;
        } else if (slug.includes('locations/') || slug.includes('locations')) {
            pageQuery = LOCATIONS_QUERY;
        } else {
            pageQuery = PAGE_DATA_QUERY;
        }
        const currentSlug: any = pageQuery === SHOP_PAGE_QUERY ? shopQuerySlug : slug;
        return [pageQuery, currentSlug === '/' ? currentSlug : currentSlug.replace(/\/+$/, '')]; //NOSONAR
    }
}
