<ul
    class="list-unstyled text-reset m-0 submenu px-4 px-md-0 bg-color"
    [class.border-bottom]="subMenuList.lastIndex"
>
    <li class="pb-3" *ngFor="let submenu of subMenuList.submenus; trackBy: trackByFn">
        <a
            [href]="submenu?.node?.url"
            [target]="submenu?.node?.target || ''"
            class="text-reset text-decoration-none"
            >{{ submenu?.node?.label }}</a
        >
    </li>
</ul>
