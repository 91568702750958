<ng-container *ngIf="_dtcBannerMapSection && _locationsData">
    <div class="map-section-dtc-img-banner" *ngIf="_locationsData?.locations?.textimageBanner === 'imagebanner'">
        <img *ngIf="_locationsData?.locations?.desktopBanner"
            class="col-12 map-sec-desk-image d-none d-md-block d-lg-block mb-3"
            [alt]="$any(_locationsData?.locations?.desktopBanner?.altText)"
            [src]="$any(_locationsData?.locations?.desktopBanner?.sourceUrl)"
            [srcset]="$any(_locationsData?.locations?.desktopBanner?.srcSet)" />

        <img *ngIf="_locationsData?.locations?.mobileBanner"
            class="col-12 d-block d-md-none d-lg-none map-sec-mob-image mb-3"
            [alt]="$any(_locationsData?.locations?.mobileBanner?.altText)"
            [src]="$any(_locationsData?.locations?.mobileBanner?.sourceUrl)"
            [srcset]="$any(_locationsData?.locations?.mobileBanner?.srcSet)" />
    </div>
    <div class="map-section-dtc-banner mb-3" *ngIf="
            _locationsData?.locations?.merchantBanner &&
            _locationsData?.locations?.textimageBanner === 'textbanner'
        " [style.background-color]="
            _locationsData?.locations?.backgroundColor ? _locationsData?.locations?.backgroundColor : '#26438c'
        " [style.color]="_locationsData?.locations?.textColor ? _locationsData?.locations?.textColor : '#fff'">
        <div class="row">
            <div class="col-md-6 map-dtc-banner-left">
                <ng-container *ngIf="
                        _locationsData?.locations?.bannerTitle &&
                        _locationsData?.locations?.bannerTitle?.toString()?.trim()?.length > 0
                    ">
                    <ng-container *ngFor="
                            let bannerTitle of _locationsData?.locations?.bannerTitle?.toString()?.split('|');
                            let index = index
                        ">
                        <h2 class="map-dtc-banner-title text-break"
                            *ngIf="bannerTitle && bannerTitle.toString()?.trim()?.length > 0">
                            {{ bannerTitle }}
                        </h2>
                    </ng-container>
                </ng-container>

                <p class="map-dtc-banner-sub-title" *ngIf="
                        _locationsData?.locations?.bannerSubText &&
                        _locationsData?.locations?.bannerSubText?.toString()?.trim()?.length > 0
                    " [innerHTML]="_locationsData?.locations?.bannerSubText | sanitizeHtml"></p>
            </div>
            <div class="col-md-6 map-dtc-banner-right" *ngIf="
                    _locationsData?.locations?.customBannerSideText ||
                    _locationsData?.locations?.showNoCreditNeeded ||
                    _locationsData?.locations?.showBuyOutOptions
                ">
                <ng-container *ngIf="
                        _locationsData?.locations?.showNoCreditNeeded &&
                        _locationsData?.locations?.showNoCreditNeededText?.toString()?.trim()?.length > 0
                    ">
                    <p><i class="fa fa-check"></i> {{ _locationsData?.locations?.showNoCreditNeededText }}</p>
                </ng-container>
                <ng-container *ngIf="_locationsData?.locations?.customBannerSideText?.toString()?.trim()?.length > 0">
                    <ng-container *ngFor="
                            let sideText of _locationsData?.locations?.customBannerSideText
                                ?.toString()
                                ?.split('|');
                            let index = index
                        ">
                        <p *ngIf="sideText && sideText.toString()?.trim()?.length > 0">
                            <i class="fa fa-check"></i> {{ sideText }}
                        </p>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="_locationsData?.locations?.showBuyOutOptions">
                    <p>
                        <i class="fa fa-check"></i>
                        {{
                        _dealerInfoData?.sac_days && _dealerInfoData?.sac_days?.toString()?.trim()?.length > 0
                        ? _dealerInfoData?.sac_days
                        : _locationsData?.locations?.buyOutOptions
                        }}
                        {{ _locationsData?.locations?.earlyBuyOutOption }}
                    </p>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="map-section-dtc-mob-banner" *ngIf="_locationsData?.locations?.bannerText">
        <div [class]="
                _locationsData?.locations?.merchantBanner && _locationsData?.locations?.textimageBanner
                    ? 'conn-banner mobile mt-3 mb-3 d-block d-md-none d-lg-none'
                    : 'conn-banner mobile mb-3'
            " [style.background-color]="
                _locationsData?.locations?.backgroundColor ? _locationsData?.locations?.backgroundColor : '#26438c'
            " [style.color]="_locationsData?.locations?.textColor ? _locationsData?.locations?.textColor : '#fff'">
            <div class="inner-wrapper">
                {{ _locationsData?.locations?.bannerText }}
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="_dtcBannerStoreListSection">
    <div class="store-list-section-dtc-banner">
        <div class="row m-0">
            <div class="col-lg-8 store-dtc-banner-left">
                <h2 class="store-dtc-banner-title">Can't find the right place?</h2>
                <p class="store-dtc-banner-sub-title">Get up to $3,000* to shop anywhere</p>
            </div>
            <div class="col-lg-4 store-dtc-banner-right">
                <ua2-aff-button class="p-0 m-0 w-100" [url]="'https://www.google.com'" [target]="'_blank'"
                    [title]="'apply'" [buttonLabel]="'Apply'" [id]="'store-dtc-banner-apply-button'"
                    [buttonCustomClass]="'store-dtc-banner-apply-btn aff-button-light'"></ua2-aff-button>
            </div>
        </div>
    </div>
</ng-container>