import {
    HERO_FIELD_GROUP_NAME,
    CONTENT_AREA_FIELD_GROUP_NAME,
    TESTIMONIAL_FIELD_GROUP_NAME,
    ICONS_FIELD_GROUP_NAME,
    FAQ_SCHEMA_FIELS_GROUP_NAME,
    MERCHANT_SIGNUP_FORM_FIELD_GROUP_NAME,
    DASR_FORM_FIELD_GROUP_NAME,
    FaqSchema,
    Website_FORM_FIELD_GROUP_NAME,
    BANNER_MODULE_GROUP_NAME,
    SHOP_CATEGORY_MODULE_GROUP_NAME,
    NATIVE_FORMS_MODULE_GROUP_NAME,
} from '../../types/kitchen-sink.types';
import { ShopByCategoryContents, ShopCategory } from '../../types/shop-by-category.types';
import { ActivatedRoute } from '@angular/router';
import {
    Component,
    ChangeDetectionStrategy,
    Renderer2,
    RendererFactory2,
    OnInit,
    Inject,
    PLATFORM_ID,
    TransferState,
    ChangeDetectorRef,
} from '@angular/core';
import { ScriptService } from '../../core/services/script.service';
import { SessionStorage } from '@janet/corporate/domain';
import { Meta, Title } from '@angular/platform-browser';
import { ChatScriptService } from '../../core/services/chat-script.service';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { LaunchDarklyStoreService } from '@janet/shared/domain';
import { MerchantSearchDetails } from '../../types/merchant-search.types';
import { Apollo } from 'apollo-angular';
import { NativeFormService } from '../../services/native-form.service';
import { SOP_DEALER_INFO_STATE_KEY } from '../../../constants/state-keys';
import { LanguageDataService } from '../../services/language.service';

@Component({
    selector: 'janet-general-container',
    templateUrl: './general-container.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralContainerComponent implements OnInit {
    moduleData: any;
    contentModules: any[] = [];
    _browser = true;
    shopByModuleData: ShopByCategoryContents;
    categoriesData: ShopCategory[] = [];
    merchantSearch: MerchantSearchDetails;
    merchantSearchStoreList: any[] = [];
    merchantSearchNoOfRecords: number;
    merchantSearchRadius: number;
    merchantSearchFilter: string;

    heroGroupName = `Page_${HERO_FIELD_GROUP_NAME}`;
    contentGroupName = `Page_${CONTENT_AREA_FIELD_GROUP_NAME}`;
    testimonialGroupName = `Page_${TESTIMONIAL_FIELD_GROUP_NAME}`;
    iconsGroupName = `Page_${ICONS_FIELD_GROUP_NAME}`;
    faqSchemaGroupname = `Page_${FAQ_SCHEMA_FIELS_GROUP_NAME}`;
    merchantSignupForm = `Page_${MERCHANT_SIGNUP_FORM_FIELD_GROUP_NAME}`;
    dsarForm = `Page_${DASR_FORM_FIELD_GROUP_NAME}`;
    websiteForm = `Page_${Website_FORM_FIELD_GROUP_NAME}`;
    bannerGroupName = `Page_${BANNER_MODULE_GROUP_NAME}`;
    shopCategoryGroupName = `Page_${SHOP_CATEGORY_MODULE_GROUP_NAME}`;
    nativeFormGroupName = `Page_${NATIVE_FORMS_MODULE_GROUP_NAME}`;
    randomImage: string;
    _renderer: Renderer2;
    gFormData: any;
    structuredGFormData: any;

    showMerchantFeature$: Observable<boolean> = this.featureFlagStore.selectFeature$('merchant-search-feature');
    locationsPageDetails: any;
    locationsDealerInfoData: any;
    locationsBaseModuleData: any;
    locationsLanguageLabelsObj: any = {};
    shopOurPartners = false;
    dealerInfo: any = null;
    pageData: any;

    constructor(
        public _chatScriptService: ChatScriptService,
        public _route: ActivatedRoute,
        public _scriptService: ScriptService,
        public rendererFactory: RendererFactory2,
        public _title: Title,
        public _meta: Meta,
        public featureFlagStore: LaunchDarklyStoreService,
        public _sessionStorage: SessionStorage,
        @Inject(PLATFORM_ID) public _platformId: any,
        public _transferState: TransferState,
        @Inject(DOCUMENT) public document: Document,
        private _appollo: Apollo,
        private _nativeFormService: NativeFormService,
        private languageDataService: LanguageDataService,
        private _cdr: ChangeDetectorRef
    ) {
        if (!isPlatformBrowser(this._platformId)) {
            this._browser = false;
        }
        this._renderer = this.rendererFactory.createRenderer(null, null);
    }

    ngOnInit(): void {
        this.languageDataService.selectedLanguageData$.subscribe((data) => {
            if (data) {
                this.pageData = data;

                this.updateContent(this.pageData);

                this._cdr.detectChanges();
            }
        });
        const pageData = (<any>this._route?.snapshot?.data)?.pageData;

        this.updateContent(pageData);
    }

    private updateContent(data: any): void {
        const pageData = data;

        if (pageData) {
            this.categoriesData = pageData?.shopCategories?.nodes;

            const categoryDetails = this.categoriesData?.map((category: any) => ({
                slug: category?.slug,
                apiKey: category?.ShopCategoryAPIKey?.apiKey,
            }));

            this.document?.defaultView?.sessionStorage?.setItem(
                'categoryDetails',
                JSON.stringify(categoryDetails)
            );

            // Check if categoriesData is not null or undefined

            const baseModuleData: any =
                pageData?.contentAreaSearch ||
                pageData?.pageBy ||
                pageData?.page ||
                pageData?.merchantStoreBy ||
                pageData?.brandBy ||
                pageData?.locations?.nodes[0];
            const moduleData: any = {
                ...baseModuleData,
                seo: baseModuleData && baseModuleData?.seo ? baseModuleData?.seo : {},
            };

            if (pageData?.shopBy) {
                const shopByModuleData: ShopByCategoryContents = pageData?.shopBy;
                this.shopByModuleData = shopByModuleData;
                this.setModuleVariables('Shop');
            } else if (pageData?.contentAreaSearch) {
                this.merchantSearchStoreList = pageData?.aResults.aResults;
                this.merchantSearchNoOfRecords = pageData?.aResults.iNumberOfRecords;
                this.merchantSearchRadius = pageData?.aResults.fRadius;
                this.merchantSearch = pageData?.merchantSearch.merchantSearch;
                moduleData.seo = pageData?.merchantSearch.seo;
                this.setModuleVariables('MerchantSearch');
            }

            this.moduleData = moduleData;
            this.contentModules = this.moduleData?.contentModules?.contentModules;
            this._title.setTitle(this.moduleData?.seo?.title || this.shopByModuleData?.seo?.title);
            this._meta.updateTag({
                name: 'description',
                content: this.moduleData?.seo?.metaDesc || this.shopByModuleData?.seo?.metaDesc,
            });

            const faqSchema = this.contentModules?.filter(
                (data) => data?.fieldGroupName === this.faqSchemaGroupname
            );
            if (faqSchema?.length > 0 && this._browser) {
                this.renderFaqSchema(faqSchema[0]?.qaItems);
            }

            this.locationsPageDetails = pageData?.locationsPageDetails;
            if (this.locationsPageDetails && this.locationsPageDetails?.isLocationPage) {
                //NOSONAR

                this.shopOurPartners = true;
                this.locationsDealerInfoData = pageData?.dealerInfoData;
                this.locationsBaseModuleData = pageData?.locations?.nodes[0];

                const languageLabelsList = pageData?.allMerchantFieldsLabel?.nodes[0]?.merchantLabels?.labelsList;
                if (languageLabelsList && Array.isArray(languageLabelsList) && languageLabelsList?.length > 0) {
                    languageLabelsList.forEach((obj) => {
                        this.locationsLanguageLabelsObj[obj?.textName] = obj?.textValue;
                    });
                }
            }

            if (isPlatformBrowser(this._platformId)) {
                let dealerInfo = this._transferState?.get(SOP_DEALER_INFO_STATE_KEY, null);

                if (dealerInfo && dealerInfo?.bBranded) {
                    //NOSONAR
                    this.dealerInfo = dealerInfo;
                } else {
                    this.dealerInfo = null;
                }
            }

            this._cdr.detectChanges();
        }
    }

    setModuleVariables(type: string) {
        this.heroGroupName = `${type}_${HERO_FIELD_GROUP_NAME}`;
        this.contentGroupName = `${type}_${CONTENT_AREA_FIELD_GROUP_NAME}`;
        this.testimonialGroupName = `${type}_${TESTIMONIAL_FIELD_GROUP_NAME}`;
        this.iconsGroupName = `${type}_${ICONS_FIELD_GROUP_NAME}`;
        this.faqSchemaGroupname = `${type}_${FAQ_SCHEMA_FIELS_GROUP_NAME}`;
        this.merchantSignupForm = `${type}_${MERCHANT_SIGNUP_FORM_FIELD_GROUP_NAME}`;
        this.dsarForm = `${type}_${DASR_FORM_FIELD_GROUP_NAME}`;
        this.websiteForm = `${type}_${Website_FORM_FIELD_GROUP_NAME}`;
        this.bannerGroupName = `${type}_${BANNER_MODULE_GROUP_NAME}`;
        this.shopCategoryGroupName = `${type}_${SHOP_CATEGORY_MODULE_GROUP_NAME}`;
        this.nativeFormGroupName = `${type}_${NATIVE_FORMS_MODULE_GROUP_NAME}`;
    }

    renderFaqSchema(schemaArray: FaqSchema[]): void {
        const schemaContent: any = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: [],
        };

        schemaArray.forEach((data: FaqSchema) => {
            const schemaItem = {
                '@type': 'Question',
                name: data.question,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: data.answer.replace(/<[^>]+>/gm, ''), //NOSONAR
                },
            };
            schemaContent.mainEntity.push(schemaItem);
        });
        this._scriptService.loadJsScript(this._renderer, '', 'application/ld+json', JSON.stringify(schemaContent));
    }
}
