import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ua2-stepper-container, aff-stepper-container',
    templateUrl: './stepper-container.component.html',
})
export class StepperContainerComponent implements OnInit {
    _stepperContainerCustomClass: any = '';
    _formData: any = {};
    _pageIndex: any = 0;
    _stepperIndicatorContainerCustomClass = '';
    _stepperIndicatorClasses = '';
    _stepperTitleClasses = '';
    showFaIcon = false;

    constructor(private _changeDetectorRef: ChangeDetectorRef,) { }

    ngOnInit(): void {
        this._changeDetectorRef.detectChanges();
    }

    @Input()
    set stepperContainerCustomClass(value: any) {
        this._stepperContainerCustomClass = value;
    }

    get stepperContainerClasses() {
        const classes: any = this._stepperContainerCustomClass;
        return classes;
    }

    @Input()
    set formData(data: any) {
        this._formData = data;
    }

    @Input()
    set pageIndex(data: any) {
        this._pageIndex = data;
    }

    @Input()
    set stepperIndicatorContainerClasses(value: any) {
        this._stepperIndicatorContainerCustomClass = value;
    }

    @Input()
    set stepperIndicatorClasses(value: any) {
        this._stepperIndicatorClasses = value;
    }

    @Input()
    set stepperTitleClasses(value: any) {
        this._stepperTitleClasses = value;
    }

    getStepperIndicatorClass(idx: any) {
        const indicatorIndex = parseInt(idx, 10);
        const pageIndex = parseInt(this._pageIndex, 10);
        this.showFaIcon = false;

        switch (true) {
            case indicatorIndex === pageIndex:
                return `${this._stepperIndicatorClasses} indicator-in-progress`;

            case indicatorIndex > pageIndex:
                return `${this._stepperIndicatorClasses} indicator-in-active`;

            case indicatorIndex < pageIndex:
                this.showFaIcon = true;
                return `${this._stepperIndicatorClasses} indicator-done`;

            default:
                return `${this._stepperIndicatorClasses} indicator-done`;
        }
    }

    getStepperTitleClass(idx: any) {
        const indicatorIndex = parseInt(idx, 10);
        const pageIndex = parseInt(this._pageIndex, 10);

        const classes = (indicatorIndex === pageIndex)
            ? `${this._stepperTitleClasses} title-active`
            : `${this._stepperTitleClasses} title-normal`;

        return classes;
    }
}
