<div class="shop-our-partners-title d-flex justify-content-center align-items-center flex-column">
    <h1 class="title m-0">
        {{
        _dealerInfoData && _dealerInfoData?.bBranded
        ? _locationsData?.locations?.displayType?.toString()?.toLowerCase() === "select your store"
        ? _locationsLanguageLabelsObj?.bannerStoreLabel
        : _locationsLanguageLabelsObj?.bannerLabel + " " + _locationsData?.locations?.merchantName
        : "Shop Our Partners"
        }}
    </h1>
    <ng-container *ngIf="_dealerInfoData && _dealerInfoData?.bBranded && _locationsData">
        <img *ngIf="_locationsData?.locations?.merchantLogo" class="logo mt-3"
            [src]="_locationsData?.locations?.merchantLogo?.sourceUrl" [srcset]="
                _locationsData?.locations?.merchantLogo?.srcSet
                    ? _locationsData?.locations?.merchantLogo?.srcSet
                    : _locationsData?.locations?.merchantLogo?.sourceUrl
            " [alt]="_locationsData?.locations?.merchantLogo?.altText" [style.max-height.px]="computedMaxHeight"
            loading="lazy" />
        <p class="sub-title m-0 mt-3 text-center" *ngIf="
                _locationsData?.locations?.merchantStat &&
                _locationsData?.locations?.merchantStat?.toString()?.trim()?.length > 0
            ">
            {{ _locationsData?.locations?.merchantStat }}
        </p>
    </ng-container>
</div>