<form [formGroup]="affSearchForm" (ngSubmit)="onSubmit()" novalidate class="aff-search-container">
    <div class="aff-search-fields" *ngIf="isShopCTA">
        <div
            class="aff-search-input"
            *ngIf="searchType === 'zip_code' || searchType === 'cat_zip'"
            [ngClass]="searchType === 'zip_code' ? onlyZipClass : zipButtonTextClass"
        >
            <aff-corporate-form-field
                formFieldContainerCustomClass="corporate-form-field-container form-floating"
                formFieldType="number"
                formFieldLabel="Zip Code"
                formFieldPlaceholder="Zip Code"
                formFieldId="zipcode"
                formFieldCustomClass="form-control"
                formFieldLabelCustomClass="form-label"
                [control]="$any(affSearchForm.controls.zipcode)"
                [errorMessages]="zipErrorMessages"
                (formFieldEvent)="handleEvent($event)"
                [errorMessageClass]="errorMessageClass"
            >
            </aff-corporate-form-field>
        </div>
        <div class="aff-search-input" *ngIf="searchType === 'store_name'" [ngClass]="onlyZipClass">
            <aff-corporate-form-field
                formFieldContainerCustomClass="corporate-form-field-container form-floating"
                formFieldType="text"
                formFieldLabel="Store Name"
                formFieldPlaceholder="Store Name"
                formFieldId="storename"
                formFieldCustomClass="form-control"
                formFieldLabelCustomClass="form-label"
                [control]="$any(affSearchForm.controls.storename)"
                [errorMessages]="storenameErrorMessages"
                [errorMessageClass]="errorMessageClass"
            >
            </aff-corporate-form-field>
        </div>
        <div class="aff-search-select" *ngIf="searchType === 'cat_zip'" [ngClass]="catZipButtonTextClass">
            <aff-corporate-form-field
                formFieldContainerCustomClass="corporate-form-field-container form-floating"
                formFieldType="singleselect"
                formFieldLabel="Category"
                formFieldPlaceholder="Category"
                formFieldId="merchantcategory"
                formFieldCustomClass="form-control"
                formFieldLabelCustomClass="form-label"
                [singleSelectoptions]="options"
                [control]="$any(affSearchForm.controls.merchantcategory)"
                [errorMessages]="categoryErrorMessages"
                [errorMessageClass]="errorMessageClass"
                [showErrorMessage]="showCategoryErrorMessage"
            >
            </aff-corporate-form-field>
        </div>
    </div>

    <ua2-aff-button
        *ngIf="isShopCTA && buttonText"
        class="display-content"
        [buttonLabel]="buttonText"
        [buttonType]="'submit'"
        [id]="affBtnId"
        [isButtonAnchor]="isButtonAnchor"
        [buttonHostCustomClass]="enableBtnDarkTheme"
    ></ua2-aff-button>

    <ua2-aff-button
        *ngIf="!isShopCTA && buttonText && simpleCtaUrl"
        class="display-content"
        [buttonType]="'submit'"
        [buttonLabel]="buttonText"
        [id]="affBtnId"
        [target]="btnTarget"
        [url]="simpleCtaUrl"
        [buttonHostCustomClass]="enableBtnDarkTheme"
    ></ua2-aff-button>
</form>
