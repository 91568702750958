import { AfterViewInit, Component, ElementRef, HostListener, Input, Renderer2, ViewChild } from '@angular/core';
import { SlidePanelService } from '../../services/slide-panel.service';

@Component({
    selector: 'ua2-slide-panel, slide-panel',
    templateUrl: './slide-panel.component.html',
})
export class SlidePanelComponent implements AfterViewInit {
    @Input() _slidePanelContainerClass = 'slide-panel-wrapper position-fixed top-0 bottom-0 end-0 w-100';
    @Input() _slidePanelClass = 'slide-panel position-absolute top-0 bottom-0 start-0 end-0';
    @ViewChild('sliderPanel', { static: true }) sliderPanel: ElementRef;
    @ViewChild('sliderContentPanel', { static: true }) sliderContentPanel: ElementRef;
    previousScrollPosition = 0;

    constructor(public renderer: Renderer2, public _sliderScrollPosition: SlidePanelService) {}

    ngAfterViewInit() {
        this.renderer.listen(this.sliderContentPanel.nativeElement, 'scroll', () => {
            this.onScroll();
        });
    }

    @Input()
    set showPanel(_isShowPanel: string) {
        const [panel, classesToRemove] = [
            this.sliderPanel.nativeElement,
            ['panel-slide-out', 'panel-slide-hidden'],
        ];
        let classesToAdd;

        if (_isShowPanel === 'true') {
            classesToAdd = ['panel-slide-in'];
        } else if (_isShowPanel === 'false') {
            classesToAdd = ['panel-slide-out'];
        } else {
            classesToAdd = ['panel-slide-hidden'];
        }

        panel.classList.remove(...classesToRemove);
        panel.classList.add(...classesToAdd);

        if (_isShowPanel === 'true') {
            document.body.classList.add('no-scroll');
        }
        if (_isShowPanel === 'false') {
            document.body.classList.remove('no-scroll');
        }
    }

    onScroll() {
        const element = this.sliderContentPanel.nativeElement;
        const currentScrollPosition = element.scrollTop;

        if (currentScrollPosition > this.previousScrollPosition) {
            this._sliderScrollPosition.emitSliderScrollPosition('down');
        } else {
            this._sliderScrollPosition.emitSliderScrollPosition('up');
        }

        this.previousScrollPosition = currentScrollPosition;
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
        event.stopPropagation();
    }

    get slidePanelContainerClass(): string {
        const classes = `${this._slidePanelContainerClass}`;
        return classes;
    }

    get slidePanelClass(): string {
        return this._slidePanelClass;
    }
}
