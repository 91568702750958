import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuDataService } from '../../services/menu-data.service';
import { Nodes } from '../../types/menu-data.types';

@Component({
    selector: 'janet-customer-service-information',
    templateUrl: './customer-service-information.component.html',
    styleUrls: ['./customer-service-information.component.scss'],
})
export class CustomerServiceInformationComponent {
    public customerServiceInformation$: Observable<Nodes>;
    _locationsBaseModuleData: any;
    _locationsDealerInfoData: any;

    constructor(private _menuDataSevice: MenuDataService) {
        this.customerServiceInformation$ = this._menuDataSevice.customerServiceInformation$;
    }

    @Input()
    set locationsBaseModuleData(data: any) {
        this._locationsBaseModuleData = data;
    }

    @Input()
    set locationsDealerInfoData(data: any) {
        this._locationsDealerInfoData = data;
    }

    isBrandedDealer() {
        return (this._locationsDealerInfoData && this._locationsDealerInfoData.bBranded && this._locationsBaseModuleData && this._locationsBaseModuleData.locations && this._locationsBaseModuleData.locations.creditText && this._locationsBaseModuleData.locations.creditText.toString().trim().length > 0);//NOSONAR
    }
}
