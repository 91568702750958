import { Component, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
    selector: 'ua2-aff-card, aff-card',
    templateUrl: './aff-card.component.html',
    styleUrls: [],
})
export class AffCardComponent implements OnInit {
    @Input() cardImage: string;
    @Input() noShadow: boolean = false;
    @Input() hoverable: boolean = true;
    @Input() borderless = false;
    @Input() shadow = false;

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    @HostListener('mouseenter') onMouseEnter() {
        if (this.hoverable) {
            this.renderer.addClass(this.elementRef.nativeElement, 'shadow');
            this.renderer.removeClass(this.elementRef.nativeElement, 'shadow-sm');
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.hoverable) {
            this.renderer.removeClass(this.elementRef.nativeElement, 'shadow');
            this.renderer.addClass(this.elementRef.nativeElement, 'shadow-sm');
        }
    }

    ngOnInit(): void {
        if (this.borderless) {
            this.renderer.addClass(this.elementRef.nativeElement, 'border-0');
        }
        if (this.shadow) {
            this.renderer.addClass(this.elementRef.nativeElement, 'shadow-sm');
        }
        this.renderer.addClass(this.elementRef.nativeElement, 'card');
        if (this.hoverable) {
            this.renderer.addClass(this.elementRef.nativeElement, 'shadow-sm');
        }
    }
}
