import { Component, HostListener, Input } from '@angular/core';

@Component({
    selector: 'janet-merchant-search',
    templateUrl: './merchant-search.component.html',
})
export class MerchantSearchComponent {
    @Input() merchantSearchStoreList: any = {};
    @Input() merchantSearchNoOfRecords: number;
    @Input() merchantSearchRadius: number;
    zipcode: any;
    selectOptions = [];
    public getScreenWidth: any;
    _contentModules: any[] = [];

    constructor() {
        this.getScreenWidth = window.innerWidth;
        window.addEventListener('resize', this.onWindowResize.bind(this));
    }

    @Input()
    set categoriesData(data: any) {
        this.populateSelectOptionValue(data);
    }

    @Input()
    set contentModules(data: any) {
        this._contentModules = data;
    }

    populateSelectOptionValue(data: any) {
        this.selectOptions = data.map((item: any) => {
            const option = {} as Option;
            [option.label, option.value, option.description, option.apiKey] = [
                item.name,
                item.slug,
                item?.description,
                item.ShopCategoryAPIKey.apiKey,
            ];
            return option;
        });
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
    }

    public get merchantSearchContainer(): string {
        let containerClass = '';
        if (this.getScreenWidth <= 767) {
            containerClass = 'merchant-search-container px-0';
        }

        if (this.getScreenWidth >= 768) {
            containerClass = 'merchant-search-container container px-0';
        }

        if (this.getScreenWidth >= 900) {
            containerClass = 'merchant-search-container container px-4';
        }

        if (this.getScreenWidth >= 1440) {
            containerClass = 'merchant-search-container container px-4';
            if (this._contentModules?.length === 0) {
                containerClass += ' pb-3';
            }
        }

        return containerClass;
    }

    getZipcode(value: any) {
        this.zipcode = value;
    }
}

export interface Option {
    label: string;
    value: string;
    description?: string;
    apiKey: string;
}
