import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { MenuEdgesArray } from '../../../types/menu-data.types';

@Component({
    selector: 'janet-nav-bar-mobile-single',
    templateUrl: './nav-bar-mobile-single.component.html',
    styleUrls: ['./nav-bar-mobile-single.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarMobileSingleComponent {
    @Input() navItem!: MenuEdgesArray;

    angleRight = faAngleRight;

    linkHandler(event: Event): void {
        event?.stopPropagation();
    }

    get hasTertiaryMenu() {
        return (
            this.navItem?.node?.ctaNavFields?.tertiaryMenu && this.navItem?.node?.ctaNavFields?.tertiaryMenu.length
        );
    }
}
