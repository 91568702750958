import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LetDirective, PushPipe } from '@ngrx/component';
import { FeatureFlagBooleanComponent } from './feature-flag-boolean.component';

@NgModule({
    declarations: [FeatureFlagBooleanComponent],
    imports: [CommonModule, MatSlideToggleModule, LetDirective, PushPipe],
    exports: [FeatureFlagBooleanComponent],
})
export class FeatureFlagValueModule {}
