<div [ngClass]="[textAreaContainerDefaultClass, textAreaContainerCustomClass]">
    <textarea
        [ngClass]="textAreaFieldClasses"
        [id]="textAreaId"
        [rows]="rows"
        [cols]="cols"
        [placeholder]="textAreaPlaceholder"
        [value]="value"
        [formControl]="control"
        (input)="onValueChange($event)"
        [readonly]="isReadonly"
        [disabled]="isDisabled"
        autocomplete="off"
        autocorrect="off"
    ></textarea>
    <label
        *ngIf="textAreaLabel && textAreaLabel.toString().trim().length > 0"
        [for]="textAreaId"
        [ngClass]="[textAreaLabelDefaultClass, textAreaLabelCustomClass]"
    >
        {{ textAreaLabel }}</label
    >
</div>
<ng-container *ngIf="control.invalid && _showErrorMessage">
    <p class="error-message-spacing" *ngFor="let err of control.errors | keyvalue" [ngClass]="[errorMessageClass]">
        {{ errorMessages[err.key] }}
    </p>
</ng-container>
