import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
    Input,
    ChangeDetectorRef,
    Inject,
    OnDestroy,
    AfterContentInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MerchantSearchResultsService } from '../../services/merchant-search-results.service';
import { MerchantFilterService } from '../../services/merchant-filter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleApiService } from '../../services/google-api-service.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'janet-merchant-search-form',
    templateUrl: './merchant-search-form.component.html',
})
export class MerchantSearchFormComponent implements AfterContentInit, OnInit, OnDestroy {
    merchantSearchForm: any;
    showErrorMessage = false;
    _multiSelectOptions: any = [];
    isInitialLoad = true;
    selectErrorMessages = {
        required: 'Field is required',
        minlength: 'Minimum 3 characters required',
    };
    categoryErrorMessages = {
        required: 'Field is required',
    };
    categoryCount = 0;
    showPanel = false;
    _showPanel = '';
    selectedCategoryList: Selected[] = [];
    public getScreenWidth: any;
    _searchButtonClass = 'zip-search-button-in-active';
    @ViewChild('zipSearchButton', { static: true }) zipSearchButton: ElementRef;
    @ViewChild('searchInputWrapper') searchInputWrapper: ElementRef;
    _sliderButtonContainerDefaultClass = 'text-center rounded-circle';
    _sliderButtonIconClass = 'fa-light fa-sliders text-center';
    _sliderButtonBadgeClass =
        'position-relative rounded-circle d-flex justify-content-center align-items-center text-center';
    sliderButtonContainerClass = this._sliderButtonContainerDefaultClass + ' slider-button-container-show';
    sliderButtonIconClass = this._sliderButtonIconClass + ' slider-button-icon-show';
    sliderButtonBadgeClass = this._sliderButtonBadgeClass + ' slider-button-count-badge-show';
    selectedOptionsList: any = [];
    merchantSearchText: any = '';
    merchantSearchCategory: any;
    categoryList = [];
    checkedList: any[] = [];
    checkedListLabel: any[] = [];
    checkedListApiKey: any[] = [];
    LoaderDisplaysubscription: any;
    isLoaderDisplay = false;
    _formFieldContainerCustomClass =
        'corporate-form-field-container form-floating merchant-search-select-form-field';
    _formFieldLabelCustomClass = 'form-label px-3';
    _formFieldCategoryContainerCustomClass =
        'corporate-form-field-container form-floating merchant-search-category-form-field d-none d-xxl-block';
    _formFieldCategoryLabelCustomClass = 'form-label';
    urlStateName = '';
    changeEventTriggered = false;
    stateNameStatusSubscription: any;
    merchantFilterService: any;

    constructor(
        public formBuilder: FormBuilder,
        public _merchantSearchResults: MerchantSearchResultsService,
        private _merchantFilterService: MerchantFilterService,
        public _cdr: ChangeDetectorRef,
        public route: ActivatedRoute,
        private googleApiService: GoogleApiService,
        @Inject(DOCUMENT) private document: Document,
        private router: Router
    ) {
        this.getScreenWidth = window.innerWidth;
    }

    ngAfterContentInit(): void {
        this.onZipSearchBtnClick();
    }
    ngOnInit(): void {
        this.route?.queryParams?.subscribe((params) => {
            if (params.search !== undefined && params.search.toString().length == 0) {
                this.router.navigate([], {
                    queryParams: {
                        search: null,
                    },
                    queryParamsHandling: 'merge',
                });
                if (this.document.defaultView?.sessionStorage?.getItem('stateName')) {
                    this.document.defaultView?.sessionStorage?.removeItem('stateName');
                }
                if (this.document.defaultView?.sessionStorage?.getItem('stateShortCode')) {
                    this.document.defaultView?.sessionStorage?.removeItem('stateShortCode');
                }
                if (this.document.defaultView?.sessionStorage?.getItem('zipCode')) {
                    this.document.defaultView?.sessionStorage?.removeItem('zipCode');
                }
            }
            this.merchantSearchText =
                params.search || this.document.defaultView?.sessionStorage?.getItem('stateName');
            this.merchantSearchCategory = params.filter;
            this.autoFillCategory();
        });

        this.merchantSearchForm = this.formBuilder.group({
            merchantSearchText: [this.merchantSearchText ? this.merchantSearchText.replace('-', ' ') : ''],
            merchantSearchCategory: [this.categoryList],
        });
        this.populateOptionsList();
    }

    ngOnDestroy() {
        if (this.LoaderDisplaysubscription) {
            this.LoaderDisplaysubscription.unsubscribe();
        }

        if (this.stateNameStatusSubscription) {
            this.stateNameStatusSubscription.unsubscribe();
        }
    }

    @Input()
    set multiSelectOptions(data: any) {
        if (data) {
            this._multiSelectOptions = data.map((obj: any) => ({ ...obj, checked: false }));
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
    }

    @HostListener('document:click', ['$event.target'])
    onDocumentClick(target: HTMLElement): void {
        const searchInputWrapper = this.searchInputWrapper?.nativeElement?.contains(target);
        if (!searchInputWrapper) {
            if (this.changeEventTriggered === true) {
                this.changeEventTriggered = false;
            }
        }
    }

    togglePanel() {
        if (this.isLoaderDisplay) {
            return;
        }
        this.showPanel = !this.showPanel;
        this._showPanel = this.showPanel.toString();
        if (this.getScreenWidth <= 1439) {
            this.onSelectCategories();
        }
    }

    toggleCheckBox(data: Selected) {
        const options: any = this._multiSelectOptions.map((obj: any) => obj);
        this._multiSelectOptions = options;
    }

    toggleMultiSelectOption(data: any) {
        if (this.getScreenWidth >= 1440) {
            this.onSelectCategories();
        }
    }

    inputChange() {
        if (
            this.getScreenWidth < 768 &&
            this.merchantSearchForm?.get('merchantSearchText')?.value?.toString()?.trim()?.length > 0
        ) {
            this.onZipSearchBtnClick('inputChange');
            this.changeEventTriggered = true;
        }
    }

    onSelectCategories() {
        const selectedOptionsList: any = this._multiSelectOptions
            .filter((item: any) => item.checked)
            .map((item: any) => item.apiKey);
        if (this.selectedOptionsList.length !== selectedOptionsList.length) {
            this.selectedOptionsList = selectedOptionsList;
            this.validateFormData();
        } else {
            const isEqual = this.selectedOptionsList.every(
                (value: any, index: any) => value === selectedOptionsList[index]
            );
            if (!isEqual) {
                this.selectedOptionsList = selectedOptionsList;
                this.validateFormData();
            }
        }
    }

    handleEvent(object: any): void {
        if (object.event === 'focusin') {
            this._searchButtonClass = 'zip-search-button-active';

            if (this.getScreenWidth <= 767) {
                this.sliderButtonContainerClass = `${this._sliderButtonContainerDefaultClass} slider-button-container-hide`;
                this.sliderButtonIconClass = `${this._sliderButtonIconClass} slider-button-icon-hide`;
                this.sliderButtonBadgeClass = `${this._sliderButtonBadgeClass} slider-button-count-badge-hide`;
            } else {
                this.sliderButtonContainerClass = `${this._sliderButtonContainerDefaultClass} slider-button-container-show`;
                this.sliderButtonIconClass = `${this._sliderButtonIconClass} slider-button-icon-show`;
                this.sliderButtonBadgeClass = `${this._sliderButtonBadgeClass} slider-button-count-badge-show`;
            }
        }
        if (object.event === 'focusout') {
            this.sliderButtonContainerClass = `${this._sliderButtonContainerDefaultClass} slider-button-container-show`;
            this.sliderButtonIconClass = `${this._sliderButtonIconClass} slider-button-icon-show`;
            this.sliderButtonBadgeClass = `${this._sliderButtonBadgeClass} slider-button-count-badge-show`;

            if (this.getScreenWidth >= 768) {
                setTimeout(() => {
                    this._searchButtonClass = 'zip-search-button-in-active';
                    this._cdr.detectChanges();
                }, 300);
            } else {
                this._searchButtonClass = 'zip-search-button-in-active';
            }
        }
        if (object.event === 'input') {
            this.onInputChange(object.data);
        }
    }

    onInputChange(event: any): void {
        const value = event.target.value;
        if (value.toString().trim().length >= 5 && !isNaN(value.slice(0, 5))) {
            const zipCode = event.target.value.replace(/[e+-]/gi, '');
            event.target.value = zipCode.slice(0, 5);
            this.merchantSearchForm.patchValue({
                merchantSearchText: zipCode.slice(0, 5),
            });
        }
    }

    public get searchButtonClass(): string {
        if (this.isLoaderDisplay) {
            return 'zip-search-button-in-active';
        }
        return `${this._searchButtonClass}`;
    }

    onZipSearchBtnClick(event?: any) {
        if (event && this.getScreenWidth < 768) {
            if (event === 'submit' && this.changeEventTriggered === true) {
                this.changeEventTriggered = false;
                return;
            }
        }

        if (
            this.merchantSearchForm?.get('merchantSearchText')?.value?.toString().length == 0 &&
            !this.isInitialLoad
        ) {
            this.checkQueryParam();
        } else {
            this.validateFormData();
        }
    }

    validateFormData(stateNameStatus?: boolean) {
        let searchText = this.merchantSearchForm?.get('merchantSearchText')?.value;
        const optionsApiKeyList = this._multiSelectOptions
            .filter((item: any) => item.checked)
            .map((item: any) => item.apiKey);

        const optionsValueList = this._multiSelectOptions
            .filter((item: any) => item.checked)
            .map((item: any) => item.value);
        if ((searchText && searchText.toString().trim().length > 0) || this.isInitialLoad) {
            let categoriesValueList: any = '';
            let categoriesApiList = '';
            if (optionsValueList.length > 0) {
                categoriesValueList = `${optionsValueList.toString().replace(/\s+/g, '-').toLowerCase()}`;
            }
            if (optionsApiKeyList.length > 0) {
                categoriesApiList = this.getCategoriesApiList(optionsApiKeyList);
            }
            this._merchantFilterService.loaderDisplay(true);
            this.urlStateName = '';
            if (this.isInitialLoad && (!searchText || searchText === '')) {
                const slug = window.location.href;
                searchText = slug.split('/');
                searchText = searchText[searchText.length - 2];
            }
            this.isInitialLoad = false;

            this.handleOnSubmitSubscriptions(searchText, categoriesValueList, categoriesApiList, stateNameStatus);
        }
    }

    handleOnSubmitSubscriptions(searchText: any, categoriesValueList: any, categoriesApiList: any, stateNameStatus?: boolean) {
        //Defining the variables
        const isValidZip = (searchText as string).trim().length === 5 && !isNaN(searchText);
        const categories =
            categoriesValueList && categoriesValueList.trim().length > 0 ? categoriesValueList.split(',') : [];
        const searchParam = this.route.snapshot.queryParamMap.get('search');

        if (searchParam !== searchText && isValidZip && stateNameStatus) {
            this.fetchResultApiData(searchText, categoriesApiList, categories, isValidZip);
            return;
        }

        //Validation logic for query string search and keyword search
        if ((searchParam === searchText && isValidZip) || searchParam === null) {
            //Hit MS results API
            this.fetchResultApiData(searchText, categoriesApiList, categories, isValidZip);
        } else if (searchParam !== searchText && isValidZip) {
            //Hit the google API
            this.googleApiService.getGoogleApiResponse((searchText as string).trim(), categories);
        } else {
            this.OnSubmitApiSubscriptions(searchText, categoriesApiList, categories, isValidZip);
        }
    }

    OnSubmitApiSubscriptions(searchText: any, categoriesApiList: any, categories: any, isValidZip: boolean) {
        if (
            this.document.defaultView?.sessionStorage &&
            !this.document.defaultView?.sessionStorage?.getItem('zipCode') &&
            isValidZip
        ) {
            this.route.url.subscribe((segments) => {
                const urlSegments = segments.map((segment) => segment.path);
                const urlStateName = urlSegments[1].toLowerCase();
                this.urlStateName = urlStateName;
                this.googleApiService.getGoogleApiResponse(
                    (searchText as string).trim(),
                    [],
                    true,
                    urlStateName
                );
            });
        } else {
            this.fetchResultApiData(searchText, categoriesApiList, categories, isValidZip);
        }
    }

    fetchResultApiData(searchText: string, categoriesApiList: any, categories: any, isValidZip: boolean) {
        this._merchantSearchResults.getSearchResults(searchText, 1, 20, 50, categoriesApiList).subscribe(
            (response) => {
                if (isValidZip) {
                    this.document.defaultView?.sessionStorage.setItem('zipCode', String(searchText));
                }
                this._merchantFilterService.filteredData({
                    ...response,
                    searchText: searchText,
                    categories: categories,
                    urlStateName: this.urlStateName,
                });
            },
            (error) => {
                this._merchantFilterService.loaderDisplay(false);
                (window as any).location = location.origin + '/maintenance';
            }
        );
    }
    getCategoriesList(arr: any) {
        const strArr = arr;
        const capitalizedStr = [];

        for (const str of strArr) {
            const words = str.split(' ');
            const capitalizedWords = [];

            for (const element of words) {
                const word = element;
                const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
                capitalizedWords.push(capitalizedWord);
            }

            const capitalizedString = capitalizedWords.join(' ');
            capitalizedStr.push(capitalizedString);
        }

        return capitalizedStr.toString();
    }

    getCategoriesApiList(arr: any) {
        const strArr = arr;
        const apiKeyStr = [];

        for (const str of strArr) {
            const apiString = str;
            apiKeyStr.push(apiString);
        }

        return apiKeyStr.toString();
    }

    getCategoriesCount() {
        return this._multiSelectOptions.filter((item: any) => item.checked).length;
    }

    autoFillCategory(): void {
        if (!this.merchantSearchCategory || this.merchantSearchCategory.length <= 0) {
            return;
        }

        const lowerCaseCategory = (value: any) => (typeof value === 'string' ? value.toLowerCase() : value);

        this.merchantSearchCategory = Array.isArray(this.merchantSearchCategory)
            ? this.merchantSearchCategory.map(lowerCaseCategory)
            : this.merchantSearchCategory.toLowerCase();

        this._multiSelectOptions.forEach((obj: any) => {
            const label = lowerCaseCategory(obj.label);
            const value = lowerCaseCategory(obj.value);
            const apiKey = lowerCaseCategory(obj.apiKey);
            obj.checked =
                this.merchantSearchCategory.includes(label) ||
                this.merchantSearchCategory.includes(value) ||
                this.merchantSearchCategory.includes(apiKey);
        });

        const [, , checkedListApiKey] = this.getCheckedListItems(
            this._multiSelectOptions,
            this.checkedList,
            this.checkedListLabel,
            this.checkedListApiKey
        );
        this.categoryList = checkedListApiKey.join(',');
    }

    getCheckedListItems(
        multiSelectOptions: any,
        checkedList: any,
        checkedListLabel: any,
        checkedListApiKey: any
    ): any {
        for (const option of multiSelectOptions) {
            if (option.checked) {
                if (checkedList.indexOf(option.apiKey) === -1) {
                    checkedList.push(option.value);
                    checkedListLabel.push(option.label);
                    checkedListApiKey.push(option.apiKey);
                }
            } else {
                const index = checkedList.indexOf(option.apiKey);
                if (index !== -1) {
                    checkedList.splice(index, 1);
                    checkedListLabel.splice(index, 1);
                    checkedListApiKey.splice(index, 1);
                }
            }
        }
        return [checkedList, checkedListLabel, checkedListApiKey];
    }

    checkQueryParam() {
        this.route?.queryParams?.subscribe((params) => {
            if (params.search && params.search.toString().length > 0) {
                this._merchantFilterService.loaderDisplay(true);
                window.location.reload();
            }
        });
    }

    set formFieldContainerCustomClass(isDisplay: boolean) {
        const defaultClass = 'corporate-form-field-container form-floating merchant-search-select-form-field';
        if (isDisplay) {
            this._formFieldContainerCustomClass = `${defaultClass} pointer-none form-field-disabled`;
        } else {
            this._formFieldContainerCustomClass = `${defaultClass}`;
        }
        this._cdr.detectChanges();
    }

    get formFieldContainerCustomClass(): any {
        return this._formFieldContainerCustomClass;
    }

    set formFieldLabelCustomClass(isDisplay: boolean) {
        const defaultClass = 'form-label px-3';
        if (isDisplay) {
            this._formFieldLabelCustomClass = `${defaultClass} form-field-disabled`;
        } else {
            this._formFieldLabelCustomClass = `${defaultClass}`;
        }
        this._cdr.detectChanges();
    }

    get formFieldLabelCustomClass(): any {
        return this._formFieldLabelCustomClass;
    }

    set formFieldCategoryContainerCustomClass(isDisplay: boolean) {
        const defaultClass =
            'corporate-form-field-container form-floating merchant-search-category-form-field d-none d-xxl-block';
        if (isDisplay) {
            this._formFieldCategoryContainerCustomClass = `${defaultClass} pointer-none form-field-disabled`;
        } else {
            this._formFieldCategoryContainerCustomClass = `${defaultClass}`;
        }
        this._cdr.detectChanges();
    }

    get formFieldCategoryContainerCustomClass(): any {
        return this._formFieldCategoryContainerCustomClass;
    }

    set formFieldCategoryLabelCustomClass(isDisplay: boolean) {
        const defaultClass = 'form-label';
        if (isDisplay) {
            this._formFieldCategoryLabelCustomClass = `${defaultClass} form-field-disabled`;
        } else {
            this._formFieldCategoryLabelCustomClass = `${defaultClass}`;
        }
        this._cdr.detectChanges();
    }

    get formFieldCategoryLabelCustomClass(): any {
        return this._formFieldCategoryLabelCustomClass;
    }

    populateOptionsList() {
        if (this.merchantSearchCategory) {
            this._multiSelectOptions.forEach((obj: any) => {
                if (
                    this.merchantSearchCategory.includes(obj.label.toLowerCase()) ||
                    this.merchantSearchCategory.includes(obj.value.toLowerCase()) ||
                    this.merchantSearchCategory.includes(obj.apiKey)
                ) {
                    this.selectedOptionsList.push(obj.apiKey);
                }
            });
        }
        this.handleOnInitSubscriptions();
    }

    handleOnInitSubscriptions() {
        const keyToCheck = 'stateShortCode';
        const isNotKeyPresent =
            (this.document.defaultView?.sessionStorage &&
                this.document.defaultView?.sessionStorage?.getItem(keyToCheck) === undefined) ||
            this.document.defaultView?.sessionStorage?.getItem(keyToCheck) === null;
        const isValidZip =
            this.merchantSearchText &&
            (this.merchantSearchText as string).trim().length === 5 &&
            !isNaN(this.merchantSearchText);

        if (isNotKeyPresent && isValidZip) {
            this.googleApiService.getGoogleApiResponse((this.merchantSearchText as string).trim(), [], true);
        }

        this.LoaderDisplaysubscription = this._merchantFilterService.isLoaderDisplay$.subscribe(
            (data: boolean) => {
                this.isLoaderDisplay = data;
                this.formFieldContainerCustomClass = data;
                this.formFieldLabelCustomClass = data;
                this.formFieldCategoryContainerCustomClass = data;
                this.formFieldCategoryLabelCustomClass = data;
            }
        );

        this.stateNameStatusSubscription = this.googleApiService?.stateNameStatusEmitter$?.subscribe(
            (stateNameStatus: boolean) => {
                if (stateNameStatus) {
                    this.validateFormData(stateNameStatus);
                }
            }
        );
    }

}

export interface Selected {
    [key: string]: string | boolean | undefined;
    checked: boolean | undefined;
    label: string;
    value: string;
    apiKey: string;
}
