import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorporateButtonComponent } from './corporate-button.component';

@NgModule({
    declarations: [CorporateButtonComponent],
    imports: [CommonModule],
    exports: [CorporateButtonComponent],
})
export class CorporateButtonModule {}
