import { AfterViewInit, Directive, ElementRef, OnInit } from '@angular/core';
import { parseHtmlStringToDomElements } from '../../util';
import { emptyStrType } from './directive';

@Directive()
export abstract class AbstractCustomShadowdomHtmlDirective implements AfterViewInit, OnInit {
    protected _htmlContent: emptyStrType;

    public get htmlContent(): emptyStrType {
        return this._htmlContent;
    }

    protected _rootElementWrapper = 'div';

    /**
     * Element which wraps innerHTML content
     * in the ShadowDom. This will be the
     * root element in the ShadowDom.
     */
    public get rootElementWrapper(): string {
        return this._rootElementWrapper;
    }

    protected _styles: emptyStrType = '';

    public get styles(): emptyStrType {
        return this._styles;
    }

    protected constructor(protected elementRef: ElementRef, protected doc: Document) {}

    protected abstract htmlParsers(html: string): string;

    public shadowHost: any;

    ngOnInit() {
        this.shadowHost = this.elementRef.nativeElement.attachShadow({ mode: 'open' });
    }

    ngAfterViewInit() {
        if (this.shadowHost && this.styles) {
            /**
             * see https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_shadow_DOM#styling_the_shadow_dom
             */
            const styleElement = document.createElement('style');

            styleElement.textContent = this.styles;

            this.shadowHost.appendChild(styleElement);
        }

        if (this.shadowHost && this.htmlContent) {
            // Process sanitized html through html string parsers
            const parsed = this.htmlParsers(this.htmlContent);

            const element: HTMLElement = parseHtmlStringToDomElements(parsed, this.doc);

            this.shadowHost.appendChild(element);
        }
    }
}
