import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NuxtState } from './nuxt.model';
import * as NuxtReducer from './nuxt.reducer';

export const selectNuxtState = createFeatureSelector<NuxtState>(NuxtReducer.nuxtsFeatureKey);

/**
 * Return $nuxt.$on value.
 *
 * similar to nuxt event handler:
 * $nuxt.$on('eventHandler, (oVal) => {
 *   // do something with oVal
 * })
 *
 * @example
 * const val$ = this.store.select(selectNuxOn('nuxtEventName', myDefaultValue ));
 *
 * val$.subscribe((oVal: any) => {
 *   // do something with oVal
 * })
 *
 * @param key - key of feature flag
 * @param defaultValue - value if api did not return feature flag key
 */
export const selectNuxtOn = (key: string, defaultValue?: any) => {
    return createSelector(selectNuxtState, (state: NuxtState) => {
        if (state[key] !== undefined && state[key] !== null) {
            return state[key];
        } else if (defaultValue !== undefined && defaultValue !== null) {
            return defaultValue;
        } else {
            return false;
        }
    });
};
