<ng-container *ngIf="isBrandedDealer(); else normalFooterContent">
    <div class="container-fluid px-2 px-md-4 px-xl-10">
        <div
            *ngIf="_locationsBaseModuleData?.locations?.creditStatus"
            class="pt-4 pb-2 px-3 px-md-4 px-xl-11 text-start disclaimer-container"
            [innerHTML]="_locationsBaseModuleData?.locations?.creditCheckText | sanitizeHtml"
        ></div>
        <div
            class="pt-4 pb-2 px-3 px-md-4 px-xl-11 text-start disclaimer-container"
            [innerHTML]="_locationsBaseModuleData?.locations?.creditText | sanitizeHtml"
        ></div>
    </div>
</ng-container>

<ng-template #normalFooterContent>
    <div
        class="container-fluid px-2 px-md-4 px-xl-10"
        *ngIf="customerServiceInformation$ | async as customerServiceInformation"
    >
        <div
            class="pt-4 pb-2 px-3 px-md-4 px-xl-11 text-start disclaimer-container"
            [innerHTML]="customerServiceInformation.footerMenu.disclaimerText | sanitizeHtml"
        ></div>
    </div>
</ng-template>
