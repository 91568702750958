<div class="slick-carousel-container">
    <div class="slick-prev-panel">
        <button
            *ngIf="store.arrows$ | async"
            (click)="previousHandler()"
            [disabled]="prevDisabled"
            type="button"
            data-role="none"
            class="slick-prev-btn slick-arrow"
            aria-label="Previous"
            role="button"
        >
            <svg
                filename="chevron_left_FILL0_wght400_GRAD0_opsz24.svg"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
            >
                <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
            </svg>
        </button>
    </div>
    <div #slickList class="slick-list" [style.width.px]="slickListWidth$ | async">
        <div
            #slickTrack
            class="slick-track"
            [style.transform]="translateSlickTrack$ | async"
            [style.transition]="transition$ | async"
            [ngClass]="{ draggable: store.draggable$ | async }"
            (mousedown)="dragStart($event)"
            (mousemove)="drag($event)"
            (mouseup)="dragEnd($event)"
            (mouseleave)="dragEnd($event)"
            (touchstart)="touchStart($event)"
            (touchmove)="touchMove($event)"
            (touchend)="touchEnd($event)"
        >
            <ng-content></ng-content>
        </div>
    </div>
    <div class="slick-next-panel">
        <button
            *ngIf="store.arrows$ | async"
            [disabled]="nextDisabled"
            type="button"
            data-role="none"
            class="slick-next-btn slick-arrow"
            aria-label="Next"
            (click)="nextHandler()"
            role="button"
        >
            <svg
                filename="chevron_right_FILL0_wght400_GRAD0_opsz24.svg"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
            >
                <path d="m376-240-56-56 184-184-184-184 56-56 240 240-240 240Z" />
            </svg>
        </button>
    </div>
</div>
<div class="slick-dots" *ngIf="store.dots$ | async">
    <button
        type="button"
        class="slick-dot-btn"
        *ngFor="let dot of $dots | async"
        [ngClass]="{ 'slick-dot-active': currentSlide$.value === dot }"
        (click)="changeSlide(dot)"
    >
        <span class="slick-dot"></span>
    </button>
</div>
