import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { AffThemePalette } from '@janet/shared/domain';

@Component({
    selector: 'aff-bouncy-flat-loader-button',
    templateUrl: './aff-bouncy-flat-loader-button.component.html',
    styleUrls: ['./aff-bouncy-flat-loader-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AffBouncyFlatLoaderButtonComponent {
    @HostBinding('class') hostClasses = 'aff-bouncy-flat-loader-button';

    @Input() design: AffThemePalette = 'aff-primary';
    @Input() color: ThemePalette = undefined;
    @Input() size = 11;
    @Input() showLoader = true;
    @Input() width = 80;
    @Input() disabled = false;

    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

    loadAction() {
        this.loading.emit(true);
        this.showLoader = true;
    }
}
