import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, HostListener, Inject, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { NativeFormService } from '../../services/native-form.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { GFORM_SUBMIT_DATA_QUERY } from '../../queries/data.query';
import { AccordionService } from 'libs/corporate/ui-corporate/src/lib/services/accordion.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LocalStorage } from '@janet/corporate/domain';
import { fileUploadProp } from 'libs/corporate/ui-corporate/src/lib/components/corporate-file-upload/corporate-file-upload.component';


@Component({
    selector: 'janet-navite-forms',
    templateUrl: './navite-forms.component.html',
})
export class NaviteFormsComponent implements OnInit, OnDestroy {
    @ViewChild('recpatchaField') recpatchaField: TemplateRef<any>;
    _subscriptions: Subscription;
    public structuredFormData: any;
    formPage: any;
    currentPageIndex: number = 0;
    nativeForm: FormGroup;
    _moduleData: any;
    _moduleIndex: any;
    nativeFormData = {};
    showReCaptchaField$: any;
    _isPagination = false;
    stepperContainerClasses = 'd-none d-md-flex flex-md-nowrap justify-content-md-between p-0 m-0 aff-stepper-container';
    _stepperIndicatorContainerCustomClass = 'stepper-indicator-container d-flex flex-row align-items-center justify-content-between gap-3';
    _stepperIndicatorClasses = 'indicator d-flex align-items-center justify-content-center text-center';
    _stepperTitleClasses = 'title text-center';
    isButtonAnchor = false;
    buttonDefaultClass = 'px-3 py-25 p-md-7 d-inline-flex flex-row justify-content-center align-items-center aff-button-state';
    sectionAccordionHeaderClass = ' d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between text-start';
    public getScreenWidth: any;
    nativeFormArray: any;
    nativeFormGroupArray: FormGroup[] = [];
    accordionFormGroup: any = {};
    accordionSectionFormGroupArray: any[] = [{}];
    pageWiseAccordionSectionFormGroupArray: any = [];
    accordionList: any = [];
    hiddenFormGroup: any = {};
    hiddenSectionFormGroupArray: any[] = [{}];
    accordionSingleToggle = true;
    formAccordionId = 'nativeFormAccordion_';
    accordionToggle: any = [];
    customAccordionEvent: Subscription;
    headerInfoText = 'Delete Store';
    additionalAcrdnInfoClass = ' text-start mb-10'
    checkBoxFormFields: any = [];
    fileUploadArray: any = [];
    checkBoxValuesArray: any = [];
    existingSectionInvalid = false;
    invalidErrorMsg = "The existing store details are incomplete.";
    reCaptchaCount = 0;
    hiddenNodes = [];
    queryParamValue: any = undefined;
    nativeFormModuleData: any = undefined;
    subscriptionsMap: Map<string, Subscription> = new Map();
    formSubmitted = false;

    constructor(
        public _nativeFormService: NativeFormService,
        private _changeDetectorRef: ChangeDetectorRef,
        public formBuilder: FormBuilder,
        private _accordionService: AccordionService,
        private _apollo: Apollo,
        private transferState: TransferState,
        @Inject(PLATFORM_ID) private platformId: any,
        private localStorage: LocalStorage,
    ) {
        this.getScreenWidth = window.innerWidth;
        this.checkBoxFormFields = this._nativeFormService.checkBoxFormFields;
        this._nativeFormService.structuredGFormData = {};
        this._nativeFormService.loopIndex = 0;
        this._nativeFormService.hiddenNodes = [];
        this._nativeFormService.formModuleId = undefined;
        this._nativeFormService.formModuleIndex = undefined;
        this._nativeFormService.paginations = false;
    }

    ngOnInit(): void {
        this.structuredFormData = null;
        this._isPagination = false;
        this.hiddenNodes = [];
        this.queryParamValue = undefined;
        this.nativeFormModuleData = undefined;
        this.formSubmitted = false;

        if (this._moduleData && (typeof this._moduleIndex === 'number')) {
            this.initializeNativeFormModule();
        }

        this.customAccordionEvent = this._accordionService.customAccordionEvent.subscribe((data: any) => {
            if (data?.type === 'header') {
                this.getAcrdnToggleIndex(data?.id, 'subscribe');
            }
            else if (data?.type === 'info') {
                this.removeSections(data?.id, 'subscribe');
            }
        });

        if (this.localStorage && this.localStorage?.getItem('queryparams') != undefined && this.localStorage?.getItem('queryparams') != null) {//NOSONAR
            const sugarCRMID = this.getSugarCRMID(this.localStorage?.getItem('queryparams'));
            if (sugarCRMID) {
                this.queryParamValue = sugarCRMID.toString();
            }
        }
    }

    ngOnDestroy(): void {
        if (this._subscriptions) {
            this._subscriptions.unsubscribe();
        }
        if (this.customAccordionEvent) {
            this.customAccordionEvent.unsubscribe();
        }

    }

    @Input()
    set moduleData(data: any) {
        this._moduleData = data;
    }

    @Input()
    set moduleIndex(index: any) {
        this._moduleIndex = index;
    }

    formLabel(field: any) {
        if (field?.label?.toString().toLowerCase().trim().length > 0 && field?.labelPlacement?.toString().toLowerCase() === 'hidden') {
            return `${field?.label}${field?.isRequired ? '*' : ''}`;
        } else if (field?.labelPlacement?.toString().toLowerCase() === 'inherit' && field?.formFieldCustomLabelText?.toString().toLowerCase().trim().length > 0) {
            return `${field?.formFieldCustomLabelText}${field?.isRequired ? '*' : ''}`;
        } else {
            return '';
        }
    }

    isCaptchaInvisible() {
        const captchaType = this.structuredFormData?.gFormSettings?.recaptcha?.type?.toString()?.toLowerCase();

        return captchaType === "invisible";
    }

    handleEvents() {
        this._changeDetectorRef.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
    }

    nextPage(event: Event): any {
        event.preventDefault();
        if (!this.isCurrentPageValid()) {
            return;
        }
        const pages = this.getFormPages();
        if (this.currentPageIndex < pages.length - 1) {
            this.currentPageIndex++;
            this.populateFormPage();
        }
    }

    previousPage(event: Event) {
        event.preventDefault();
        if (this.currentPageIndex > 0) {
            this.currentPageIndex--;
            this.populateFormPage();
        }
    }

    populateFormPage() {
        const pages = this.getFormPages();
        this.formPage = pages[this.currentPageIndex];
        this.scrollToElement();
    }

    getFormPages(): any[] {
        return this.structuredFormData?.gFormsData?.formPageInformations?.formPages || [];
    }

    scrollToElement() {
        const element = document?.getElementById('nativeFormContainer_' + this._moduleData.ddGfFormIds + '_' + this._moduleIndex);
        const navbarHeight = 75;

        if (element) {
            window?.scrollTo({
                top: element.offsetTop - navbarHeight,
                behavior: 'smooth'
            });
        }
    }

    get buttonCardClass(): string {
        let classes = 'col-12 col-md-12 m-0 p-0 mt-4';

        if (this._isPagination) {
            classes = `${classes} py-4 py-md-10 px-3 corporate-card-container`;
            if (this.currentPageIndex !== 0) {
                classes = `${classes} d-flex justify-content-between`;
            } else {
                classes += (this.getScreenWidth >= 768) ? ' d-flex justify-content-end' : ' d-block';
            }
        } else {
            classes = `${classes} d-block`;
        }

        return classes;
    }

    btnCustomClass(btn: string, type?: string): string {
        let classes = this.buttonDefaultClass;

        classes += (btn === 'previous') ? ' aff-button-light form-button-properties' : ' aff-button form-button-properties';

        if (this._isPagination && this.currentPageIndex !== 0 && !type) {
            classes = `${classes} form-button-width`;
        } else {
            classes += (this.getScreenWidth >= 768) ? ' form-button-width' : ' form-button-min-width';
        }

        return classes;
    }

    getButtonType(btn: string) {
        if (this._isPagination) {
            if (btn === 'previous') {
                return 'button';
            } else {
                return (this.currentPageIndex < this.getFormPages().length - 1) ? 'button' : 'submit';
            }
        } else {
            return 'submit';
        }
    }

    buildFormGroup() {//NOSONAR
        this.nativeForm = this.formBuilder.group({});
        const formPages: any = this.getFormPages();

        this.nativeFormArray = this.formBuilder.array([]);
        this.nativeFormGroupArray = [];
        this.accordionList = [];
        this.accordionToggle = [];
        this.fileUploadArray = [];
        this.checkBoxValuesArray = [];
        if (formPages?.length > 0) {
            formPages?.forEach((page: any, idx: any) => {
                const formGroup: any = {};
                this.accordionSectionFormGroupArray = [{}];

                const processFields = (fields: any[], section: any) => {

                    if (section?.sectionDisplay) {
                        if (!section?.sectionAccordion || (section?.sectionAccordion && section?.accordionNumber === 1)) {
                            assignFormGroup(fields, formGroup);
                        }
                        if (section?.sectionAccordion && section?.accordionNumber >= 1) {
                            assignFormGroup(fields, this.accordionFormGroup);
                        }
                    } else {
                        assignFormGroup(fields, this.hiddenFormGroup);
                    }
                };

                const assignFormGroup = (fields: any[], group: any) => {
                    fields.forEach((control) => {
                        group[control?.formFieldName] = [control?.formFieldValue, control?.formFieldValidators];
                    });
                };

                page?.pageSections?.forEach((section: any) => {
                    this.hiddenFormGroup = {};
                    this.accordionFormGroup = {};
                    section?.sectionFields?.length && processFields(section?.sectionFields, section);


                    if (section?.subSections?.length > 0) {
                        section?.subSections?.forEach((subSection: any) => {
                            const subSectionField = subSection?.subSectionFields;
                            subSectionField?.length && processFields(subSectionField, section);
                        });
                    }


                    if (!section?.sectionDisplay && !section?.sectionAccordion) {
                        this.hiddenSectionFormGroupArray[0]['section_' + section?.id] = this.hiddenFormGroup;
                    }
                    if (section?.sectionDisplay && section?.sectionAccordion) {
                        this.accordionSectionFormGroupArray[0]['accordion_' + section?.accordionNumber] = this.accordionFormGroup;
                    }
                });

                /**
                * @description Page-wise-accordion group
                */

                if (page?.pageCustomField) {
                    const acrdnGroup = this.accordionSectionFormGroupArray[0];
                    this.pageWiseAccordionSectionFormGroupArray['page_' + (idx)] = acrdnGroup;
                    const maxLimit = page?.pageCustomField?.customFields?.defaultValue ? parseInt(page?.pageCustomField?.customFields?.defaultValue) : 1;
                    const acrdnListObj = { currentAccordionNumber: 1, maxAccordionLimit: maxLimit };
                    this.accordionList.push(acrdnListObj);
                    this.setAccordionToggleValues(maxLimit);
                } else {
                    this.pageWiseAccordionSectionFormGroupArray['page_' + (idx)] = null;
                    const acrdnListObj = { currentAccordionNumber: 1, maxAccordionLimit: 1 };
                    this.accordionList.push(acrdnListObj);
                    this.setAccordionToggleValues(1);
                }

                /**
                 * @description Include page-consent in the form group
                 */
                if (page?.pageConsent) {
                    formGroup[page?.pageConsent?.formFieldName] = [page?.pageConsent?.formFieldValue, page?.pageConsent?.formFieldValidators];
                }
                this.nativeFormArray.push(this.formBuilder.group(formGroup));
                this.nativeFormGroupArray.push(this.formBuilder.group(formGroup));
            });

            this.formPage = formPages[this.currentPageIndex];
            this.handleEvents();
        }
    }

    _isDisplayNativeForm() {
        return (this.formPage && this.nativeFormArray && (this.nativeFormArray.length > 0) && (this.nativeFormArray.at(this.currentPageIndex) as FormGroup));
    }

    _isSectionDisplay(section: any) {
        const conditionalLogic = section?.conditionalLogic;
        const rulesArray = conditionalLogic?.rules;

        if (rulesArray?.length > 0 && (this.nativeFormArray && (this.nativeFormArray.at(this.currentPageIndex) as FormGroup))) {
            const formGroupValues = this.getFieldValues((this.nativeFormArray.at(this.currentPageIndex) as FormGroup).value, 'conditonalLogic');

            const _isFieldValueMatched = rulesArray.every((rule: any) => {
                const field = formGroupValues?.find((value: any) => value?.id === rule?.fieldId);
                return field?.value?.toString()?.toLowerCase() === rule?.value?.toString()?.toLowerCase();
            });

            if (_isFieldValueMatched) {
                this.appendFormGroup('section', section, this.hiddenSectionFormGroupArray);

            } else {
                this.removeFormGroup('section', section, this.hiddenSectionFormGroupArray);
            }
            return _isFieldValueMatched;
        }

        return false;
    }

    _isActiveFormControl(controlName: any) {
        if ((this.nativeFormArray.at(this.currentPageIndex) as FormGroup)?.controls[controlName]) {
            return true;
        }
        return false;
    }

    appendFormGroup(type: any, section?: any, formGroupArray?: any) {
        let fields: any;
        if (type === 'section') {
            fields = formGroupArray[0]?.[`section_${section?.id}`];
        } else if (type === 'accordion') {
            fields = this.getFormFieldGroups();
        }

        this.addFormControls(fields);
    }

    removeFormGroup(type: any, section?: any, formGroupArray?: any) {
        let fields: any;
        if (type === 'section') {
            fields = formGroupArray[0]?.[`section_${section?.id}`];
        } else if (type === 'accordion') {
            fields = this.getFormFieldGroups();
        }
        this.removeFormControls(fields, type);
    }

    addFormControls(fields: any) {
        if (fields) {
            for (const field in fields) {
                const [controlName, controlValue, controlValidators] = [field, fields[field][0], fields[field][1]];
                (this.nativeFormArray.at(this.currentPageIndex) as FormGroup)?.addControl(controlName, this.formBuilder.control(controlValue, controlValidators));//NOSONAR
            }
        }
    }

    removeFormControls(fields: any, type?: any) {//NOSONAR
        if (fields) {
            for (const field in fields) {
                const [controlName] = [field];
                if ((this.nativeFormArray.at(this.currentPageIndex) as FormGroup).contains(controlName)) {
                    if (controlName && controlName?.toString()?.split('_')[1]?.toString()?.trim()?.toLowerCase() === 'checkboxfield') {
                        const existingValueIndex = this.checkBoxValuesArray?.findIndex((item: any) => item?.id === parseInt(controlName?.toString()?.split('_')[0]));
                        if (existingValueIndex !== -1) {
                            this.checkBoxValuesArray.splice(existingValueIndex, 1);
                        }
                    }
                    (this.nativeFormArray.at(this.currentPageIndex) as FormGroup).removeControl(controlName);
                }
            }
            if (type && type === 'accordion' || type === 'section') {
                this.removeFileUploadValues(fields);
            }
        }
    }


    removeFileUploadValues(fields: any) {//NOSONAR
        if (fields) {
            const fileUploadArr = Object.keys(fields || {})?.filter(key => key?.split("_")[1]?.toLowerCase() === "fileuploadfield");//NOSONAR
            fileUploadArr.forEach((value: any) => {
                const id = parseInt(value.split("_")[0]);
                this.fileUploadArray = this.fileUploadArray.filter((obj: any) => obj.id !== id);
                Object.keys(fileUploadProp).forEach((prop: any) => {
                    const propId = parseInt(prop.split("_")[0]);
                    if (propId === id) {
                        delete fileUploadProp[prop];
                    }
                });
            });
        }
    }

    checkFormControlValidity(type: any): any {
        let fields: any;
        if (type === 'accordion') {
            fields = this.getFormFieldGroups();
            return this.areFormFieldsValid(fields);
        }
    }

    areFormFieldsValid(fields: any): boolean {
        const formGroup = (this.nativeFormArray.at(this.currentPageIndex) as FormGroup);
        for (const field in fields) {
            const controlName = field;

            if (formGroup?.contains(controlName)) {
                const control = formGroup.get(controlName);
                if (control && !control.valid) {
                    return false;
                }
            } else {
                return false;
            }
        }
        return true;
    }

    getFormFieldGroups() {
        let fields;
        const crtPage = this.currentPageIndex;
        const acrdnNum = this.accordionList[this.currentPageIndex]['currentAccordionNumber'];
        fields = this.pageWiseAccordionSectionFormGroupArray['page_' + crtPage]['accordion_' + acrdnNum];
        return fields;
    }


    _isPostCustomField() {
        if (this.formPage?.pageCustomField?.customFields?.defaultValue) {
            return (parseInt(this.formPage?.pageCustomField?.customFields?.defaultValue) > 1);
        }
        return false;
    }

    setAccordionToggleValues(maxLimit?: any) {
        const arr = [];
        for (let i = 0; i < maxLimit; i++) {
            arr.push(false);
        }
        this.accordionToggle.push(arr);
    }

    addSections(event: any): any {//NOSONAR
        event.preventDefault();
        const customField = this.formPage?.pageCustomField?.customFields;
        if (customField) {
            if (!this.checkFormControlValidity('accordion')) {
                this.existingSectionInvalid = true;
                setTimeout(() => {
                    this.existingSectionInvalid = false;
                    this.handleEvents();
                }, 3000);
                const fields = this.getFormFieldGroups();
                const formGroup = (this.nativeFormArray.at(this.currentPageIndex) as FormGroup);
                if (fields) {
                    for (const field in fields) {
                        const controlName = field;

                        if (formGroup?.contains(controlName)) {
                            this.markFieldAsTouched(controlName, this.currentPageIndex);
                        }
                    }
                }
                return false;
            }

            if (this.checkFormControlValidity('accordion') && this.accordionList[this.currentPageIndex]['currentAccordionNumber'] < this.accordionList[this.currentPageIndex]['maxAccordionLimit']) {
                (this.accordionList[this.currentPageIndex]['currentAccordionNumber'])++;
                this.appendFormGroup('accordion');
                this.getAcrdnToggleIndex(this.accordionList[this.currentPageIndex]['currentAccordionNumber'], 'addSection');
            }
        }
    }

    removeSections(data: any, type: any) {
        if (this.accordionList[this.currentPageIndex]['currentAccordionNumber'] > 1) {
            this.removeFormGroup('accordion');
            (this.accordionList[this.currentPageIndex]['currentAccordionNumber'])--;
            this.getAcrdnToggleIndex(this.accordionList[this.currentPageIndex]['currentAccordionNumber'], 'removeSection');
        }
    }

    getAcrdnToggleIndex(data: any, type: any) {
        let index: any;
        if (type === 'subscribe') {
            index = (parseInt(data?.toString().split('_')?.[2]) - 1);
        } else if (type === 'addSection' || type === 'removeSection') {
            index = (data - 1);
        }

        if (index != undefined && index != null && !isNaN(index)) {
            this.onAccordionToggle(index, type);
        }
    }

    onAccordionToggle(index: number, type?: any) {
        const accordionToggleArr = this.accordionToggle[this.currentPageIndex];
        for (let i = 0; i < accordionToggleArr.length; i++) {
            if (index === i) {
                accordionToggleArr[i] = !accordionToggleArr[i];
            } else {
                accordionToggleArr[i] = false;
            }
        }

        if (type && type === 'addSection') {
            this.scrollToCurrentAcrdn(index);
        }
    }

    scrollToCurrentAcrdn(index: any) {
        setTimeout(() => {
            const element = document?.getElementById('nativeFormAccordion_' + this.currentPageIndex + '_' + (index + 1));
            const navbarHeight = 100;

            if (element) {
                window?.scrollTo({
                    top: element.offsetTop - navbarHeight,
                    behavior: 'instant'
                });
            }
        }, 300);
    }

    getSubTitle(section: any) {//NOSONAR
        const [acrdnNonRequiredFields, requiredFields]: [any, any] = [this._nativeFormService.acrdnNonRequiredFields, []];
        let controlValues = [];
        if (section?.sectionAccordion && this.shouldProcessAccordion(section)) {
            for (const sectionField of section.sectionFields) {
                const [secType, secLabel] = [sectionField?.inputType?.toLowerCase(), sectionField?.label?.toLowerCase()];
                let isValidType = true;
                for (const field of acrdnNonRequiredFields) {
                    if (secType.indexOf(field) !== -1 || secLabel.indexOf(field) !== -1) {
                        isValidType = false;
                        break;
                    }
                }
                if (isValidType) {
                    requiredFields.push(sectionField);
                }
            }
            controlValues = this.getControlValues(requiredFields);

            return controlValues.length > 1 ? this.formattedValues(controlValues) : '';

        } else {
            return section?.description || '';
        }
    }

    shouldProcessAccordion(section: any): boolean {
        const accordionNumber = section?.accordionNumber;
        return this.accordionList[this.currentPageIndex]?.currentAccordionNumber > 1 &&
            !this.accordionToggle[this.currentPageIndex]?.[accordionNumber - 1];
    }

    getControlValues(fields: any) {
        const formGroup: any = (this.nativeFormArray.at(this.currentPageIndex) as FormGroup);
        const values: any = [];
        for (const field of fields) {
            const controlName = field?.formFieldName;
            if (formGroup?.contains(controlName)) {
                values.push(formGroup?.get(controlName)?.value);
            }
        }

        return values;
    }

    formattedValues(formFieldValues: any): string {
        const chunkSize = Math.ceil(formFieldValues.length / 2);
        const paragraphs = this.chunkArray(formFieldValues, chunkSize)
            .map(chunk => `<p class='m-0 p-0'>${chunk.join(' ')}</p>`);
        return paragraphs.join('');
    }

    chunkArray(array: any[], size: number): any[] {
        const chunkedArray = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
    }

    getCheckBoxFormFieldType(typeName: string) {
        if (typeName?.toString().toLowerCase()) {
            const isMiscField = this.checkBoxFormFields.some((field: any) => {
                return typeName?.toString().toLowerCase() === field.name.toLowerCase();
            });
            return isMiscField;
        }
        return false;
    }

    getFloatingLabelText(sectionField: any) {
        if (sectionField?.label?.toString()?.trim()?.length > 0 && sectionField?.labelPlacement?.toString()?.toLowerCase() === 'inherit') {
            return `${sectionField?.label}${sectionField?.isRequired ? '*' : ''}`;
        } else {
            return '';
        }
    }

    onCheckBoxValueChange(isChecked: any, section: any, field: any) {
        if (field && field?.__typename?.toString()?.toLowerCase() === "checkboxfield") {
            const id: any = parseInt(field?.id);
            if (id) {
                if (isChecked) {
                    const obj = { inputId: parseFloat((id + '.' + 1)), value: field?.formFieldOptions[0]?.value ?? '' };
                    const checkboxValues = [obj];
                    this.checkBoxValuesArray.push({ id: id, checkboxValues: checkboxValues });
                } else {
                    const existingValueIndex = this.checkBoxValuesArray?.findIndex((item: any) => item?.id === id);
                    if (existingValueIndex !== -1) {
                        this.checkBoxValuesArray.splice(existingValueIndex, 1);
                    }
                }
                this.populateFormFieldValues(isChecked, section, field);
            }
        }
    }

    onFileInputValueChange(data: any, field: any) {
        const id: any = parseInt(field.split("_")[0]);

        if (id) {
            const existingFileIndex = this.fileUploadArray?.findIndex((item: any) => item?.id === id);
            if (data?.type === 'upload') {
                const file = (data?.obj?.target as any)?.files[0]; //NOSONAR
                const uploadFile = [file];

                if (existingFileIndex !== -1) {
                    this.fileUploadArray[existingFileIndex].fileUploadValues = uploadFile;
                } else {
                    this.fileUploadArray.push({ id: id, fileUploadValues: uploadFile });
                }
            } else {
                if (existingFileIndex !== -1) {//NOSONAR
                    this.fileUploadArray.splice(existingFileIndex, 1);
                    this.setFormFieldValue(field, '', this.currentPageIndex);
                }
            }
        }
    }

    populateFormFieldValues(isChecked: any, section: any, field: any, isCompCreated?: any) {//NOSONAR
        if (field && field?.__typename?.toString()?.toLowerCase() === "checkboxfield") {
            const id: any = parseInt(field?.id);
            if (id) {
                const isAutoFillCheckBox = (field?.formFieldAutoFillCheckBox);
                if ((section?.sectionChild || section?.subSectionChild) && (section?.parentId || section?.subSectionParentId) && isAutoFillCheckBox) {
                    const parentId = (section?.parentId || section?.subSectionParentId);
                    const [parentFields, parentFieldPageIdx] = this.getParentFields(parentId);
                    const fields = (section?.sectionFields && section?.sectionFields.length > 0) ? section?.sectionFields : section?.subSectionFields;
                    const matchedFields = this.getMatchingFields(parentFields, fields, field);
                    this.setFieldsValue(matchedFields, parentFieldPageIdx, isChecked, isCompCreated);
                }
            }
        }
    }

    getParentFields(id: any) {
        const pages = this.getFormPages();
        let parentFieldsSection: any;
        let curPageIdx: any;
        const pageFields = [];

        for (let i = 0; i < pages.length; i++) {
            parentFieldsSection = pages[i].pageSections.find((section: any) => (section?.id === id));
            if (parentFieldsSection) {
                curPageIdx = i;
                break;
            }
        }

        if (parentFieldsSection && parentFieldsSection?.sectionFields) {//NOSONAR
            for (const field of parentFieldsSection.sectionFields) {
                if (field?.__typename?.toString()?.toLowerCase() === "fileuploadfield") {
                    continue;
                }
                pageFields.push(field);
            }
        }

        return [pageFields, curPageIdx];
    }

    getMatchingFields(parentFields: any, childFields: any, currentField: any) {
        const parentFlds = parentFields;
        const matchedField = [];
        if (childFields) {//NOSONAR
            for (const field of childFields) {

                if (field?.__typename?.toString()?.toLowerCase() === "fileuploadfield" || field?.id === currentField?.id) {
                    continue;
                }

                const fld = parentFlds.find((fld: any) => {
                    let parentLabel = fld?.label?.toString()?.toLowerCase();
                    const childLabel = field?.label?.toString()?.toLowerCase();

                    if (parentLabel) {
                        parentLabel = parentLabel.split(/[^a-zA-Z0-9]+/);
                    }

                    return parentLabel && childLabel && parentLabel.some((substring: any) => childLabel.includes(substring));
                });

                if (fld) {
                    matchedField.push({ childFieldName: field?.formFieldName, parentFieldName: fld?.formFieldName });
                }
            }
        }

        return matchedField;
    }

    setFieldsValue(matchedFields: any, parentFieldPageIdx: any, isChecked: any, isCompCreated?: any) {
        for (const field of matchedFields) {
            const formGroup: any = (this.nativeFormArray.at(parentFieldPageIdx) as FormGroup);
            const parentFieldName = field?.parentFieldName;
            const childFieldName = field?.childFieldName;

            if (isChecked && formGroup?.contains(parentFieldName)) {
                this.setFormFieldValue(childFieldName, formGroup?.get(parentFieldName)?.value, this.currentPageIndex);
                const parentControl = formGroup.get(parentFieldName);
                if (parentControl) {
                    const subscription = parentControl?.valueChanges?.subscribe((newValue: any) => {
                        this.setFormFieldValue(childFieldName, newValue, this.currentPageIndex);
                    });
                    this.subscriptionsMap.set(childFieldName, subscription);
                }
                this.markFieldAsTouched(field?.childFieldName, this.currentPageIndex);
            } else {
                if (!isCompCreated) {//NOSONAR
                    const subscription = this.subscriptionsMap?.get(childFieldName);
                    if (subscription) {
                        subscription?.unsubscribe();
                        this.subscriptionsMap?.delete(childFieldName);
                    }
                    this.setFormFieldValue(childFieldName, '', this.currentPageIndex);
                }
            }
        }
    }

    setFormFieldValue(field: any, value?: any, index?: any): any {
        const formGroup = (this.nativeFormArray.at(index) as FormGroup);
        if (formGroup?.contains(field)) {
            formGroup?.get(field)?.setValue(value);
        }
    }

    patchFormFieldValue(field: any, value?: any): any {
        const formGroup = (this.nativeFormArray.at(this.currentPageIndex) as FormGroup);
        if (formGroup?.contains(field)) {
            formGroup?.patchValue({
                field: value
            });
        }
    }

    markFieldAsTouched(field: any, index: any) {
        const formGroup = (this.nativeFormArray.at(index) as FormGroup);
        if (formGroup?.contains(field)) {
            formGroup?.get(field)?.markAsTouched();
        }
    }

    getFormFieldCustomClass(field: any, section: any) {//NOSONAR
        if ((section?.sectionChild || section?.subSectionChild) && (section?.parentId || section?.subSectionParentId)) {
            const fields = (section?.sectionFields && section?.sectionFields.length > 0) ? section?.sectionFields : section?.subSectionFields;
            if (fields) {
                const checkBoxField = fields.find((field: any) => {
                    return ((field?.__typename?.toString()?.toLowerCase() === 'checkboxfield') && (field?.formFieldAutoFillCheckBox));//NOSONAR
                });

                if (checkBoxField) {
                    const value = this.getControlValues([checkBoxField]);
                    if ((value[0]?.toString()?.trim()?.length > 0) && value[0] === true) {
                        return 'form-control pointer-none';
                    }
                }
            }
        }

        return 'form-control';
    }

    getFormFieldContainerClass(field: any) {
        const classes = "corporate-form-field-container form-floating ";
        if (field?.__typename?.toString()?.trim()?.toLowerCase() === 'selectfield' && parseInt(field?.layoutGridColumnSpan) === 12) {
            return `${classes} col-md-6 field-width `;
        }
        return classes;
    }

    initiateRecaptchaFields() {
        this.reCaptchaCount = this._nativeFormService.reCaptchaCount;
        if ((this._nativeFormService.reCaptchaStates.length > 0) && this._nativeFormService.reCaptchaStates[this.reCaptchaCount]) {
            this._nativeFormService.reCaptchaCount++;
            this.showReCaptchaField$ = this._nativeFormService.getReCaptchaState(this.reCaptchaCount);
            if (this.reCaptchaCount === 0) {
                this._nativeFormService.updateCaptchaState(0, true);
                this._changeDetectorRef.detectChanges();
            }
        }
    }

    onCurrentCaptchaReady() {
        const nextCaptcha = (this.reCaptchaCount + 1);
        if (this._nativeFormService.reCaptchaStates[nextCaptcha]) {
            this._nativeFormService.updateCaptchaState(nextCaptcha, true);
            this._changeDetectorRef.detectChanges();
        }
    }

    hiddenFieldValue() {
        const hiddenField: any = this.hiddenNodes?.find((node: any) => {
            return (node?.label?.toString()?.trim()?.toLowerCase() === this._nativeFormService.hiddenFieldName);
        });
        let field: any = [];
        if (hiddenField) {
            field = [{ id: hiddenField?.id, value: (this.queryParamValue ?? "") }];
        }
        return field;
    }


    getPageConsentDescription(pageConsent: any) {
        const response = (pageConsent?.description)?.replace(/[\r\n]+/g, '');
        const data = response ? JSON.parse(response) : null;
        if (data?.subTitle) {
            return data.subTitle;
        }
        return '';
    }

    getPageConsentTerms(pageConsent: any) {
        const response = (pageConsent?.description)?.replace(/[\r\n]+/g, '');
        const data = response ? JSON.parse(response) : null;
        if (data?.terms) {
            return data.terms;
        }
        return '';
    }

    onPasswordTypeChange(value: boolean, field: any) {
        field['isPasswordType'] = value;
        this._changeDetectorRef.detectChanges();
    }

    getSugarCRMID(url: any): any {
        if (url) {
            const paramsArray = url?.split('scrmacctid=');
            if (paramsArray?.length > 1) {
                const scrmId = paramsArray[1]?.split('&');
                return scrmId[0];
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    isFormSubmitted(){
        const pages = this.getFormPages();
        if(this.formSubmitted === true && (this.currentPageIndex === (pages.length - 1))){
            return true;
        }
        return false;
    }

    isCurrentPageValid() {
        return (this.nativeFormArray.at(this.currentPageIndex) as FormGroup).valid;
    }

    initializeNativeFormModule() {
        const NATIVE_FORM_MODULE_DATA_KEY = makeStateKey<any>('nativeFormModuleData_' + this._moduleData?.ddGfFormIds);
        if (isPlatformBrowser(this.platformId)) {
            this.nativeFormModuleData = this.transferState?.get(NATIVE_FORM_MODULE_DATA_KEY, null);
            if (this.nativeFormModuleData) {
                this.populateNativeFormModule();
            }
        } else if (isPlatformServer(this.platformId)) {
            this._nativeFormService.getNativeFormModuleData(this._moduleData?.ddGfFormIds).subscribe((responseData: any) => {
                this.transferState?.set(NATIVE_FORM_MODULE_DATA_KEY, responseData);
            });
        }
    }

    populateNativeFormModule() {
        const structuredData = this._nativeFormService.getStructuredGFormData(this.nativeFormModuleData, this._moduleData?.ddGfFormIds, this._moduleIndex);
        if (structuredData) {
            this.structuredFormData = structuredData;
            this.hiddenNodes = this._nativeFormService.hiddenNodes;
            this._isPagination = this.structuredFormData?.gFormsData?.formPageInformations?.pagination;
            if (this.getFormPages()) {
                this.buildFormGroup();
                this.initiateRecaptchaFields();
            }
        }
    }

    onSubmit() {
        if (this.nativeFormArray.valid) {
            this.formSubmitted = true;
            this.nativeFormData = this.getStructuredNativeFormData(this.nativeFormArray?.value);
            const fieldValues = [...this.getFieldValues(this.nativeFormData, 'submit'), ...this.fileUploadArray, ...this.checkBoxValuesArray, ...this.hiddenFieldValue()];
            const input = {
                id: parseInt(this._moduleData.ddGfFormIds),
                fieldValues,
                saveAsDraft: false,
                sourcePage: 2,
                targetPage: 0,
            };
            this.submitNativeFormData(input);
        }
    }

    getStructuredNativeFormData(formArr: any) {
        if (formArr && formArr.length > 0) {
            return Object.assign({}, ...formArr);
        }
        return {};
    }

    getFieldValues(data: any, type: string): any {//NOSONAR
        const fieldValues = [];

        for (const key in data) {
            const [id, fieldName]: [any, any] = [parseInt(key.split("_")[0]), (key.split("_")[1]).toString().toLowerCase()];
            const obj: any = {};
            obj["id"] = id;

            if (fieldName === 'fileuploadfield' || fieldName === 'checkboxfield') {
                continue;
            }

            if (type === 'submit') {
                let fieldValue: any = "";
                if (data[key]) {
                    fieldValue = (data[key])?.toString()?.trim()?.length > 0 ? (data[key])?.toString() : "";
                }

                if (fieldName === 'emailfield') {
                    obj["emailValues"] = { value: fieldValue };
                } else {
                    obj["value"] = fieldValue;
                }
            } else {
                obj["value"] = data[key] || '';
            }

            fieldValues.push(obj);
        }
        return fieldValues;
    }

    submitNativeFormData(data: any) {
        const input: any = data;
        this._apollo.mutate({
            mutation: GFORM_SUBMIT_DATA_QUERY,
            variables: { input },
            context: {
                useMultipart: true,
            },
        }).subscribe(
            (response: any) => {
                //Redirect to respective pages based on response
                window.location.href = response?.data?.submitGfForm?.confirmation?.url;

                this.resetForm();
            },
            (error) => {
                // Handle errors
                console.error(error);
            });
    }

    resetForm() {
        this.structuredFormData = null;
        this._isPagination = false;
        this.hiddenNodes = [];
        this.queryParamValue = undefined;
        this.nativeFormModuleData = undefined;
        this.currentPageIndex = 0;
        this.nativeFormData = {};
        this.pageWiseAccordionSectionFormGroupArray = [];
        this.subscriptionsMap = new Map();
        this.formSubmitted = false;

        if (this._moduleData && (typeof this._moduleIndex === 'number')) {
            this.initializeNativeFormModule();
        }

        if (this.localStorage && this.localStorage?.getItem('queryparams') != undefined && this.localStorage?.getItem('queryparams') != null) {//NOSONAR
            const sugarCRMID = this.getSugarCRMID(this.localStorage?.getItem('queryparams'));
            if (sugarCRMID) {
                this.queryParamValue = sugarCRMID.toString();
            }
        }
    }
}
