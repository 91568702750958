import { Component, ViewChild, ElementRef, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIconService } from '../../services/font-awesome-icon.service';
import { from, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { QueryParams } from '@janet/corporate/domain';
import { ActivatedRoute } from '@angular/router';

declare global {
    interface Window {
        googleMapsReady?: () => void;
    }
}
@Component({
    selector: 'ua2-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
    @Input() mapApiKey: string;
    @Input() googleMapUrl: string;
    @Input() isBrandPage?: boolean = false;
    @Input() merchantBrandData?: any;
    @ViewChild('googleMapContainer', { static: false }) gmap: ElementRef;
    @Output() selectedLocationData: EventEmitter<any> = new EventEmitter();
    @Output() selectedMarkerIndex: EventEmitter<number> = new EventEmitter<number>();

    map: google.maps.Map;
    infowindow: google.maps.InfoWindow;
    icon: Icon = {
        path: faLocationDot.icon[4] as string,
        fillOpacity: 1,
        scale: 0.075,
    };
    markerIcon: Icon;
    markerIconActive: Icon;
    markers: any = [];
    prevIndex = '';
    circle: google.maps.Circle;
    locationsDetails: any = [];
    private _storeIndex: number | null = null;
    private _zipcode: number;
    mapZoomLevel: number;
    _sopLocationsData: any;
    _dealerInfoData: any;
    _applyBtnLabel: any = 'Apply';
    _getDirectionBtnLabel: any = 'Get Directions';

    constructor(
        private _fontAwesomeIconService: FontAwesomeIconService,
        private queryParams: QueryParams,
        private route: ActivatedRoute
    ) { }
    ngOnInit(): void {
        if (this.mapApiKey) {
            const loader: Loader = new Loader({
                apiKey: this.mapApiKey,
            });

            from(loader.importLibrary('maps'))
                .pipe(take(1))
                .subscribe(
                    () => {
                        this.mapInitializer();
                    },
                    (error) => {
                        console.error('Failed to load Google Maps API:', error);
                    }
                );
        } else {
            console.error('Missing mapApiKey');
        }
    }

    getQueryParams() {
        return this.queryParams.getQueryParams();
    }

    ngOnDestroy(): void {
        this.clearMarkers();
    }

    @Input()
    set storeIndex(index: number | null) {
        if (index !== this._storeIndex) {
            // Check if the index has changed
            this._storeIndex = index;
            setTimeout(() => {
                this.openMarker(index);
            }, 100);
        }
    }

    @Input()
    set locationsData(displayData: any) {
        if (displayData) {
            this.locationsDetails = [];
            this.locationsDetails = displayData;
            if (typeof google !== 'undefined' && google.maps) {
                google.maps && google.maps.LatLng && this.mapInitializer();
            } else {
                this.googleMapsReady()
                    .pipe(take(1))
                    .subscribe(() => {
                        this.mapInitializer();
                    });
            }
        } else {
            this.clearMarkers();
        }
    }

    @Input()
    set clearMarker(data: any) {
        this.clearMarkers();
    }

    @Input()
    set sopLocationsData(data: any) {
        this._sopLocationsData = data;
    }

    @Input()
    set dealerInfoData(data: any) {
        this._dealerInfoData = data;
    }

    @Input()
    set applyBtnLabel(data: any) {
        if (data) {
            this._applyBtnLabel = data;
        }
    }

    @Input()
    set getDirectionBtnLabel(data: any) {
        if (data) {
            this._getDirectionBtnLabel = data;
        }
    }

    private googleMapsReady(): Observable<void> {
        return new Observable<void>((observer) => {
            if (typeof google !== 'undefined' && google.maps) {
                observer.next();
                observer.complete();
            } else {
                window.googleMapsReady = () => {
                    observer.next();
                    observer.complete();
                };
            }
        });
    }

    mapInitializer(): void {
        const center =
            this.locationsDetails && this.locationsDetails.length > 0
                ? new google.maps.LatLng(this.locationsDetails[0].LATITUDE, this.locationsDetails[0].LONGITUDE)
                : new google.maps.LatLng(
                    32.7767, // Dallas Latitude
                    -96.797 // Dallas Longitude
                );
        this.setZoomLevel();

        const [map, radius] = [this.map, (parseFloat(this.locationsDetails?.[0]?.DISTANCE) || 1) * 1609.34];
        this.circle = new google.maps.Circle({
            center: center,
            map: map,
            radius: radius, // IN METERS.
            fillOpacity: 0,
            strokeWeight: 0, // DON'T SHOW CIRCLE BORDER.
        });
        this.markerIcon = {
            ...this.icon,
            strokeWeight: 2,
            strokeColor: '#ffffff',
            fillColor: '#262626',
            anchor: new google.maps.Point(faLocationDot.icon[0] / 2, faLocationDot.icon[1]),
        };
        this.markerIconActive = {
            ...this.icon,
            strokeWeight: 0,
            strokeColor: '#0043CE',
            fillColor: '#0043CE',
            anchor: new google.maps.Point(faLocationDot.icon[0] / 2, faLocationDot.icon[1]),
        };
        this.infowindow = new google.maps.InfoWindow();
        setTimeout(() => {
            this.clearMarkers();
            this.populateLocations();
        }, 2000);
    }

    populateLocations(): void {
        this.markers = [];
        const locations = this.locationsDetails;
        locations.forEach((location: any, index: any) => {
            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(location?.LATITUDE, location?.LONGITUDE),
                map: this.map,
                icon: this.markerIcon,
            });
            const element: HTMLElement | null = document.getElementById(`iw_${index}`);
            this.onMarkerClick(marker, element, `iw_${index}`, location);
            this.markers?.push(marker);
        });
        if (locations.length > 0) {
            this.fitMapBounds();
        }
    }

    onMarkerClick(marker: google.maps.Marker, content: any, index: string, location: any): void {
        google.maps.event.addListener(
            marker,
            'click',
            ((marker) => {
                return () => {
                    if (this.prevIndex !== index) {
                        this.prevIndex = index;
                        this.setMarkerIcon(index);
                        marker.setIcon(this.markerIconActive);
                        this.infowindow.setContent(content);
                        this.infowindow.open(this.map, marker);
                        this.selectedLocationData.emit({ index: index, location: location });
                        const divIndex = Number(index.replace('iw_', ''));
                        this.selectedMarkerIndex.emit(divIndex);
                        // Introduce a slight delay before scrolling
                        setTimeout(() => {
                            this.scrollToHighlightedBlock(divIndex);
                        }, 200);
                    } else {
                        this.closeInfoWindow(index);
                        this.prevIndex = ''; // Remove the previous index
                        this.selectedMarkerIndex.emit(-1); // Emit -1 to remove highlighting in the template
                    }
                };
            })(marker)
        );
    }

    scrollToHighlightedBlock(index: number | null): void {
        const highlightedBlock = document.getElementById('iw_' + index);
        if (highlightedBlock) {
            const offset = 150; // Adjust the offset value as needed
            const top = highlightedBlock.getBoundingClientRect().top + window.scrollY - offset;
            window.scrollTo({ top, behavior: 'smooth' });
        }
    }

    setMarkerIcon(index: string): void {
        for (const marker of this.markers) {
            this.markers.forEach((marker: any, markerIndex: any) => {
                if (markerIndex === Number(index.replace('iw_', ''))) {
                    marker.setZIndex(999999);
                } else {
                    marker.setZIndex(0);
                }
            });
            marker.setIcon(this.markerIcon);
        }
    }

    clearMarkers(): void {
        for (const marker of this.markers) {
            marker?.setMap(null); // Remove marker from the map
        }
        this.markers = []; // Clear the markers array
        this.prevIndex = '';
    }

    closeInfoWindow(index: string): void {
        this.prevIndex = '';
        this.setMarkerIcon(index);
        this.infowindow.close();
    }

    getFaIcon(iconStr?: string | null | undefined): string {
        if (iconStr && iconStr !== '') {
            return `fa-light ${iconStr} get-directions-icon`;
        }
        return '';
    }

    getDirectionUrl(lat: string, long: string): string {
        return `${this.googleMapUrl}${lat},${long}`;
    }

    openMarker(index: number | null): void {
        if (index !== null) {
            this.markers.forEach((marker: any, markerIndex: any) => {
                if (markerIndex === index) {
                    marker.setZIndex(999999);
                } else {
                    marker.setZIndex(0);
                }
            });
            if (index >= 0 && index < this.markers.length) {
                google.maps.event.trigger(this.markers[index], 'click');
            }
        }
    }

    setZoomLevel() {
        const getMaxDistance = this.locationsDetails;
        const hasDistance = getMaxDistance.some((item: any) => 'DISTANCE' in item);
        const maxDistance = getMaxDistance.reduce((max: number, item: { DISTANCE: string }) => {
            const distance = parseFloat(item.DISTANCE);
            return distance > max ? distance : max;
        }, 0);

        const center =
            this.locationsDetails && this.locationsDetails.length > 0
                ? new google.maps.LatLng(this.locationsDetails[0].LATITUDE, this.locationsDetails[0].LONGITUDE)
                : new google.maps.LatLng(
                    32.7767, // Dallas Latitude
                    -96.797 // Dallas Longitude
                );

        if (hasDistance) {
            if (maxDistance > 200) {
                this.mapZoomLevel = 4;
            } else if (maxDistance > 150) {
                this.mapZoomLevel = 5;
            } else if (maxDistance > 100) {
                this.mapZoomLevel = 6;
            } else if (maxDistance > 50) {
                this.mapZoomLevel = 7;
            } else if (maxDistance > 25) {
                this.mapZoomLevel = 10;
            } else if (maxDistance < 10) {
                this.mapZoomLevel = 12;
            } else {
                this.mapZoomLevel = 10;
            }
        } else {
            this.mapZoomLevel = 5;
        }

        this.map = new google.maps.Map(this.gmap.nativeElement, {
            zoom: this.mapZoomLevel,
            center: center,
            disableDefaultUI: true,
        });
    }

    fitMapBounds(): void {
        const bounds = new google.maps.LatLngBounds();

        for (const marker of this.markers) {
            bounds.extend(marker.getPosition());
        }
        this.map.fitBounds(bounds);
    }

    isBrandedDealer() {
        let backgroundClr = '#161616';
        let linkClr = '#fff';

        const locations = this._sopLocationsData?.locations;
        const branded = this._dealerInfoData?.bBranded;
        if (locations && branded) {
            backgroundClr = locations?.backgroundColor ?? '#161616';
            linkClr = locations?.linkColor ?? '#fff';
            return { setStyleAttr: true, attributeName: 'style', attributes: `background-color:${backgroundClr} !important;color:${linkClr} !important;` };
        } else {
            backgroundClr = '#161616';
            linkClr = '#fff';
            return { setStyleAttr: false, attributeName: 'style', attributes: `background-color:${backgroundClr} !important;color:${linkClr} !important;` };
        }
    }
}

export interface Icon {
    path: string;
    fillOpacity: number;
    anchor?: google.maps.Point;
    strokeWeight?: number;
    strokeColor?: string;
    scale: number;
    fillColor?: string;
}
