import { PageDataResolver } from './../core/resolvers/page-data.resolver';
import { RedirectionResponse } from './../types/redirects.types';
import { REDIRECTS_QUERY } from './../queries/routes.query';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Redirect } from '../types/redirects.types';
import { map, tap } from 'rxjs/operators';
import { GeneralContainerComponent } from '../containers/general-container/general-container.component';

@Injectable({
    providedIn: 'root',
})
export class RoutesService {
    private _redirects: BehaviorSubject<Redirect[]> = new BehaviorSubject([] as Redirect[]);
    redirects$: Observable<Redirect[]> = this._redirects.asObservable();

    get redirects(): Redirect[] {
        return this._redirects.value;
    }

    constructor(private _apollo: Apollo, private _router: Router) { }

    getRedirects(): Observable<Redirect[]> {
        return this._apollo.query<RedirectionResponse>({ query: REDIRECTS_QUERY }).pipe(
            map((response) => response.data.redirection.redirects),
            tap((redirects) => this._redirects.next(redirects)),
            tap(() => this._addRedirectsToRouterConfig())
        );
    }

    private _addRedirectsToRouterConfig(): void {
        const routes = this._router.config;

        this.redirects.forEach((redirect) => {
            if (redirect.target.includes('https://')) {
                const protomatch = /^(https?|ftp):\/\//;
                routes.push({
                    path: `${redirect.origin.replace(/(^\/+|\/+$)/g, '')}`, //NOSONAR
                    redirectTo: redirect.target.replace(protomatch, 'www.'),
                });
            } else {
                routes.push({
                    path: `${redirect.origin.replace(/(^\/+|\/+$)/g, '')}`, //NOSONAR
                    redirectTo: redirect.target,
                });
            }
        });

        routes.sort((a: any, b: any) => (a.path < b.path ? 1 : -1));

        routes.push({
            path: '**',
            component: GeneralContainerComponent,
            resolve: { pageData: PageDataResolver },
        });

        this._router.resetConfig(routes);
    }

    appInitialize() {
        return from(this.getRedirects());
    }
}
