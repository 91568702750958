import { ContentModulesContentArea } from './../../types/kitchen-sink.types';
import { Component, HostListener, Input } from '@angular/core';
import { AffFullWidthLayout, AffFullWidthColorScheme } from './enums/aff-fullWidth.enums';

@Component({
    selector: 'janet-full-width-content',
    templateUrl: './full-width-content.component.html',
    styleUrls: ['./full-width-content.component.scss'],
})
export class FullWidthContentComponent {
    @Input() moduleData: ContentModulesContentArea;
    public layouts = AffFullWidthLayout;
    public colorSchemes = AffFullWidthColorScheme;
    public getScreenWidth: any;

    constructor() {
        this.getScreenWidth = window.innerWidth;
    }

    get setFullWidthButtonClasses() {
        let classes = '';

        if (this.moduleData?.contentWidth === '50_width') {
            classes += ' col-lg-4';
        }

        if (this.moduleData?.contentWidth === 'full_width') {
            classes += ' col-lg-2';
        }
        return classes;
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
    }

    getScreenSize(): boolean {
        if (this.getScreenWidth >= 1440) {
            return true;
        }
        return false;
    }
}
