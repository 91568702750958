import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomShadowdomHtmlDirective } from './custom-shadowdom-html.directive';

@NgModule({
    declarations: [CustomShadowdomHtmlDirective],
    imports: [CommonModule],
    exports: [CustomShadowdomHtmlDirective],
})
export class CustomShadowdomHtmlModule {}
