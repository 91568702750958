export const FILTEREDINPUTDATA = [
    {
        "label": "Appliances",
        "value": "appliances",
        "description": null,
        "checked": false
    },
    {
        "label": "Auto Repair",
        "value": "auto-repair",
        "description": null,
        "checked": false
    },
    {
        "label": "Electronics",
        "value": "electronics",
        "description": null,
        "checked": false
    },
    {
        "label": "Furniture",
        "value": "furniture",
        "description": null,
        "checked": false
    },
    {
        "label": "Jewelry",
        "value": "jewelry",
        "description": null,
        "checked": false
    },
    {
        "label": "Mattresses",
        "value": "mattresses",
        "description": null,
        "checked": false
    },
    {
        "label": "Pets",
        "value": "pets",
        "description": null,
        "checked": false
    },
    {
        "label": "Tire & Wheel",
        "value": "tire-wheel",
        "description": null,
        "checked": false
    },
    {
        "label": "Misc",
        "value": "misc",
        "description": "<em>Funeral Services, Hearing Aids, Tax Help, Tools</em>",
        "checked": false
    }
];