import { Pipe, PipeTransform } from '@angular/core';
import { TertiaryItemType, TertiaryMenu } from '../../types/menu-data.types';

@Pipe({
    name: 'tertiaryItemType',
})
export class TertiaryItemTypePipe implements PipeTransform {
    transform(items?: TertiaryMenu[], type: TertiaryItemType = 'Icon with Text Link'): TertiaryMenu[] {
        if (!items) return [];
        return items.filter((item) => item.listItemType === type);
    }
}
