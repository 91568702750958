import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as LocalStorageActions from './local-storage.actions';
import { LocalStorageItem } from './local-storage.model';

import { localStorageService } from './local-storage.service';

@Injectable()
export class LocalStorageEffects implements OnInitEffects {
    onInitLocalStorageEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LocalStorageActions.onInitLocalStorageEffect),
            switchMap(() =>
                this.localStorageService.getAllLocalStorageItems().pipe(
                    map((localStorages: LocalStorageItem[]) =>
                        LocalStorageActions.loadLocalStorageItems({
                            localStorageItems: localStorages,
                        })
                    ),
                    catchError((message: string) => of(LocalStorageActions.localStorageError({ message })))
                )
            )
        )
    );

    onAddItem$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LocalStorageActions.addLocalStorageItemKeyValue, LocalStorageActions.setLocalStorageItem),
                tap(({ localStorageItem }) => {
                    let value: string | undefined;

                    try {
                        value = JSON.stringify(localStorageItem.value);
                    } catch (e: any) {
                        console.error(e);
                    }

                    if (value !== undefined) {
                        this.localStorageService.setItem(localStorageItem.key, value);
                    }
                })
            ),
        { dispatch: false }
    );

    onRemoveItems$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LocalStorageActions.removeLocalStorageItemsKeys),
                tap(({ ids }) => {
                    ids.forEach((id: string) => {
                        this.localStorageService.removeItem(id);
                    });
                })
            ),
        { dispatch: false }
    );

    onRemoveItem$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LocalStorageActions.removeLocalStorageItemKey),
                tap(({ id }) => {
                    this.localStorageService.removeItem(id);
                })
            ),
        { dispatch: false }
    );

    onClearStorageItems$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LocalStorageActions.clearLocalStorageItems),
                tap(() => {
                    this.localStorageService.clear();
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private localStorageService: localStorageService
    ) {}

    ngrxOnInitEffects(): Action {
        return LocalStorageActions.onInitLocalStorageEffect();
    }
}
