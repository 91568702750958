import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffLogoComponent } from './aff-logo.component';

@NgModule({
    declarations: [AffLogoComponent],
    exports: [AffLogoComponent],
    imports: [CommonModule],
})
export class AffLogoModule {}
