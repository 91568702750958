import { trigger, state, style, animate, transition, AnimationTriggerMetadata } from '@angular/animations';

/**
 * Animations used by the aff-popover component.
 * Animation duration and timing values are based on:
 * https://material.io/guidelines/components/popovers.html#popovers-usage
 * @docs-private
 */
export const affPopoverAnimations: {
    readonly transformMenu: AnimationTriggerMetadata;
    readonly fadeInItems: AnimationTriggerMetadata;
} = {
    /**
     * This animation controls the popover panel's entry and exit from the page.
     *
     * When the popover panel is added to the DOM, it scales in and fades in its border.
     *
     * When the popover panel is removed from the DOM, it simply fades out after a brief
     * delay to display the ripple.
     */
    transformMenu: trigger('transformMenu', [
        state(
            'void',
            style({
                opacity: 0,
                transform: 'scale(0.8)',
            })
        ),
        transition(
            'void => enter',
            animate(
                '120ms cubic-bezier(0, 0, 0.2, 1)',
                style({
                    opacity: 1,
                    transform: 'scale(1)',
                })
            )
        ),
        transition('* => void', animate('100ms 25ms linear', style({ opacity: 0 }))),
    ]),

    /**
     * This animation fades in the background color and content of the popover panel
     * after its containing element is scaled in.
     */
    fadeInItems: trigger('fadeInItems', [
        // This is intentional & this is inside the `transformMenu`
        // now. Remove next time we do breaking changes.
        state('showing', style({ opacity: 1 })),
        transition('void => *', [style({ opacity: 0 }), animate('400ms 100ms cubic-bezier(0.55, 0, 0.55, 0.2)')]),
    ]),
};

/**
 * @deprecated
 * @breaking-change 8.0.0
 * @docs-private
 */
export const fadeInItems = affPopoverAnimations.fadeInItems;

/**
 * @deprecated
 * @breaking-change 8.0.0
 * @docs-private
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const transformMenu = affPopoverAnimations.transformMenu;
