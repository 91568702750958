export enum AffBannerBackground {
    WHITE = 'white',
    WHITE_WITH_BLUE_BORDERS = 'white_blue_borders',
    GRAY = 'light_grey',
    BLUE = 'blue',
    DARKBLUE = 'dark_blue',
    BlueGradient = 'blue_gradient',
    YELLOW = 'yellow',
}

export enum AffBackgroundTypes {
    BACKGROUND_TYPE_COLOR = 'color',
    BACKGROUND_TYPE_IMAGE = 'image',
}

export enum AffBannerTypes {
    BANNER_TYPE_SHOP = 'shop',
    BANNER_TYPE_SIMPLE = 'simple',
}

export enum AffBannerTextAlignment {
    BANNER_TEXT_CENTER = 'center',
    BANNER_TEXT_LEFT = 'left',
}

export enum AffBannerColorScheme {
    BANNER_COLOR_DARK = 'dark',
    BANNER_COLOR_LIGHT = 'light',
}
