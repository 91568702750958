<div class="aff-calendar-container">
    <div class="aff-calendar-header">
        <div class="aff-month-year-selector-wrapper">
            <span class="aff-caret-icon">
                <i [ngClass]="[caretIcon]"></i>
            </span>
            <span class="aff-month-year-selector" (click)="headerYearClicked()" #selectedDateLabel></span>
        </div>

        <div class="aff-calendar-navigator">
            <span class="aff-calendar-prev-selector" (click)="prevNavigatorClicked()">
                <i class="fa fa-chevron-left"></i>
            </span>
            <span class="aff-calendar-next-selector" (click)="nextNavigatorClicked()">
                <i class="fa fa-chevron-right"></i>
            </span>
        </div>
    </div>

    <div class="aff-calendar-content-wrapper">
        <div class="aff-calendar-content">
            <div class="aff-calendar-day-of-week">
                <div *ngFor="let day of daysOfWeek">
                    <div>{{ day }}</div>
                </div>
            </div>

            <div #calendarDaysRef class="aff-calendar-days">
                <div *ngFor="let day of [].constructor(daysCount); let i = index">
                    <span>{{ i }}</span>
                </div>
            </div>
        </div>

        <div class="aff-calendar-years-list" #yearsListRef>
            <div *ngFor="let _ of [].constructor(gridSize)"></div>
        </div>
    </div>

    <div class="aff-calendar-footer">
        <span class="aff-today-button" (click)="generateCurrentDayCalendar()">Today</span>
        <span class="aff-apply-button" (click)="onApplyClick()" #applyButtonRef>Apply</span>
    </div>
</div>
