<section class="d-none d-xxl-block header-overlay">
    <nav class="navbar navbar-expand-lg navbar-light menu">
        <div class="container d-flex justify-content-end">
            <ul
                class="d-flex list-unstyled flex-wrap justify-content-sm-center justify-content-xs-center m-0 mx-5 px-5"
            >
                <li *ngFor="let item of menus">
                    <a
                        class="text-reset"
                        [href]="item?.node?.url"
                        target="{{ item?.node?.target ? item?.node?.target : '' }}"
                        >{{ item?.node?.label }}</a
                    >
                </li>
            </ul>
        </div>
    </nav>
</section>

<section class="main-header d-none d-xxl-block position-sticky top-0 header-overlay">
    <div class="sub-menu pt-4 pb-4">
        <div class="px-3 ps-md-0 mx-auto mx-md-0 d-flex flex-column flex-md-row justify-content-around">
            <a href="/" class="text-reset">
                <img
                    src="../../../assets/images/Logo-Aff.svg"
                    width="260px"
                    height="28px"
                    alt="American First Finance"
                    role="button"
                />
            </a>
            <ul class="nav-bar-desktop d-flex flex-wrap">
                <janet-nav-bar-desktop-single
                    *ngFor="let menu of mainMenus; let i = index; let l = last"
                    [menu]="menu"
                ></janet-nav-bar-desktop-single>
            </ul>
            <ng-container *ngIf="_isButton; else linkBtn">
                <ua2-aff-button
                    [url]="headerMenu?.headerFindAStore?.url"
                    [title]="headerMenu?.headerFindAStore?.title"
                    [buttonLabel]="headerMenu?.headerFindAStore?.title"
                    [buttonCustomClass]="btnVariantClass + 'find-a-store-btn px-3 py-25'"
                    [affButtonIcon]="getFaIcon(headerMenu?.headerFindAStoreStyle?.ctaIcon)"
                    [showAffButtonIcon]="showIcon"
                >
                </ua2-aff-button>
            </ng-container>
            <ng-template #linkBtn>
                <a
                    class="text-decoration-none find-a-store-link-btn"
                    [target]="headerMenu?.headerFindAStore?.target"
                    [title]="headerMenu?.headerFindAStore?.title"
                    [href]="headerMenu?.headerFindAStore?.url"
                    >{{ headerMenu?.headerFindAStore?.title }}</a
                >
            </ng-template>
        </div>
    </div>
</section>
