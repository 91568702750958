/**
 * Throws an exception for the case when popover's x-position value isn't valid.
 * In other words, it doesn't match 'before' or 'after'.
 * @docs-private
 */
export function throwAffPopoverInvalidPositionX() {
    throw Error(`xPosition value must be either 'before' or after'.
      Example: <aff-popover xPosition="before" #popover="affPopover"></aff-popover>`);
}

/**
 * Throws an exception for the case when popover's y-position value isn't valid.
 * In other words, it doesn't match 'above' or 'below'.
 * @docs-private
 */
export function throwAffPopoverInvalidPositionY() {
    throw Error(`yPosition value must be either 'above' or below'.
      Example: <aff-popover yPosition="above" #popover="affPopover"></aff-popover>`);
}

/**
 * Throws an exception for the case when a popover is assigned
 * to a trigger that is placed inside the same popover.
 * @docs-private
 */
export function throwAffPopoverRecursiveError() {
    throw Error(
        `affPopoverTriggerFor: popover cannot contain its own trigger. Assign a popover that is ` +
            `not a parent of the trigger or move the trigger outside of the popover.`
    );
}
