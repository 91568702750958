import { ElementRef, Renderer2 } from '@angular/core';
export class StylesHelper {
    static removeClassFromElement(renderer: Renderer2, element: ElementRef, classes: string[]) {
        classes.forEach((className: string) => {
            renderer.removeClass(element, className);
        });
    }

    static addClassToElement(renderer: Renderer2, element: ElementRef, classes: string[]) {
        classes.forEach((className: string) => {
            renderer.addClass(element, className);
        });
    }
}
