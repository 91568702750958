import { BehaviorSubject } from 'rxjs';
import { LaunchDarklyFeatureFlagStore, LaunchDarklyStoreType } from './launch-darkly.models';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';

/**
 *
 * @param key
 * @param storeType
 * @param defaultValue
 */
export function createFeatureFlatStoreItem(
    key: string,
    storeType: LaunchDarklyStoreType,
    defaultValue: LDFlagValue = false
): LaunchDarklyFeatureFlagStore {
    return {
        defaultValue,
        key,
        storeType,
        store: new BehaviorSubject<LDFlagValue>(defaultValue),
        updatedByLaunchDarkly: false,
    };
}
