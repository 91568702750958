<div [ngClass]="merchantSearchContainer">
    <janet-merchant-search-form-container
        [selectOptions]="selectOptions"
        [zipCode]="zipcode"
    ></janet-merchant-search-form-container>
    <janet-merchant-location
        [merchantSearchStoreList]="merchantSearchStoreList"
        [merchantSearchNoOfRecords]="merchantSearchNoOfRecords"
        (zipCodeValue)="getZipcode($event)"
        [merchantSearchRadius]="merchantSearchRadius"
    ></janet-merchant-location>
</div>
