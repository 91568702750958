import { Component, AfterContentInit, ContentChildren, QueryList, OnDestroy } from '@angular/core';
import { ToggleComponent } from './toggle/toggle.component';

@Component({
    selector: 'ua2-aff-accordion',
    templateUrl: './aff-accordion.component.html',
    styleUrls: ['./aff-accordion.component.scss'],
})
export class AffAccordionComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(ToggleComponent) toggles: QueryList<ToggleComponent>;

    ngAfterContentInit() {
        this.initializeAccordion();
    }

    ngOnDestroy(): void {
        /**
         * @description un-subscribes to the event emitter from toggle(child) component.
         */
        this.toggles?.map((toggle) => {
            toggle.setActive.unsubscribe();
        });
    }

    /**
     * @method setActive used to set the toggle property either true/false based on index.
     */
    setActive(index: number) {
        this.toggles.map((toggle, i) => {
            toggle.active = !(index !== i || toggle.active === true);
        });
    }

    initializeAccordion() {
        this.toggles.map((toggle, i) => {
            toggle.setActive.subscribe(() => this.setActive(i));
        });
    }
}
