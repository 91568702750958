import {
    Component,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild,
    Input,
    AfterViewInit,
    Renderer2,
    HostListener,
} from '@angular/core';

@Component({
    selector: 'janet-category-filter',
    templateUrl: './category-filter.component.html',
})
export class CategoryFilterComponent implements AfterViewInit {
    @Output() togglePanel: EventEmitter<any> = new EventEmitter();
    @Output() toggleCheckBox: EventEmitter<any> = new EventEmitter();
    isButtonAnchor = false;
    buttonCustomClass =
        'px-3 py-25 p-xxl-3 d-inline-flex flex-row justify-content-center align-items-center aff-button-state aff-button filter-button-properties';
    @ViewChild('categoryFilterHeader', { static: true }) categoryFilterHeader: ElementRef;
    @ViewChild('categoryFilterFooter', { static: true }) categoryFilterFooter: ElementRef;
    @ViewChild('categoryFilterContent', { static: false }) categoryFilterContent: ElementRef;

    _multiSelectOptions: any = [];
    elementScrollTop = 0;
    _isOverflow = false;
    _isCompletelyScrolled = false;
    scrollEventListener: any;

    constructor(public renderer: Renderer2) {}

    ngAfterViewInit() {
        this.checkOverflow();
    }

    @Input()
    set multiSelectOptions(data: any) {
        const miscData = data.filter((item: any) => item.value.toLowerCase() === 'misc');
        let sortedData = data
            ?.filter((item: any) => item.value.toLowerCase() !== 'misc')
            .sort((a: any, b: any) => a.value - b.value);
        if (miscData && miscData.length > 0) {
            sortedData = [...sortedData, ...miscData];
        }
        this._multiSelectOptions = sortedData;
    }

    @Input()
    set showPanel(_isShowPanel: string) {
        setTimeout(() => {
            this.checkOverflow();
            this.toggleBoxShadow(_isShowPanel);
        }, 150);
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.checkOverflow();
        this.toggleBoxShadow();
    }

    onClickCloseIcon(): void {
        this.togglePanel.emit();
    }

    onSelectedOptionsChange(selectedOptions: any) {
        this.toggleCheckBox.emit(selectedOptions);
    }

    getCheckBoxContainerClass(isLast: boolean, option: string, desc: any) {
        if (isLast && !desc) {
            return 'form-check text-start d-flex align-items-center check-box-container m-0 mt-4 mb-4';
        }
        return 'form-check text-start d-flex align-items-center check-box-container m-0 mt-4';
    }

    checkOverflow() {
        const element = this.categoryFilterContent?.nativeElement;

        if (this.scrollEventListener) {
            element.removeEventListener('scroll', this.scrollEventListener);
            this.scrollEventListener = null;
        }

        if (element?.scrollHeight > element?.clientHeight) {
            this._isOverflow = true;

            this.scrollEventListener = () => {
                this.onScroll();
            };

            element.addEventListener('scroll', this.scrollEventListener);
        } else {
            this._isOverflow = false;
        }
    }

    onScroll() {
        const element = this.categoryFilterContent.nativeElement;
        this.elementScrollTop = element.scrollTop;
        this._isCompletelyScrolled = false;
        if (element.scrollTop === element.scrollHeight - element.clientHeight) {
            this._isCompletelyScrolled = true;
        }
        this.toggleBoxShadow();
    }

    toggleBoxShadow(_isShowPanel?: any) {
        const [headerElement, footerElement] = [
            this.categoryFilterHeader.nativeElement,
            this.categoryFilterFooter.nativeElement,
        ];
        const [headerShadowClass, headerBorderClass, footerShadowClass] = [
            'category-filter-header-shadow',
            'category-filter-header-border',
            'category-filter-footer-shadow',
        ];
        headerElement.classList.toggle(headerShadowClass, false);
        headerElement.classList.toggle(headerBorderClass, true);
        footerElement.classList.toggle(footerShadowClass, false);
        if (this._isOverflow) {
            if (!this._isCompletelyScrolled) {
                if (this.elementScrollTop > 28) {
                    headerElement.classList.toggle(headerBorderClass, false);
                    headerElement.classList.toggle(headerShadowClass, true);
                    footerElement.classList.toggle(footerShadowClass, true);
                } else {
                    headerElement.classList.toggle(headerBorderClass, true);
                    headerElement.classList.toggle(headerShadowClass, false);
                    footerElement.classList.toggle(footerShadowClass, true);
                }
            } else {
                headerElement.classList.toggle(headerBorderClass, false);
                headerElement.classList.toggle(headerShadowClass, true);
                footerElement.classList.toggle(footerShadowClass, false);
            }
        } else {
            headerElement.classList.toggle(headerShadowClass, false);
            headerElement.classList.toggle(headerBorderClass, true);
            footerElement.classList.toggle(footerShadowClass, false);
        }

        if (_isShowPanel && _isShowPanel.trim().length > 0 && _isShowPanel === 'false') {
            headerElement.classList.toggle(headerShadowClass, false);
            headerElement.classList.toggle(headerBorderClass, true);
            footerElement.classList.toggle(footerShadowClass, false);
        }
    }
}
