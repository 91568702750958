export interface LatLong {
    latitude: number;
    longitude: number;
}

export interface AffGoogleAddressResultComponentIndex {
    index: number;
    component: 'long_name' | 'short_name';
}

/**
 * Show input field, or form
 */
export type AFFGoogleAutocompleteTemplateType = 'input' | 'form';

export const components: AffGoogleAddressResultComponentIndex[] = [{ index: 0, component: 'long_name' }];

export type AFFGoolgeAddressAutocompleteValue = string;

export interface AffGoogleAddressFormValue {
    suitApt: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
}

export type AFFGoogleAddress = { streetAddress: string } & AffGoogleAddressFormValue;
