import { Component, Input } from '@angular/core';

@Component({
  selector: 'ua2-category',
  templateUrl: './category.component.html',
  styleUrls: []
})
export class CategoryComponent {

  @Input() parentContainerClass?: string = "rounded-pill bg-black-alpha-100 d-inline-block px-2 py-1 mb-3 me-3 mb-md-4 me-md-4 mb-xxl-3 me-xxl-3";
  @Input() childContainerClass?: string = "d-inline-block category-list-icon";
  @Input() categoryIconClass?: string = "me-2";
  @Input() categoryListName?: string = "";
  @Input() categoryIcon?: string = "";
}