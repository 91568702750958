import { Inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { MENU_DATA_QUERY } from '../queries/';
import { MenuData, MenuResponse, Nodes } from '../types/menu-data.types';

import { MENU_STATE_KEY } from '../app.module';

import { isPlatformBrowser } from '@angular/common';


@Injectable({
    providedIn: 'root',
})
export class MenuDataService {
    private _browser = true;
    private _menus: BehaviorSubject<MenuData> = new BehaviorSubject({} as MenuData);
    _menus$: Observable<MenuData> = this._menus.asObservable();
    copyrights$: Observable<Nodes> = this._menus$.pipe(
        map((menuData: MenuData): Nodes => {
            const menuSections = menuData.menus;
            return this._pickSection(menuSections.nodes, 'Secondary Footer Menu', true);
        })
    );

    customerServiceInformation$: Observable<Nodes> = this._menus$.pipe(
        map((menuData: MenuData): Nodes => {
            const menuSections = menuData.menus;
            return this._pickSection(menuSections.nodes, 'Main Footer Menu', true);
        })
    );

    socialIconsMenu$: Observable<Nodes> = this._menus$.pipe(
        map((menuData: MenuData): Nodes => {
            const menuSections = menuData.menus;
            return this._pickSection(menuSections.nodes, 'Social Icons Menu', true);
        })
    );

    constructor(
        private _apollo: Apollo,
        @Inject(PLATFORM_ID) private _platformId: any,
        private _transferState: TransferState
    ) {
        if (!isPlatformBrowser(this._platformId)) {
            this._browser = false;
        }
    }

    getData() {
        const data = this._hasCacheData();

        if (data) {
            return of(data).pipe(tap((response) => this._menus.next(response)));
        }

        return this._apollo.query<MenuData>({ query: MENU_DATA_QUERY }).pipe(
            map((response: MenuResponse): MenuData => response.data),
            tap((response: MenuData) => this._cachePageData(response)),
            tap((response: MenuData) => this._menus.next(response))
        );
    }

    private _pickSection(section: Nodes[], type: string, filter = false): Nodes {
        if (!filter) {
            return section.find((m) => m.name === type) || ({} as Nodes[])[0];
        }

        const nodes: Nodes[] = section.filter((m) => m.name === type);
        const node = nodes[0];
        return node ? node : ({} as Nodes[])[0];
        // return <Nodes>section.filter((m) => m.name === type)[0] || [];
    }

    getTree(data: any, primaryIdName: string, parentIdName: string, menuType?: string) {
        if (!data || !primaryIdName || !parentIdName) return [];
        const tree: any[] = [];
        const treeObjs: any = {};
        data.forEach((item: { node: { [x: string]: any; url: string }; _isSubMenu: boolean }) => {
            const primaryKey = item.node[primaryIdName];
            treeObjs[primaryKey] = item;
            const parentId = item.node[parentIdName];
            const parent = treeObjs[parentId];
            if (parentId && parent) {
                if (item?.node?.url === '#' && menuType === 'header') {
                    item._isSubMenu = true;
                } else {
                    item._isSubMenu = false;
                }
                const children = parent.children ? parent.children.concat([item]) : [item];
                parent.children = children;
            } else {
                tree.push(item);
            }
        });
        return tree;
    }

    private _cachePageData(data: any) {
        if (!this._browser) {
            this._transferState.onSerialize(MENU_STATE_KEY, () => data);
        }
    }

    private _hasCacheData() {
        if (this._browser) {
            return this._transferState.get(MENU_STATE_KEY, null);
        }

        return null;
    }
}
