import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { fadeSlideInAnimation } from '@janet/shared/animations';

import { TertiaryMenu } from '../../../types/menu-data.types';

@Component({
    selector: 'janet-nav-bar-desktop-tertiary',
    templateUrl: './nav-bar-desktop-tertiary.component.html',
    styleUrls: ['./nav-bar-desktop-tertiary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeSlideInAnimation()],
})
export class NavBarDesktopTertiaryComponent {
    @Input() menu: TertiaryMenu[] = [];
    @Input() cta: TertiaryMenu[] = [];

    angleRight = faAngleRight;
}
