<ua2-aff-accordion>
    <ng-container *ngFor="let menu of mainMenus; let i = index; let l = last; trackBy: trackByFn">
        <!-- Highest level item -->
        <ua2-toggle
            accordionlist="false"
            title="{{ menu?.node?.label }}"
            titleUrl="{{ menu?.node?.url }}"
            titleUrlTarget="{{ menu?.node?.target }}"
            isSubMenuExist="{{ menu?.children ? 'true' : 'false' }}"
            [classes]="classes"
        >
            <ul
                class="list-unstyled text-reset m-0 submenu px-6 px-md-14 px-xl-23 pb-2 px-xxl-0 bg-color border-bottom"
                [ngClass]="hasSubHeader(menu) ? 'pt-25' : 'pt-3'"
            >
                <li
                    class="sub-header-menu"
                    *ngIf="hasChildren(menu.children) && menu.node?.ctaNavFields?.subHeading"
                >
                    {{ menu.node?.ctaNavFields?.subHeading }}
                </li>
                <janet-nav-bar-mobile-single
                    *ngFor="let firstChildMenu of menu.children"
                    [navItem]="firstChildMenu"
                ></janet-nav-bar-mobile-single>
            </ul>
        </ua2-toggle>
    </ng-container>
</ua2-aff-accordion>
<ng-container *ngIf="_isButton; else linkBtn">
    <ua2-aff-button
        [url]="headerMenu?.headerFindAStore?.url"
        [title]="headerMenu?.headerFindAStore?.title"
        [buttonLabel]="headerMenu?.headerFindAStore?.title"
        [buttonCustomClass]="
            btnVariantClass +
            'store-btn px-3 py-25 mt-4 mb-4 d-flex justify-content-center d-grid gap-2 col-6 mx-sm-auto mx-md-auto me-3 ms-3 me-sm-3 ms-sm-3'
        "
        [affButtonIcon]="getFaIcon(headerMenu?.headerFindAStoreStyle?.ctaIcon)"
        [showAffButtonIcon]="showIcon"
    >
    </ua2-aff-button>
</ng-container>
<ng-template #linkBtn>
    <a
        class="text-decoration-none py-6 d-flex find-a-store-link-btn px-4 py-6 px-md-5 px-xl-21"
        [target]="headerMenu?.headerFindAStore?.target"
        [title]="headerMenu?.headerFindAStore?.title"
        [href]="headerMenu?.headerFindAStore?.url"
        >{{ headerMenu?.headerFindAStore?.title }}</a
    >
</ng-template>

<ul class="list-unstyled text-reset m-0 submenu px-4 px-md-5 px-xl-21 pt-8 pb-3 px-xxl-0 bg-light">
    <li class="pb-4" *ngFor="let submenu of menus">
        <a
            [href]="submenu?.node?.url"
            target="{{ submenu?.node?.target ? submenu?.node?.target : '' }}"
            class="text-reset text-decoration-none d-block"
            >{{ submenu?.node?.label }}
            <fa-icon class="ms-1" [icon]="angleRight"></fa-icon>
        </a>
    </li>
</ul>
