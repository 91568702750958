import { createAction, props } from '@ngrx/store';

import { LocalStorageItem } from './local-storage.model';

export const onInitLocalStorageEffect = createAction('[localStorageItem/API] Initial Query LocalStorageItems');

export const localStorageError = createAction('[localStorageItem/API] Error', props<{ message: string }>());

export const loadLocalStorageItems = createAction(
    '[localStorageItem/API] Load LocalStorageItems',
    props<{ localStorageItems: LocalStorageItem[] }>()
);

export const setLocalStorageItems = createAction(
    '[localStorageItem/API] Set LocalStorageItems',
    props<{ localStorageItems: LocalStorageItem[] }>()
);

export const addLocalStorageItemKeyValue = createAction(
    '[localStorageItem/API] Add localStorageItem',
    props<{ localStorageItem: LocalStorageItem }>()
);

export function addLocalStorageItem(key: string, value: any) {
    return addLocalStorageItemKeyValue({
        localStorageItem: {
            key,
            value,
        },
    });
}

export const setLocalStorageItem = createAction(
    '[localStorageItem/API] Set localStorageItem',
    props<{ localStorageItem: LocalStorageItem }>()
);

export const addLocalStorageItems = createAction(
    '[localStorageItem/API] Add LocalStorageItems',
    props<{ localStorageItems: LocalStorageItem[] }>()
);

export const removeLocalStorageItemKey = createAction(
    '[localStorageItem/API] Delete LocalStorageItem',
    props<{ id: string }>()
);

export function removeLocalStorageItem(key: string) {
    return removeLocalStorageItemKey({ id: key });
}

export const removeLocalStorageItemsKeys = createAction(
    '[localStorageItem/API] Delete LocalStorageItems',
    props<{ ids: string[] }>()
);

export const clearLocalStorageItems = createAction('[localStorageItem/API] Clear LocalStorageItems');
