import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SNAPENGAGE_CHAT_SCRIPT, WINDOW } from '@janet/shared/domain';

@Injectable({ providedIn: 'root' })
export class ChatScriptService {
    _isDone = false;
    _isChatInterfaceInitialised = false;

    constructor(@Inject(DOCUMENT) private doc: Document, @Inject(WINDOW) private win: Window) {}

    /**
     * @method initiateSnapEngageChatScript used to add the chat-script in the head of the index.html file during app initialization.
     * @description It is added during app_initialization to avoid the unwanted issues which are raised after app initialization.
     */
    initiateSnapEngageChatScript(): void {
        console.log('initiateSnapEngageChatScript', this.doc, this.win);
        const se: HTMLScriptElement = this.doc.createElement('script');
        se.src = SNAPENGAGE_CHAT_SCRIPT;
        se.type = 'text/javascript';
        se.async = true;
        se.onload = () => {
            if (
                !this._isDone &&
                (!this.doc.readyState ||
                    this.doc.readyState === 'complete' ||
                    this.doc.readyState === 'interactive')
            ) {
                if (this.win && (<any>this.win).SnapEngage) {
                    (<any>this.win).SnapEngage.hideButton();
                    this._isChatInterfaceInitialised = true;
                    this._isDone = true;
                }
            }
        };
        this.doc.getElementsByTagName('head')[0].appendChild(se);
    }
}
