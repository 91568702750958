export const ShopByCategoryMock = {
    __typename: 'Page_Contentmodules_ContentModules_ShopCategoryModule',
    shopCategoryHeading: 'Shop By Category',
    shopCategorySubheading: '<p>Test Banner icons</p>\n',
    shopCategoryList: [
        {
            fieldGroupName: 'shop_category_list',
            shopCategory: {
                slug: 'electronics',
                name: 'Electronics',
                taxonomyName: 'shop_category',
                shopCategoryId: 17,
                termTaxonomyId: 17,
                ShopCategoryIcon: {
                    fieldGroupName: 'ShopCategoryIcon',
                    icon: '{ "style" : "light", "id" : "laptop-mobile", "label" : "Laptop Mobile", "unicode" : "f87a" }',
                },
            },
        },
        {
            fieldGroupName: 'shop_category_list',
            shopCategory: {
                slug: 'auto-repair',
                name: 'Auto Repair',
                taxonomyName: 'shop_category',
                shopCategoryId: 18,
                termTaxonomyId: 18,
                ShopCategoryIcon: {
                    fieldGroupName: 'ShopCategoryIcon',
                    icon: '{ "style" : "light", "id" : "car-wrench", "label" : "Car Wrench", "unicode" : "f5e3" }',
                },
            },
        },
        {
            fieldGroupName: 'shop_category_list',
            shopCategory: {
                slug: 'furniture',
                name: 'Furniture',
                taxonomyName: 'shop_category',
                shopCategoryId: 14,
                termTaxonomyId: 14,
                ShopCategoryIcon: {
                    fieldGroupName: 'ShopCategoryIcon',
                    icon: '{ "style" : "light", "id" : "couch", "label" : "Couch", "unicode" : "f4b8" }',
                },
            },
        },
        {
            fieldGroupName: 'shop_category_list',
            shopCategory: {
                slug: 'jewelry',
                name: 'Jewelry',
                taxonomyName: 'shop_category',
                shopCategoryId: 21,
                termTaxonomyId: 21,
                ShopCategoryIcon: {
                    fieldGroupName: 'ShopCategoryIcon',
                    icon: '{ "style" : "light", "id" : "gem", "label" : "Gem", "unicode" : "f3a5" }',
                },
            },
        },
    ],
};
