import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebsiteFormModule } from '../../types/kitchen-sink.types';

@Component({
    selector: 'janet-website-form',
    templateUrl: './website-form.component.html',
    styleUrls: [],
})
export class WebsiteFormComponent {
    @Input() websiteFormData: WebsiteFormModule;
    websiteForm: string;

    constructor(private route: ActivatedRoute) {}
    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            const tag = document.createElement('div');
            tag.innerHTML = this.websiteFormData.websiteForm;
            const iframe = tag.firstElementChild as HTMLIFrameElement;
            iframe.src = `${iframe.src}?${new URLSearchParams(params).toString()}`;
            this.websiteForm = iframe.outerHTML.toString();
        });
    }
}
