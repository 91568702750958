import { gql } from 'apollo-angular';
export const MERCHANT_SEARCH_QUERY = gql`
    query getStateData($id: ID!) {
        merchantSearch(idType: SLUG, id: $id) {
            id
            seo {
                title
                metaDesc
            }
            merchantSearch {
                fullStateName
                backgroundImage {
                    sourceUrl
                    srcSet
                    altText
                }
                bannerText
                backgroundImage {
                    id
                    sourceUrl
                }
            }
        }
        contentAreaSearch: merchantSearch(id: "content-area", idType: URI) {
            id
            contentModules {
                contentModules {
                    ... on MerchantSearch_Contentmodules_ContentModules_ContentArea {
                        fieldGroupName
                        content
                        content2
                        contentWidth
                        image {
                            sourceUrl
                            srcSet
                            altText
                        }
                        image2 {
                            sourceUrl
                            srcSet
                            altText
                        }
                        imagePosition
                        imagePosition2
                        showButton
                        showButton2
                        showImage1
                        showImage2
                        button {
                            target
                            title
                            url
                        }
                        button2 {
                            target
                            title
                            url
                        }
                    }
                    ... on MerchantSearch_Contentmodules_ContentModules_Hero {
                        fieldGroupName
                        backgroundImage {
                            sourceUrl
                            srcSet
                            altText
                        }
                        title
                        contentArea
                        colorScheme
                        contentAlignment
                        showButton
                        heroLayoutMobile
                        button {
                            target
                            title
                            url
                        }
                    }
                    ... on MerchantSearch_Contentmodules_ContentModules_Testimonials {
                        fieldGroupName
                        testimonials {
                            fieldGroupName
                            rating
                            testimonialCustomerName
                            testimonialText
                        }
                    }
                    ... on MerchantSearch_Contentmodules_ContentModules_Icons {
                        fieldGroupName
                        showButton
                        button {
                            target
                            title
                            url
                        }
                        icons {
                            fieldGroupName
                            iconFa
                            useFaIcon
                            iconImage {
                                altText
                                mediaItemUrl
                            }
                            iconTitle
                            iconSubText
                        }
                    }
                    ... on MerchantSearch_Contentmodules_ContentModules_FaqSchema {
                        fieldGroupName
                        qaItems {
                            question
                            answer
                        }
                    }
                    ... on MerchantSearch_Contentmodules_ContentModules_CcpaDsarForm {
                        fieldGroupName
                        otDsarWebForm
                    }
                    ... on MerchantSearch_Contentmodules_ContentModules_MerchantSignupForm {
                        fieldGroupName
                        merchantSignupForm
                    }
                    ... on MerchantSearch_Contentmodules_ContentModules_WebsiteForms {
                        fieldGroupName
                        websiteForm
                    }
                    ... on MerchantSearch_Contentmodules_ContentModules_BannerModule {
                        fieldGroupName
                        bannerType
                        backgroundType
                        backgroundColor
                        backgroundImage {
                            sourceUrl
                            srcSet
                            altText
                        }
                        headingText
                        colorScheme
                        useAsHero
                        buttonCta {
                            target
                            title
                            url
                        }
                        buttonCtaText
                        subheadingText
                        bannerTextAlignment
                        fontAwesomeIcon
                        bannerHeight
                        bannerTextAlignment
                        searchType
                    }
                    ... on MerchantSearch_Contentmodules_ContentModules_ShopCategoryModule {
                        fieldGroupName
                        shopCategoryHeading
                        shopCategorySubheading
                        shopCategoryList {
                            fieldGroupName
                            shopCategory {
                                slug
                                name
                                taxonomyName
                                shopCategoryId
                                termTaxonomyId
                                ShopCategoryIcon {
                                    fieldGroupName
                                    icon
                                }
                                ShopCategoryAPIKey {
                                    fieldGroupName
                                    apiKey
                                }
                            }
                        }
                    }
                }
            }
        }
        shopCategories(first: 100) {
            nodes {
                ShopCategoryIcon {
                    fieldGroupName
                    icon
                }
                ShopCategoryAPIKey {
                    fieldGroupName
                    apiKey
                }
                name
                id
                slug
                taxonomyName
                shopCategoryId
                termTaxonomyId
                description
            }
        }
    }
`;
