import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'aff-bouncy-loader',
    templateUrl: './aff-bouncy-loader.component.html',
    styleUrls: ['./aff-bouncy-loader.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AffBouncyLoaderComponent {
    @HostBinding('class') hostClasses = 'aff-bouncy-loader';

    @Input() size = 13;
}
