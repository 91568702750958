<!-- Button trigger modal -->
<button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#affModal" #modalOpenBtn></button>

<!-- Modal -->
<div class="modal fade" id="affModal" tabindex="-1" aria-labelledby="affModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered justify-content-center">
        <div class="modal-content mx-12 mx-md-0 p-0 border-0 bg-light">
            <div class="modal-body m-0 p-0">
                <ng-template #modalContentPlaceholder></ng-template>
            </div>
        </div>
    </div>

    <!-- Button close Modal -->
    <button type="button" class="d-none" data-bs-dismiss="modal" #modalCloseBtn></button>
</div>
