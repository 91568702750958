export const ContentAreaMock = {
    Content_50_Width: {
        __typename: 'Page_Contentmodules_ContentModules_ContentArea',
        fieldGroupName: 'Page_Contentmodules_ContentModules_ContentArea',
        content:
            '<p><strong>Content:1</strong></p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. content Left</p>\n',
        content2:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip e<a href="https://qa1hcms1.americanfirstfinance.com/magni-quia-aspernatur-occaecati-nemo-cupiditate-adipisci/" target="_blank" rel="noopener">x ea commodo consequat.</a> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <em>culpa</em> qui officia <strong>deserunt</strong> mollit anim<span style="text-decoration: underline;"> id est laborum.</span></p>\n<h5>H5 headimng</h5>\n<ul>\n<li>list item one</li>\n<li>list item two with a longer name written here keep going you&#8217;re almost done reading</li>\n<li>list item three and a half which is just a skosh longer</li>\n<li>list item four goes here and here</li>\n</ul>\n<p>&nbsp;</p>\n<p><strong>HERE IS A NUMBERED LIST</strong></p>\n<ol>\n<li>list item one</li>\n<li>list item two with a longer name written here keep going you&#8217;re almost done reading</li>\n<li>list item three and a half which is just a skosh longer</li>\n<li>list item four goes here and here</li>\n</ol>\n',
        contentWidth: '50_width',
        image: {
            __typename: 'MediaItem',
            sourceUrl:
                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg',
            srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-300x200.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-1024x682.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-768x512.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg 1382w',
            altText: 'Test content Area',
        },
        image2: {
            __typename: 'MediaItem',
            sourceUrl:
                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36.jpg',
            srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36-300x200.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36-1024x682.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36-768x512.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36.jpg 1099w',
            altText: '',
        },
        imagePosition: 'left',
        imagePosition2: 'left',
        showButton: true,
        showButton2: true,
        showImage1: true,
        showImage2: null,
        button: {
            __typename: 'AcfLink',
            target: '_blank',
            title: 'Learn More',
            url: 'https://www.foundanimals.org/facts-about-kittens/',
        },
        button2: {
            __typename: 'AcfLink',
            target: '',
            title: 'TEST',
            url: 'https://google.com',
        },
    },
    Content_Full_width: {
        __typename: 'Page_Contentmodules_ContentModules_ContentArea',
        fieldGroupName: 'Page_Contentmodules_ContentModules_ContentArea',
        content:
            '<p><strong>Content:1</strong></p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. content Left</p>\n',
        content2:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip e<a href="https://qa1hcms1.americanfirstfinance.com/magni-quia-aspernatur-occaecati-nemo-cupiditate-adipisci/" target="_blank" rel="noopener">x ea commodo consequat.</a> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <em>culpa</em> qui officia <strong>deserunt</strong> mollit anim<span style="text-decoration: underline;"> id est laborum.</span></p>\n<h5>H5 headimng</h5>\n<ul>\n<li>list item one</li>\n<li>list item two with a longer name written here keep going you&#8217;re almost done reading</li>\n<li>list item three and a half which is just a skosh longer</li>\n<li>list item four goes here and here</li>\n</ul>\n<p>&nbsp;</p>\n<p><strong>HERE IS A NUMBERED LIST</strong></p>\n<ol>\n<li>list item one</li>\n<li>list item two with a longer name written here keep going you&#8217;re almost done reading</li>\n<li>list item three and a half which is just a skosh longer</li>\n<li>list item four goes here and here</li>\n</ol>\n',
        contentWidth: 'full_width',
        image: {
            __typename: 'MediaItem',
            sourceUrl:
                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg',
            srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-300x200.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-1024x682.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-768x512.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg 1382w',
            altText: 'Test content Area',
        },
        image2: null,
        imagePosition: 'left',
        imagePosition2: 'left',
        showButton: true,
        showButton2: null,
        showImage1: true,
        showImage2: null,
        button: {
            __typename: 'AcfLink',
            target: '_blank',
            title: 'Learn More',
            url: 'https://www.foundanimals.org/facts-about-kittens/',
        },
        button2: null,
    },
};
