/**
 * Create a regular expression to get DOM tags.
 *
 * @example
 * const aTagRegExp = createTagRegExp('a');
 *
 * // aTagRegExp is /<a(.*?)<\/a>/gm
 *
 * @param tag - HTML tag such as 'div', 'a', etc.
 */
export function createHtmlTagRegExp(tag: string): RegExp {
    return new RegExp(`<${tag}(.*?)</${tag}>`, 'gm');
}

/**
 * Create HTMLElement from html string. Expected output
 * is ONE single DOM element.
 *
 * @example
 * const el: HTMLElement = parseHtmlStringToDomElement('<a href="foo.com">foo</a>');
 *
 * // returns HTMLElement of string input.
 *
 * @param html string
 */
export function parseHtmlStringToDomElement(html: string): Element | string | any {
    // Convert sanitized html into a DOM element
    // Wraps sanitized in  <html><body> ... </body></html>
    const htmlElement = new DOMParser().parseFromString(html, 'text/html');

    // Unwrap sanitized DOM node by
    // getting the first item of the <body> tag
    const bodyElement = htmlElement.getElementsByTagName('body').item(0);

    // TypeScript compiler requires non null check
    if (bodyElement && bodyElement.firstElementChild) {
        // Append sanitized DOM element to ShadowHost
        return bodyElement.firstElementChild;
    }

    return html;
}

/**
 * Create HTMLCollection from html string. The output is expected
 * to be multiple elements in an array to an entire DOM structure.
 *
 * @example
 * const el: HTMLElement = parseHtmlStringToDomElement('<a href="foo.com">foo</a>');
 *
 * // returns HTMLElement of string input.
 *
 * @param html string
 * @param doc document object
 * @param wrapperElement element to wrap content. Root element in shadowDom
 */
export function parseHtmlStringToDomElements(html: string, doc: Document, wrapperElement = 'div'): HTMLElement {
    const domElment: HTMLElement = doc.createElement(wrapperElement);

    domElment.innerHTML = html;

    return domElment;
}
