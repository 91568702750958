<ng-content select="mat-icon, [affPopoverItemIcon]"></ng-content>
<span class="mdc-list-item__primary-text"><ng-content></ng-content></span>
<div
    class="aff-mdc-popover-ripple"
    matRipple
    [matRippleDisabled]="disableRipple || disabled"
    [matRippleTrigger]="_getHostElement()"
></div>
<svg *ngIf="_triggersSubpopover" class="aff-mdc-popover-subpopover-icon" viewBox="0 0 5 10" focusable="false">
    <polygon points="0,0 5,5 0,10" />
</svg>
