<!-- NOSONAR -->
<li
    class="dropdown"
    (mouseover)="toggleActive(true)"
    (mouseleave)="toggleActive(false)"
    [ngClass]="{ 'dropdown-child': hasChildren }"
    *ngIf="menu"
>
    <span class="main-menu-title">
        <span class="main-menu-title-span" [ngClass]="{ 'cursor-pointer': !hasChildren }">
            <a
                class="text-reset text-decoration-none"
                [ngClass]="{ 'primary-click-remove': hasChildren }"
                [href]="menu.node?.url"
                >{{ menu.node?.label }}</a
            >
            <fa-icon class="ms-2 fa-sm" [icon]="active ? angleUp : angleDown" *ngIf="menu?.children"></fa-icon>
        </span>
    </span>
    <div class="menu-single-wrapper" *ngIf="active">
        <div
            class="menu-single-container"
            @fadeSlideInLeft
            [ngClass]="{ 'd-flex flex-row align-items-stretch': hasTertiaryMenu }"
        >
            <div
                class="secondary-menu"
                [ngClass]="{ 'secondary-full-width d-flex flex-wrap py-2': !hasTertiaryMenu }"
            >
                <div class="w-100 sub-header-menu" *ngIf="!hasTertiaryMenu && menu.node?.ctaNavFields?.subHeading">
                    {{ menu.node?.ctaNavFields?.subHeading }}
                </div>
                <a
                    *ngFor="let secondary of menu.children; let i = index"
                    class="secondary-item d-flex flex-row"
                    [ngClass]="{
                        'secondary-item-limit': !hasTertiaryMenu,
                        active: hasTertiaryMenu && i === activeMenu
                    }"
                    [attr.href]="secondary.node.url || null"
                    (mouseout)="onSecondaryMenuLeave()"
                    (mouseover)="setTertiaryMenu(i, secondary.node.ctaNavFields.tertiaryMenu)"
                >
                    <div class="flex-grow-1">
                        <div class="secondary-item-label">{{ secondary.node.label }}</div>
                        <div class="secondary-item-heading">
                            {{ secondary.node.ctaNavFields.secondaryMenuHeading }}
                        </div>
                    </div>
                    <fa-icon
                        class="secondary-item-caret ms-2 fa-sm mt-27"
                        [ngClass]="{
                            invisible: !secondary.node.ctaNavFields?.tertiaryMenu
                        }"
                        [icon]="angleRight"
                    ></fa-icon>
                </a>
            </div>
            <div class="tertiary-menu py-2 flex-fill" *ngIf="hasTertiaryMenu">
                <janet-nav-bar-desktop-tertiary
                    *ngIf="activeTertiaryMenu$ | async as tertiary"
                    [menu]="tertiary | tertiaryItemType"
                    [cta]="tertiary | tertiaryItemType : 'Long Text Link'"
                ></janet-nav-bar-desktop-tertiary>
            </div>
        </div>
    </div>
</li>
<!-- NOSONAR -->
