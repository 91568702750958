<div class="container px-0 d-none d-sm-none d-md-none d-lg-none d-xl-none d-xxl-block">
    <div class="row pagination-container">
        <div class="col-10 d-flex align-items-center text-center text-start p-2 result-cont"
            *ngIf="totalRecords?.record !== 0">
            <p class="mb-0">
                {{_paginationLabel}} {{ startIndexVal }}-{{ endIndex }}
                <span *ngIf="totalRecords?.record > 10">of {{ totalRecords?.record }}</span>
            </p>
        </div>
        <div class="col-2 d-flex align-items-center text-center text-end p-0" *ngIf="totalRecords?.record !== 0">
            <a href="javascript:void(0)" class="pagination-left-arrow"
                [class.pagination-arrow-disabled]="startIndexVal === 1 || totalRecords?.record <= 10"
                (click)="previousPage(currentPage)">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" class="pagination-right-arrow"
                [class.pagination-arrow-disabled]="endIndex === totalRecords?.record || totalRecords?.record <= 10"
                (click)="nextPage(currentPage)">
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </a>
        </div>
    </div>
</div>
<div
    class="container pagination-container-mobile px-0 col-12 col-md-12 col-xxl-6 d-block d-sm-block d-md-block d-lg-block d-xl-block d-xxl-none">
    <div class="row d-flex align-items-center text-center justify-content-between pagination-cont me-0"
        *ngIf="totalRecords?.record !== 0">
        <div class="col-2 ps-2">
            <a href="javascript:void(0)" class="pagination-left-arrow"
                [class.pagination-arrow-disabled]="startIndexVal === 1 || totalRecords?.record <= 10"
                (click)="previousPage(currentPage)">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </a>
        </div>
        <div class="col-8 text-center pagination-text px-0">
            {{_paginationLabel}} {{ startIndexVal }}-{{ endIndex }}
            <span *ngIf="totalRecords?.record > 10">of {{ totalRecords?.record }}</span>
        </div>
        <div class="col-2 pe-2 ps-4 right-arrow-mobile">
            <a href="javascript:void(0)" class="pagination-right-arrow"
                [class.pagination-arrow-disabled]="endIndex === totalRecords?.record || totalRecords?.record <= 10"
                (click)="nextPage(currentPage)">
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </a>
        </div>
    </div>
</div>