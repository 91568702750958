import { Component } from '@angular/core';
import { faComments } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'janet-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
    public faComments = faComments;
}
