import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ua2-stepper-indicator, aff-stepper-indicator',
    templateUrl: './stepper-indicator.component.html',
})
export class StepperIndicatorComponent implements OnInit {
    _stepperIndicatorContainerCustomClass = 'stepper-indicator-container d-flex flex-row align-items-center justify-content-between gap-3';
    _stepperIndicatorClasses = 'indicator d-flex align-items-center justify-content-center text-center';
    _stepperTitleClasses = 'title';
    _indicatorIndex: any = 0;
    _pageTitle: any = '';
    _showFaIcon: boolean = false;
    @Input() faIconClass = 'fa-duotone fa-check';

    constructor(private _changeDetectorRef: ChangeDetectorRef,) { }

    ngOnInit(): void {
        this._changeDetectorRef.detectChanges();
    }

    @Input()
    set stepperIndicatorContainerClasses(value: any) {
        this._stepperIndicatorContainerCustomClass = value;
    }

    get stepperIndicatorContainerClasses() {
        const classes: any = this._stepperIndicatorContainerCustomClass;
        return classes;
    }

    @Input()
    set stepperIndicatorClasses(value: any) {
        this._stepperIndicatorClasses = value;
    }

    get stepperIndicatorClasses() {
        const classes: any = this._stepperIndicatorClasses;
        return classes;
    }

    @Input()
    set stepperTitleClasses(value: any) {
        this._stepperTitleClasses = value;
    }

    get stepperTitleClasses() {
        const classes: any = this._stepperTitleClasses;
        return classes;
    }

    @Input()
    set indicatorIndex(data: any) {
        if (data) {
            this._indicatorIndex = (parseInt(data) + 1);
        }
    }

    @Input()
    set pageTitle(data: any) {
        this._pageTitle = data;
    }

    @Input()
    set showFaIcon(isFaIcon: boolean) {
        this._showFaIcon = isFaIcon;
    }
}
