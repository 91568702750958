import { ChangeDetectorRef, Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { GOOGLE_MAPS_PLACE_URL, GOOGLE_MAP_API_KEY } from '@janet/shared/domain';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SOPInputValueEmitterService } from '../../services/sop-input-value-emitter.service';
import { GeoLocationService } from '../../services/geo-location.service';

@Component({
    selector: 'janet-shop-our-partners-body',
    standalone: false,
    templateUrl: './shop-our-partners-body.component.html',
})
export class ShopOurPartnersBodyComponent implements OnInit {
    _merchantShopLists: any;
    _merchantShopTotalRecords: number;
    public getScreenWidth: any;
    totalRecords: any;
    isLoaderDisplay = false;
    displayData: any = [];
    nextShopsListData = [];
    paginationClicked = false;
    startIndex: number;
    endIndex: number;
    previousStoreData: any[] = [];
    storeListPaginationClicked = { clicked: false };
    mapApiKey: string = GOOGLE_MAP_API_KEY;
    mapUrl: string = GOOGLE_MAPS_PLACE_URL;
    _storeListMobileDisplayClass = 'px-0';
    _contentModules: any[] = [];
    clickAction: string;
    selectedMarkerIndex: number;
    storeIndex: number | null;
    _clearMarker = { clear: false };
    display = false;
    totalDataLength: number;
    nextStoreData: any[] = [];
    itemsPerPage = 10;
    currentPage = 1;
    queryParamSubscription: Subscription;
    searchParamValue: any = null;
    sopLoaderDisplaysubscription: Subscription;
    _locationsPageDetails: any;
    _locationsData: any;
    _dealerInfoData: any;
    _options: any = [];
    sopBodyContentDisplaySubscription: Subscription;
    isSOPBodyContentDisplay = false;
    _locationsLanguageLabelsObj: any;
    _storeListErrorMessage: any;
    locationZipCode: any = '';

    constructor(private _cdr: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId: any, public route: ActivatedRoute, private _sopInputValueEmitterService: SOPInputValueEmitterService, private geoLocationService: GeoLocationService,) {
        this.getScreenWidth = window.innerWidth;
        if (isPlatformBrowser(this.platformId)) {
            this.display = true;
        }
    }
    ngOnInit(): void {
        this.sopLoaderDisplaysubscription = this._sopInputValueEmitterService.isSOPLoaderDisplay$.subscribe(
            (data: boolean) => {
                this.isLoaderDisplay = data;
                this.storeListMobileDisplayClass = data;
                this.clearMarker();
                this._cdr.detectChanges();
            }
        );

        this.queryParamSubscription = this.route?.queryParams?.subscribe((params) => {
            const searchParam = params?.search;
            if (searchParam) {
                this.searchParamValue = searchParam;
            } else {
                this.searchParamValue = null;
            }
        });

        this.sopBodyContentDisplaySubscription = this._sopInputValueEmitterService.isSOPBodyContentDisplay$.subscribe(
            (data: boolean) => {
                this.isSOPBodyContentDisplay = data;
                this._cdr.detectChanges();
            }
        );

        this.initializeData();
    }
    ngOnDestroy() {
        if (this.sopLoaderDisplaysubscription) {
            this.sopLoaderDisplaysubscription.unsubscribe();
        }

        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }

        if (this.sopBodyContentDisplaySubscription) {
            this.sopBodyContentDisplaySubscription.unsubscribe();
        }
    }

    @Input()
    set merchantShopLists(data: any) {
        this._merchantShopLists = data;
        this.initializeData();
    }

    @Input()
    set merchantShopTotalRecords(data: any) {
        this._merchantShopTotalRecords = data;
        this.updateTotalRecords();
    }

    @Input()
    set contentModules(data: any) {
        this._contentModules = data;
    }

    @Input()
    set locationsPageDetails(data: any) {
        this._locationsPageDetails = data;
        this._cdr.detectChanges();
    }

    @Input()
    set locationsData(data: any) {
        this._locationsData = data;
    }

    @Input()
    set dealerInfoData(data: any) {
        this._dealerInfoData = data;
    }

    @Input()
    set options(data: any) {
        if (data) {
            this._options = data;
        }
    }

    @Input()
    set locationsLanguageLabelsObj(data: any) {
        this._locationsLanguageLabelsObj = data;
    }


    @Input()
    set storeListErrorMessage(data: any) {
        this._storeListErrorMessage = data;
        this._cdr.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
    }

    initializeData(): void {
        this.currentPage = 1;
        this.itemsPerPage = 10;
        this.updateDisplayData();
        this.updatePaginationInputProperties();
        this.paginationClicked = false;
        this.getLocationZipCode();
    }

    async getLocationZipCode() {//NOSONAR
        const location = await this.getLatLangDetails();
        if (location?.latitude && location?.longitude) {
            const zipCode = await this.getZipCode(location?.latitude, location?.longitude);
            this.locationZipCode = zipCode ? zipCode?.trim() : '';
        } else if ((!location?.latitude && !location?.longitude) && (!this.searchParamValue || this.searchParamValue?.toString()?.length <= 0) && this._locationsPageDetails.isBrandDealer) {
            let latitude: any = '';
            let longitude: any = '';
            if (this._dealerInfoData) {
                latitude = (this._dealerInfoData?.fDefaultLat && this._dealerInfoData?.fDefaultLat?.toString()?.trim()?.length > 0) ? parseFloat(this._dealerInfoData?.fDefaultLat).toString() : 39.111689;
                longitude = (this._dealerInfoData?.fDefaultLon && this._dealerInfoData?.fDefaultLon?.toString()?.trim()?.length > 0) ? parseFloat(this._dealerInfoData?.fDefaultLon).toString() : -97.119141;
                const zipCode = await this.getZipCode(latitude, longitude);
                this.locationZipCode = zipCode ? zipCode?.trim() : '';
            }
        }
    }

    updatePaginationInputProperties() {
        this.startIndex = 1;
        this.endIndex = 0;
        this.updateTotalRecords();
    }

    updateTotalRecords() {
        const dataRecord = this._merchantShopTotalRecords;
        const records = { record: dataRecord };
        this.totalRecords = { ...records };
        this._cdr.detectChanges();
    }

    handlePaginationUpdate(event: { page: number; action: string }) {
        this.clickAction = event.action;
        this.updateDataOnClick();
        const clicked = { clicked: true };
        this.storeListPaginationClicked = { ...clicked };
    }

    updateDataOnClick() {
        this.paginationClicked = false;
        if (this.clickAction === 'next') {
            if ((this.currentPage * this.itemsPerPage) < this._merchantShopLists?.length) {
                this.currentPage++;
                this.updateDisplayData();
                this._cdr.detectChanges();
            }
        } else if (this.clickAction === 'previous') {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.updateDisplayData();
                this._cdr.detectChanges();
            }
        }
    }

    updateDisplayData() {
        if (this._merchantShopLists && Array.isArray(this._merchantShopLists)) {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            const paginatedData = this._merchantShopLists.slice(startIndex, endIndex);
            this.displayData = paginatedData;
            this._cdr.detectChanges();
        }
    }

    onMarkerSelected(index: number): void {
        this.selectedMarkerIndex = index;
        this.paginationClicked = true;
    }

    onSelectedMarkerIndexValEmitted(index: number | null) {
        this.storeIndex = index;
    }

    clearMarker() {
        if (this.isLoaderDisplay) {
            const clearMarker = { clear: true };
            this._clearMarker = { ...clearMarker };
        }
    }

    isNumeric(value: any): boolean {
        return !isNaN(Number(value));
    }

    storeListErrMsg(msg: any) {
        this._storeListErrorMessage = msg;
    }

    async getLatLangDetails() {
        try {
            const location = await this.geoLocationService.getGeoLocation();
            return location;
        } catch (error) {
            return { latitude: null, longitude: null };
        }
    }

    async getZipCode(lat: any, lang: any) {
        try {
            const zipCode = await this.geoLocationService.getPostalCode(lat, lang);
            return zipCode;
        } catch (error) {
            return '';
        }
    }

    public get shopOurPartnersBodyContainer(): string {
        let containerClass = '';
        if (this.getScreenWidth <= 1439) {
            containerClass = 'shop-our-partners-body row flex-md-row mx-auto my-0 pt-0 pb-0 px-0 pt-md-0 pt-xxl-00 mt-3 mt-md-4';
        }

        if (this.getScreenWidth >= 1440) {
            containerClass = 'shop-our-partners-body container row flex-column-reverse flex-md-row mx-auto my-0 pt-0 pb-0 px-0 pt-md-0 pt-xxl-00 mt-3 mt-md-4';
        }

        if (this.getScreenWidth >= 768) {
            containerClass = containerClass + ' position-relative';
        }

        if (this.totalRecords?.record === 0 && this.getScreenWidth < 768) {
            containerClass += ' px-3';
        }

        return containerClass;
    }

    public get shopOurPartnersBannerContainer(): string {
        let containerClass = '';
        if (this.getScreenWidth <= 1439) {
            containerClass = 'shop-our-partners-body row  mx-auto px-0';
        }

        if (this.getScreenWidth >= 1440) {
            containerClass = 'shop-our-partners-body container row mx-auto px-0';
        }

        if (this.getScreenWidth >= 768) {
            containerClass = containerClass + ' position-relative';
        }
        return containerClass;
    }

    set storeListMobileDisplayClass(display: boolean) {
        const defaultClass = 'px-0';
        if (display) {
            this._storeListMobileDisplayClass = `${defaultClass} d-none`;
        } else {
            this._storeListMobileDisplayClass = `${defaultClass} d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none`;
        }
        this._cdr.detectChanges();
    }

    get storeListMobileDisplayClass(): any {
        return this._storeListMobileDisplayClass;
    }

    public get shopOurPartnersBodyMainContainer(): string {
        let containerClass = '';
        if (this.getScreenWidth <= 767) {
            containerClass = 'px-0';
        }

        if (this.getScreenWidth >= 768) {
            containerClass = 'container px-0';
        }

        if (this.getScreenWidth >= 900) {
            containerClass = 'container px-4';
        }

        if (this.getScreenWidth >= 1440) {
            containerClass = 'container px-4';
            if (this._contentModules?.length === 0) {
                containerClass += ' pb-3';
            }
        }

        return containerClass;
    }

    get paginationDisplayClass() {
        if (this.isLoaderDisplay) {
            if (this.getScreenWidth >= 768) {
                return 'invisible';
            }
            if (this.getScreenWidth < 768) {
                return 'd-none';
            }
        }
        return '';
    }

}
