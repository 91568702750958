import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MerchantFilterService {
    private _filteredData: any = new BehaviorSubject<unknown>({});
    readonly filteredData$: any = this._filteredData.asObservable();

    private _isLoaderDisplay: any = new BehaviorSubject<unknown>(false);
    readonly isLoaderDisplay$: any = this._isLoaderDisplay.asObservable();

    private _sessionStorageSubject = new BehaviorSubject<string>('');
    readonly sessionStorageSubject$: any = this._sessionStorageSubject.asObservable();

    filteredData(value: object) {
        this._filteredData.next(value);
    }

    loaderDisplay(value: boolean) {
        this._isLoaderDisplay.next(value);
    }

    sessionStorageSubject(value: any) {
        this._sessionStorageSubject.next(value);
    }
}
