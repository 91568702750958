<div
    [ngClass]="heroClasses"
    class="hero-container"
    [class.bg-black-alpha-100]="
        moduleData.heroLayoutMobile === layouts.FEATURED && !(isMediumScreen$ | async)?.matches
    "
    #heroContainer
>
    <img
        [ngClass]="{
            'banner-img': moduleData.heroLayoutMobile === layouts.FEATURED && !(isMediumScreen$ | async)?.matches,
            'position-absolute d-block start-0 banner-size banner-img-overlay':
                moduleData.heroLayoutMobile === layouts.BACKGROUND ||
                (moduleData.heroLayoutMobile === layouts.FEATURED && (isMediumScreen$ | async)?.matches)
        }"
        [src]="moduleData.backgroundImage.sourceUrl"
        [srcset]="moduleData.backgroundImage.srcSet"
        class="mw-100"
        [alt]="moduleData.backgroundImage.altText"
    />
    <div class="container hero-body-container" #heroBodyContainer>
        <div class="row">
            <div #heroBody>
                <h1 class="m-0">{{ moduleData.title }}</h1>
                <div
                    class="mt-3 mt-xxl-4"
                    [class.mx-auto]="moduleData.contentAlignment === 'center'"
                    *ngIf="moduleData.contentArea && moduleData.contentArea.toString().trim().length > 0"
                >
                    <div class="m-0 p-0 content" [innerHTML]="moduleData.contentArea | sanitizeHtml"></div>
                </div>
                <ng-container *ngIf="moduleData.showButton">
                    <div class="row">
                        <div
                            class="d-grid d-md-block col-12 mx-auto"
                            [class.justify-content-xxl-center]="moduleData.contentAlignment === 'center'"
                        >
                            <ua2-aff-button
                                class="display-content"
                                [url]="moduleData?.button?.url"
                                [title]="moduleData?.button?.title"
                                [buttonLabel]="moduleData?.button?.title"
                                [target]="moduleData?.button?.target"
                                [id]="'heroBtn'"
                                [buttonCustomClass]="
                                    setHeroButtonClasses +
                                    ' mt-4 mt-xxl-8 mb-md-2 mb-xxl-0 p-3 p-md-4 d-inline-block'
                                "
                            ></ua2-aff-button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
