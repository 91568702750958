<div class="merchant-stores-content-container">
    <div class="card-container-mobile position-relative" (click)="onStoresContainerClick($event)">
        <div class="store-card-highlighter-mobile position-absolute" #highlighter></div>
        <div class="col-12 warning-no-data-mobile m1" *ngIf="totalRecords === 0">
            <p class="mb-0">Sorry, but there aren’t any stores that match your search.</p>
        </div>
        <ua2-aff-accordion>
            <ng-container *ngFor="let merchantStoreList of _displayListSearchResults; let i = index">
                <div
                    [id]="i"
                    class="card-toggle-container-mobile"
                    [ngClass]="{ active: merchantStoreList.active }"
                >
                    <ua2-toggle
                        accordionlist="true"
                        isSubMenuExist="false"
                        title="{{ merchantStoreList.PDNAM1 }}"
                        [distance]="merchantStoreList.DISTANCE ? merchantStoreList.DISTANCE : ''"
                        bullet="{{ ' • ' }}"
                        subTitle="{{ merchantStoreList.PDADR1 }}"
                        [classes]="'m-0 p-3'"
                        [addSpacing]="true"
                        [active]="merchantStoreList.active"
                        (setStorelistActive)="setStoreListActive(i)"
                    >
                        <div class="card-container-submenu">
                            <div class="px-3 py-3">
                                <ua2-aff-button
                                    *ngIf="
                                        !(
                                            merchantStoreList?.DTYPE?.toLowerCase() === 'risa' &&
                                            merchantStoreList?.PDST?.toLowerCase() === 'ca'
                                        )
                                    "
                                    class="display-content"
                                    [url]="merchantStoreList.TARGETURL + getQueryParams()"
                                    [title]=""
                                    [buttonLabel]="'Apply Now'"
                                    [target]="'_blank'"
                                    [id]=""
                                    [buttonCustomClass]="'store-button px-3 d-flex justify-content-center'"
                                ></ua2-aff-button>
                                <ua2-aff-button
                                    *ngIf="
                                        merchantStoreList?.DTYPE?.toLowerCase() === 'risa' &&
                                        merchantStoreList?.PDST?.toLowerCase() === 'ca'
                                    "
                                    [url]="
                                        getDirectionUrl(merchantStoreList?.LATITUDE, merchantStoreList?.LONGITUDE)
                                    "
                                    target="_blank"
                                    [title]="'Get Directions'"
                                    [buttonLabel]="'Get Directions'"
                                    [affButtonIcon]="getFaIcon('fa-diamond-turn-right')"
                                    [showAffButtonIcon]="true"
                                    [buttonCustomClass]="'store-button px-3 d-flex justify-content-center'"
                                    class="display-content"
                                ></ua2-aff-button>
                            </div>
                            <div class="card-text-mobile px-3 pt-2 pb-3" *ngIf="merchantStoreList.CATEGORY !== ''">
                                <div class="col-icon">
                                    <i class="fa-light fa-store pe-2 store-mobile-icon"></i>
                                </div>
                                <div class="col-text">{{ merchantStoreList.CATEGORY }}</div>
                            </div>
                            <div class="card-subtext-mobile px-3 py-2">
                                <div class="col-icon">
                                    <i class="fa-light fa-location-dot pe-2 store-mobile-icon"></i>
                                </div>
                                <div class="col-text mb-2">
                                    {{ merchantStoreList.PDADR1
                                    }}{{ merchantStoreList.PDADR2 ? ", " + merchantStoreList.PDADR2 : "" }}
                                </div>
                            </div>
                            <div class="card-subtext-city-mobile px-3">
                                <span class="state-city-text"
                                    >{{ merchantStoreList.PDCITY
                                    }}{{ merchantStoreList.PDST ? ", " + merchantStoreList.PDST : "" }}
                                    {{ merchantStoreList.PDZIP }}
                                </span>
                            </div>
                        </div>
                    </ua2-toggle>
                </div>
            </ng-container>
        </ua2-aff-accordion>
    </div>
</div>
