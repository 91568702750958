import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { LoaderOptions } from '@googlemaps/js-api-loader';
import { GOOGLE_MAPS_LOADER_OPTIONS } from './services/aff-google-maps-loader.service';

/**
 * Provide in root application providers array.
 * @param options - see https://github.com/googlemaps/js-api-loader/blob/main/src/index.ts#L45-L179
 */
export function provideAffGoogleMapsOptions(options: LoaderOptions): EnvironmentProviders {
    return makeEnvironmentProviders([
        {
            provide: GOOGLE_MAPS_LOADER_OPTIONS,
            useValue: options,
        },
    ]);
}
