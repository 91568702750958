import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const calendarsType = {
    'date-range': 'date-range',
    'date-of-birth': 'date-of-birth',
    'pay-day': 'pay-day',
};

type calendarType = keyof typeof calendarsType;

@Component({
    selector: 'ua2-corporate-date-picker, aff-corporate-date-picker',
    templateUrl: './corporate-date-picker.component.html',
})
export class CorporateDatePickerComponent implements OnInit {
    @Input() formFieldLabel?: string = '';
    @Input() formFieldPlaceholder?: string = '';
    @Input() formFieldId?: string = '';
    @Input() formFieldLabelDefaultClass?: string = '';
    @Input() formFieldLabelCustomClass?: string = '';
    @Input() formFieldDefaultClass?: string = 'shadow-none';
    @Input() formFieldCustomClass?: string = '';
    @Input() type: calendarType = 'date-of-birth';
    @Input() datePickerContainerDefaultClass = 'aff-date-picker-container';
    @Input() datePickerContainerCustomClass = '';
    @Input() inputDateDefaultClass = 'aff-date-input';
    @Input() inputDateCustomClass = '';
    @Input() calendarIconDefaultClass = 'aff-calendar-icon';
    @Input() calendarIconCustomClass = '';
    @Input() dateFormatDefaultClass = 'aff-date-format';
    @Input() dateFormatCustomClass = '';
    @Input() dateFormat = 'yyyy-MM-dd';
    @Input() lifeExpectancy = 70;
    @Input() control = new FormControl();
    // number of days
    @Input() dateRangeDuration = 1000;
    // number of months to show paydays
    @Input() paydayMonthsDuration = 12;
    // holiday calendar for payday
    @Input() holidayCalendar = [
        '2024/01/01',
        '2024/01/15',
        '2024/01/26',
        '2024/03/25',
        '2024/03/29',
        '2024/04/09',
        '2024/04/10',
        '2024/05/01',
        '2024/08/15',
        '2024/10/02',
        '2024/10/31',
        '2024/12/25',
    ];

    inputTextId = '';
    updatedDate = '';
    daysOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    showError = false;
    calendarSelected = false;
    showCalendar = false;
    @Input() _isReadOnly = true;
    _showErrorMessage = true;

    constructor(private datePickerComponentRef: ElementRef, private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.inputTextId = this.generateUniqueId();

        this.control.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((date: string) => {
            if (date === '' || this.calendarSelected) {
                this.updatedDate = date;
                this.calendarSelected = false;
                this.toggleError(true);
                return;
            }

            const isvalidDate =
                this.type === calendarsType['date-range'] ? this.isValidDateRange(date) : this.isValidDate(date);
            if (isvalidDate) {
                if (this.type === calendarsType['date-of-birth'] || this.type === calendarsType['pay-day']) {
                    this.updatedDate = formatDate(date, this.dateFormat, 'en-US');
                } else if (this.type === calendarsType['date-range']) {
                    const split = date.split('-');
                    this.updatedDate =
                        formatDate(split[0].trim(), this.dateFormat, 'en-US') +
                        ' - ' +
                        formatDate(split[1].trim(), this.dateFormat, 'en-US');
                }
                this.showCalendar = true;
            } else {
                this.showCalendar = false;
            }
            this.toggleError(isvalidDate);
        });
    }

    @HostListener('document:click', ['$event.target'])
    closeCalendar(target: unknown) {
        if (!this.datePickerComponentRef.nativeElement.contains(target)) {
            this.showCalendar = false;
        }
    }

    toggleError(isvalidDate: boolean) {
        this.showError = !isvalidDate;
        this.cd.detectChanges();
    }

    checkLeapYear(year: number) {
        return (year % 4 === 0 && year % 100 !== 0 && year % 400 !== 0) || (year % 100 === 0 && year % 400 === 0);
    }

    getFebDays(year: number) {
        return this.checkLeapYear(year) ? 29 : 28;
    }

    isValidDateRange(date: string) {
        const dateSplit = date.split('-');

        if (!dateSplit[0] || !dateSplit[1]) {
            return false;
        }

        const isStartValid = this.isValidDate(dateSplit[0]);
        const isEndValid = this.isValidDate(dateSplit[1]);

        if (!isStartValid || !isEndValid) {
            return false;
        }

        return true;
    }

    isValidDate(date: string) {
        const dateSplit = date.split('-');
        const year = Number(dateSplit[0]);
        const monthIdx = Number(dateSplit[1]) - 1;
        const day = Number(dateSplit[2]);

        if (
            isNaN(year) ||
            isNaN(monthIdx) ||
            isNaN(day) ||
            year < 0 ||
            monthIdx > 11 ||
            monthIdx < 0 ||
            day < 1 ||
            day > this.daysOfMonth[monthIdx]
        ) {
            return false;
        }

        this.daysOfMonth[1] = this.getFebDays(year);
        const inputDate = new Date(year, monthIdx, day);
        const inputDateString = formatDate(inputDate, this.dateFormat, 'en-US');
        const start = new Date();
        const end = new Date();
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        if (this.type === calendarsType['date-of-birth']) {
            start.setFullYear(start.getFullYear() - this.lifeExpectancy);
        } else if (this.type === calendarsType['date-range']) {
            end.setDate(end.getDate() + this.dateRangeDuration);
        } else if (this.type === calendarsType['pay-day']) {
            end.setDate(-1);
            end.setMonth(end.getMonth() + this.paydayMonthsDuration);
        }

        if (
            inputDate < start ||
            inputDate > end ||
            (this.type === calendarsType['pay-day'] &&
                (this.holidayCalendar.includes(inputDateString) ||
                    inputDate.getDay() === 6 ||
                    inputDate.getDay() === 0))
        ) {
            return false;
        }

        return true;
    }

    generateUniqueId(): string {
        return crypto.randomUUID();
    }

    onDateSelection(selectedDate: string) {
        this.control.setValue(selectedDate);
        this.showCalendar = false;
        this.calendarSelected = true;
    }

    @Input()
    set showErrorMessage(value: any) {
        this._showErrorMessage = value;
    }

    get formFieldClasses() {
        let classes = `${this.formFieldDefaultClass} ${this.formFieldCustomClass}`;

        if (
            this._showErrorMessage &&
            this.control.touched &&
            this.formFieldPlaceholder?.includes('*') &&
            !this.showCalendar
        ) {
            classes += ' error-message';
        }
        return classes.trim();
    }
}
