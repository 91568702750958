export const ContentModulesMock = {
    __typename: 'Page',
    content: null,
    contentTypeName: 'page',
    id: 'cG9zdDo5Mw==',
    uri: '/',
    title: 'Kitchen Sink',
    status: 'publish',
    slug: 'kitchen-sink-2-2',
    parentId: null,
    pageId: 93,
    seo: {
        __typename: 'PostTypeSEO',
        title: 'Kitchen Sink - hcms1',
    },
    contentModules: {
        __typename: 'Page_Contentmodules',
        contentModules: [
            {
                __typename: 'Page_Contentmodules_ContentModules_Hero',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Hero',
                backgroundImage: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero-300x86.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero-1024x293.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero-768x219.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero.jpg 1400w',
                    altText: 'Siva',
                },
                title: 'SIva',
                contentArea: null,
                colorScheme: 'light',
                contentAlignment: 'left',
                showButton: null,
                heroLayoutMobile: 'background',
                button: {
                    __typename: 'AcfLink',
                    target: '',
                    title: 'Google',
                    url: 'https://www.linkedin.com/company/americanfirstfinance/',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Hero',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Hero',
                backgroundImage: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-300x86.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-1024x293.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-768x219.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero.jpg 1400w',
                    altText: 'hero dark',
                },
                title: 'Lorem Ipsum Dolor Sit Amet',
                contentArea: '<p>Lorem ipsum dolor sit amet</p>\n',
                colorScheme: 'light',
                contentAlignment: 'center',
                showButton: null,
                heroLayoutMobile: 'background',
                button: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'New Tab AFF Testimonials Long Text Here and Here',
                    url: 'https://dev1hcms1.americanfirstfinance.com/aff-testimonials/',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Hero',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Hero',
                backgroundImage: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-300x86.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-1024x293.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-768x219.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero.jpg 1400w',
                    altText: 'hero dark',
                },
                title: 'Lorem Ipsum Dolor Sit Amet',
                contentArea: null,
                colorScheme: 'light',
                contentAlignment: 'center',
                showButton: true,
                heroLayoutMobile: 'background',
                button: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'New Tab AFF',
                    url: 'https://dev1hcms1.americanfirstfinance.com/aff-testimonials/',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Hero',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Hero',
                backgroundImage: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-cream-grey-hero.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-cream-grey-hero-300x86.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-cream-grey-hero-1024x293.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-cream-grey-hero-768x219.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-cream-grey-hero.jpg 1400w',
                    altText: 'hero light',
                },
                title: 'Loremipsumdolorsit Amet Adipscing',
                contentArea: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>\n',
                colorScheme: 'dark',
                contentAlignment: 'center',
                showButton: true,
                heroLayoutMobile: 'background',
                button: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'View Our Policy',
                    url: 'https://dev1hcms1.americanfirstfinance.com/how-to-apply/',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Hero',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Hero',
                backgroundImage: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-300x86.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-1024x293.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-768x219.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero.jpg 1400w',
                    altText: 'hero dark',
                },
                title: 'Shorty',
                contentArea:
                    '<p><del>Left &#8211; Light &#8211; Button &#8211; Content Below Image</del></p>\n<blockquote><p><em>When you meet kittens face t</em>o face, your heart suddenl<a href="http://testin">y melts and the gid</a>dy child in you can’t help but give an appropriate, “AWWW!” You want to handle, play, and snuggle with them until the cows come home. Some of us have had the opportunity to witness the birth and development of kittens from their first cry, to when they first open their eyes, and on i<strong>nto adulthood. If you haven’t had that chance, there are likely some fun facts about kittens that you don’t know about and that can help you raise a healthy and happy kitty.</strong></p></blockquote>\n<p style="text-align: center;">When you meet kittens face to face, your heart suddenly melts and the giddy child in you can’t help but give an appropriate, “AWWW!” You want to handle, play, and snuggle with them un<span style="text-decoration: underline;">til the cows come home. Some of us have had the opportunity to witness the birth and development of kittens from their first cry, to when they first open their eyes, and on into adulthood. If you haven’t had that chance, there are likely some fun facts about kittens that you don’t know about and that can help you raise a healthy and happy kitty.</span></p>\n<p style="text-align: right;">When you meet kittens face to face, your heart suddenly melts and the giddy child in you can’t help but give an appropriate, “AWWW!” You want to handle, play, and snuggle with them until the cows come home. Some of us have had the opportunity to witness the birth and development of kittens from their first cry, to when they first open their eyes, and on into adulthood. If you haven’t had that chance, there are likely some fun facts about kittens that you don’t know about and that can help you raise a healthy and happy kitty.</p>\n',
                colorScheme: 'light',
                contentAlignment: 'left',
                showButton: true,
                heroLayoutMobile: 'featured',
                button: {
                    __typename: 'AcfLink',
                    target: '',
                    title: 'Contact This',
                    url: 'https://dev1hcms1.americanfirstfinance.com/contact-us/',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Hero',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Hero',
                backgroundImage: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36-300x200.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36-1024x682.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36-768x512.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36.jpg 1099w',
                    altText: '',
                },
                title: 'Good People Making Bad Decisions Having More Options!',
                contentArea:
                    '<p>Development of kittens from their first cry, to when they first open their eyes, and on into adulthood. If you haven’t had that chance, there are likely some fun facts about kittens that you don’t know about and that can help you raise a healthy and happy kitty.</p>\n',
                colorScheme: 'dark',
                contentAlignment: 'center',
                showButton: null,
                heroLayoutMobile: 'background',
                button: {
                    __typename: 'AcfLink',
                    target: '',
                    title: 'Home',
                    url: '/',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Hero',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Hero',
                backgroundImage: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero-300x86.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero-1024x293.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero-768x219.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero.jpg 1400w',
                    altText: 'Siva',
                },
                title: 'All About Kittens',
                contentArea:
                    '<p>When you meet kittens face to face, your heart suddenly melts and the giddy child in you can’t help but give an appropriate, “AWWW!” You want to handle, play, and snuggle with them until the cows come home. Some of us have had the opportunity to witness the birth and development of kittens from their first cry, to when they first open their eyes, and on into adulthood. If you haven’t had that chance, there are likely some fun facts about kittens that you don’t know about and that can help you raise a healthy and happy kitty.</p>\n',
                colorScheme: 'dark',
                contentAlignment: 'left',
                showButton: true,
                heroLayoutMobile: 'background',
                button: {
                    __typename: 'AcfLink',
                    target: '',
                    title: 'Home',
                    url: '/',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_ContentArea',
                fieldGroupName: 'Page_Contentmodules_ContentModules_ContentArea',
                content:
                    '<p><strong>Content:1</strong></p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. content Left</p>\n',
                content2:
                    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip e<a href="https://qa1hcms1.americanfirstfinance.com/magni-quia-aspernatur-occaecati-nemo-cupiditate-adipisci/" target="_blank" rel="noopener">x ea commodo consequat.</a> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <em>culpa</em> qui officia <strong>deserunt</strong> mollit anim<span style="text-decoration: underline;"> id est laborum.</span></p>\n<h5>H5 headimng</h5>\n<ul>\n<li>list item one</li>\n<li>list item two with a longer name written here keep going you&#8217;re almost done reading</li>\n<li>list item three and a half which is just a skosh longer</li>\n<li>list item four goes here and here</li>\n</ul>\n<p>&nbsp;</p>\n<p><strong>HERE IS A NUMBERED LIST</strong></p>\n<ol>\n<li>list item one</li>\n<li>list item two with a longer name written here keep going you&#8217;re almost done reading</li>\n<li>list item three and a half which is just a skosh longer</li>\n<li>list item four goes here and here</li>\n</ol>\n',
                contentWidth: '50_width',
                image: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-300x200.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-1024x682.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-768x512.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg 1382w',
                    altText: 'Test content Area',
                },
                image2: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36-300x200.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36-1024x682.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36-768x512.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36.jpg 1099w',
                    altText: '',
                },
                imagePosition: 'left',
                imagePosition2: 'left',
                showButton: true,
                showButton2: true,
                showImage1: true,
                showImage2: null,
                button: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'Learn More',
                    url: 'https://www.foundanimals.org/facts-about-kittens/',
                },
                button2: {
                    __typename: 'AcfLink',
                    target: '',
                    title: 'TEST',
                    url: 'https://google.com',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_ContentArea',
                fieldGroupName: 'Page_Contentmodules_ContentModules_ContentArea',
                content:
                    '<p><strong>Content Area2 :</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. content left 1</p>\n',
                content2:
                    '<p><strong>Content:23232 </strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. content right 1</p>\n',
                contentWidth: '50_width',
                image: {
                    __typename: 'MediaItem',
                    sourceUrl: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2021/12/300.jpeg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2021/12/300.jpeg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2021/12/300-150x150.jpeg 150w',
                    altText: 'Small Kitty',
                },
                image2: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-300x86.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-1024x293.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero-768x219.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/geo-1-darkblue-blue-hero.jpg 1400w',
                    altText: 'hero dark',
                },
                imagePosition: 'left',
                imagePosition2: 'left',
                showButton: true,
                showButton2: true,
                showImage1: null,
                showImage2: true,
                button: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'Learn More',
                    url: 'https://www.foundanimals.org/facts-about-kittens/',
                },
                button2: {
                    __typename: 'AcfLink',
                    target: '',
                    title: 'goo',
                    url: 'https://google.com',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_ContentArea',
                fieldGroupName: 'Page_Contentmodules_ContentModules_ContentArea',
                content:
                    '<p><strong>Title1</strong></p>\n<p style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>\n',
                content2: null,
                contentWidth: 'full_width',
                image: null,
                image2: null,
                imagePosition: null,
                imagePosition2: null,
                showButton: null,
                showButton2: null,
                showImage1: null,
                showImage2: null,
                button: null,
                button2: null,
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_ContentArea',
                fieldGroupName: 'Page_Contentmodules_ContentModules_ContentArea',
                content:
                    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 123</p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip e<a href="https://dev1hcms1.americanfirstfinance.com/magni-quia-aspernatur-occaecati-nemo-cupiditate-adipisci/" target="_blank" rel="noopener">x ea commodo consequat.</a> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <em>culpa</em> qui officia <strong>deserunt</strong> mollit anim<span style="text-decoration: underline;"> id est laborum. content left and right as image.</span></p>\n<ul>\n<li>list item one</li>\n<li>list item two with a longer name written here keep going you&#8217;re almost done reading</li>\n<li>list item three and a half which is just a skosh longer</li>\n<li>list item four goes here and here</li>\n</ul>\n<p>&nbsp;</p>\n<p><strong>HERE IS A NUMBERED LIST</strong></p>\n<ol>\n<li>list item one</li>\n<li>list item two with a longer name written here keep going you&#8217;re almost done reading</li>\n<li>list item three and a half which is just a skosh longer</li>\n<li>list item four goes here and here</li>\n</ol>\n',
                content2: null,
                contentWidth: '50_width',
                image: null,
                image2: {
                    __typename: 'MediaItem',
                    sourceUrl: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/03/images.jpg',
                    srcSet: null,
                    altText: 'test hero',
                },
                imagePosition: null,
                imagePosition2: 'left',
                showButton: true,
                showButton2: null,
                showImage1: null,
                showImage2: true,
                button: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'KITTENs',
                    url: 'https://dev1hcms1.americanfirstfinance.com/faq-sample/',
                },
                button2: null,
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Testimonials',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Testimonials',
                testimonials: [
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Testimonials_testimonials',
                        fieldGroupName: 'testimonials',
                        rating: '4',
                        testimonialCustomerName: 'Freddie Mercury',
                        testimonialText:
                            'Probably the best kitten I have ever owned, super friendly and meows when he sees me. Great with my kids and likes to cuddle up with me at night when I am lounging on the sofa. Gets into trouble sometimes (eats my plants so dropping a star). Would definitely recommend!',
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Testimonials_testimonials',
                        fieldGroupName: 'testimonials',
                        rating: '4.5',
                        testimonialCustomerName: 'Dave Franco',
                        testimonialText:
                            'The perfect fit for myself and my family, so happy we decided to adopt this little guy! Zero complaints.',
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Testimonials_testimonials',
                        fieldGroupName: 'testimonials',
                        rating: '5',
                        testimonialCustomerName: 'John Lennon',
                        testimonialText:
                            "Can't say enough about my decision to become a cat owner, they are the absolutely best companions, I think I might adopt 10 more. Help!",
                    },
                ],
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Testimonials',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Testimonials',
                testimonials: [
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Testimonials_testimonials',
                        fieldGroupName: 'testimonials',
                        rating: '5',
                        testimonialCustomerName: null,
                        testimonialText:
                            'Probably the best kitten I have ever owned, super friendly and meows when he sees me. Great with my kids and likes to cuddle up with me at night when I am lounging on the sofa. Gets into trouble sometimes (eats my plants so dropping a star). Would definitely recommend!Probably the best kitten I have',
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Testimonials_testimonials',
                        fieldGroupName: 'testimonials',
                        rating: '4',
                        testimonialCustomerName: 'Freddie Mercury',
                        testimonialText:
                            'Probably the best kitten I have ever owned, super friendly and meows when he sees me. Great with my kids and likes to cuddle up with me at night when I am lounging on the sofa. Gets into trouble sometimes (eats my plants so dropping a star). Would definitely recommend!',
                    },
                ],
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Testimonials',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Testimonials',
                testimonials: [
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Testimonials_testimonials',
                        fieldGroupName: 'testimonials',
                        rating: '5',
                        testimonialCustomerName: null,
                        testimonialText:
                            'Probably the best kitten I have ever owned, super friendly and meows when he sees me. Great with my kids and likes to cuddle up with me at night when I am lounging on the sofa. Gets into trouble sometimes (eats my plants so dropping a star). Would definitely recommend!Probably the best kitten I have',
                    },
                ],
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_CcpaDsarForm',
                fieldGroupName: 'Page_Contentmodules_ContentModules_CcpaDsarForm',
                otDsarWebForm:
                    '<p><!-- OneTrust Web Form start --></p>\n<style>\n    .ot-form-wrapper {\n        max-width: 750px;\n        height: 800px;\n        border: 1px solid #c0c2c7;\n        margin: auto;\n    }\n    .ot-form-wrapper iframe {\n        width: 100%;\n        height: 100%;\n        border: none;\n    }\n</style>\n<div class="ot-form-wrapper">\n    <iframe src="https://privacyportal.onetrust.com/webform/7bd2ce3b-91eb-4ef7-a171-66ee2cc29908/b2218667-23ad-473d-8809-29e91d2f88de"><br />\n    </iframe>\n</div>\n<p><!-- OneTrust Web Form end --></p>\n',
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_ContentArea',
                fieldGroupName: 'Page_Contentmodules_ContentModules_ContentArea',
                content:
                    '<p><strong>Content wissy1: </strong></p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>\n',
                content2: null,
                contentWidth: '50_width',
                image: null,
                image2: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-300x200.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-1024x682.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-768x512.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg 1382w',
                    altText: 'Test content Area',
                },
                imagePosition: null,
                imagePosition2: 'right',
                showButton: true,
                showButton2: null,
                showImage1: null,
                showImage2: true,
                button: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'Button Text',
                    url: 'https://pln-dev1corp1.aff.local/kitchen-sink/content-area-module',
                },
                button2: null,
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_ContentArea',
                fieldGroupName: 'Page_Contentmodules_ContentModules_ContentArea',
                content:
                    '<p><strong>Content wissy1: </strong></p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>\n',
                content2:
                    '<p><strong>Content wissy1: </strong></p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>\n',
                contentWidth: '50_width',
                image: null,
                image2: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-300x200.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-1024x682.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-768x512.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg 1382w',
                    altText: 'Test content Area',
                },
                imagePosition: null,
                imagePosition2: 'right',
                showButton: true,
                showButton2: true,
                showImage1: null,
                showImage2: null,
                button: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'Test Merchant',
                    url: 'https://pln-dev1corp1.aff.local/kitchen-sink/content-area-module',
                },
                button2: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'Test Content Area Module',
                    url: 'https://pln-dev1corp1.aff.local/kitchen-sink/content-area-module',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_ContentArea',
                fieldGroupName: 'Page_Contentmodules_ContentModules_ContentArea',
                content:
                    '<p><strong>Content wissy1: </strong></p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>\n',
                content2:
                    '<p><strong>Content wissy1: </strong></p>\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>\n',
                contentWidth: 'full_width',
                image: null,
                image2: {
                    __typename: 'MediaItem',
                    sourceUrl:
                        'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg',
                    srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-300x200.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-1024x682.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e-768x512.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/7fdd35b2-69ff-3613-a230-07cb264a035e.jpg 1382w',
                    altText: 'Test content Area',
                },
                imagePosition: null,
                imagePosition2: 'right',
                showButton: true,
                showButton2: true,
                showImage1: null,
                showImage2: null,
                button: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'Button Text',
                    url: 'https://pln-dev1corp1.aff.local/kitchen-sink/content-area-module',
                },
                button2: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'Test Content Area Module',
                    url: 'https://pln-dev1corp1.aff.local/kitchen-sink/content-area-module',
                },
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Icons',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Icons',
                showButton: true,
                button: {
                    __typename: 'AcfLink',
                    target: '_blank',
                    title: 'Test',
                    url: 'https://www.google.com',
                },
                icons: [
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "light-ceiling", "label" : "Light Ceiling", "unicode" : "e016" }',
                        useFaIcon: true,
                        iconImage: null,
                        iconTitle: 'Light Ceiling',
                        iconSubText: '<p>This is test Sub-Text</p>\n',
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "chair-office", "label" : "Office Chair", "unicode" : "f6c1" }',
                        useFaIcon: true,
                        iconImage: null,
                        iconTitle: 'Office Chair',
                        iconSubText: '<p><strong>This is test Bold Sub-Text</strong></p>\n',
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "bed", "label" : "Bed", "unicode" : "f236" }',
                        useFaIcon: true,
                        iconImage: null,
                        iconTitle: 'Bed',
                        iconSubText:
                            '<p><span style="text-decoration: underline;">This is a test underline Sub-Text</span></p>\n',
                    },
                ],
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Icons',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Icons',
                showButton: null,
                button: {
                    __typename: 'AcfLink',
                    target: '',
                    title: 'Test',
                    url: 'https://www.google.com',
                },
                icons: [
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "light-ceiling", "label" : "Light Ceiling", "unicode" : "e016" }',
                        useFaIcon: null,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: 'doggo much cute',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/03/dog-puppy-on-garden-royalty-free-image-1586966191.jpg',
                        },
                        iconTitle: 'Dog',
                        iconSubText: '<p><em>This is test italic sub text</em></p>\n',
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "chair-office", "label" : "Office Chair", "unicode" : "f6c1" }',
                        useFaIcon: null,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: '',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/51e2929c-7c6f-338c-bf5a-739a104ad812.jpg',
                        },
                        iconTitle: 'Deer',
                        iconSubText:
                            '<p><a href="https://www.google.com" target="_blank" rel="noopener">This is hyperlink</a></p>\n',
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "bed", "label" : "Bed", "unicode" : "f236" }',
                        useFaIcon: null,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: 'ball o yarn',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-31-at-8.55.13-AM.png',
                        },
                        iconTitle: 'Ball',
                        iconSubText:
                            '<p>This is a test paragraph break<br />\nThis is a test paragraph break</p>\n',
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: null,
                        useFaIcon: null,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: '',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/a15a6a62-59df-3ac9-afd7-585261f6c9ce.jpg',
                        },
                        iconTitle: 'Bus',
                        iconSubText: '<p>this is test sub text</p>\n',
                    },
                ],
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Icons',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Icons',
                showButton: true,
                button: {
                    __typename: 'AcfLink',
                    target: '',
                    title: 'Button Text',
                    url: 'https://www.google.com',
                },
                icons: [
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "football-helmet", "label" : "Football Helmet", "unicode" : "f44f" }',
                        useFaIcon: true,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: 'doggo much cute',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/03/dog-puppy-on-garden-royalty-free-image-1586966191.jpg',
                        },
                        iconTitle: 'Helmet',
                        iconSubText: null,
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "boot-heeled", "label" : "Boot Heeled", "unicode" : "e33f" }',
                        useFaIcon: true,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: '',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/51e2929c-7c6f-338c-bf5a-739a104ad812.jpg',
                        },
                        iconTitle: 'Boot',
                        iconSubText: null,
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "socks", "label" : "Socks", "unicode" : "f696" }',
                        useFaIcon: true,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: 'ball o yarn',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-31-at-8.55.13-AM.png',
                        },
                        iconTitle: 'Socks',
                        iconSubText: null,
                    },
                ],
            },
            {
                __typename: 'Page_Contentmodules_ContentModules_Icons',
                fieldGroupName: 'Page_Contentmodules_ContentModules_Icons',
                showButton: true,
                button: {
                    __typename: 'AcfLink',
                    target: '',
                    title: 'Button Text',
                    url: 'https://www.google.com',
                },
                icons: [
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "light-ceiling", "label" : "Light Ceiling", "unicode" : "e016" }',
                        useFaIcon: null,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: '',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/bbd51476-35ef-33e6-9cf6-c514d1301383.jpg',
                        },
                        iconTitle: 'Nature',
                        iconSubText: null,
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: '{ "style" : "solid", "id" : "chair-office", "label" : "Office Chair", "unicode" : "f6c1" }',
                        useFaIcon: null,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: '',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/962e0c2e-1f79-3935-bc4e-191b6d1611b7.jpg',
                        },
                        iconTitle: 'Human',
                        iconSubText: null,
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: null,
                        useFaIcon: null,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: '',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/10/conns.png',
                        },
                        iconTitle: 'Brand',
                        iconSubText: null,
                    },
                    {
                        __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
                        fieldGroupName: 'icons',
                        iconFa: null,
                        useFaIcon: null,
                        iconImage: {
                            __typename: 'MediaItem',
                            altText: '',
                            mediaItemUrl:
                                'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/02/e935f79e-138d-3e9e-8902-c497f50bfb36.jpg',
                        },
                        iconTitle: 'Nature',
                        iconSubText: null,
                    },
                ],
            },
        ],
    },
};
