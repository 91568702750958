import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { MerchantFilterService } from '../../services/merchant-filter.service';

@Component({
    selector: 'janet-merchant-search-form-container',
    templateUrl: './merchant-search-form-container.component.html',
})
export class MerchantSearchFormContainerComponent implements OnInit, OnDestroy {
    merchantSearchFormContainer =
        'merchant-search-form-container px-3 px-md-0 d-flex flex-column flex-md-row justify-content-md-between align-items-start align-items-md-center ps-3 ps-sm-3';
    displayTooltip = false;
    locationSearchForm: FormGroup;
    errorMessages = {
        required: 'Field is required',
    };
    options = [];
    searchDropdownClass =
        'autocomplete autocomplete-size list-unstyled m-0 p-0 border-top-0 position-absolute w-100 text-start';
    _autoFocus = false;
    _zipCode = '';
    LoaderDisplaysubscription: Subscription;
    isLoaderDisplay = false;

    constructor(public formBuilder: FormBuilder, public route: ActivatedRoute, private _merchantFilterService: MerchantFilterService,) { }

    @Input()
    set zipCode(value: any) {
        this._zipCode = value;
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            const zipCode = params.get('search');
            this._zipCode = zipCode !== null ? zipCode : ''; // Set _zipCode to an empty string if it's null
        });

        this.locationSearchForm = this.formBuilder.group({
            locationSearchFormField: [''],
        });

        this.LoaderDisplaysubscription = this._merchantFilterService.isLoaderDisplay$.subscribe((data: boolean) => {
            this.isLoaderDisplay = data;
        });
    }

    ngOnDestroy() {

        if (this.LoaderDisplaysubscription) {
            this.LoaderDisplaysubscription.unsubscribe();
        }
    }

    @Input()
    set selectOptions(data: any) {
        this.options = data;
    }

    toggleTooltip() {
        this.displayTooltip = !this.displayTooltip;
    }

    onToolTipClick() {
        this._autoFocus = true;
        this.locationSearchForm.reset();
    }

    isNumeric(value: any): boolean {
        return !isNaN(Number(value));
    }

    get containerClass() {
        return this.merchantSearchFormContainer;
    }
}
