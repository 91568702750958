import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
@Injectable({
    providedIn: 'root',
})
export class SOPSearchResultsService {

    SOPSearchURL = environment.SOPSearchURL;

    constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document) { }

    getSOPSearchResults(
        searchValue: number | string | null,
        filterValue: string | null,
        location: any,
        distance: any,
        dealerSlug?: any,
        dealerNumber?: any,
    ): Observable<any> {
        let url = `${this.SOPSearchURL}${searchValue}/${location.latitude}/${location.longitude}/${distance}`;

        if (dealerSlug && dealerNumber) {
            url = `${url}/${dealerSlug}/${dealerNumber}`;
        } else {
            url = dealerSlug ? `${url}/${dealerSlug}?version=2` : `${url}?version=2`;

            if (filterValue) {
                url = `${url}&filter=${filterValue}`;
            }
        }

        return this.http.get(url);
    }
}
