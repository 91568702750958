import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AffBtnColorVariantService {
    getColorVariant(variant: string | null | undefined): string {
        let colorVariant = '';
        switch (variant) {
            case 'black':
                colorVariant = 'btn-black-alpha-900';
                break;
            case 'black-gradient':
                colorVariant = 'btn-black-gradient';
                break;
            case 'blue':
                colorVariant = 'btn-blue-500';
                break;
            case 'blue-gradient':
                colorVariant = 'btn-blue-gradient';
                break;
            case 'green':
                colorVariant = 'btn-green-700';
                break;
            case 'yellow':
                colorVariant = 'btn-yellow-600';
                break;
            case 'dark-red':
                colorVariant = 'btn-dark-red-700';
                break;
            case 'light-red':
                colorVariant = 'btn-light-red-500';
                break;
            default:
                colorVariant = 'btn-black-alpha-900';
                break;
        }
        return colorVariant;
    }
}
