<div class="container-fluid px-2 px-md-4 px-xl-10 bg-black-alpha-50">
    <div class="row pt-4 pb-2 px-3 px-md-4 px-xl-11">
        <div *ngFor="let menu of menuList; let i = index; let l = last; trackBy: trackByFn" class="col-md-2 pb-2">
            <h6 class="fw-bold">
                {{ menu?.node?.label | uppercase }}
            </h6>
            <ng-container
                [ngTemplateOutlet]="subMenuList"
                [ngTemplateOutletContext]="{ submenuList: { submenus: menu.children, lastIndex: l } }"
            >
            </ng-container>
        </div>
    </div>
</div>

<ng-template let-submenuList="submenuList" #subMenuList>
    <janet-footer-sub-menu-list [subMenuList]="submenuList"></janet-footer-sub-menu-list>
</ng-template>
