<div class="full-width-content-wrapper container" *ngIf="moduleData">
    <div class="row px-3 px-xxl-4 d-flex justify-content-center me-0">
        <div
            class="col-md-12 padding-0 p-32"
            *ngIf="moduleData.showImage1 && moduleData?.image?.sourceUrl"
            [ngClass]="{
                'col-md-12 col-xxl-6': moduleData.contentWidth === '50_width',
                'col-lg-12': moduleData.contentWidth === 'full_width',
                'order-last':
                    moduleData.contentWidth === '50_width' &&
                    moduleData.imagePosition === 'right' &&
                    getScreenSize()
            }"
        >
            <img
                [src]="moduleData.image.sourceUrl"
                [srcset]="moduleData.image.srcSet ? moduleData.image.srcSet : moduleData.image.sourceUrl"
                [alt]="moduleData.image.altText"
                loading="lazy"
                [ngClass]="{ 'm-0 p-0 mb-4 d-inline-block': getScreenWidth < 768 }"
            />

            <div *ngIf="moduleData.showButton">
                <ua2-aff-button
                    class="display-content"
                    [url]="moduleData?.button?.url"
                    [title]="moduleData?.button?.title"
                    [target]="moduleData?.button?.target"
                    [buttonLabel]="moduleData?.button?.title"
                    [id]="'button'"
                    [buttonCustomClass]="
                        setFullWidthButtonClasses + ' p-xxl-4 p-3 d-inline-block col-12 col-md-3 my-3'
                    "
                ></ua2-aff-button>
            </div>
        </div>

        <div
            #fullWidthBody
            *ngIf="!moduleData.showImage1 && moduleData.content !== null"
            [ngClass]="{
                'col-md-12 col-xxl-6': moduleData.contentWidth === '50_width',
                'col-lg-12': moduleData.contentWidth === 'full_width',
                'order-last md-order-first':
                    moduleData.contentWidth === '50_width' &&
                    moduleData.showImage2 &&
                    moduleData.imagePosition2 === 'left'
            }"
            class="col-sm-12 col-md-12 p-32 padding-0"
        >
            <div [class.mx-auto]="moduleData.contentAlignment === 'center'">
                <div
                    class="mb-4 content-list"
                    [innerHTML]="moduleData.content | addLazyLoading | sanitizeHtml"
                ></div>
            </div>
            <div *ngIf="moduleData.showButton">
                <ua2-aff-button
                    class="display-content"
                    [url]="moduleData?.button?.url"
                    [title]="moduleData?.button?.title"
                    [target]="moduleData?.button?.target"
                    [buttonLabel]="moduleData?.button?.title"
                    [id]="'button'"
                    [buttonCustomClass]="'p-xxl-4 px-3 py-25 d-inline-block text-break mt-2 mb-3 mt-xxl-3'"
                ></ua2-aff-button>
            </div>
        </div>

        <div
            class="col-md-12 p-32 py-2 padding-0"
            [ngClass]="{
                'col-md-12 col-xxl-6': moduleData.contentWidth === '50_width',
                'col-lg-12': moduleData.contentWidth === 'full_width',
                'order-first':
                    (moduleData.contentWidth === '50_width' && moduleData.imagePosition2 === 'left') ||
                    (!moduleData.showImage1 &&
                        moduleData.content !== null &&
                        moduleData.contentWidth === '50_width' &&
                        !getScreenSize())
            }"
            *ngIf="
                moduleData.showImage2 && moduleData?.image2?.sourceUrl && moduleData.contentWidth !== 'full_width'
            "
        >
            <img
                [src]="moduleData.image2.sourceUrl"
                [alt]="moduleData.image2.altText"
                [srcset]="moduleData.image2.srcSet ? moduleData.image2.srcSet : moduleData.image2.sourceUrl"
                loading="lazy"
                [ngClass]="{ 'm-0 p-0 mb-4 d-inline-block': getScreenWidth < 768 }"
            />
            <!-- second column-->
            <div *ngIf="moduleData.showButton2">
                <ua2-aff-button
                    class="display-content"
                    [url]="moduleData?.button2?.url"
                    [title]="moduleData?.button2?.title"
                    [target]="moduleData?.button?.target"
                    [buttonLabel]="moduleData?.button2?.title"
                    [id]="'button'"
                    [buttonCustomClass]="'p-xxl-4 px-3 py-25 col-md-4 my-3 d-inline-block text-break col-lg-4'"
                ></ua2-aff-button>
            </div>
        </div>

        <div
            #fullWidthBody2
            *ngIf="
                !moduleData.showImage2 && moduleData.content2 !== null && moduleData.contentWidth === '50_width'
            "
            class="col-sm-12 col-md-12 col-xxl-6 p-32 padding-0"
        >
            <div [class.mx-auto]="moduleData.contentAlignment === 'center'">
                <div class="mb-4 content-list" [innerHTML]="moduleData.content2 | sanitizeHtml"></div>
            </div>
            <!-- second column-->
            <div *ngIf="moduleData.showButton2">
                <ua2-aff-button
                    class="display-content"
                    [url]="moduleData?.button2?.url"
                    [title]="moduleData?.button2?.title"
                    [target]="moduleData?.button?.target"
                    [buttonLabel]="moduleData?.button2?.title"
                    [id]="'button'"
                    [buttonCustomClass]="'p-xxl-4 px-3 py-25 mt-2 mb-3 mt-xxl-3 d-inline-block text-break'"
                ></ua2-aff-button>
            </div>
        </div>
    </div>
</div>
