<div class="d-flex align-items-start align-self-stretch flex-column aff-file-upload-container-wrapper">
    <div [ngClass]="[titleDefaultClass, titleCustomClass]">{{ titleText }}</div>
    <div class="aff-file-upload-container">
        <div class="d-flex justify-content-between align-items-center mt-2 aff-file-upload-control-wrapper">
            <ng-container>
                <input
                    [id]="fileUploadFieldId"
                    type="file"
                    [formControl]="control"
                    #uploadFileRef
                    hidden
                    (change)="onFileChange($event)"
                />
                <label
                    [for]="fileUploadFieldId"
                    [ngClass]="[buttonDefaultClass, buttonCustomClass, buttonHostCustomClass]"
                    >{{ buttonText }}</label
                >
            </ng-container>
            <span [ngClass]="[fileSelectionLabelWrapperDefaultClass, fileSelectionLabelWrapperCustomClass]">
                <span
                    [ngClass]="[
                        fileSelectionLabelDefaultClass,
                        fileSelectionLabelCustomClass,
                        isFileSelected ? '' : 'aff-selected-file-label-margin'
                    ]"
                    #selectedFileRef
                    >{{ selectedFileLabel }}</span
                >
            </span>

            <span
                [ngClass]="[deleteFileDefaultClass, deleteFileCustomClass]"
                *ngIf="isFileSelected"
                (click)="deleteFile($event)"
            >
                <i class="fa fa-trash" aria-hidden="true"></i>
            </span>
        </div>

        <div class="d-flex justify-content-between mt-1">
            <div [ngClass]="[fileTypesDefaultClass, fileTypesCustomClass]">
                File type allowed: {{ allowedFileTypes }}
            </div>
            <div [ngClass]="[fileSizeDefaultClass, fileSizeCustomClass]">Max Size: {{ maxFileSize }}MB</div>
        </div>
    </div>
</div>
