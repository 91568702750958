import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormFieldMethods } from './corporate-form-field';
import { provideNgxMask } from 'ngx-mask';

@Component({
    selector: 'ua2-corporate-form-field, aff-corporate-form-field',
    templateUrl: './corporate-form-field.component.html',
    providers: [provideNgxMask()]
})
export class CorporateFormFieldComponent implements OnInit, AfterViewInit {
    @Input() formFieldType?: string = '';
    @Input() formFieldLabel?: string = '';
    @Input() formFieldPlaceholder?: string = '';
    @Input() formFieldId?: string = '';
    @Input() formFieldContainerDefaultClass?: string = '';
    @Input() formFieldContainerCustomClass?: string = '';
    @Input() formFieldDefaultClass?: string = 'shadow-none';
    @Input() formFieldCustomClass?: string = '';
    @Input() formFieldLabelDefaultClass?: string = '';
    @Input() formFieldLabelCustomClass?: string = '';
    @Input() control = new FormControl();
    @Input() options?: FormFieldOptions[] = [{ label: '', value: '' }];
    @Input() singleSelectoptions?: FormFieldOptions[] = [{ label: '', value: '' }];
    @Input() errorMessages: FormFieldErrorMessages = {};
    @Input() errorMessageClass?: string = 'text-start text-red m-0';
    @Input() searchDropdownClass?: string =
        'autocomplete list-unstyled m-0 p-0 border-top-0 position-absolute w-100 text-start';
    @Input() searchSingleSelectDropdownClass?: string =
        'autocomplete list-unstyled m-0 p-0 border-top-0 position-absolute w-100 text-start';
    @Input() multiSelectOverlayClass?: string =
        'position-absolute top-0 end-0 bottom-0 start-0 multi-select-overlay py-3 px-25';
    @Input() maxMultiSelectRange = 3;
    @Input() inputMode?: string;
    @Input() floatingLabelText = '';
    @Input() floatingLabelTextClass = '';
    @Input() hideInputText = false;
    @Output() formFieldEvent: EventEmitter<{ event: string; data: any }> = new EventEmitter();
    @Output() multiSelectedOptionData: EventEmitter<any> = new EventEmitter();
    @Output() enterKeyPress: EventEmitter<any> = new EventEmitter();
    @Output() inputChange: EventEmitter<any> = new EventEmitter();
    @Output() passwordTypeChange: EventEmitter<any> = new EventEmitter();
    @ViewChild('coporateInputContainer') coporateInputContainer: any;
    @ViewChild('coporateInputField') coporateInputField: any;
    multiSelectDropdownClass =
        'multi-select-dropdown m-0 px-3 pb-3 border-top-0 position-absolute w-100 text-start';
    fielIconClasses = 'fa fa-chevron-down position-absolute float-arrow';
    inputTypes: string[] = ['text', 'number', 'select', 'singleselect', 'multiselect', 'tel', 'email', 'date', 'url'];
    showSearchDropdown = false;
    showSingleSelectDropdown = false;
    filteredOptions: any = [];
    isReadonly = false;
    isDisabled = false;
    showMultiSelectDropDown = false;
    checkedList: any[] = [];
    checkedListLabel: any[] = [];
    shouldRotateArrow = false;
    _showErrorMessage = true;
    _autoFocus = false;
    selectedOptionLabel = '';
    multiSelectedOptionLabel = '';
    _multiSelectOptions?: any[] = [];
    _loaderDisplay = false;
    _checkBoxContainerCustomClass = 'form-check text-start d-flex align-items-center check-box-container mt-3';
    _checkBoxLabelCustomClass = 'form-check-label form-check-box-label check-box-label-vr-align';
    _mask = null;
    _isAutoClickSingleSelection = false;
    _passwordType = false;

    constructor(public _cdr: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.setSpecialAttributeValues();
        if (this.control) {
            this.control?.valueChanges?.subscribe((newValue) => {
                this.formFieldValueChanges(newValue);
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.inputMode && this.coporateInputField.nativeElement) {
            this.coporateInputField.nativeElement.setAttribute('inputmode', this.inputMode);
        }

        if (this._isAutoClickSingleSelection && this.singleSelectoptions) {
            this.singleSelectOption(this.singleSelectoptions[0]);
        }
    }

    @Input()
    set showErrorMessage(value: any) {
        this._showErrorMessage = value;
    }

    @Input()
    set multiSelectOptions(data: any) {
        const miscData = data.filter((item: any) => item.value.toLowerCase() === 'misc');
        let sortedData = data
            ?.filter((item: any) => item.value.toLowerCase() !== 'misc')
            .sort((a: any, b: any) => a.value - b.value);
        if (miscData && miscData.length > 0) {
            sortedData = [...sortedData, ...miscData];
        }
        this._multiSelectOptions = sortedData;

        this.onSelectedOptionsChange();
    }

    @Input()
    set loaderDisplay(data: boolean) {
        this._loaderDisplay = data;
        this.checkBoxContainerCustomClass = data;
    }

    set checkBoxContainerCustomClass(isDisplay: boolean) {
        const defaultClass = 'form-check text-start d-flex align-items-center check-box-container mt-3';
        if (isDisplay) {
            this._checkBoxContainerCustomClass = `${defaultClass} pointer-none form-check-field-disabled`;
        } else {
            this._checkBoxContainerCustomClass = `${defaultClass}`;
        }
        this._cdr.detectChanges();
    }

    get checkBoxContainerCustomClass(): any {
        return this._checkBoxContainerCustomClass;
    }

    onFormFieldEvent(event: string, data: any) {
        this.formFieldEvent.emit({ event, data });
    }

    @HostListener('document:click', ['$event.target'])
    onDocumentClick(target: HTMLElement): void {
        const coporateInputContainer = this.coporateInputContainer?.nativeElement?.contains(target);
        if (!coporateInputContainer) {
            if (!this._loaderDisplay) {
                if (this.formFieldType?.toLowerCase() === 'multiselect' && this.showMultiSelectDropDown) {
                    this.markAsTouched();
                }
                this.showMultiSelectDropDown = false;
                this.shouldRotateArrow = false;

            }
            if (this.formFieldType?.toLowerCase() === 'singleselect' && this.showSingleSelectDropdown) {
                this.markAsTouched();
            }
            this.showSingleSelectDropdown = false;
            this.showSearchDropdown = false;
        }
    }

    @HostListener('input', ['$event'])
    @HostListener('keyup', ['$event'])
    @HostListener('keydown', ['$event'])
    @HostListener('mouseup', ['$event'])
    @HostListener('mousedown', ['$event'])
    @HostListener('mouseleave', ['$event'])
    @HostListener('focusin', ['$event'])
    @HostListener('focusout', ['$event'])
    @HostListener('click', ['$event'])
    onInputClick(event: Event): void {
        if (event?.target === this.coporateInputField?.nativeElement) {
            this.onFormFieldEvent(event?.type, event);
            if (event?.type === 'input' && this.formFieldType?.toLowerCase() === 'select' && this.options) {
                [this.filteredOptions, this.showSearchDropdown] = FormFieldMethods.showOptions(
                    this.options,
                    this.control?.value
                );
            }

            if (this.formFieldType?.toLowerCase() === 'singleselect') {
                this.selectedOptionLabel = this.inputValue;
            }

            if (this.formFieldType?.toLowerCase() === 'multiselect') {
                this.multiSelectedOptionLabel = this.inputValue;
            }
        }
    }

    onEnterKeyPress(event: any) {
        if (this.coporateInputField.nativeElement) {
            this.coporateInputField.nativeElement.blur();
        }
    }

    selectOption(option: any): void {
        this.control?.setValue(option.value);
        this.showSearchDropdown = false;
    }

    singleSelectOption(option: any): void {
        this.control?.setValue(option.value);
        this.selectedOptionLabel = option.label;
        this.showSingleSelectDropdown = false;
        this.shouldRotateArrow = false;
    }

    highlightMatching(option: FormFieldOptions): string {
        return FormFieldMethods.highlightMatching(option, this.control?.value);
    }

    setSpecialAttributeValues(): void {
        const { formFieldType } = this;
        this.isReadonly = this.isDisabled = formFieldType === 'multiselect' || formFieldType === 'singleselect';
    }

    getFormFieldLabel(formFieldLabel: any): string {
        return FormFieldMethods.getFormFieldLabel(this.formFieldType, formFieldLabel, this._multiSelectOptions);
    }

    toggleMultiSelect(type: string): void {
        if (type === 'multiselect') {
            this.showMultiSelectDropDown = !this.showMultiSelectDropDown;
            if (!this.showMultiSelectDropDown) {
                this.markAsTouched();
            }
        }

        if (type === 'singleselect') {
            this.showSingleSelectDropdown = !this.showSingleSelectDropdown;
            if (!this.showSingleSelectDropdown) {
                this.markAsTouched();
            }
        }
        this.toggleMultiSelectHelper();
    }

    toggleMultiSelectHelper() {
        this.shouldRotateArrow = !this.shouldRotateArrow;
    }

    formFieldValueChanges(value?: any): void {
        if (
            this.formFieldType?.toLowerCase() === 'multiselect' ||
            this.formFieldType?.toLowerCase() === 'singleselect'
        ) {
            this.markAsDirty();
        }
    }

    markAsTouched() {
        this.control?.markAsTouched();
    }

    markAsDirty() {
        this.control?.markAsDirty();
    }

    get formFieldClasses() {
        return FormFieldMethods.formFieldClasses(
            this.formFieldDefaultClass,
            this.formFieldCustomClass,
            this.formFieldType,
            this.control?.value,
            this._showErrorMessage,
            this.showSingleSelectDropdown,
            this.showMultiSelectDropDown
        );
    }

    get multiSelectOverlayClasses() {
        return FormFieldMethods.formFieldLayoutClasses(this.multiSelectOverlayClass);
    }

    onSelectedOptionsChange(selectedOptions?: any) {
        const [, checkedListLabel] = FormFieldMethods.getCheckedListItems(
            this._multiSelectOptions,
            this.checkedList,
            this.checkedListLabel
        );
        this.control?.setValue(checkedListLabel);
        this.multiSelectedOptionLabel = checkedListLabel;
        this.multiSelectedOptionData.emit(this._multiSelectOptions);
    }

    @Input()
    set autoFocus(value: boolean) {
        this._autoFocus = value;
    }

    @Input()
    set mask(value: any) {
        this._mask = value;
    }

    @Input()
    set isAutoClickSingleSelection(value: boolean) {
        this._isAutoClickSingleSelection = value;
    }

    get inputValue(): any {
        if (this.formFieldType === 'singleselect') {
            return this.selectedOptionLabel.toString();
        } else if (this.formFieldType === 'multiselect') {
            return this.multiSelectedOptionLabel.toString();
        } else {
            return null;
        }
    }

    onInputChange(event: any) {
        this.inputChange.emit();
    }

    @Input()
    set passwordType(value: boolean) {
        this._passwordType = value;
    }

    toggleInputType() {
        this._passwordType = !this._passwordType;
        this.passwordTypeChange.emit(this._passwordType);
    }

    get passwordInputIconClasses() {
        let classes = 'position-absolute eye-icon fa-regular';
        if (this._passwordType) {
            classes += ' fa-eye-slash';
            return classes;
        }
        classes += ' fa-eye';
        return classes;
    }

    get getFieldType() {
        if (this.hideInputText) {
            if (this._passwordType) {
                return 'password';
            }
            return this.formFieldType;
        }
        return this.formFieldType;
    }
}

export interface FormFieldOptions {
    label?: string;
    value?: any;
}

export interface FormFieldErrorMessages {
    [key: string]: string;
}
