import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
@Injectable({
    providedIn: 'root',
})
export class MerchantSearchResultsService {
    constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document) {}

    getSearchResults(
        zipCode: number | string | null,
        page: number,
        limit: number,
        radius: number,
        filter: string
    ): Observable<any> {
        let url = '';
        if (zipCode) {
            url = `${environment.merchantSearchURL}${zipCode}/50/d/0/UA/0/`;
        } else {
            const searchKey = this.document.defaultView?.sessionStorage?.getItem('stateName');
            url = `${environment.merchantSearchURL}${searchKey}/50/d/0/UA/0/`;
        }

        const filterResult = filter;

        if (page) {
            url = `${url}?page=${page}&limit=${limit}&filter=${filterResult}&radius=${radius}&version=2`;
        }

        return this.http.get(url);
    }
}
