import { Renderer2, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AttributesTypes } from '../models/attribute-types.model';

@Injectable({ providedIn: 'root' })
export class ScriptService {
    constructor(@Inject(DOCUMENT) private document: Document) {}
    /**
     * Append the JS tag to the Document head.
     * @param renderer The Angular Renderer
     * @param src The path to the script
     * @param type  To specify script type
     * @param body Add text inside tag
     * @param attributes Add list of attributes for the tag
     */
    public loadJsScript(
        renderer: Renderer2,
        src?: string,
        type?: string,
        body?: string,
        attributes?: AttributesTypes[]
    ): void {
        const script = renderer.createElement('script');
        script.type = type ? type : '';
        if (src !== '') script.src = src;
        script.text = body ? body : '';
        attributes?.forEach((obj) => {
            script.setAttribute(obj.name, obj.value);
        });
        renderer.appendChild(this.document.head, script);
    }
}
