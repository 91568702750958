import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffCarouselComponent, AffCarouselSlide } from './aff-carousel.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [AffCarouselComponent, AffCarouselSlide],
    imports: [CommonModule, FontAwesomeModule],
    exports: [AffCarouselComponent, AffCarouselSlide],
})
export class AffCarouselModule {}
