<ng-container *ngIf="floatingLabelText && floatingLabelText.toString().toLowerCase().trim().length > 0">
    <ng-container *ngTemplateOutlet="floatingLblText"></ng-container>
</ng-container>

<div [ngClass]="[formFieldContainerDefaultClass, formFieldContainerCustomClass]" #coporateInputContainer>
    <ng-template #floatingLblText>
        <p [ngClass]="floatingLabelTextClass">{{floatingLabelText}}</p>
    </ng-template>
    <ng-container *ngIf="formFieldType && inputTypes.includes(formFieldType)">
        <input [type]="getFieldType" [value]="inputValue" [ngClass]="formFieldClasses" [id]="formFieldId"
            [placeholder]="formFieldPlaceholder" [formControl]="control" [readonly]="isReadonly" [disabled]="isDisabled"
            autocomplete="off" [mask]="_mask" (keyup.enter)="onEnterKeyPress($event)" (change)="onInputChange($event)"
            #coporateInputField />
        <ng-container *ngIf="_autoFocus">
            {{ coporateInputField?.focus() }}
        </ng-container>
        <div *ngIf="formFieldType === 'multiselect' || formFieldType === 'singleselect'"
            [ngClass]="multiSelectOverlayClasses" [id]="formFieldId" (click)="toggleMultiSelect(formFieldType)">
            <i [class]="fielIconClasses" [ngClass]="{ 'rotate-up': shouldRotateArrow }"></i>
        </div>
        <span *ngIf="hideInputText" [id]="formFieldId" [ngClass]="passwordInputIconClasses"
            (click)="toggleInputType()"></span>
        <label [for]="formFieldId" [ngClass]="[formFieldLabelDefaultClass, formFieldLabelCustomClass]"
            [innerHTML]="getFormFieldLabel(formFieldLabel)"></label>
        <ng-container *ngIf="formFieldType === 'select'">
            <ul [ngClass]="[searchDropdownClass]" *ngIf="showSearchDropdown">
                <li *ngFor="let option of filteredOptions" (click)="selectOption(option)"
                    [innerHTML]="highlightMatching(option)">
                    {{ option.label }}
                </li>
            </ul>
        </ng-container>
        <ng-container *ngIf="formFieldType === 'singleselect'">
            <ul [ngClass]="[searchSingleSelectDropdownClass]" *ngIf="showSingleSelectDropdown">
                <li *ngFor="let option of singleSelectoptions" (click)="singleSelectOption(option)">
                    {{ option.label }}
                </li>
            </ul>
        </ng-container>
        <ng-container *ngIf="formFieldType === 'multiselect'">
            <div [ngClass]="[multiSelectDropdownClass]" *ngIf="showMultiSelectDropDown">
                <div *ngFor="let option of _multiSelectOptions; let i = index">
                    <aff-corporate-check-box [checkBoxLabel]="option.label" [option]="option"
                        [isChecked]="option.checked" [checkBoxId]="'formCheckBox_' + (i + 1)"
                        [isDisabled]="option.disabled" [checkBoxContainerCustomClass]="checkBoxContainerCustomClass"
                        [checkBoxLabelCustomClass]="_checkBoxLabelCustomClass"
                        (selectedOptionsChange)="onSelectedOptionsChange($event)"></aff-corporate-check-box>
                    <p *ngIf="option?.description" [innerHTML]="option?.description | sanitizeHtml"
                        class="m-0 p-0 ms-8 mt-1 category-filter-misc"></p>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<ng-container *ngIf="control?.invalid && _showErrorMessage">
    <p class="error-message-spacing" *ngFor="let err of control?.errors | keyvalue" [ngClass]="[errorMessageClass]">
        {{ errorMessages[err.key] }}
    </p>
</ng-container>