import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import { ReplaySubject } from 'rxjs';

export const GOOGLE_MAPS_LOADER_OPTIONS: InjectionToken<LoaderOptions> = new InjectionToken<LoaderOptions>(
    'GOOGLE_MAPS_LOADER_OPTIONS'
);

/**
 * DO NOT LOAD THIS SERVICE DIRECTLY!
 * Use the `provideAffGoogleMapsOptions` function
 * in the `google-maps-loader.provider.ts`
 * to load in the root of the application.
 */
@Injectable({
    providedIn: 'root',
})
export class AffGoogleMapsLoaderService {
    public loader: Loader;

    public loaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    constructor(@Inject(GOOGLE_MAPS_LOADER_OPTIONS) public options: LoaderOptions) {
        this.loader = new Loader(options);

        /**
         * Automi
         */
        this.loader.importLibrary('maps').then(() => {
            setTimeout(() => {
                this.loaded$.next(true);
            }, 500);
        });
    }
}
