<section id="merchantStore" [ngClass]="merchantLocationContainer">
    <div class="col-12 col-md-6 pe-md-3 pe-0 mobile-padding" [ngClass]="{ 'pointer-none': isLoaderDisplay }">
        <janet-merchant-stores
            class="px-0 col-12 col-md-12 col-xxl-6 d-none d-sm-none d-md-block d-lg-block"
            [displayListSearchResults]="displayData"
            [storeListSearchResults]="previousStoreData"
            [totalRecords]="totalRecords?.record"
            [zipcode]="zipcode"
            [selectedMarkerIndex]="selectedMarkerIndex"
            [paginationClicked]="paginationClicked"
            (displayListSearchResultsChange)="handleDisplayListSearchResultsChange($event)"
            (selectedMarkerIndexValEmitter)="onSelectedMarkerIndexValEmitted($event)"
            [class.invisible]="isLoaderDisplay"
            [storeListPaginationClicked]="storeListPaginationClicked"
        ></janet-merchant-stores>
        <janet-merchant-stores-mobile
            [displayListSearchResults]="displayData"
            [totalRecords]="totalRecords?.record"
            [paginationClicked]="paginationClicked"
            [showAccordion]="showAccordion"
            [googleMapUrl]="mapUrl"
            [ngClass]="storeListMobileDisplayClass"
            [storeListPaginationClicked]="storeListPaginationClicked"
        ></janet-merchant-stores-mobile>
        <ua2-stores-pagination
            *ngIf="displayData"
            class="ps-0"
            [startIndex]="startIndex"
            [endIndex]="endIndex"
            [totalRecords]="totalRecords"
            (paginationUpdated)="handlePaginationUpdate($event)"
            [ngClass]="paginationDisplayClass"
        ></ua2-stores-pagination>
    </div>

    <div
        class="px-0 col-12 col-md-6 col-xxl-6 d-none d-sm-none d-md-block d-lg-block"
        [ngClass]="{ 'pointer-none': isLoaderDisplay }"
    >
        <ng-container *ngIf="displayData">
            <ua2-map
                [mapApiKey]="mapApiKey"
                [googleMapUrl]="mapUrl"
                [locationsData]="displayData"
                [storeIndex]="storeIndex"
                (selectedMarkerIndex)="onMarkerSelected($event)"
                [clearMarker]="_clearMarker"
            ></ua2-map>
        </ng-container>
    </div>
    <ng-container *ngIf="getScreenWidth >= 768">
        <div
            class="merchant-storelist-overlay"
            [class.visible-layout]="isLoaderDisplay"
            [class.in-visible-layout]="!isLoaderDisplay"
        ></div>
        <div
            class="merchant-storelist-loader-gif d-flex justify-content-center align-items-center"
            [class.gif-visible]="isLoaderDisplay"
            [class.gif-in-visible]="!isLoaderDisplay"
        >
            <img src="assets/gif/loader.gif" alt="Loader GIF" />
        </div>
    </ng-container>
    <div
        class="merchant-storelist-loader-gif-mobile d-flex justify-content-center align-items-center"
        *ngIf="isLoaderDisplay && getScreenWidth < 768"
    >
        <img src="assets/gif/loader.gif" alt="Loader GIF" />
    </div>
</section>
