import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GOOGLE_API_URL, GOOGLE_MAP_API_KEY } from '@janet/shared/domain';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class GeoLocationService {

    constructor(private http: HttpClient) { }

    getGeoLocation(): Promise<{ latitude: number | null, longitude: number | null }> {
        return new Promise((resolve) => {
            if (window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        });
                    },
                    () => {
                        resolve({ latitude: null, longitude: null });
                    }
                );
            } else {
                resolve({ latitude: null, longitude: null });
            }
        });
    }

    getPostalCode(lat: number, lng: number): Promise<string> {
        return new Promise((resolve, reject) => {
            const url = `${GOOGLE_API_URL}?latlng=${lat},${lng}&key=${GOOGLE_MAP_API_KEY}`;
            this.http.get<any>(url).pipe(
                map((data: any) => {
                    const addressComponents = data.results[0]?.address_components || [];
                    for (let i = 0; i < addressComponents.length; i++) {//NOSONAR
                        if (addressComponents[i].types.includes('postal_code')) {
                            return addressComponents[i].long_name;
                        }
                    }
                    return '';
                })
            ).subscribe({
                next: postalCode => resolve(postalCode),
                error: error => reject(error)//NOSONAR
            });
        });
    }

}