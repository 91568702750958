import { gql } from 'apollo-angular';

export const PREVIEW_QUERY = gql`
    query GetPageDataByIDAndPreview($id: ID!) {
        page(id: $id, idType: DATABASE_ID) {
            content
            contentTypeName
            id
            title
            status
            slug
            parentId
            pageId
            seo {
                title
            }
            contentModules {
                contentModules {
                    ... on Page_Contentmodules_ContentModules_ContentArea {
                        fieldGroupName
                        content
                        content2
                        contentWidth
                        image {
                            sourceUrl
                            srcSet
                            altText
                        }
                        image2 {
                            sourceUrl
                            srcSet
                            altText
                        }
                        imagePosition
                        imagePosition2
                        showButton
                        showButton2
                        showImage1
                        showImage2
                        button {
                            target
                            title
                            url
                        }
                        button2 {
                            target
                            title
                            url
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_Hero {
                        fieldGroupName
                        backgroundImage {
                            sourceUrl
                            srcSet
                            altText
                        }
                        title
                        contentArea
                        colorScheme
                        contentAlignment
                        showButton
                        heroLayoutMobile
                        button {
                            target
                            title
                            url
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_Testimonials {
                        fieldGroupName
                        testimonials {
                            fieldGroupName
                            rating
                            testimonialCustomerName
                            testimonialText
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_Icons {
                        fieldGroupName
                        showButton
                        button {
                            target
                            title
                            url
                        }
                        icons {
                            fieldGroupName
                            iconFa
                            useFaIcon
                            iconImage {
                                altText
                                mediaItemUrl
                            }
                            iconTitle
                            iconSubText
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_FaqSchema {
                        fieldGroupName
                        qaItems {
                            question
                            answer
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_CcpaDsarForm {
                        fieldGroupName
                        otDsarWebForm
                    }
                    ... on Page_Contentmodules_ContentModules_MerchantSignupForm {
                        fieldGroupName
                        merchantSignupForm
                    }
                    ... on Page_Contentmodules_ContentModules_WebsiteForms {
                        fieldGroupName
                        websiteForm
                    }
                    ... on Page_Contentmodules_ContentModules_BannerModule {
                        fieldGroupName
                        bannerType
                        backgroundType
                        backgroundColor
                        backgroundImage {
                            sourceUrl
                            srcSet
                            altText
                        }
                        headingText
                        useAsHero
                        buttonCta {
                            target
                            title
                            url
                        }
                        subheadingText
                        bannerTextAlignment
                        fontAwesomeIcon
                        bannerHeight
                        bannerTextAlignment
                        searchType
                    }
                    ... on Page_Contentmodules_ContentModules_ShopCategoryModule {
                        fieldGroupName
                        shopCategoryHeading
                        shopCategorySubheading
                        shopCategoryList {
                            fieldGroupName
                            shopCategory {
                                slug
                                name
                                taxonomyName
                                shopCategoryId
                                termTaxonomyId
                                ShopCategoryIcon {
                                    fieldGroupName
                                    icon
                                }
                                ShopCategoryAPIKey {
                                    fieldGroupName
                                    apiKey
                                }
                            }
                        }
                    }
                }
            }
        }
        shopCategories(first: 100) {
            nodes {
                ShopCategoryIcon {
                    fieldGroupName
                    icon
                }
                ShopCategoryAPIKey {
                    fieldGroupName
                    apiKey
                }
                name
                id
                slug
                taxonomyName
                shopCategoryId
                termTaxonomyId
                description
            }
        }
    }
`;
