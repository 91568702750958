import { FormControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GOOGLE_MAP_API_KEY, GOOGLE_API_URL } from '@janet/shared/domain';

const googleApiUrl = GOOGLE_API_URL;
const googleApiKey = GOOGLE_MAP_API_KEY;

export function zsr(http: HttpClient): AsyncValidatorFn | any {
    return (control: FormControl): Observable<ValidationErrors | null> => {
        if (control.value.toString().length < 5) {
            return of(null);
        }
        const url = `${googleApiUrl}?components=postal_code:${control.value
            .toString()
            .slice(0, 5)}|country:${getCountryCode(control?.value)}&key=${googleApiKey}`;
        return http.get<any>(url).pipe(
            map((response) => {
                if (response?.results && response?.results.length <= 0) {
                    return { zsr: true };
                } else {
                    return null;
                }
            }),
            catchError(() => of({ zsr: true }))
        );
    };
}

export function getCountryCode(value: any) {
    if (
        value &&
        value?.toString()?.length === 5 &&
        !isNaN(+value) &&
        ((+value >= +'00600' && +value <= +'00799') || (+value >= +'00900' && +value <= +'00999'))
    ) {
        return 'PR';
    }
    return 'US';
}
