<form
    [formGroup]="merchantSearchForm"
    class="d-flex flex-row align-items-center merchant-search-form justify-content-between"
    (ngSubmit)="onZipSearchBtnClick('submit')"
    novalidate
>
    <div class="d-flex flex-row-reverse align-items-center search-input-wrapper" #searchInputWrapper>
        <aff-corporate-form-field
            [formFieldContainerCustomClass]="formFieldContainerCustomClass"
            formFieldType="text"
            formFieldLabel="Search by zip or merchant name"
            formFieldPlaceholder="Search by zip or merchant name"
            formFieldId="merchantSearchText"
            formFieldCustomClass="form-control"
            [formFieldLabelCustomClass]="formFieldLabelCustomClass"
            [control]="$any(merchantSearchForm.controls.merchantSearchText)"
            [errorMessages]="selectErrorMessages"
            [showErrorMessage]="showErrorMessage"
            class="w-100"
            (formFieldEvent)="handleEvent($event)"
            (inputChange)="inputChange()"
        >
        </aff-corporate-form-field>
        <button
            class="border-0 position-absolute d-flex justify-content-center align-items-center"
            [ngClass]="searchButtonClass"
            type="submit"
            #zipSearchButton
        >
            <em class="fa-light fa-magnifying-glass"></em>
        </button>
    </div>

    <aff-corporate-form-field
        [formFieldContainerCustomClass]="formFieldCategoryContainerCustomClass"
        formFieldType="multiselect"
        formFieldLabel="Category"
        formFieldPlaceholder="Category"
        formFieldId="merchantSearchCategory"
        formFieldCustomClass="form-control"
        [formFieldLabelCustomClass]="formFieldCategoryLabelCustomClass"
        [multiSelectOptions]="_multiSelectOptions"
        [control]="$any(merchantSearchForm.controls.merchantSearchCategory)"
        [errorMessages]="categoryErrorMessages"
        [showErrorMessage]="showErrorMessage"
        [loaderDisplay]="isLoaderDisplay"
        (multiSelectedOptionData)="toggleMultiSelectOption($event)"
    >
    </aff-corporate-form-field>

    <ng-container *ngIf="getScreenWidth <= '1439'">
        <slider-button
            (sliderButtonClicked)="togglePanel()"
            [count]="getCategoriesCount()"
            [sliderButtonContainerClass]="sliderButtonContainerClass"
            [sliderButtonIconClass]="sliderButtonIconClass"
            [sliderButtonBadgeClass]="sliderButtonBadgeClass"
        ></slider-button>

        <slide-panel [showPanel]="_showPanel">
            <janet-category-filter
                (togglePanel)="togglePanel()"
                (toggleCheckBox)="toggleCheckBox($event)"
                [multiSelectOptions]="_multiSelectOptions"
                [showPanel]="_showPanel"
            ></janet-category-filter>
        </slide-panel>
    </ng-container>
</form>
