import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FeatureFlagListItem, LaunchDarklyStoreService } from '@janet/shared/domain';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'aff-feature-flag-boolean',
    templateUrl: './feature-flag-boolean.component.html',
    styleUrls: ['./feature-flag-boolean.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureFlagBooleanComponent implements OnInit {
    @Input() flagListItem = {} as FeatureFlagListItem;

    isChecked$: Observable<boolean> = of(false);

    constructor(public featureFlagStore: LaunchDarklyStoreService) {}

    ngOnInit(): void {
        this.isChecked$ = this.featureFlagStore.selectFeature$(this.flagListItem.key);
    }

    onToggleChange(e: MatSlideToggleChange) {
        this.featureFlagStore.toggleFeature(this.flagListItem.key, e.checked);
    }
}
