<div [ngClass]="getShopByCategoryContainerClass">
    <div [ngClass]="getShopByCategoryTitleContainerClass">
        <h2 [class]="shopByCategoryTitle">{{ shopCategoryHeading }}</h2>
        <div
            *ngIf="shopCategorySubheading"
            [class]="shopByCategorySubTitle"
            [innerHTML]="shopCategorySubheading | sanitizeHtml"
        ></div>
    </div>

    <div [ngClass]="getShopByCategoryIconsWrapperClass">
        <div [ngClass]="getShopByCategoryIconContainerClass">
            <ng-container *ngFor="let list of shopByCategoryLists; let i = index">
                <coporate-category-icon
                    [categoryLabel]="list?.shopCategory?.name"
                    [categoryIcon]="getFaIcon(list?.shopCategory?.ShopCategoryIcon?.icon)"
                    [ngClass]="getCategoryIconSelectorClass"
                    (selectedCategory)="onCategorySelect(list)"
                ></coporate-category-icon>
            </ng-container>
        </div>
    </div>
</div>
