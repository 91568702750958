import { Component, Input } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular, faStarHalfStroke } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'ua2-star-rating, star-rating',
  templateUrl: './star-rating.component.html',
})
export class StarRatingComponent {

  emptyStar = faStarRegular;
  halfStar = faStarHalfStroke;
  fullStar = faStar;
  color = 'text-secondary';

  @Input() iconSize?: string = 'fa-rating-icon-size';
  @Input() contextFill?: number = 0;
}
