<section
    class="social-media-wrapper w-100 bg-black-alpha-50 px-xl-10"
    *ngIf="socialIconsMenu$ | async as socialIconsMenu"
>
    <!-- Social Media -->

    <div
        class="social-media px-4 pt-4 pt-md-2 px-md-5 px-xl-11 d-flex justify-content-center flex-column flex-md-row justify-content-md-between align-items-center"
    >
        <div class="px-3 ps-md-0 mx-auto mx-md-0 d-flex flex-column flex-md-row">
            <a href="/" class="logo-wrapper-footer text-reset col-md-4">
                <img
                    src="../../../assets/AFFHorizontalLogo.svg"
                    alt="American First Finance"
                    role="button"
                    class="w-100 logo-aff"
                    height="28"
                    loading="lazy"
                />
            </a>
            <ul
                class="social-icons d-flex list-unstyled flex-wrap flex-md-nowrap m-0 pr-md-2 px-md-3 mt-4 mt-md-0 align-items-center col-md-4"
            >
                <li
                    *ngFor="let icon of socialIconsMenu.menuItems.edges; trackBy: trackByFn"
                    class="mx-2 mx-sm-3 mx-md-2"
                >
                    <a
                        class="text-reset social-icons"
                        [href]="sanatizeURL(icon.node.url)"
                        target="{{ icon?.node?.target ? icon.node.target : '' }}"
                    >
                        <em
                            [ngClass]="getIconClass(icon.node)"
                            [ngStyle]="{ 'background-color': icon.node.socialColor.iconColor }"
                        ></em>
                    </a>
                </li>
            </ul>
        </div>

        <ng-container>
            <ua2-aff-button
                [target]="footerMenu?.footerFindAStore?.target"
                [url]="footerMenu?.footerFindAStore?.url"
                [title]="footerMenu?.footerFindAStore?.title"
                [buttonLabel]="footerMenu?.footerFindAStore?.title"
                [id]="'button'"
                class="display-content"
                [buttonCustomClass]="
                    btnVariantClass +
                    'px-3 py-25 my-4 my-md-3 mw-100 text-nowrap d-flex justify-content-center align-items-center d-inline-block'
                "
                [affButtonIcon]="getFaIcon(footerMenu?.footerFindAStoreStyle?.ctaIcon)"
                [showAffButtonIcon]="showIcon"
            >
            </ua2-aff-button>
        </ng-container>
    </div>
    <!-- Social Media -->
</section>
