export const IconsMock = {
    __typename: 'Page_Contentmodules_ContentModules_Icons',
    fieldGroupName: 'Page_Contentmodules_ContentModules_Icons',
    showButton: true,
    button: {
        __typename: 'AcfLink',
        target: '_blank',
        title: 'Test',
        url: 'https://www.google.com',
    },
    icons: [
        {
            __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
            fieldGroupName: 'icons',
            iconFa: '{ "style" : "solid", "id" : "light-ceiling", "label" : "Light Ceiling", "unicode" : "e016" }',
            useFaIcon: true,
            iconImage: null,
            iconTitle: 'Light Ceiling',
            iconSubText: null,
        },
        {
            __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
            fieldGroupName: 'icons',
            iconFa: '{ "style" : "solid", "id" : "chair-office", "label" : "Office Chair", "unicode" : "f6c1" }',
            useFaIcon: true,
            iconImage: null,
            iconTitle: 'Office Chair',
            iconSubText: null,
        },
        {
            __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
            fieldGroupName: 'icons',
            iconFa: '{ "style" : "solid", "id" : "bed", "label" : "Bed", "unicode" : "f236" }',
            useFaIcon: true,
            iconImage: null,
            iconTitle: 'Bed',
            iconSubText: null,
        },
    ],
};

export const IconsMock2 = {
    __typename: 'Page_Contentmodules_ContentModules_Icons',
    fieldGroupName: 'Page_Contentmodules_ContentModules_Icons',
    showButton: true,
    button: {
        __typename: 'AcfLink',
        target: '_blank',
        title: 'Test',
        url: 'https://www.google.com',
    },
    icons: [
        {
            __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
            fieldGroupName: 'icons',
            iconFa: '{ "style" : "solid", "id" : "light-ceiling", "label" : "Light Ceiling", "unicode" : "e016" }',
            useFaIcon: true,
            iconImage: null,
            iconTitle: 'Light Ceiling',
            iconSubText: '<p>This is test Sub-Text</p>\n',
        },
        {
            __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
            fieldGroupName: 'icons',
            iconFa: '{ "style" : "solid", "id" : "chair-office", "label" : "Office Chair", "unicode" : "f6c1" }',
            useFaIcon: true,
            iconImage: null,
            iconTitle: 'Office Chair',
            iconSubText: '<p><strong>This is test Bold Sub-Text</strong></p>\n',
        },
    ],
};

export const IconsMock4 = {
    __typename: 'Page_Contentmodules_ContentModules_Icons',
    fieldGroupName: 'Page_Contentmodules_ContentModules_Icons',
    showButton: true,
    button: {
        __typename: 'AcfLink',
        target: '_blank',
        title: 'Test',
        url: 'https://www.google.com',
    },
    icons: [
        {
            __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
            fieldGroupName: 'icons',
            iconFa: '{ "style" : "solid", "id" : "light-ceiling", "label" : "Light Ceiling", "unicode" : "e016" }',
            useFaIcon: true,
            iconImage: null,
            iconTitle: 'Light Ceiling',
            iconSubText: '<p>This is test Sub-Text</p>\n',
        },
        {
            __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
            fieldGroupName: 'icons',
            iconFa: '{ "style" : "solid", "id" : "chair-office", "label" : "Office Chair", "unicode" : "f6c1" }',
            useFaIcon: true,
            iconImage: null,
            iconTitle: 'Office Chair',
            iconSubText: '<p><strong>This is test Bold Sub-Text</strong></p>\n',
        },
        {
            __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
            fieldGroupName: 'icons',
            iconFa: '{ "style" : "solid", "id" : "chair-office", "label" : "Office Chair", "unicode" : "f6c1" }',
            useFaIcon: true,
            iconImage: null,
            iconTitle: 'Office Chair',
            iconSubText: '<p><strong>This is test Bold Sub-Text</strong></p>\n',
        },
        {
            __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
            fieldGroupName: 'icons',
            iconFa: '{ "style" : "solid", "id" : "chair-office", "label" : "Office Chair", "unicode" : "f6c1" }',
            useFaIcon: true,
            iconImage: null,
            iconTitle: 'Office Chair',
            iconSubText: '<p><strong>This is test Bold Sub-Text</strong></p>\n',
        },
    ],
};

export const IconsMock1 = {
    __typename: 'Page_Contentmodules_ContentModules_Icons',
    fieldGroupName: 'Page_Contentmodules_ContentModules_Icons',
    showButton: true,
    button: {
        __typename: 'AcfLink',
        target: '_blank',
        title: 'Test',
        url: 'https://www.google.com',
    },
    icons: [
        {
            __typename: 'Page_Contentmodules_ContentModules_Icons_icons',
            fieldGroupName: 'icons',
            iconFa: '{ "style" : "solid", "id" : "light-ceiling", "label" : "Light Ceiling", "unicode" : "e016" }',
            useFaIcon: true,
            iconImage: null,
            iconTitle: 'Light Ceiling',
            iconSubText: '<p>This is test Sub-Text</p>\n',
        },
    ],
};
