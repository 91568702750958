import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Testimonial } from '../../types/kitchen-sink.types';

@Component({
    selector: 'janet-testimonial',
    templateUrl: './testimonial.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})
export class TestimonialComponent {
    @Input() testimonial: Testimonial;
    @Input() showRating = false;
    @Input() alignment: 'left' | 'center' | 'right' = 'center';

    ratingContainerClasses() {
        let classes = '';
        switch (this.alignment) {
            case 'center': {
                classes += 'justify-content-center';
                break;
            }
            case 'left': {
                classes += 'justify-content-start';
                break;
            }
            case 'right': {
                classes += 'justify-content-end';
                break;
            }
        }
        return classes;
    }

    textAlignmentClasses() {
        let classes = '';
        switch (this.alignment) {
            case 'center': {
                classes += 'text-center';
                break;
            }
            case 'left': {
                classes += 'text-start';
                break;
            }
            case 'right': {
                classes += 'text-end';
                break;
            }
        }
        return classes;
    }
}
