export const FooterMenuDataMock = [
    {
        __typename: 'MenuToMenuItemConnectionEdge',
        node: {
            __typename: 'MenuItem',
            label: 'Customers',
            order: 1,
            url: '#',
            target: null,
            menuIcons: {
                __typename: 'MenuItem_Menuicons',
                icon: null,
            },
            socialColor: {
                __typename: 'MenuItem_Socialcolor',
                iconColor: null,
            },
            parentId: null,
            id: 'cG9zdDoxMDYzNA==',
        },
        selected: false,
        children: [
            {
                __typename: 'MenuToMenuItemConnectionEdge',
                node: {
                    __typename: 'MenuItem',
                    label: 'Customer Login',
                    order: 2,
                    url: '#',
                    target: null,
                    menuIcons: {
                        __typename: 'MenuItem_Menuicons',
                        icon: null,
                    },
                    socialColor: {
                        __typename: 'MenuItem_Socialcolor',
                        iconColor: null,
                    },
                    parentId: 'cG9zdDoxMDYzNA==',
                    id: 'cG9zdDoxMDYzNQ==',
                },
                selected: false,
                _isSubMenu: false,
            },
        ],
    },
    {
        __typename: 'MenuToMenuItemConnectionEdge',
        node: {
            __typename: 'MenuItem',
            label: 'Help',
            order: 3,
            url: '#',
            target: null,
            menuIcons: {
                __typename: 'MenuItem_Menuicons',
                icon: null,
            },
            socialColor: {
                __typename: 'MenuItem_Socialcolor',
                iconColor: null,
            },
            parentId: null,
            id: 'cG9zdDoxMDYzOA==',
        },
        selected: false,
        children: [
            {
                __typename: 'MenuToMenuItemConnectionEdge',
                node: {
                    __typename: 'MenuItem',
                    label: 'Payment & Billing',
                    order: 4,
                    url: '#',
                    target: null,
                    menuIcons: {
                        __typename: 'MenuItem_Menuicons',
                        icon: null,
                    },
                    socialColor: {
                        __typename: 'MenuItem_Socialcolor',
                        iconColor: null,
                    },
                    parentId: 'cG9zdDoxMDYzOA==',
                    id: 'cG9zdDoxMDYzOQ==',
                },
                selected: false,
                _isSubMenu: false,
            },
        ],
    },
    {
        __typename: 'MenuToMenuItemConnectionEdge',
        node: {
            __typename: 'MenuItem',
            label: 'Partners',
            order: 5,
            url: '#',
            target: null,
            menuIcons: {
                __typename: 'MenuItem_Menuicons',
                icon: null,
            },
            socialColor: {
                __typename: 'MenuItem_Socialcolor',
                iconColor: null,
            },
            parentId: null,
            id: 'cG9zdDoxMDY0Mg==',
        },
        selected: false,
        children: [
            {
                __typename: 'MenuToMenuItemConnectionEdge',
                node: {
                    __typename: 'MenuItem',
                    label: 'Merchant Login',
                    order: 6,
                    url: '#',
                    target: null,
                    menuIcons: {
                        __typename: 'MenuItem_Menuicons',
                        icon: null,
                    },
                    socialColor: {
                        __typename: 'MenuItem_Socialcolor',
                        iconColor: null,
                    },
                    parentId: 'cG9zdDoxMDY0Mg==',
                    id: 'cG9zdDoxMDY0Mw==',
                },
                selected: false,
                _isSubMenu: false,
            },
        ],
    },
    {
        __typename: 'MenuToMenuItemConnectionEdge',
        node: {
            __typename: 'MenuItem',
            label: 'AFF',
            order: 7,
            url: '#',
            target: null,
            menuIcons: {
                __typename: 'MenuItem_Menuicons',
                icon: null,
            },
            socialColor: {
                __typename: 'MenuItem_Socialcolor',
                iconColor: null,
            },
            parentId: null,
            id: 'cG9zdDoxMDY0NA==',
        },
        selected: false,
        children: [
            {
                __typename: 'MenuToMenuItemConnectionEdge',
                node: {
                    __typename: 'MenuItem',
                    label: 'For Business',
                    order: 8,
                    url: '#',
                    target: null,
                    menuIcons: {
                        __typename: 'MenuItem_Menuicons',
                        icon: null,
                    },
                    socialColor: {
                        __typename: 'MenuItem_Socialcolor',
                        iconColor: null,
                    },
                    parentId: 'cG9zdDoxMDY0NA==',
                    id: 'cG9zdDozMDUwMg==',
                },
                selected: false,
                _isSubMenu: false,
                children: [
                    {
                        __typename: 'MenuToMenuItemConnectionEdge',
                        node: {
                            __typename: 'MenuItem',
                            label: 'About Us',
                            order: 9,
                            url: '#',
                            target: null,
                            menuIcons: {
                                __typename: 'MenuItem_Menuicons',
                                icon: null,
                            },
                            socialColor: {
                                __typename: 'MenuItem_Socialcolor',
                                iconColor: null,
                            },
                            parentId: 'cG9zdDozMDUwMg==',
                            id: 'cG9zdDoxMDY0NQ==',
                        },
                        selected: false,
                        _isSubMenu: false,
                    },
                    {
                        __typename: 'MenuToMenuItemConnectionEdge',
                        node: {
                            __typename: 'MenuItem',
                            label: 'Contact Us',
                            order: 10,
                            url: 'https://dev1corp1.americanfirstfinance.com/contact-us/',
                            target: null,
                            menuIcons: {
                                __typename: 'MenuItem_Menuicons',
                                icon: null,
                            },
                            socialColor: {
                                __typename: 'MenuItem_Socialcolor',
                                iconColor: null,
                            },
                            parentId: 'cG9zdDozMDUwMg==',
                            id: 'cG9zdDozMDUwMw==',
                        },
                        selected: false,
                        _isSubMenu: false,
                    },
                ],
            },
            {
                __typename: 'MenuToMenuItemConnectionEdge',
                node: {
                    __typename: 'MenuItem',
                    label: 'Test Title',
                    order: 11,
                    url: '#',
                    target: null,
                    menuIcons: {
                        __typename: 'MenuItem_Menuicons',
                        icon: null,
                    },
                    socialColor: {
                        __typename: 'MenuItem_Socialcolor',
                        iconColor: null,
                    },
                    parentId: 'cG9zdDoxMDY0NA==',
                    id: 'cG9zdDozMDUwNA==',
                },
                selected: false,
                _isSubMenu: false,
                children: [
                    {
                        __typename: 'MenuToMenuItemConnectionEdge',
                        node: {
                            __typename: 'MenuItem',
                            label: 'How To Apply',
                            order: 12,
                            url: 'https://dev1corp1.americanfirstfinance.com/how-to-apply/',
                            target: null,
                            menuIcons: {
                                __typename: 'MenuItem_Menuicons',
                                icon: null,
                            },
                            socialColor: {
                                __typename: 'MenuItem_Socialcolor',
                                iconColor: null,
                            },
                            parentId: 'cG9zdDozMDUwNA==',
                            id: 'cG9zdDozMDUwNg==',
                        },
                        selected: false,
                        _isSubMenu: false,
                    },
                ],
            },
        ],
    },
    {
        __typename: 'MenuToMenuItemConnectionEdge',
        node: {
            __typename: 'MenuItem',
            label: 'Rent To Own',
            order: 13,
            url: 'https://dev1corp1.americanfirstfinance.com/rent-to-own/',
            target: null,
            menuIcons: {
                __typename: 'MenuItem_Menuicons',
                icon: null,
            },
            socialColor: {
                __typename: 'MenuItem_Socialcolor',
                iconColor: null,
            },
            parentId: 'cG9zdDoxMDYzNg==',
            id: 'cG9zdDoxMDYzNw==',
        },
        selected: false,
    },
    {
        __typename: 'MenuToMenuItemConnectionEdge',
        node: {
            __typename: 'MenuItem',
            label: 'Rent To Own',
            order: 14,
            url: 'https://dev1corp1.americanfirstfinance.com/rent-to-own/',
            target: null,
            menuIcons: {
                __typename: 'MenuItem_Menuicons',
                icon: null,
            },
            socialColor: {
                __typename: 'MenuItem_Socialcolor',
                iconColor: null,
            },
            parentId: 'cG9zdDoxMDY0MA==',
            id: 'cG9zdDoxMDY0MQ==',
        },
        selected: false,
    },
];
