import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'ua2-aff-button',
    templateUrl: './aff-button.component.html',
    styleUrls: [],
})
export class AffButtonComponent implements OnChanges {
    @Input() url?: string = '';
    @Input() target?: string = '';
    @Input() title?: string = '';
    @Input() buttonLabel?: string = '';
    @Input() buttonDefaultClass?: string =
        'text-decoration-none text-center shadow-none border border-0 aff-button-state';
    @Input() buttonCustomClass?: string =
        'px-3 py-25 p-xxl-3 d-inline-flex flex-row justify-content-center align-items-center aff-button-state aff-button aff-button-properties';
    @Input() disabled?: boolean = false;
    @Input() affButtonIcon?: string = '';
    @Input() affButtonIconClass?: string = 'ms-1';
    @Input() showAffButtonIcon?: boolean = false;
    @Input() id?: string = '';
    @Input() isButtonAnchor?: boolean = true;
    @Input() buttonType?: string = 'button';
    @Input() buttonHostCustomClass?: string = '';
    @Input() setStyleAttribute: any;
    @Output() buttonClickEvt = new EventEmitter();
    disabledBtnClass = '';
    disable = false;
    @ViewChild('affAnchorButton', { static: false }) affAnchorButton: ElementRef<any>;

    ngOnChanges(changes: SimpleChanges): void {
        for (const property in changes) {
            if (property === 'buttonDefaultClass' && changes[property].currentValue !== this.buttonDefaultClass)
                this.buttonDefaultClass = `aff-button-state ${changes[property].currentValue}`;
            if (property === 'buttonCustomClass')
                this.buttonCustomClass = `aff-button-state ${changes[property].currentValue}`;
            if (property === 'disabled') {
                this.disable = changes[property].currentValue;
                this.updateButtonClass();
            }
            if (property === 'setStyleAttribute') {
                this.setButtonBackgroundStyle(changes[property]);
            }
        }
    }

    updateButtonClass() {
        this.disabledBtnClass = this.disable ? 'disabled' : '';
    }

    onClick(event: Event) {
        if (this.buttonType?.toString().toLowerCase() === 'button') {
            this.buttonClickEvt.emit(event);
        }
    }

    setButtonBackgroundStyle(property: any) {
        if (property?.currentValue?.setStyleAttr === true) {
            if (this.affAnchorButton?.nativeElement) {
                this.affAnchorButton.nativeElement.setAttribute(property.currentValue.attributeName, property.currentValue.attributes);
            }
        }
    }
}
