import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'ua2-slider-button, slider-button',
    templateUrl: './slider-button.component.html',
})
export class SliderButtonComponent {

    @Input() sliderButtonContainerClass?: string = 'slider-button-container  text-center rounded-circle';
    @Input() sliderButtonIconClass?: string = 'fa-light fa-sliders text-center';
    @Input() sliderButtonBadgeClass?: string = 'position-relative rounded-circle d-flex justify-content-center align-items-center text-center';
    @Output() sliderButtonClicked: EventEmitter<any> = new EventEmitter();
    _count = 0;

    @HostListener('click', ['$event'])
    onSliderButtonClick(): void {
        this.sliderButtonClicked.emit();
    }

    @Input()
    set count(value: number) {
        this._count = value;
    }

    get getSliderButtonContainerClass(): string {
        const classes = `${this.sliderButtonContainerClass}`;
        return classes;
    }

    get getSliderButtonIconClass(): string {
        const classes = `${this.sliderButtonIconClass}`;
        return classes;
    }

    get getSliderButtonBadgeClass(): string {
        const classes = `${this.sliderButtonBadgeClass}`;
        return classes;
    }
}
