<ng-container *ngIf="isButtonAnchor; else isNotButtonAnchor">
    <a [href]="url" [target]="target" [title]="title"
        [ngClass]="[buttonDefaultClass, buttonCustomClass, buttonHostCustomClass, disabledBtnClass]" [id]="id"
        role="button" #affAnchorButton>
        <span class="d-inline-block text-reset">{{ buttonLabel }} <em *ngIf="showAffButtonIcon"
                [ngClass]="[affButtonIcon, affButtonIconClass]"></em></span>
    </a>
</ng-container>
<ng-template #isNotButtonAnchor>
    <button [ngClass]="[buttonDefaultClass, buttonCustomClass, buttonHostCustomClass, disabledBtnClass]" [id]="id"
        [type]="buttonType" (click)="onClick($event)">
        <span class="d-inline-block text-reset">{{ buttonLabel }} <em *ngIf="showAffButtonIcon"
                [ngClass]="[affButtonIcon, affButtonIconClass]"></em></span>
    </button>
</ng-template>