<div [ngClass]="stepperIndicatorContainerClasses">
    @if(_indicatorIndex){
    <div [ngClass]="stepperIndicatorClasses">
        @if(_showFaIcon){
        <em [ngClass]="faIconClass"></em>
        }@else{
        {{_indicatorIndex}}
        }
    </div>
    }

    @if(_pageTitle){
    <div [ngClass]="stepperTitleClasses">
        {{_pageTitle}}
    </div>
    }
</div>