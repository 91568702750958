export const BannerMockSimpleGrayLeft = {
    backgroundColor: 'light_grey',
    backgroundImage: {
        __typename: 'MediaItem',
        sourceUrl:
            'https://acdn.americanfirstfinance.com/wp-content/u…6/man-installing-shingles-on-roof-repair-hero.jpg',
        srcSet: 'https://acdn.americanfirstfinance.com/wp-content/u…installing-shingles-on-roof-repair-hero.jpg 1440w',
        altText: 'man installing shingles on roof repair',
    },
    backgroundType: 'color',
    bannerHeight: 'short',
    bannerTextAlignment: 'left',
    bannerType: 'simple',
    buttonCta: null,
    buttonCtaText: 'search for nearest store',
    colorScheme: 'light',
    defaultCategory: {
        __typename: 'ShopCategory',
        name: 'home repair',
        slug: 'home-repair',
        taxonomyName: 'shop_category',
    },
    fieldGroupName: 'Page_Contentmodules_ContentModules_BannerModule',
    fontAwesomeIcon: null,
    headingText: 'test',
    searchType: 'zip_code',
    subheadingText:
        '<p><span style="background-color: #ffffff; color: #000000;">Banner and shop by category testing</span></p>',
    useAsHero: null,
    __typename: 'Page_Contentmodules_ContentModules_BannerModule',
};

export const BannerMockShopYellowCenter = {
    backgroundColor: 'yellow',
    backgroundImage: {
        __typename: 'MediaItem',
        sourceUrl:
            'https://acdn.americanfirstfinance.com/wp-content/u…6/man-installing-shingles-on-roof-repair-hero.jpg',
        srcSet: 'https://acdn.americanfirstfinance.com/wp-content/u…installing-shingles-on-roof-repair-hero.jpg 1440w',
        altText: 'man installing shingles on roof repair',
    },
    backgroundType: 'color',
    bannerHeight: 'short',
    bannerTextAlignment: 'center',
    bannerType: 'shop',
    buttonCta: null,
    buttonCtaText: 'search for nearest store',
    colorScheme: 'light',
    defaultCategory: {
        __typename: 'ShopCategory',
        name: 'home repair',
        slug: 'home-repair',
        taxonomyName: 'shop_category',
    },
    fieldGroupName: 'Page_Contentmodules_ContentModules_BannerModule',
    fontAwesomeIcon: null,
    headingText: 'test',
    searchType: 'zip_code',
    subheadingText:
        '<p><span style="background-color: #ffffff; color: #000000;">Banner and shop by category testing</span></p>',
    useAsHero: null,
    __typename: 'Page_Contentmodules_ContentModules_BannerModule',
};

export const BannerMockShopImageCenter = {
    backgroundColor: 'yellow',
    backgroundImage: {
        __typename: 'MediaItem',
        sourceUrl:
            'https://acdn.americanfirstfinance.com/wp-content/u…6/man-installing-shingles-on-roof-repair-hero.jpg',
        srcSet: 'https://acdn.americanfirstfinance.com/wp-content/u…installing-shingles-on-roof-repair-hero.jpg 1440w',
        altText: 'man installing shingles on roof repair',
    },
    backgroundType: 'image',
    bannerHeight: 'tall',
    bannerTextAlignment: 'center',
    bannerType: 'shop',
    buttonCta: null,
    buttonCtaText: 'search for nearest store',
    colorScheme: 'light',
    defaultCategory: {
        __typename: 'ShopCategory',
        name: 'home repair',
        slug: 'home-repair',
        taxonomyName: 'shop_category',
    },
    fieldGroupName: 'Page_Contentmodules_ContentModules_BannerModule',
    fontAwesomeIcon: null,
    headingText: 'test',
    searchType: 'zip_code',
    subheadingText:
        '<p><span style="background-color: #ffffff; color: #000000;">Banner and shop by category testing</span></p>',
    useAsHero: null,
    __typename: 'Page_Contentmodules_ContentModules_BannerModule',
};

export const BannerMockShopImageCenterDark = {
    backgroundColor: 'yellow',
    backgroundImage: {
        __typename: 'MediaItem',
        sourceUrl:
            'https://acdn.americanfirstfinance.com/wp-content/u…6/man-installing-shingles-on-roof-repair-hero.jpg',
        srcSet: 'https://acdn.americanfirstfinance.com/wp-content/u…installing-shingles-on-roof-repair-hero.jpg 1440w',
        altText: 'man installing shingles on roof repair',
    },
    backgroundType: 'image',
    bannerHeight: 'tall',
    bannerTextAlignment: 'center',
    bannerType: 'shop',
    buttonCta: null,
    buttonCtaText: 'search for nearest store',
    colorScheme: 'dark',
    defaultCategory: {
        __typename: 'ShopCategory',
        name: 'home repair',
        slug: 'home-repair',
        taxonomyName: 'shop_category',
    },
    fieldGroupName: 'Page_Contentmodules_ContentModules_BannerModule',
    fontAwesomeIcon: null,
    headingText: 'test',
    searchType: 'zip_code',
    subheadingText:
        '<p><span style="background-color: #ffffff; color: #000000;">Banner and shop by category testing</span></p>',
    useAsHero: null,
    __typename: 'Page_Contentmodules_ContentModules_BannerModule',
};

export const BannerMockSimpleBlueLeft = {
    backgroundColor: 'blue',
    backgroundImage: {
        __typename: 'MediaItem',
        sourceUrl:
            'https://acdn.americanfirstfinance.com/wp-content/u…6/man-installing-shingles-on-roof-repair-hero.jpg',
        srcSet: 'https://acdn.americanfirstfinance.com/wp-content/u…installing-shingles-on-roof-repair-hero.jpg 1440w',
        altText: 'man installing shingles on roof repair',
    },
    backgroundType: 'color',
    bannerHeight: 'short',
    bannerTextAlignment: 'left',
    bannerType: 'simple',
    buttonCta: null,
    buttonCtaText: 'search for nearest store',
    colorScheme: 'light',
    defaultCategory: {
        __typename: 'ShopCategory',
        name: 'home repair',
        slug: 'home-repair',
        taxonomyName: 'shop_category',
    },
    fieldGroupName: 'Page_Contentmodules_ContentModules_BannerModule',
    fontAwesomeIcon:
        '{ "style" : "solid", "id" : "building-columns", "label" : "Building Columns", "unicode" : "f19c" }',
    headingText: 'test',
    searchType: 'zip_code',
    subheadingText:
        '<p><span style="background-color: #ffffff; color: #000000;">Banner and shop by category testing</span></p>',
    useAsHero: null,
    __typename: 'Page_Contentmodules_ContentModules_BannerModule',
};

export const BannerMockShopWhiteCenter = {
    backgroundColor: 'green',
    backgroundImage: {
        __typename: 'MediaItem',
        sourceUrl:
            'https://acdn.americanfirstfinance.com/wp-content/u…6/man-installing-shingles-on-roof-repair-hero.jpg',
        srcSet: 'https://acdn.americanfirstfinance.com/wp-content/u…installing-shingles-on-roof-repair-hero.jpg 1440w',
        altText: 'man installing shingles on roof repair',
    },
    backgroundType: 'color',
    bannerHeight: 'short',
    bannerTextAlignment: 'left',
    bannerType: 'shop',
    buttonCta: null,
    buttonCtaText: 'search for nearest store',
    colorScheme: 'light',
    defaultCategory: {
        __typename: 'ShopCategory',
        name: 'home repair',
        slug: 'home-repair',
        taxonomyName: 'shop_category',
    },
    fieldGroupName: 'Page_Contentmodules_ContentModules_BannerModule',
    fontAwesomeIcon: null,
    headingText: 'test',
    searchType: 'cat_zip',
    subheadingText:
        '<p><span style="background-color: #ffffff; color: #000000;">Banner and shop by category testing</span></p>',
    useAsHero: ['yes'],
    __typename: 'Page_Contentmodules_ContentModules_BannerModule',
};

export const BannerMockSimpleYellowLeft = {
    backgroundColor: 'yellow',
    backgroundImage: {
        __typename: 'MediaItem',
        sourceUrl:
            'https://acdn.americanfirstfinance.com/wp-content/u…6/man-installing-shingles-on-roof-repair-hero.jpg',
        srcSet: 'https://acdn.americanfirstfinance.com/wp-content/u…installing-shingles-on-roof-repair-hero.jpg 1440w',
        altText: 'man installing shingles on roof repair',
    },
    backgroundType: 'color',
    bannerHeight: 'short',
    bannerTextAlignment: 'left',
    bannerType: 'simple',
    buttonCta: {
        title: 'search',
    },
    buttonCtaText: 'search for nearest store',
    colorScheme: 'light',
    defaultCategory: {
        __typename: 'ShopCategory',
        name: 'home repair',
        slug: 'home-repair',
        taxonomyName: 'shop_category',
    },
    fieldGroupName: 'Page_Contentmodules_ContentModules_BannerModule',
    fontAwesomeIcon: null,
    headingText: 'test',
    searchType: 'zip_code',
    subheadingText:
        '<p><span style="background-color: #ffffff; color: #000000;">Banner and shop by category testing</span></p>',
    useAsHero: null,
    __typename: 'Page_Contentmodules_ContentModules_BannerModule',
};

export const BannerMockShopYellowLeft = {
    backgroundColor: 'yellow',
    backgroundImage: {
        __typename: 'MediaItem',
        sourceUrl:
            'https://acdn.americanfirstfinance.com/wp-content/u…6/man-installing-shingles-on-roof-repair-hero.jpg',
        srcSet: 'https://acdn.americanfirstfinance.com/wp-content/u…installing-shingles-on-roof-repair-hero.jpg 1440w',
        altText: 'man installing shingles on roof repair',
    },
    backgroundType: 'color',
    bannerHeight: 'short',
    bannerTextAlignment: 'left',
    bannerType: 'shop',
    buttonCta: null,
    buttonCtaText: 'search for nearest store',
    colorScheme: 'light',
    defaultCategory: {
        __typename: 'ShopCategory',
        name: 'home repair',
        slug: 'home-repair',
        taxonomyName: 'shop_category',
    },
    fieldGroupName: 'Page_Contentmodules_ContentModules_BannerModule',
    fontAwesomeIcon: null,
    headingText: 'test',
    searchType: 'zip_code',
    subheadingText:
        '<p><span style="background-color: #ffffff; color: #000000;">Banner and shop by category testing</span></p>',
    useAsHero: null,
    __typename: 'Page_Contentmodules_ContentModules_BannerModule',
};

export const categoriesData = [
    {
        id: 'dGVybToxNg==',
        name: 'Appliances',
        shopCategoryId: 16,
        slug: 'appliances',
        taxonomyName: 'shop_category',
        termTaxonomyId: 16,
        __typename: 'ShopCategory',
    },
    {
        id: 'dGVybToxOA==',
        name: 'Auto Repair',
        shopCategoryId: 18,
        slug: 'auto-repair',
        taxonomyName: 'shop_category',
        termTaxonomyId: 18,
        __typename: 'ShopCategory',
    },
    {
        id: 'dGVybToxNw==',
        name: 'Electronics',
        shopCategoryId: 17,
        slug: 'electronics',
        taxonomyName: 'shop_category',
        termTaxonomyId: 17,
        __typename: 'ShopCategory',
    },
    {
        id: 'dGVybToxNA==',
        name: 'Furniture',
        shopCategoryId: 14,
        slug: 'furniture',
        taxonomyName: 'shop_category',
        termTaxonomyId: 14,
        __typename: 'ShopCategory',
    },
    {
        id: 'dGVybToyMQ==',
        name: 'Jewelry',
        shopCategoryId: 21,
        slug: 'jewelry',
        taxonomyName: 'shop_category',
        termTaxonomyId: 21,
        __typename: 'ShopCategory',
    },
    {
        id: 'dGVybToxNQ==',
        name: 'Mattresses',
        shopCategoryId: 15,
        slug: 'mattresses',
        taxonomyName: 'shop_category',
        termTaxonomyId: 15,
        __typename: 'ShopCategory',
    },
];

export const selectOptions = [
    { label: 'Appliances', value: 'appliances' },
    { label: 'Auto Repair', value: 'auto-repair' },
    { label: 'Electronics', value: 'electronics' },
    { label: 'Furniture', value: 'furniture' },
    { label: 'Jewelry', value: 'jewelry' },
    { label: 'Mattresses', value: 'mattresses' },
];
