import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormControlStatus,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AffGoogleMapsLoaderService } from '../services/aff-google-maps-loader.service';
import { getAddressResult, getAddressString } from './aff-google-address-autocomplete.fns';
import {
    AFFGoogleAddress,
    AffGoogleAddressFormValue,
    AFFGoogleAutocompleteTemplateType,
} from './aff-google-address-autocomplete.models';
import MapsEventListener = google.maps.MapsEventListener;
import PlaceResult = google.maps.places.PlaceResult;
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'aff-google-address-autocomplete',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        MatSelectModule,
    ],
    providers: [provideNgxMask()],
    templateUrl: './aff-google-address-autocomplete.component.html',
    styleUrls: ['./aff-google-address-autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'aff-google-address-autocomplete',
        '[class.input-active]': "templateType === 'input'",
    },
})
export class AffGoogleAddressAutocompleteComponent implements OnInit, OnDestroy {
    private _onDestroy$: Subject<boolean> = new Subject();

    @ViewChild('search', { static: true })
    public searchElementRef!: ElementRef;

    @Input()
    set addressInput(a: string) {
        this.autocompleteControl.setValue(a);
    }

    @Input()
    set address(a: AFFGoogleAddress | null) {
        if (a) {
            if (this.autocompleteControl.value !== a.streetAddress) {
                this.autocompleteControl.patchValue(a.streetAddress, { emitEvent: false });
            }

            const update = {
                suitApt: a.suitApt,
                city: a.city,
                state: a.state,
                zipCode: a.zipCode,
            };

            let isEqual = false;

            try {
                isEqual = JSON.stringify(update) === JSON.stringify(this.addressFormGroup.value);
            } catch (e: any) {
                console.error(e);
            }

            if (!isEqual) {
                this.addressFormGroup.patchValue(
                    {
                        suitApt: a.suitApt,
                        city: a.city,
                        state: a.state,
                        zipCode: a.zipCode,
                    },
                    { emitEvent: false }
                );
            }
        }
    }

    private _templateType: AFFGoogleAutocompleteTemplateType = 'form';

    @Input()
    set templateType(t) {
        this._templateType = t;

        if (
            this.autocompleteControl &&
            this.shortAddress &&
            this.shortAddress.length &&
            this.longAddress &&
            this.longAddress.length
        ) {
            if (t === 'input') {
                this.autocompleteControl.setValue(this.longAddress, { emitEvent: false });
            } else {
                this.autocompleteControl.setValue(this.shortAddress, { emitEvent: false });
            }
        }

        this.cd.detectChanges();
    }

    get templateType() {
        return this._templateType;
    }

    private _customValidationMsg = {
        addressMsg: 'Address required.',
        cityMsg: 'City required.',
        stateMsg: 'State required.',
        zipMsg: 'Zip code required.',
    };
    @Input()
    set customValidationMsg(customMsg: any) {
        this._customValidationMsg = customMsg;
    }

    get customValidationMsg() {
        return this._customValidationMsg;
    }

    @Output()
    placeResult: EventEmitter<PlaceResult | string | null> = new EventEmitter<PlaceResult | string | null>();

    @Output()
    autocompleteSelected: EventEmitter<string | null> = new EventEmitter<string | null>();

    @Output()
    addressChange: EventEmitter<AFFGoogleAddress | null> = new EventEmitter<AFFGoogleAddress | null>();

    @Output() isInvalidState: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() isValidState: EventEmitter<boolean> = new EventEmitter<boolean>();

    get isValid() {
        return this.autocompleteControl.valid && this.addressFormGroup.valid;
    }

    get isInValid() {
        return this.autocompleteControl.invalid || this.addressFormGroup.invalid;
    }

    get isPristine() {
        return this.autocompleteControl.pristine && this.addressFormGroup.pristine;
    }

    get isDirty() {
        return this.autocompleteControl.dirty && this.addressFormGroup.dirty;
    }

    private googleMapsPlacesAutocomplete: google.maps.places.Autocomplete | undefined;
    private googleMapsPlacesAutocompleteEventListener: MapsEventListener | undefined;
    private shortAddress = '';
    private longAddress = '';

    autocompleteControl = new FormControl('', [Validators.required]);
    addressFormGroup: FormGroup;

    constructor(
        private googleMapsLoader: AffGoogleMapsLoaderService,
        private fb: FormBuilder,
        private cd: ChangeDetectorRef
    ) {
        this.addressFormGroup = this.fb.group({
            suitApt: new FormControl(''),
            city: new FormControl('', [Validators.required]),
            state: new FormControl('', [Validators.required]),
            zipCode: new FormControl('', [Validators.required, Validators.minLength(5)]),
        });
    }

    ngOnInit() {
        this.initGoogleMaps();

        this.addressFormGroup.statusChanges.pipe(takeUntil(this._onDestroy$)).subscribe((s: FormControlStatus) => {
            this.isInvalidState.emit(s === 'INVALID');
            this.isValidState.emit(s === 'VALID');
        });
    }

    /**
     * See https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
     */
    initGoogleMaps() {
        this.addressFormGroup.valueChanges
            .pipe(takeUntil(this._onDestroy$), debounceTime(300))
            .subscribe((v: AffGoogleAddressFormValue) => {
                if (this.templateType === 'form') {
                    this.addressChange.emit({
                        ...v,
                        streetAddress: this.autocompleteControl.value || '',
                    });
                }
            });

        this.googleMapsLoader.loaded$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
            this.googleMapsPlacesAutocomplete = new google.maps.places.Autocomplete(
                this.searchElementRef.nativeElement,
                {
                    componentRestrictions: { country: ['us', 'ca'] },
                    fields: ['address_components', 'geometry'],
                    types: ['address'],
                }
            );

            this.googleMapsPlacesAutocompleteEventListener = this.googleMapsPlacesAutocomplete.addListener(
                'place_changed',
                () => {
                    if (this.googleMapsPlacesAutocomplete) {
                        const place: PlaceResult = this.googleMapsPlacesAutocomplete.getPlace();
                        this.placeResult.emit(place);
                        const address = getAddressResult(place);
                        this.shortAddress = address.streetAddress;
                        this.longAddress = getAddressString(address);

                        this.autocompleteSelected.emit(this.longAddress);
                        this.addressChange.emit(address);

                        this.autocompleteControl.setValue(address.streetAddress, { emitEvent: false });

                        this.addressFormGroup.patchValue({
                            suitApt: address.suitApt,
                            city: address.city,
                            state: address.state,
                            zipCode: address.zipCode,
                        });

                        this.autocompleteControl.markAsDirty();
                        this.addressFormGroup.markAsDirty();
                    }
                }
            );
        });
    }

    reset() {
        if (this.autocompleteControl) {
            this.autocompleteControl.markAsPristine();
            this.autocompleteControl.markAsUntouched();
        }

        if (this.addressFormGroup) {
            this.addressFormGroup.markAsPristine();
            this.addressFormGroup.markAsUntouched();
        }
    }

    ngOnDestroy() {
        this._onDestroy$.next(true);
        if (this.googleMapsPlacesAutocompleteEventListener) {
            google.maps.event.removeListener(this.googleMapsPlacesAutocompleteEventListener);
        }
    }
}
