import { Injectable } from '@angular/core';

import { LocalStorage } from '../services/storage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class QueryParams {
    constructor(private _localStorage: LocalStorage) {}
    getQueryParams() {
        let paramsToAppend: string | null = '';

        paramsToAppend = this._localStorage.getItem('queryparams');

        if (paramsToAppend) {
            return paramsToAppend?.includes('osource') ? `&${paramsToAppend}` : `&${paramsToAppend}&osource=sop`;
        } else {
            return '&osource=sop';
        }
    }
}
