import { Component, HostBinding, Input } from '@angular/core';
import { ButtonColor } from '../enums/button-color.enum';
import { ButtonSize } from '../enums/button-size.enum';

@Component({
    selector: 'ua2-corporate-button, button[affButton]',
    templateUrl: './corporate-button.component.html',
    styleUrls: [],
})
export class CorporateButtonComponent {
    @Input() public color: ButtonColor;
    @Input() public size: ButtonSize;

    constructor() {
        this.color = ButtonColor.DEFAULT;
    }

    @HostBinding('class')
    get classes(): string {
        return `btn ${this.color} ${this.size}`;
    }
}
