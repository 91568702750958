<!-- NOSONAR -->
<li class="pb-3" *ngIf="navItem?.node as item">
    <a
        [href]="item?.url"
        target="{{ item?.target || '' }}"
        class="mobile-menu-subheader text-reset text-decoration-none"
        >{{ item?.label }}
        <fa-icon class="ms-1" [icon]="angleRight"></fa-icon>
    </a>

    <ul class="list-unstyled text-reset m-0 childmenu px-3 pt-2 px-xxl-0" *ngIf="hasTertiaryMenu">
        <ng-container
            *ngFor="let child of this.item?.ctaNavFields?.tertiaryMenu | tertiaryItemType; let last = last"
        >
            <li [ngClass]="!last ? 'pb-6' : 'last-item'" *ngIf="child.heading !== 'View All'; else viewTemplate">
                <a
                    [href]="child?.link?.url"
                    target="{{ child?.link?.target || '' }}"
                    class="text-reset text-decoration-none"
                    [ngClass]="{ 'icon-offset': !child.icon }"
                >
                    <em *ngIf="child.icon" [ngClass]="child.icon | getHeaderIconClass" class="icon-style"></em>
                    <span [innerHTML]="child.heading" *ngIf="child.heading && child.icon; else linkBlock"></span>
                    <ng-template #linkBlock><span [innerHTML]="child?.link?.title"></span></ng-template>
                </a>
            </li>
            <ng-template #viewTemplate>
                <li class="last-item">
                    <a
                        [href]="child?.link?.url"
                        target="{{ child?.link?.target || '' }}"
                        class="text-reset text-decoration-none icon-offset view-all"
                        >View All
                        <fa-icon class="ms-1" [icon]="angleRight"></fa-icon>
                    </a>
                </li>
            </ng-template>
        </ng-container>
    </ul>
</li>
<!-- NOSONAR -->
