export const ARESULTS: any = [
    {
        PDNO: 'Test01',
        PDNAME: 'AFF Test Dealer',
        PDNAM1: 'Sport clips',
        PDADR1: '6025 royal lane',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75230',
        LATITUDE: '32.895818800',
        LONGITUDE: '-96.802760000',
        PDLOC: '901',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '5.1',
        DTYPE: 'RISA',
        CATEGORY: 'Appliances',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/Test01/901/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2689',
        PDNAME: 'A New Concept In Furniture INC.',
        PDNAM1: 'RISA',
        PDADR1: '10444 Carry back circle',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75229',
        LATITUDE: '32.886508700',
        LONGITUDE: '-96.870735800',
        PDLOC: '108',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '6.0',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2689/108/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2852',
        PDNAME: 'Testing RTO/FWB',
        PDNAM1: 'Arno',
        PDADR1: '123 Test Ln',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75010',
        LATITUDE: '33.027410500',
        LONGITUDE: '-96.926280500',
        PDLOC: '2',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '7.0',
        DTYPE: 'RISA',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2852/2/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '8585',
        PDNAME: 'Furniture Mall Direct',
        PDNAM1: 'Furniture Mall Direct',
        PDADR1: '8585 N Stemmons Fwy',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75229',
        LATITUDE: '32.881017100',
        LONGITUDE: '-96.898531200',
        PDLOC: '8585',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '7.1',
        DTYPE: 'RTO',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/8585/8585/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2585',
        PDNAME: 'RBLS Inc',
        PDNAM1: 'Levitz Mattress Company- Plano',
        PDADR1: '1100 N Central Expy',
        PDADR2: 'Suite B',
        PDCITY: 'Plano',
        PDST: 'TX',
        PDZIP: '75074',
        LATITUDE: '33.013932400',
        LONGITUDE: '-96.707385500',
        PDLOC: '4',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '7.8',
        DTYPE: 'LTO',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2585/4/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2855',
        PDNAME: 'Premiere Management Company',
        PDNAM1: 'Texas',
        PDADR1: '6565 Headquarters Drive',
        PDADR2: '',
        PDCITY: 'Plano',
        PDST: 'TX',
        PDZIP: '75247',
        LATITUDE: '33.081180900',
        LONGITUDE: '-96.841015000',
        PDLOC: '2855',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '8.0',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2855/2855/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'LTODEMOMZ',
        PDNAME: 'MZ',
        PDNAM1: 'MZ Location 2',
        PDADR1: '10110 Technology Boulevard East',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75247',
        LATITUDE: '32.858329300',
        LONGITUDE: '-96.893922200',
        PDLOC: '2',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '8.3',
        DTYPE: 'RTO',
        CATEGORY: 'Appliances',
        PRODSOLD1: 'Appliances',
        TARGETURL: 'https://devapp.americanfirstfinance.com/LTODEMOMZ/2/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'WOO',
        PDNAME: 'WOO',
        PDNAM1: 'Big Tires #2',
        PDADR1: '8524 Harry Hines Blvd',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75235',
        LATITUDE: '32.837239000',
        LONGITUDE: '-96.864428000',
        PDLOC: '2',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.1',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/WOO/2/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'EZPRO',
        PDNAME: 'EZ Process Pro',
        PDNAM1: 'EZ Process Pro',
        PDADR1: '8585 North Stemmons Freeway',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75247',
        LATITUDE: '32.829613100',
        LONGITUDE: '-96.874657200',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RTO',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/EZPRO/1/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'Test01',
        PDNAME: 'AFF Test Dealer',
        PDNAM1: 'AFF',
        PDADR1: '8585 N Stemmons Fwy',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75229',
        LATITUDE: '32.829613100',
        LONGITUDE: '-96.874657200',
        PDLOC: '9',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RISA',
        CATEGORY: 'Appliances',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/Test01/9/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
];

export const FILTEREDDATA: any = {
    success: 'true',
    fRadius: 50,
    iNumberOfRecords: 9,
    aResults: ARESULTS,
    finwiseLoc: true,
    increasedMiles: false,
    searchText: '75002',
    categories: ['Appliances'],
};

export const nextStoreData = [
    {
        PDNO: '2884',
        PDNAME: 'Oyoba',
        PDNAM1: 'Seaspan Corporation',
        PDADR1: '8585 North Stemmons Freeway',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75247',
        LATITUDE: '32.829595600',
        LONGITUDE: '-96.874806400',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2884/1/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2590',
        PDNAME: 'TestDealer',
        PDNAM1: 'RA RM #2',
        PDADR1: '8585 N Stemmons Fwy',
        PDADR2: 'Apt273',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75249',
        LATITUDE: '32.829595600',
        LONGITUDE: '-96.874806400',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RISA',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2590/1/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2425',
        PDNAME: 'Jeremy Test',
        PDNAM1: 'Test Location',
        PDADR1: '8585 North Stemmons Fwy',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75249',
        LATITUDE: '32.829595600',
        LONGITUDE: '-96.874806400',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RTO',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2425/1/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'InstallmentLoanRegression',
        PDNAME: 'Installment Loan Regression',
        PDNAM1: 'Reg ND',
        PDADR1: '8585 N Stemmons Freeway',
        PDADR2: '',
        PDCITY: 'Adams',
        PDST: 'ND',
        PDZIP: '58210',
        LATITUDE: '32.829594800',
        LONGITUDE: '-96.874808200',
        PDLOC: '14',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RISA',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/InstallmentLoanRegression/14/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'InstallmentLoanRegression',
        PDNAME: 'Installment Loan Regression',
        PDNAM1: 'RegOR',
        PDADR1: '8585 N Stemmons Freeway',
        PDADR2: '',
        PDCITY: 'Portland',
        PDST: 'OR',
        PDZIP: '97035',
        LATITUDE: '32.829594800',
        LONGITUDE: '-96.874808200',
        PDLOC: '15',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RISA',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/InstallmentLoanRegression/15/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'InstallmentLoanRegression',
        PDNAME: 'Installment Loan Regression',
        PDNAM1: 'RegPA',
        PDADR1: '8585 N Stemmons Freeway',
        PDADR2: '',
        PDCITY: 'Reading',
        PDST: 'PA',
        PDZIP: '19601',
        LATITUDE: '32.829594800',
        LONGITUDE: '-96.874808200',
        PDLOC: '16',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RISA',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/InstallmentLoanRegression/16/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
];

export const previousStoreData = [
    {
        PDNO: 'Test01',
        PDNAME: 'AFF Test Dealer',
        PDNAM1: 'Sport clips',
        PDADR1: '6025 royal lane',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75230',
        LATITUDE: '32.895818800',
        LONGITUDE: '-96.802760000',
        PDLOC: '901',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '5.1',
        DTYPE: 'RISA',
        CATEGORY: 'Appliances',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/Test01/901/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2689',
        PDNAME: 'A New Concept In Furniture INC.',
        PDNAM1: 'RISA',
        PDADR1: '10444 Carry back circle',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75229',
        LATITUDE: '32.886508700',
        LONGITUDE: '-96.870735800',
        PDLOC: '108',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '6.0',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2689/108/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2852',
        PDNAME: 'Testing RTO/FWB',
        PDNAM1: 'Arno',
        PDADR1: '123 Test Ln',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75010',
        LATITUDE: '33.027410500',
        LONGITUDE: '-96.926280500',
        PDLOC: '2',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '7.0',
        DTYPE: 'RISA',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2852/2/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '8585',
        PDNAME: 'Furniture Mall Direct',
        PDNAM1: 'Furniture Mall Direct',
        PDADR1: '8585 N Stemmons Fwy',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75229',
        LATITUDE: '32.881017100',
        LONGITUDE: '-96.898531200',
        PDLOC: '8585',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '7.1',
        DTYPE: 'RTO',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/8585/8585/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2585',
        PDNAME: 'RBLS Inc',
        PDNAM1: 'Levitz Mattress Company- Plano',
        PDADR1: '1100 N Central Expy',
        PDADR2: 'Suite B',
        PDCITY: 'Plano',
        PDST: 'TX',
        PDZIP: '75074',
        LATITUDE: '33.013932400',
        LONGITUDE: '-96.707385500',
        PDLOC: '4',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '7.8',
        DTYPE: 'LTO',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2585/4/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2855',
        PDNAME: 'Premiere Management Company',
        PDNAM1: 'Texas',
        PDADR1: '6565 Headquarters Drive',
        PDADR2: '',
        PDCITY: 'Plano',
        PDST: 'TX',
        PDZIP: '75247',
        LATITUDE: '33.081180900',
        LONGITUDE: '-96.841015000',
        PDLOC: '2855',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '8.0',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2855/2855/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'LTODEMOMZ',
        PDNAME: 'MZ',
        PDNAM1: 'MZ Location 2',
        PDADR1: '10110 Technology Boulevard East',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75247',
        LATITUDE: '32.858329300',
        LONGITUDE: '-96.893922200',
        PDLOC: '2',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '8.3',
        DTYPE: 'RTO',
        CATEGORY: 'Appliances',
        PRODSOLD1: 'Appliances',
        TARGETURL: 'https://devapp.americanfirstfinance.com/LTODEMOMZ/2/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'WOO',
        PDNAME: 'WOO',
        PDNAM1: 'Big Tires #2',
        PDADR1: '8524 Harry Hines Blvd',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75235',
        LATITUDE: '32.837239000',
        LONGITUDE: '-96.864428000',
        PDLOC: '2',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.1',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/WOO/2/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'EZPRO',
        PDNAME: 'EZ Process Pro',
        PDNAM1: 'EZ Process Pro',
        PDADR1: '8585 North Stemmons Freeway',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75247',
        LATITUDE: '32.829613100',
        LONGITUDE: '-96.874657200',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RTO',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/EZPRO/1/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'Test01',
        PDNAME: 'AFF Test Dealer',
        PDNAM1: 'AFF',
        PDADR1: '8585 N Stemmons Fwy',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75229',
        LATITUDE: '32.829613100',
        LONGITUDE: '-96.874657200',
        PDLOC: '9',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RISA',
        CATEGORY: 'Appliances',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/Test01/9/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'LTO',
        PDNAME: 'American First Finance',
        PDNAM1: 'Lease to Own',
        PDADR1: '8585 N Stemmons Fwy',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75240',
        LATITUDE: '32.829595600',
        LONGITUDE: '-96.874806400',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'LTO',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/LTO/1/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2424',
        PDNAME: 'Jeremy Test',
        PDNAM1: 'Test location',
        PDADR1: '8585 N Stemmons Fwy',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75247',
        LATITUDE: '32.829595600',
        LONGITUDE: '-96.874806400',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '1',
        DISTANCE: '9.7',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2424/1/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2860',
        PDNAME: 'RA-DTC',
        PDNAM1: 'Rent Advance',
        PDADR1: '8585 North Stemmons Fwy',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75249',
        LATITUDE: '32.829595600',
        LONGITUDE: '-96.874806400',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2860/1/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2859',
        PDNAME: 'Riverview Apartments',
        PDNAM1: 'FW Test 2',
        PDADR1: '8585 North Stemmons Fwy',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75249',
        LATITUDE: '32.829595600',
        LONGITUDE: '-96.874806400',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2859/1/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2884',
        PDNAME: 'Oyoba',
        PDNAM1: 'Seaspan Corporation',
        PDADR1: '8585 North Stemmons Freeway',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75247',
        LATITUDE: '32.829595600',
        LONGITUDE: '-96.874806400',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'FINWISE',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2884/1/apply?c=bankprod-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2590',
        PDNAME: 'TestDealer',
        PDNAM1: 'RA RM #2',
        PDADR1: '8585 N Stemmons Fwy',
        PDADR2: 'Apt273',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75249',
        LATITUDE: '32.829595600',
        LONGITUDE: '-96.874806400',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RISA',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2590/1/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: '2425',
        PDNAME: 'Jeremy Test',
        PDNAM1: 'Test Location',
        PDADR1: '8585 North Stemmons Fwy',
        PDADR2: '',
        PDCITY: 'Dallas',
        PDST: 'TX',
        PDZIP: '75249',
        LATITUDE: '32.829595600',
        LONGITUDE: '-96.874806400',
        PDLOC: '1',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RTO',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/2425/1/apply?c=lto-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'InstallmentLoanRegression',
        PDNAME: 'Installment Loan Regression',
        PDNAM1: 'Reg ND',
        PDADR1: '8585 N Stemmons Freeway',
        PDADR2: '',
        PDCITY: 'Adams',
        PDST: 'ND',
        PDZIP: '58210',
        LATITUDE: '32.829594800',
        LONGITUDE: '-96.874808200',
        PDLOC: '14',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RISA',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/InstallmentLoanRegression/14/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'InstallmentLoanRegression',
        PDNAME: 'Installment Loan Regression',
        PDNAM1: 'RegOR',
        PDADR1: '8585 N Stemmons Freeway',
        PDADR2: '',
        PDCITY: 'Portland',
        PDST: 'OR',
        PDZIP: '97035',
        LATITUDE: '32.829594800',
        LONGITUDE: '-96.874808200',
        PDLOC: '15',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RISA',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/InstallmentLoanRegression/15/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
    {
        PDNO: 'InstallmentLoanRegression',
        PDNAME: 'Installment Loan Regression',
        PDNAM1: 'RegPA',
        PDADR1: '8585 N Stemmons Freeway',
        PDADR2: '',
        PDCITY: 'Reading',
        PDST: 'PA',
        PDZIP: '19601',
        LATITUDE: '32.829594800',
        LONGITUDE: '-96.874808200',
        PDLOC: '16',
        SRCLAT: '32.9654778',
        SRCLON: '-96.8297403',
        SRCZIP: '1',
        UAVERSION: '',
        DISTANCE: '9.7',
        DTYPE: 'RISA',
        CATEGORY: '',
        PRODSOLD1: '',
        TARGETURL: 'https://devapp.americanfirstfinance.com/InstallmentLoanRegression/16/apply?c=risa-txtapply',
        sIsLangToggleAllowed: 'N',
    },
];
