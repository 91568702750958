import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ContentModulesBanner } from '../../types/kitchen-sink.types';
import {
    AffBannerBackground,
    AffBannerTypes,
    AffBannerTextAlignment,
    AffBackgroundTypes,
    AffBannerColorScheme,
} from './enums/aff-banner.enums';
import { environment } from '../../../environments/environment';
import { FontAwesomeIconService } from '@janet/corporate/ui-corporate';

interface BackgroundColor {
    [key: string]: string;
}

interface Option {
    label: string;
    value: string;
}

const backgroundColorClass: BackgroundColor = {
    white: 'banner-container-bg--white',
    white_blue_borders: 'banner-container-bg--white-with-blue-border',
    light_grey: 'banner-container-bg--light-grey-shop',
    blue: 'banner-container-bg--blue',
    dark_blue: 'banner-container-bg--dark-blue',
    blue_gradient: 'banner-container-bg--gradient-blue-2',
    yellow: 'banner-container-bg--yellow',
};

type nullableString = string | undefined | null;

@Component({
    selector: 'janet-banner',
    templateUrl: './banner.component.html',
})
export class BannerComponent implements OnInit {
    @Input() moduleData: ContentModulesBanner;
    @ViewChild('bannerContainer') bannerContainer: ElementRef;
    affBannerTypes = AffBannerTypes;
    affBannerBackground = AffBannerBackground;
    affBannerTextAlignment = AffBannerTextAlignment;
    affBackgroundTypes = AffBackgroundTypes;
    enableButtonDarkTheme: string;
    darkThemeBgColors = [
        <string>this.affBannerBackground.WHITE,
        <string>this.affBannerBackground.WHITE_WITH_BLUE_BORDERS,
        <string>this.affBackgroundTypes.BACKGROUND_TYPE_IMAGE,
        <string>this.affBannerBackground.YELLOW,
    ];
    selectOptions = [];
    findStoreURL = environment.findStoreURL;
    errorMessageClass = '';

    constructor(public _fontAwesomeIconService: FontAwesomeIconService) { }

    @Input()
    set categoriesData(data: any) {
        this.populateSelectOptionValue(data);
    }

    ngOnInit(): void {
        this.enableButtonDarkTheme = this.colorSchemeButonClasses();
        this.errorMessageClass = this.setErrorMessageClass();
    }

    setErrorMessageClass(): string {
        let classes = '';
        const backgroundColor = this.moduleData?.backgroundColor;
        if (this.moduleData?.backgroundType === AffBackgroundTypes.BACKGROUND_TYPE_COLOR) {
            if (
                backgroundColor === AffBannerBackground.BLUE ||
                backgroundColor === AffBannerBackground.DARKBLUE ||
                backgroundColor === AffBannerBackground.BlueGradient
            ) {
                classes = 'text-general text-start error-text-white';
            } else {
                classes = 'text-general text-start text-red ';
            }
        }

        if (this.moduleData?.backgroundType === AffBackgroundTypes.BACKGROUND_TYPE_IMAGE) {
            if (this.moduleData?.colorScheme === AffBannerColorScheme.BANNER_COLOR_DARK) {
                classes = 'text-general text-start text-red';
            } else {
                classes = 'text-general text-start error-text-white';
            }
        }
        return classes;
    }

    get bannerWrapperClasses() {
        let classes = '';

        classes += this.moduleData?.bannerHeight === 'short' ? ' banner-short' : ' banner-tall';

        classes +=
            this.moduleData?.bannerTextAlignment === AffBannerTextAlignment.BANNER_TEXT_LEFT &&
                ((this.moduleData.bannerType === 'shop' && this.moduleData.searchType !== 'cat_zip') ||
                    this.moduleData.bannerType === 'simple')
                ? ' banner-wrapper-align-left'
                : ' banner-wrapper-align-center';

        if (this.moduleData?.backgroundType === AffBackgroundTypes.BACKGROUND_TYPE_IMAGE) {
            return classes;
        }

        classes += ' ' + (backgroundColorClass[this.moduleData?.backgroundColor] || backgroundColorClass['white']);

        return classes;
    }

    get bannerIconClasses() {
        const classByHeroFlag = this.moduleData.useAsHero
            ? 'banner-fa-icon-margin-h1'
            : 'banner-fa-icon-margin-h3';
        return this.moduleData?.bannerTextAlignment === AffBannerTextAlignment.BANNER_TEXT_LEFT &&
            ((this.moduleData.bannerType === 'shop' && this.moduleData.searchType !== 'cat_zip') ||
                this.moduleData.bannerType === 'simple')
            ? `banner-fa-icon-left banner-fa-icon-left-xxl ${classByHeroFlag}`
            : 'banner-fa-icon-center';
    }

    get bannerContainerClasses() {
        let classes = '';

        classes +=
            this.moduleData?.bannerTextAlignment === AffBannerTextAlignment.BANNER_TEXT_LEFT &&
                ((this.moduleData.bannerType === 'shop' && this.moduleData.searchType !== 'cat_zip') ||
                    this.moduleData.bannerType === 'simple')
                ? ' banner-container-left'
                : '';

        if (!this.moduleData.useAsHero && this.moduleData?.fontAwesomeIcon) {
            classes += this.moduleData?.bannerTextAlignment === AffBannerTextAlignment.BANNER_TEXT_LEFT &&
                ((this.moduleData.bannerType === 'shop' && this.moduleData.searchType !== 'cat_zip') ||
                    this.moduleData.bannerType === 'simple')
                ? ' banner-container-align-left'
                : ' banner-container-align-center';
        } else {
            classes += '';
        }

        return classes;
    }

    get bannerHeadingContainerClasses() {
        let classes = '';

        classes +=
            this.moduleData?.bannerTextAlignment === AffBannerTextAlignment.BANNER_TEXT_LEFT &&
                ((this.moduleData.bannerType === 'shop' && this.moduleData.searchType !== 'cat_zip') ||
                    this.moduleData.bannerType === 'simple')
                ? ' banner-heading-container-left'
                : '';

        return classes;
    }

    get bannerHeadingWrapperClasses() {
        let classes =
            (this.moduleData.buttonCtaText && this.moduleData.bannerType === AffBannerTypes.BANNER_TYPE_SHOP) ||
                (this.moduleData.buttonCta?.title && this.moduleData.bannerType === AffBannerTypes.BANNER_TYPE_SIMPLE)
                ? 'banner-heading-bottom-margin'
                : '';

        classes +=
            this.moduleData?.bannerTextAlignment === AffBannerTextAlignment.BANNER_TEXT_LEFT &&
                ((this.moduleData.bannerType === 'shop' && this.moduleData.searchType !== 'cat_zip') ||
                    this.moduleData.bannerType === 'simple')
                ? ' banner-heading-wrapper-left'
                : '';

        return classes;
    }

    get bannerTitleH3Classes() {
        let classes = '';

        classes +=
            this.moduleData?.bannerTextAlignment === AffBannerTextAlignment.BANNER_TEXT_LEFT &&
                ((this.moduleData.bannerType === 'shop' && this.moduleData.searchType !== 'cat_zip') ||
                    this.moduleData.bannerType === 'simple')
                ? 'banner-heading-h3-left'
                : '';

        return classes;
    }

    get bannerTitleH1Classes() {
        let classes = '';

        classes +=
            this.moduleData?.bannerTextAlignment === AffBannerTextAlignment.BANNER_TEXT_LEFT &&
                ((this.moduleData.bannerType === 'shop' && this.moduleData.searchType !== 'cat_zip') ||
                    this.moduleData.bannerType === 'simple')
                ? ' banner-heading-h1-left'
                : '';

        return classes;
    }

    get bannerSubheadingClasses() {
        let classes = '';

        classes +=
            this.moduleData?.bannerTextAlignment === AffBannerTextAlignment.BANNER_TEXT_LEFT &&
                ((this.moduleData.bannerType === 'shop' && this.moduleData.searchType !== 'cat_zip') ||
                    this.moduleData.bannerType === 'simple')
                ? ' banner-sub-heading-left'
                : '';

        return classes;
    }

    get colorSchemeTextClasses() {
        let classes = '';
        const backgroundColor = this.moduleData?.backgroundColor;

        if (this.moduleData?.backgroundType === AffBackgroundTypes.BACKGROUND_TYPE_COLOR) {
            if (
                backgroundColor === AffBannerBackground.BLUE ||
                backgroundColor === AffBannerBackground.DARKBLUE ||
                backgroundColor === AffBannerBackground.BlueGradient
            ) {
                classes = 'banner-text-color-light';
            } else {
                classes = 'banner-text-color-dark';
            }
        }

        if (this.moduleData?.backgroundType === AffBackgroundTypes.BACKGROUND_TYPE_IMAGE) {
            if (this.moduleData?.colorScheme === AffBannerColorScheme.BANNER_COLOR_DARK) {
                classes = 'banner-text-color-dark';
            } else {
                classes = 'banner-text-color-light';
            }
        }

        return classes;
    }

    colorSchemeButonClasses() {
        let classes = '';
        const backgroundColor = this.moduleData?.backgroundColor;

        if (this.moduleData?.backgroundType === AffBackgroundTypes.BACKGROUND_TYPE_COLOR) {
            if (
                backgroundColor === AffBannerBackground.BLUE ||
                backgroundColor === AffBannerBackground.DARKBLUE ||
                backgroundColor === AffBannerBackground.BlueGradient
            ) {
                classes = 'aff-btn-light-theme';
            } else {
                classes = 'aff-btn-dark-theme';
            }
        }

        if (this.moduleData?.backgroundType === AffBackgroundTypes.BACKGROUND_TYPE_IMAGE) {
            if (this.moduleData?.colorScheme === AffBannerColorScheme.BANNER_COLOR_DARK) {
                classes = 'aff-btn-dark-theme';
            } else {
                classes = 'aff-btn-light-theme';
            }
        }

        return classes;
    }

    getFaIcon(iconStr: nullableString): nullableString {
        if (iconStr) {
            return this._fontAwesomeIconService.getFaIcon(iconStr);
        }
        return '';
    }

    populateSelectOptionValue(data: any) {
        this.selectOptions = data.map((item: any) => {
            const option = {} as Option;
            [option.label, option.value] = [item.name, item.slug];
            return option;
        });
    }
}
