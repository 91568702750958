<header class="navbar-mobile bg-light fixed-top nav-bar-width">
    <nav class="mx-3 my-12 px-2 py-1 mx-md-5 my-md-2 px-md-0 py-md-1 mx-xl-10 px-xl-11 clearfix">
        <a class="float-start m-0 p-0 logo-wrapper-header" href="/">
            <img
                src="../../../assets/images/Logo-Aff.svg"
                alt="American First Finance"
                role="button"
                class="w-100 logo-aff"
                height="28"
            />
        </a>
        <button
            class="p-0 m-0 bg-transparent float-end overflow-visible border-0 hamburger hamburger--squeeze"
            [ngClass]="{ 'is-active': isActive }"
            type="button"
            (click)="toggle()"
        >
            <span class="d-inline-block position-relative hamburger-box">
                <span class="m-0 d-block position-relative top-50 hamburger-inner"></span>
            </span>
        </button>
    </nav>
</header>
<ng-container *ngIf="isActive">
    <div class="w-100 position-fixed nav-dropdown">
        <janet-nav-bar-mobile
            [menus]="menus"
            [mainMenus]="mainMenus"
            [headerMenu]="headerMenu"
        ></janet-nav-bar-mobile>
    </div>
</ng-container>
