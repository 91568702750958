import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  AFF_LOCAL_STORAGE,
  AFF_LOCAL_STORAGE_CONFIGURATION,
  defaultKeysExcluded,
  LocalStorageConfiguration,
  LocalStorageItem,
} from './local-storage.model';

/**
 * Tests if string is a valid JSON object
 */
function isJson( str: string ): boolean {
  try {
    JSON.parse(str);
  } catch ( e: any ) {
    return false;
  }
  return true;
}

@Injectable({
              providedIn: 'root',
            })
export class localStorageService { //NOSONAR
  constructor(
    @Inject(AFF_LOCAL_STORAGE) public storage: Storage,
    @Inject(AFF_LOCAL_STORAGE_CONFIGURATION)
    private _config: LocalStorageConfiguration,
  ) {
  }

  getItem( key: string ): string | null {
    return this.storage.getItem(key);
  }

  setItem( key: string, value: string ): void {
    this.storage.setItem(key, value);
  }

  removeItem( key: string ): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }

  /**
   * See https://stackoverflow.com/questions/17745292/how-to-retrieve-all-localstorage-items-without-knowing-the-keys-in-advance
   */
  getAllLocalStorageItems(): Observable<LocalStorageItem[]> {
    let exludedKeys = [...defaultKeysExcluded];

    if ( this._config && this._config.excludeKeys && this._config.excludeKeys.length ) {
      exludedKeys = [...this._config.excludeKeys, ...defaultKeysExcluded];
    }

    const values: LocalStorageItem[] = Object.entries(this.storage).reduce(
      ( a: LocalStorageItem[], [key, _value]: [string, any] ) => {
        const itemIsExcluded = exludedKeys.reduce(( isExcluded: boolean, excludedKey: string ) => {
          if ( !isExcluded ) {
            isExcluded = key.includes(excludedKey);
          }
          return isExcluded;
        }, false);

        if ( !itemIsExcluded ) {

          /**
           * Parse JSON if possible
           * otherwise return string;
           */
          const value = isJson(_value) ? JSON.parse(_value) : _value;

          a.push({
                   key,
                   value,
                 });
        }

        return a;
      },
      [],
    );

    return of(values);
  }
}
