import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { Inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { catchError, first, tap, switchMap } from 'rxjs/operators';
import { PAGE_DATA_STATE_KEY } from '../app.module';
import { of } from 'rxjs';
import { FillterQueryService } from './filter-query';
import { LOGIN_QUERY } from '../queries/login.query';
import { MERCHANT_SEARCH_QUERY } from '../queries/merchant-search.query';
import { MerchantSearchResultsService } from './merchant-search-results.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from '@janet/corporate/domain';
import { CategoryUtilsService } from './category.service';
import { LOCATIONS_QUERY } from '../queries';
import { LocationService } from '../services/locations.service';
import { environment } from '../../environments/environment';
import { SOP_BASE_URL_PARAMS, SOP_DEALER_INFO_STATE_KEY } from '../../constants/state-keys';
import { ExitPopupService } from './exit-site-confirmation-data.service';

@Injectable({
    providedIn: 'root',
})
export class PageDataService {
    private _browser = true;
    apiKeyResult: any[];
    apiKeyParam: string;
    filters: string;
    popUpData: any;
    constructor(
        private _appollo: Apollo,
        @Inject(PLATFORM_ID) private _platformId: any,
        private _transferState: TransferState,
        private queryFilterService: FillterQueryService,
        private merchatSearch: MerchantSearchResultsService,
        private router: Router,
        private localStorage: LocalStorage,
        @Inject(DOCUMENT) private document: Document,
        private categoryUtilsService: CategoryUtilsService,
        private locationService: LocationService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private exitPopupService: ExitPopupService,
        public route: ActivatedRoute
    ) {
        if (!isPlatformBrowser(this._platformId)) {
            this._browser = false;
        }
    }

    getPageData(slug: string, baseUrl?: any): any {
        const [data, defaultPage, urlParams]: [any, any, any] = [
            this._hasCacheData(),
            slug ? '402-2' : '/',
            new URLSearchParams(slug),
        ];

        let variables: { id?: string; slug?: string } = {};

        let languageCode = null;
        const queryString = slug.split('?')[1];
        if (queryString) {
            const params = queryString.split('&');
            for (let param of params) {
                const [key, value] = param.split('=');
                if (key === 'lang') {
                    languageCode = value;
                    break;
                }
            }
        }
        this.exitPopupService.getPopupData(languageCode).subscribe((popupData) => {
            this.popUpData = popupData;
            this.document?.defaultView?.sessionStorage?.setItem('exitConfPop', JSON.stringify(popupData));
        });

        this.setLocalStorageData(urlParams, slug);
        const [isPreview, pageId, filter]: [any, any, any] = [
            urlParams.get('preview'),
            urlParams.get('page_id'),
            urlParams.get('filter'),
        ];
        let searchKey = urlParams.get('search') || slug.split('search=')[1]?.split('&')[0];

        this.filters = filter;

        if (filter && searchKey.search('&') != -1) {
            searchKey = searchKey.split('&')[0];
        }

        const [pageQuery, currentSlug] = this.queryFilterService.getQueryDetails(slug, isPreview);

        if (data && languageCode !== 'es') {
            return of(data).pipe(first());
        }
        variables = isPreview ? { id: pageId } : { slug: currentSlug.split('?')[0] };
        variables.slug = variables?.slug === '' ? '/' : variables.slug;
        if (isPreview) {
            return this.handleIfStatementPageData(pageQuery, variables, defaultPage);
        } else if (pageQuery === MERCHANT_SEARCH_QUERY) {
            return this.processData(pageQuery, slug, searchKey, defaultPage, data);
        } else if (pageQuery === LOCATIONS_QUERY) {
            //Update logic based on PHP API
            return this.processLocationData(pageQuery, slug, baseUrl);
        } else {
            return this.handleElseStatementPageData(pageQuery, variables, defaultPage);
        }
    }

    setLocalStorageData(urlParams: any, slug: string) {
        if (this.localStorage && urlParams.size > 0) {
            const url = new URL(slug, window.location.origin);
            const params = url.searchParams;
            const json: Record<string, string> = {};

            params.forEach((value, key) => {
                if (key !== 'filter' && key !== 'search') {
                    json[key] = value;
                }
            });

            const queryString = Object.keys(json)
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
                .join('&');

            if (
                queryString &&
                queryString !== '%2F=' &&
                !queryString.endsWith('%2F=') &&
                queryString.trim() !== ''
            ) {
                this.localStorage.setItem('queryparams', queryString);
            }
        }
    }

    handleIfStatementPageData(pageQuery: any, variables: any, defaultPage: any) {
        return this._appollo.mutate({ mutation: LOGIN_QUERY }).pipe(
            switchMap((data: any) => {
                const authToken = data.data.login.authToken;
                return this._appollo
                    .query<any>({
                        query: pageQuery,
                        variables: variables,
                        context: {
                            headers: {
                                Authorization: `Bearer ${authToken}`,
                            },
                        },
                    })
                    .pipe(
                        tap((data) => this._cachePageData(data)),
                        switchMap((response) => {
                            if (response.data.page) {
                                return of(response);
                            }
                            return this.getPageData(defaultPage);
                        }),
                        catchError((error: any) => {
                            return this.getPageData(defaultPage);
                        })
                    );
            })
        );
    }

    private processData(pageQuery: any, slug: string, searchKey: string, defaultPage: string, data: any) {
        const state = slug.substring(slug.lastIndexOf('/') + 1);
        let results: any = [];
        const finalSearchKey = searchKey || state;
        const finalSearchKeyVal = finalSearchKey.replace('-', ' ');

        const variables = { id: state };
        return this._appollo
            .query<any>({
                query: pageQuery,
                variables: variables,
            })
            .pipe(
                switchMap((respData): any => {
                    const data = { ...respData.data, aResults: [] };
                    const responseData = { data };
                    const categoryDetails = responseData.data.shopCategories.nodes.map((category: any) => ({
                        slug: category.slug,
                        apiKey: category.ShopCategoryAPIKey.apiKey,
                    }));

                    this.apiKeyParam = categoryDetails
                        .map((data: any) => {
                            if (this.filters?.includes(data.slug)) {
                                return data.apiKey;
                            }
                            return '';
                        })
                        .filter((apiKey: string) => apiKey !== '')
                        .join(',');

                    return this.merchatSearch
                        .getSearchResults(finalSearchKeyVal, 1, 20, 50, this.apiKeyParam ? this.apiKeyParam : '')
                        .pipe(
                            switchMap((response): any => {
                                results = response;
                                const isShopData = responseData.data.merchantSearch;
                                responseData.data.aResults = results;
                                if (isShopData) {
                                    this._cachePageData(responseData);
                                    return of(responseData);
                                }
                                return this.getPageData('shop');
                            }),
                            catchError((e) => {
                                console.log(e);
                                return this.getPageData('maintenance');
                            })
                        );
                })
            );
    }

    private processLocationData(pageQuery: any, slug: string, baseUrl?: any) {
        const segments = slug
            .split('/')
            .map((item: any) => {
                const questionMarkIndex = item.indexOf('?');
                if (questionMarkIndex !== -1) {
                    return item.substring(0, questionMarkIndex);
                }
                return item;
            })
            .filter((segment: any) => segment.trim() !== '' && segment.trim() !== '.');
        //hashID
        let params: any = { HashId: segments[1] };
        // Consider dealerID
        if (segments.length > 2) {
            params = { DealerPdNo: segments[2] };
        }

        if (baseUrl) {
            const searchTerm = 'americanfirstfinance';
            if (!baseUrl.toString().trim().toLowerCase().includes(searchTerm)) {
                params['BaseUrl'] = baseUrl;
            }
        }

        if (isPlatformServer(this.platformId)) {
            this._transferState?.set(SOP_BASE_URL_PARAMS, params);

            console.log('sop page dealer info params server (PageDataService) --> ');
            console.log(params);
        }

        let queryString = slug?.split('?')[1];

        const dealerInfoURL = `${environment.locationsBaseURL}dealer-info`;
        return this.locationService.getDealerInfo(dealerInfoURL, params).pipe(
            switchMap((dealerInfo: any) => {
                if (dealerInfo && isPlatformServer(this.platformId)) {
                    this._transferState?.set(SOP_DEALER_INFO_STATE_KEY, dealerInfo);
                }

                const searchKey = dealerInfo.slug ? dealerInfo.slug : 'default';
                let languageCode: any = null;
                if (dealerInfo?.bBranded && queryString) {
                    const langParam = new URLSearchParams(queryString);
                    if (langParam?.has('lang')) {
                        languageCode = langParam?.get('lang')?.toString()?.toLowerCase();
                    } else {
                        languageCode = dealerInfo?.sLang?.toString()?.toLowerCase() ?? null;
                    }
                    languageCode = languageCode?.toString()?.toLowerCase() === 'sp' ? 'es' : languageCode;
                }

                return this._appollo
                    .query<any>({
                        query: pageQuery,
                        variables: {
                            search: searchKey,
                            language: languageCode,
                        },
                    })
                    .pipe(
                        switchMap((response) => {
                            //Integrate nearby or search API here
                            const locationsData = this.manipulateLocationResponse(
                                segments,
                                searchKey,
                                dealerInfo,
                                response
                            );
                            this._cachePageData(locationsData);
                            return of(locationsData);
                        }),
                        catchError((e) => {
                            console.log(e);
                            return this.getPageData('maintenance');
                        })
                    );
            }),
            catchError((e) => {
                console.log(e);
                return this.getPageData('maintenance');
            })
        );
    }

    handleElseStatementPageData(pageQuery: any, variables: any, defaultPage: any) {
        return this._appollo
            .query<any>({
                query: pageQuery,
                variables: variables,
            })
            .pipe(
                tap((data) => this._cachePageData(data)),
                switchMap((response) => {
                    const isShopData = response.data.shopBy;
                    if (isShopData) {
                        return of(response);
                    }
                    const isPageData = response.data.pageBy;
                    if (isPageData) {
                        return of(response);
                    }
                    return this.getPageData(defaultPage);
                }),
                catchError((e) => {
                    return this.getPageData(defaultPage);
                })
            );
    }

    processAndFilterCategoryDetails(
        storedCategoryDetails: string | null | undefined,
        filterResults: string[]
    ): any[] {
        if (typeof storedCategoryDetails === 'string' && storedCategoryDetails) {
            const parsedCategoryDetails = JSON.parse(storedCategoryDetails);
            return this.categoryUtilsService.filterCategoryDetails(parsedCategoryDetails, filterResults);
        }

        return [];
    }
    manipulateLocationResponse(sgmts: any, searchKey: any, dealerInfoResponse: any, queryResponse: any) {
        //NOSONAR
        const locationsPageDetails = {
            isLocationPage: true,
            isDealerLocationPage: false,
            isBrandDealer: false,
        };
        const segments = sgmts
            .map((item: any) => {
                const questionMarkIndex = item.indexOf('?');
                if (questionMarkIndex !== -1) {
                    return item.substring(0, questionMarkIndex);
                }
                return item;
            })
            .filter((segment: any) => segment.trim() !== '' && segment.trim() !== '.');

        if (segments && segments?.length > 0) {
            if (segments.length > 2 && segments[0] === 'locations' && segments[1] === 'd' && !isNaN(segments[2])) {
                locationsPageDetails['isDealerLocationPage'] = true;
                if (dealerInfoResponse?.bBranded) {
                    locationsPageDetails['isBrandDealer'] = true;
                }
            } else if (segments.length === 2) {
                if (segments[0] === 'locations') {
                    if (segments[1] === 'd') {
                        locationsPageDetails['isDealerLocationPage'] = false;
                        locationsPageDetails['isBrandDealer'] = false;
                        if (dealerInfoResponse?.bBranded) {
                            locationsPageDetails['isDealerLocationPage'] = true;
                            locationsPageDetails['isBrandDealer'] = true;
                        }
                    } else {
                        locationsPageDetails['isDealerLocationPage'] = true;
                        if (dealerInfoResponse?.bBranded) {
                            locationsPageDetails['isBrandDealer'] = true;
                        }
                    }
                }
            } else if (segments.length === 1 && segments[0] === 'locations') {
                locationsPageDetails['isDealerLocationPage'] = false;
                locationsPageDetails['isBrandDealer'] = false;
                if (dealerInfoResponse?.bBranded) {
                    locationsPageDetails['isDealerLocationPage'] = true;
                    locationsPageDetails['isBrandDealer'] = true;
                }
            } else {
                console.log('Unknown path structure');
            }
            const data = {
                ...queryResponse.data,
                dealerInfoData: dealerInfoResponse,
                locationsPageDetails: locationsPageDetails,
            };
            const locationsData = { data };
            return locationsData;
        }
        const data = {
            ...queryResponse.data,
            dealerInfoData: dealerInfoResponse,
            locationsPageDetails: locationsPageDetails,
        };
        const locationsData = { data };
        return locationsData;
    }

    private _cachePageData(data: any) {
        if (!this._browser) {
            this._transferState.onSerialize(PAGE_DATA_STATE_KEY, () => data);
        }
    }

    private _hasCacheData() {
        if (this._browser) {
            return this._transferState.get(PAGE_DATA_STATE_KEY, null);
        }

        return null;
    }
}
