import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
    selector: 'janet-shop-our-partners-dtc-banner',
    standalone: false,
    templateUrl: './shop-our-partners-DTC-banner.component.html',
})
export class ShopOurPartnersDTCBannerComponent {
    _dtcBannerMapSection: boolean = false;
    _dtcBannerStoreListSection: boolean = false;
    _locationsData: any;
    _dealerInfoData: any;

    constructor(private _cdr: ChangeDetectorRef) { }

    @Input()
    set dtcBannerMapSection(_isDtcMapSection: any) {
        this._dtcBannerMapSection = _isDtcMapSection;
    }

    @Input()
    set dtcBannerStoreListSection(_isDtcStoreListSection: any) {
        this._dtcBannerStoreListSection = _isDtcStoreListSection;
    }

    @Input()
    set locationsData(data: any) {
        this._locationsData = data;
        this._cdr.detectChanges();
    }

    @Input()
    set dealerInfoData(data: any) {
        this._dealerInfoData = data;
    }
}
