<div class="merchant-stores-content-container">
    <div class="card-container-mobile position-relative" (click)="onStoresContainerClick($event)">
        <div class="store-card-highlighter-mobile d-none" #highlighter></div>
        <div class="col-12 warning-no-data-mobile m1" *ngIf="_totalRecords <= 0">
            <p class="mb-0">{{ _storeListErrorMessage ? _storeListErrorMessage : "" }}</p>
        </div>
        <ua2-aff-accordion>
            <ng-container *ngFor="let merchantStoreList of _displayListSearchResults; let i = index; let l = last">
                <div
                    [id]="i"
                    class="card-toggle-container-mobile"
                    [ngClass]="{
                        active: merchantStoreList.active,
                        'border border-2 border-secondary': merchantStoreList.active
                    }"
                >
                    <ua2-toggle
                        accordionlist="true"
                        isSubMenuExist="false"
                        title="{{ merchantStoreList?.PDNAM1 }}"
                        [distance]="merchantStoreList?.DISTANCE ? merchantStoreList.DISTANCE : ''"
                        bullet="{{ ' • ' }}"
                        subTitle="{{ merchantStoreList.PDADR1 }}"
                        [classes]="'m-0 p-3'"
                        [addSpacing]="true"
                        [active]="merchantStoreList.active"
                        (setStorelistActive)="setStoreListActive(i)"
                    >
                        <div class="card-container-submenu">
                            <div class="px-3 py-3">
                                <ua2-aff-button
                                    *ngIf="
                                        !(
                                            merchantStoreList?.DTYPE?.toString()?.trim()?.toLowerCase() ===
                                                'risa' &&
                                            merchantStoreList?.PDST?.toString()?.trim()?.toLowerCase() === 'ca'
                                        )
                                    "
                                    class="display-content"
                                    [url]="getButtonUrl(merchantStoreList?.TARGETURL) + getQueryParams()"
                                    [title]=""
                                    [buttonLabel]="
                                        _dealerInfoData?.bBranded
                                            ? _locationsLanguageLabelsObj?.storeListApplyNowButtonLabel
                                            : 'Apply Now'
                                    "
                                    [target]="'_blank'"
                                    [id]=""
                                    [buttonCustomClass]="'store-button d-flex justify-content-center'"
                                    [setStyleAttribute]="isBrandedDealer()"
                                ></ua2-aff-button>
                                <ua2-aff-button
                                    *ngIf="
                                        merchantStoreList?.DTYPE?.toString()?.trim()?.toLowerCase() === 'risa' &&
                                        merchantStoreList?.PDST?.toString()?.trim()?.toLowerCase() === 'ca'
                                    "
                                    [url]="
                                        getDirectionUrl(merchantStoreList?.LATITUDE, merchantStoreList?.LONGITUDE)
                                    "
                                    target="_blank"
                                    [title]="'Get Directions'"
                                    [buttonLabel]="
                                        _dealerInfoData?.bBranded
                                            ? _locationsLanguageLabelsObj?.getDirectionsLabel
                                            : 'Get Directions'
                                    "
                                    [affButtonIcon]="getFaIcon('fa-diamond-turn-right')"
                                    [showAffButtonIcon]="true"
                                    [buttonCustomClass]="'store-button d-flex justify-content-center'"
                                    class="display-content"
                                    [setStyleAttribute]="isBrandedDealer()"
                                ></ua2-aff-button>
                            </div>
                            <div
                                class="card-text-mobile px-3 pt-2 pb-3"
                                *ngIf="merchantStoreList?.CATEGORY !== ''"
                            >
                                <div class="col-icon">
                                    <i class="fa-light fa-store pe-2 store-mobile-icon"></i>
                                </div>
                                <div class="col-text">{{ merchantStoreList?.CATEGORY }}</div>
                            </div>
                            <div class="card-subtext-mobile px-3 py-2">
                                <div class="col-icon">
                                    <i class="fa-light fa-location-dot pe-2 store-mobile-icon"></i>
                                </div>
                                <div class="col-text mb-2">
                                    {{ merchantStoreList?.PDADR1
                                    }}{{ merchantStoreList?.PDADR2 ? ", " + merchantStoreList?.PDADR2 : "" }}
                                </div>
                            </div>
                            <div class="card-subtext-city-mobile px-3">
                                <span class="state-city-text"
                                    >{{ merchantStoreList?.PDCITY
                                    }}{{ merchantStoreList?.PDST ? ", " + merchantStoreList?.PDST : "" }}
                                    {{ merchantStoreList?.PDZIP }}
                                </span>
                            </div>
                        </div>
                    </ua2-toggle>
                </div>
                <ng-container
                    *ngIf="
                        getScreenWidth < 768 &&
                        ((i === iShowDTCBannerAfterStore && object?.keys(merchantStoreList)?.length !== 0) ||
                            (i < iShowDTCBannerAfterStore && l))
                    "
                >
                    <div class="col-12 ps-0 pe-0 d-none">
                        <janet-shop-our-partners-dtc-banner
                            [dtcBannerStoreListSection]="false"
                        ></janet-shop-our-partners-dtc-banner>
                    </div>
                </ng-container>
            </ng-container>
        </ua2-aff-accordion>
    </div>
</div>
