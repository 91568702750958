import { gql } from 'apollo-angular';

export const MENU_DATA_QUERY = gql`
    query Menus {
        menus {
            nodes {
                menuId
                name
                menuItems(first: 100) {
                    edges {
                        node {
                            label
                            order
                            url
                            target
                            menuIcons {
                                icon
                                fieldGroupName
                            }
                            socialColor {
                                iconColor
                            }
                            parentId
                            id
                            uri
                            title
                            path
                            parentDatabaseId
                            linkRelationship
                            isRestricted
                            description
                            databaseId
                            cssClasses
                            ctaNavFields {
                                fieldGroupName
                                menuType
                                secondaryMenuHeading
                                subHeading
                                tertiaryMenu {
                                    listItemType
                                    description
                                    fieldGroupName
                                    heading
                                    icon
                                    link {
                                        target
                                        title
                                        url
                                    }
                                }
                            }
                            childItems {
                                nodes {
                                    url
                                    uri
                                    title
                                    target
                                    path
                                    parentId
                                    parentDatabaseId
                                    order
                                    menuIcons {
                                        fieldGroupName
                                        icon
                                    }
                                    menu {
                                        node {
                                            count
                                            databaseId
                                            id
                                            isRestricted
                                            name
                                            slug
                                            menuItems {
                                                nodes {
                                                    url
                                                    uri
                                                    title
                                                    target
                                                    path
                                                    parentId
                                                    parentDatabaseId
                                                    order
                                                    label
                                                    isRestricted
                                                    id
                                                    databaseId
                                                    description
                                                    cssClasses
                                                    childItems {
                                                        nodes {
                                                            url
                                                            uri
                                                            title
                                                            target
                                                            path
                                                            parentId
                                                            parentDatabaseId
                                                            order
                                                            menuItemId
                                                            label
                                                            isRestricted
                                                            id
                                                            description
                                                            databaseId
                                                            cssClasses
                                                            childItems {
                                                                nodes {
                                                                    id
                                                                    description
                                                                    label
                                                                    order
                                                                    url
                                                                    uri
                                                                    title
                                                                    target
                                                                    path
                                                                    isRestricted
                                                                    databaseId
                                                                    cssClasses
                                                                    childItems {
                                                                        nodes {
                                                                            id
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ctaNavFields {
                                        fieldGroupName
                                        menuType
                                        secondaryMenuHeading
                                        subHeading
                                        tertiaryMenu {
                                            listItemType
                                            description
                                            fieldGroupName
                                            icon
                                            heading
                                            link {
                                                target
                                                title
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                headerMenu {
                    fieldGroupName
                    headerFindAStore {
                        target
                        title
                        url
                    }
                    headerFindAStoreStyle {
                        ctaIcon
                        ctaType
                        ctaVariant
                    }
                }
                footerMenu {
                    disclaimerText
                    fieldGroupName
                    footerFindAStore {
                        target
                        title
                        url
                    }
                    footerFindAStoreStyle {
                        ctaIcon
                        ctaVariant
                    }
                }
            }
        }
    }
`;
