<div class="merchant-stores-content-container m-0 p-0 ms-3 ms-md-0">
    <div
        class="row merchant-store-lists-cont mb-3 mb-md-0 me-md-2 position-relative"
        (click)="onStoresContainerClick($event)"
    >
        <div class="store-card-highlighter d-none" #highlighter></div>
        <div *ngIf="_totalRecords <= 0" class="col-12 warning-no-data mt-3">
            <p class="mb-0">{{ _storeListErrorMessage ? _storeListErrorMessage : "" }}</p>
        </div>

        <ng-container
            *ngFor="
                let merchantStoreList of getExtendedDisplayListSearchResults();
                let i = index;
                let e = even;
                let o = odd
            "
        >
            <div
                class="col-12 col-xxl-6 card-container ps-0 pe-0"
                [ngClass]="{
                    'highlighted-block': selectedMarkerIndexVal === i && paginationClicked,
                    'no-border-container': !merchantStoreList.PDNAM1,
                    'border border-2 border-secondary': i === selectedMarkerIndexVal
                }"
                (click)="onCardClick(i)"
                [id]="'card_' + i"
            >
                <div class="card card-wrap border-0 rounded-0">
                    <div
                        class="card-body col-md-8"
                        [ngClass]="{
                            'p-3': !(
                                merchantStoreList?.DTYPE?.toString()?.trim()?.toLowerCase() === 'risa' &&
                                merchantStoreList?.PDST?.toString()?.trim()?.toLowerCase() === 'ca'
                            ),
                            'p-3 pe-40':
                                merchantStoreList?.DTYPE?.toString()?.trim()?.toLowerCase() === 'risa' &&
                                merchantStoreList?.PDST?.toString()?.trim()?.toLowerCase() === 'ca'
                        }"
                    >
                        <h5 class="card-title">{{ merchantStoreList?.PDNAM1 }}</h5>
                        <p class="card-text m-0">
                            {{ merchantStoreList?.PDADR1 }}
                            {{ merchantStoreList?.PDCITY ? merchantStoreList?.PDCITY + "," : "" }}
                            {{ merchantStoreList?.PDST }}
                            {{ merchantStoreList?.PDZIP }}
                        </p>
                        <p class="card-text mt-2">{{ merchantStoreList?.CATEGORY }}</p>
                    </div>
                    <div
                        class="merchant-stores-button ps-0 pe-36 top-20"
                        *ngIf="
                            merchantStoreList?.PDNAM1 &&
                            !(
                                merchantStoreList?.DTYPE?.toString()?.trim()?.toLowerCase() === 'risa' &&
                                merchantStoreList?.PDST?.toString()?.trim()?.toLowerCase() === 'ca'
                            )
                        "
                    >
                        <ua2-aff-button
                            class="display-content"
                            [url]="getButtonUrl(merchantStoreList?.TARGETURL) + getQueryParams()"
                            [title]=""
                            [buttonLabel]="
                                _dealerInfoData?.bBranded
                                    ? _locationsLanguageLabelsObj?.storeListApplyButtonLabel
                                    : 'Apply'
                            "
                            [target]="'_blank'"
                            [id]=""
                            [buttonCustomClass]="
                                'store-apply-button px-3 py-25 d-inline-flex flex-row justify-content-center align-items-center aff-button-state aff-button-properties'
                            "
                            [setStyleAttribute]="isBrandedDealer()"
                        ></ua2-aff-button>
                        <p
                            class="miles m-0"
                            *ngIf="
                                merchantStoreList?.DISTANCE !== null && merchantStoreList?.DISTANCE !== undefined
                            "
                        >
                            {{ merchantStoreList?.DISTANCE }} MI
                        </p>
                    </div>
                    <div
                        class="merchant-stores-button-apply ps-0 pe-36 top-20"
                        *ngIf="
                            merchantStoreList?.PDNAM1 &&
                            merchantStoreList?.DTYPE?.toString()?.trim()?.toLowerCase() === 'risa' &&
                            merchantStoreList?.PDST?.toString()?.trim()?.toLowerCase() === 'ca'
                        "
                    >
                        <div class="apply-store-section">
                            <p class="apply-to-store m-0" [style.color]="applyStoreTextColor">
                                {{
                                    _dealerInfoData?.bBranded
                                        ? _locationsLanguageLabelsObj?.applyInStoreLabel
                                        : "Apply In Store"
                                }}
                            </p>
                            <p
                                class="miles m-0"
                                *ngIf="
                                    merchantStoreList?.DISTANCE !== null &&
                                    merchantStoreList?.DISTANCE !== undefined
                                "
                            >
                                {{ merchantStoreList?.DISTANCE }} MI
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container
                *ngIf="
                    getScreenWidth > 1439 &&
                    ((i === iShowDTCBannerAfterStore &&
                        ((object?.keys(merchantStoreList)?.length === 0 &&
                            i - 1 >= 0 &&
                            object?.keys(extendedResults[i - 1])?.length !== 0) ||
                            object?.keys(merchantStoreList)?.length !== 0)) ||
                        (i < iShowDTCBannerAfterStore &&
                            object?.keys(merchantStoreList)?.length !== 0 &&
                            o &&
                            i + 1 < 10 &&
                            object?.keys(extendedResults[i + 1])?.length === 0) ||
                        (i < iShowDTCBannerAfterStore &&
                            object?.keys(merchantStoreList)?.length === 0 &&
                            o &&
                            i - 1 >= 0 &&
                            object?.keys(extendedResults[i - 1])?.length !== 0))
                "
            >
                <div class="col-12 ps-0 pe-0 d-none">
                    <janet-shop-our-partners-dtc-banner
                        [dtcBannerStoreListSection]="false"
                    ></janet-shop-our-partners-dtc-banner>
                </div>
            </ng-container>
            <ng-container
                *ngIf="
                    getScreenWidth > 767 &&
                    getScreenWidth < 1440 &&
                    ((i === iShowDTCBannerAfterStore && object?.keys(merchantStoreList)?.length !== 0) ||
                        (i < iShowDTCBannerAfterStore &&
                            object?.keys(merchantStoreList)?.length !== 0 &&
                            i + 1 < 10 &&
                            object?.keys(extendedResults[i + 1])?.length === 0))
                "
            >
                <div class="col-12 ps-0 pe-0 d-none">
                    <janet-shop-our-partners-dtc-banner
                        [dtcBannerStoreListSection]="false"
                    ></janet-shop-our-partners-dtc-banner>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
