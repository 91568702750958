import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({ providedIn: 'root' })
export class GTMscriptService {
    constructor(private _gtmService: GoogleTagManagerService, private _router: Router) {}
    trackPageNavigation(): void {
        // push GTM oModal layer for every visited page
        this._router.events.forEach((page) => {
            if (page instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: page.url,
                };
                this._gtmService.pushTag(gtmTag);
            }
        });
    }
}
