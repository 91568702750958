import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SanitizeUrlService } from '../../core/services/sanitize-url.service';
import { MenuDataService } from '../../services/menu-data.service';
import { EdgesNode, FooterMenu } from '../../types/menu-data.types';
import { AffBtnColorVariantService, FontAwesomeIconService } from '@janet/corporate/ui-corporate';
import { Subscription } from 'rxjs';

@Component({
    selector: 'janet-social-media',
    templateUrl: './social-media.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaComponent implements OnInit {
    constructor(
        private _menuDataSevice: MenuDataService,
        private _sanitizeUrlService: SanitizeUrlService,
        private _affBtnVariantService: AffBtnColorVariantService,
        private _fontAwesomeIconService: FontAwesomeIconService
    ) {}
    socialIconsMenu$ = this._menuDataSevice.socialIconsMenu$;
    findStoreURL = environment.findStoreURL;
    menuSubscrption$: Subscription;
    footerMenu?: FooterMenu;
    showIcon = false;

    ngOnInit(): void {
        this.getMenuData();
    }

    getMenuData() {
        this.menuSubscrption$ = this._menuDataSevice._menus$.subscribe((menu) => {
            const footerMenu = menu.menus.nodes[1]?.footerMenu;
            this.getFooterMenu(footerMenu);
        });
    }

    sanatizeURL(url: string) {
        return this._sanitizeUrlService.sanitize(url);
    }

    getIconClass(iconNode: EdgesNode): string {
        const icon = JSON.parse(iconNode?.menuIcons?.icon);
        return `fa-${icon.style} fa-${icon.id} fa-fw fa-2x`;
    }

    trackByFn(index: number, item: any): string {
        return item.node.id;
    }

    getFooterMenu(footerMenu: FooterMenu | null | undefined): void {
        this.footerMenu = footerMenu?.footerFindAStore?.url ? footerMenu : undefined;
        this.showIcon = this.footerMenu?.footerFindAStoreStyle?.ctaIcon ? true : false;
    }

    get btnVariantClass(): string {
        return `${this._affBtnVariantService.getColorVariant(
            this.footerMenu?.footerFindAStoreStyle?.ctaVariant
        )} `;
    }

    getFaIcon(iconStr: string | null | undefined): string {
        return this._fontAwesomeIconService.getFaIcon(iconStr);
    }
}
