import { Component, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'ua2-aff-logo',
    templateUrl: './aff-logo.component.html',
    styleUrls: [],
})
export class AffLogoComponent {
    @Input() variant: 'colored' | 'white' = 'colored';
    @Input() includePoweredBy?: boolean = false;
    @Input() defaultPadding?: boolean = false;
    @Input() height?: number = 22;
    @Input() width?: number = 208;
    @Input() viewBox?: string = '0 0 208 22';
    @Input() language = 'en';
}
