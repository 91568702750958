import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'ua2-corporate-tooltip, corporate-tooltip',
    templateUrl: './corporate-tooltip.component.html',
})
export class CorporateTooltipComponent {
    @Input() toolTipLabel = '75040';
    toolTipContainerDefaultClass = '';
    toolTipContainerCustomClass = 'corporate-tooltip position-relative d-inline-block';
    toolTipChildContainerDefaultClass = '';
    toolTipChildContainerCustomClass = 'tooltip-text text-center position-absolute';
    @ViewChild('toolTip', { static: true }) toolTip: ElementRef;
    @ViewChild('tooTipContainer', { static: true }) tooTipContainer: ElementRef<HTMLElement>;
    @Output() toolTipClicked = new EventEmitter<boolean>();

    constructor(private renderer: Renderer2) {}

    onToolTipTextClick(event: MouseEvent): void {
        const clickedElement = event.target as HTMLElement;
        if (clickedElement !== this.tooTipContainer.nativeElement) {
            // Click event occurred inside a child element
            event.stopPropagation();
            return;
        }
        this.toggleTooltip();
        this.toolTipClicked.emit();
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        const clickedElement = event.target as HTMLElement;
        if (!this.tooTipContainer.nativeElement.contains(clickedElement)) {
            this.renderer.removeClass(this.toolTip.nativeElement, 'tool-tip-active');
        }
    }

    toggleTooltip(): void {
        this.toolTip.nativeElement.classList.toggle('tool-tip-active');
    }

    get toolTipContainerClasses(): string {
        const classes = `${this.toolTipContainerDefaultClass} ${this.toolTipContainerCustomClass}`;
        return classes.trim();
    }

    get toolTipChildContainerClasses(): string {
        const classes = `${this.toolTipChildContainerDefaultClass} ${this.toolTipChildContainerCustomClass}`;
        return classes.trim();
    }
}
