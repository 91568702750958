import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    Directive,
    ElementRef,
    Inject,
    Input,
    PLATFORM_ID,
    QueryList,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleEmpty } from '@fortawesome/free-regular-svg-icons';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';

let nextId = 0;

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[affCarouselSlide]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AffCarouselSlide {
    @Input() id = nextId++;

    constructor(public element: ElementRef<any>) {}
}

@Component({
    selector: 'ua2-aff-carousel',
    templateUrl: './aff-carousel.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AffCarouselComponent implements AfterViewInit, AfterContentInit {
    @ContentChildren(AffCarouselSlide) slides: QueryList<AffCarouselSlide>;
    @ViewChild('carouselRef') carouselRef: ElementRef;

    /**
     * Whether to show the dot indicators
     */
    @Input() showIndicators = true;
    /**
     * Whether or not to show the side caret navigators
     */
    @Input() showNavigation = true;

    @Input() carouselContainer?: string = 'carouselContainer';
    @Input() carouselIconsize?: string = 'fa-carousel-icon-size';

    isDesktop$ = this.breakpointObserver.observe([Breakpoints.Web]);
    activeId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    faFilledCircle = faCircle;
    faEmptyCircle = faCircleEmpty;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private document: Document,
        private breakpointObserver: BreakpointObserver
    ) {}

    ngAfterViewInit() {
        /**
         * Sets the activeId based on which slide has the active class
         */
        this.getCurrentSlide();
    }

    ngAfterContentInit() {
        /**
         * Sets the first slide as active and initializes the carousel slide
         */
        if (this.slides && this.slides.length > 0) {
            // Sets the first slide as active and initializes the carousel slide
            const firstSlide = this.slides.first;
            firstSlide.element.nativeElement.classList.add('active');
        }
    }

    getCurrentSlide() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.document.defaultView?.bootstrap) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const carousel = new this.document.defaultView.bootstrap.Carousel(this.carouselRef.nativeElement, {
                pause: false,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                slide: true,
            });
            carousel?.cycle();
        }

        this.carouselRef.nativeElement.addEventListener('slid.bs.carousel', () => {
            const activeItem = this.slides
                .toArray()
                .findIndex((slide: any) => slide.element.nativeElement.classList.contains('active'));
            this.activeId.next(activeItem);
        });
    }
}
