import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'ua2-stores-pagination',
    templateUrl: './stores-pagination.component.html',
    styleUrls: ['./stores-pagination.component.scss'],
})
export class StoresPaginationComponent implements OnInit, OnChanges {
    @Input() totalRecords: any;
    @Input() startIndex: number;
    @Input() endIndex: number;
    storeListData: any[] = [];
    recordsPerPage = 10;
    currentPage = 1;
    startIndexVal: number;
    _paginationLabel = 'Showing results';

    @Output() paginationUpdated: EventEmitter<{
        page: number;
        action: string;
    }> = new EventEmitter<{
        page: number;
        action: string;
    }>();

    ngOnInit() {
        this.calculateIndex();
    }

    @Input()
    set paginationLabel(data: any) {
        if (data) {
            this._paginationLabel = data;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.totalRecords) {
            // Handle startIndex change here
            this.startIndexVal = this.startIndex;
            this.currentPage = 1;
            this.calculateIndex();
        }
    }

    previousPage(clickPage: number) {
        if (this.startIndexVal > 1) {
            this.currentPage = clickPage;
            this.currentPage--;
            this.updatePagination('previous');
        }
    }

    nextPage(clickPage: number) {
        if (this.endIndex < this.totalRecords?.record) {
            this.currentPage = clickPage;
            this.currentPage++;
            this.updatePagination('next');
        }
    }

    calculateIndex() {
        this.endIndex = Math.min(this.startIndexVal + this.recordsPerPage - 1, this.totalRecords?.record);
    }

    updatePagination(action: string) {
        this.startIndexVal = (this.currentPage - 1) * this.recordsPerPage + 1;
        this.endIndex = Math.min(this.startIndexVal + this.recordsPerPage - 1, this.totalRecords?.record);

        this.paginationUpdated.emit({
            page: this.currentPage,
            action,
        });
    }

    getTotalPages(): number {
        return Math.ceil(this.totalRecords?.record / this.recordsPerPage);
    }
}
