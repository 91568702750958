import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { faAngleDown, faAngleRight, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { fadeSlideInLeftAnimation } from '@janet/shared/animations';
import { IMenuDataNew, MenuEdgesArray, TertiaryMenu } from '../../../types/menu-data.types';

@Component({
    selector: 'janet-nav-bar-desktop-single',
    templateUrl: './nav-bar-desktop-single.component.html',
    styleUrls: ['./nav-bar-desktop-single.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeSlideInLeftAnimation()],
})
export class NavBarDesktopSingleComponent implements OnChanges {
    @Input() menu: IMenuDataNew;

    angleDown = faAngleDown;
    angleRight = faAngleRight;
    angleUp = faAngleUp;

    active!: boolean;
    hasTertiaryMenu!: boolean;

    activeMenu: number;
    _activeTertiaryMenu: BehaviorSubject<TertiaryMenu[]> = new BehaviorSubject([] as TertiaryMenu[]);
    activeTertiaryMenu$ = this._activeTertiaryMenu.asObservable().pipe(distinctUntilChanged());

    hoverTimeout: any;

    ngOnChanges({ menu }: SimpleChanges) {
        if (menu) {
            this.hasTertiaryMenu = this.childrenCheck(this.menu.children);
            this.setActiveMenu();
        }
    }

    /**
     * Only sets an active value if the value has changed
     * Prevents this from constantly firing
     *
     * @param {boolean} value
     * @return {*}
     * @memberof NavBarDesktopSingleComponent
     */
    toggleActive(value: boolean) {
        if (value === this.active) return;
        if (!this.hasChildren) return;

        this.active = value;
        this.setActiveMenu();
    }

    setActiveMenu() {
        if (this.hasTertiaryMenu && this.menu.children && this.menu.children.length) {
            this.setTertiaryMenu(0, this.menu.children[0].node.ctaNavFields.tertiaryMenu);
        }
    }

    onSecondaryMenuLeave() {
        if (this.hoverTimeout) clearTimeout(this.hoverTimeout);
    }
    setTertiaryMenu(index: number, menu?: TertiaryMenu[]) {
        this.hoverTimeout = setTimeout(() => {
            if (this.activeMenu === index) return;
            this.activeMenu = index;
            this._activeTertiaryMenu.next(menu || []);
        }, 50);
    }

    childrenCheck(children?: MenuEdgesArray[]) {
        if (!children) return false;
        return !!children.filter((child) => child.node.ctaNavFields?.tertiaryMenu).length;
    }

    get hasChildren() {
        return this.menu?.children && this.menu?.children?.length;
    }
}
