import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffLogoModule, SanitizeHtmlModule } from '@janet/shared/ui-common';
import { LetDirective, PushPipe } from '@ngrx/component';
import { AffSlideToggleComponent } from './aff-slide-toggle/aff-slide-toggle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CorporateButtonModule } from './components/corporate-button/corporate-button.module';
import { AffCardModule } from './components/aff-card/aff-card.module';
import { AffCarouselModule } from './components/aff-carousel/aff-carousel.module';
import { RatingComponent } from './components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BouncyLoaderComponent } from './components/bouncy-loader/bouncy-loader.component';
import { AffAccordionModule } from './components/aff-accordion/aff-accordion.module';
import { AffModalModule } from './components/aff-modal/aff-modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryComponent } from './components/category/category.component';
import { AffButtonComponent } from './components/aff-button/aff-button.component';
import { MapComponent } from './components/map/map.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { CorporateFormFieldComponent } from './components/corporate-form-field/corporate-form-field.component';
import { CorporateCheckBoxComponent } from './components/corporate-check-box/corporate-check-box.component';
import { CorporateSwitchComponent } from './components/corporate-switch/corporate-switch.component';
import { CoporateCategoryIconComponent } from './components/coporate-category-icon/coporate-category-icon.component';
import { AffSearchComponent } from './components/aff-search/aff-search.component';
import { CorporateTooltipComponent } from './components/corporate-tooltip/corporate-tooltip.component';
import { SliderButtonComponent } from './components/slider-button/slider-button.component';
import { SlidePanelComponent } from './components/slide-panel/slide-panel.component';
import { StoresPaginationComponent } from './components/stores-pagination/stores-pagination.component';
import { CorporateFileUploadComponent } from './components/corporate-file-upload/corporate-file-upload.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { CorporateTextareaComponent } from './components/corporate-textarea/corporate-textarea.component';
import { CorporateCardComponent } from './components/corporate-card/corporate-card.component';
import { CorporateRadioButtonComponent } from './components/corporate-radio-button/corporate-radio-button.component';
import { CorporateCalendarComponent } from './components/corporate-calendar/corporate-calendar.component';
import { CorporateDatePickerComponent } from './components/corporate-date-picker/corporate-date-picker.component';
import { CorporateFormRecaptchaComponent } from './components/corporate-form-recaptcha/corporate-form-recaptcha.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { StepperContainerComponent } from './components/stepper-container/stepper-container.component';
import { StepperIndicatorComponent } from './components/stepper-indicator/stepper-indicator.component';
import { NgxMaskDirective } from 'ngx-mask';

/**
 * Note: Shared modules bloat code
 *
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CorporateButtonModule,
        AffLogoModule,
        AffCardModule,
        AffCarouselModule,
        FontAwesomeModule,
        AffAccordionModule,
        AffModalModule,
        TranslateModule,
        MatListModule,
        MatCardModule,
        SanitizeHtmlModule,
        LetDirective,
        PushPipe,
        NgxCaptchaModule,
        NgxMaskDirective,
    ],
    declarations: [
        AffSlideToggleComponent,
        RatingComponent,
        BouncyLoaderComponent,
        CategoryComponent,
        AffButtonComponent,
        MapComponent,
        StarRatingComponent,
        CorporateFormFieldComponent,
        CorporateCheckBoxComponent,
        CorporateSwitchComponent,
        AffSearchComponent,
        CoporateCategoryIconComponent,
        StoresPaginationComponent,
        CorporateTooltipComponent,
        SliderButtonComponent,
        SlidePanelComponent,
        StoresPaginationComponent,
        CorporateFileUploadComponent,
        CorporateTextareaComponent,
        CorporateCardComponent,
        CorporateRadioButtonComponent,
        CorporateCalendarComponent,
        CorporateDatePickerComponent,
        CorporateFormRecaptchaComponent,
        StepperContainerComponent,
        StepperIndicatorComponent,
    ],
    exports: [
        AffSlideToggleComponent,
        CorporateButtonModule,
        AffCardModule,
        AffCarouselModule,
        RatingComponent,
        FontAwesomeModule,
        BouncyLoaderComponent,
        AffAccordionModule,
        AffModalModule,
        CategoryComponent,
        AffButtonComponent,
        MapComponent,
        StarRatingComponent,
        CorporateFormFieldComponent,
        CorporateCheckBoxComponent,
        CorporateSwitchComponent,
        AffSearchComponent,
        CoporateCategoryIconComponent,
        StoresPaginationComponent,
        CorporateTooltipComponent,
        SliderButtonComponent,
        SlidePanelComponent,
        CorporateFileUploadComponent,
        CorporateTextareaComponent,
        CorporateCardComponent,
        CorporateRadioButtonComponent,
        CorporateCalendarComponent,
        CorporateDatePickerComponent,
        CorporateFormRecaptchaComponent,
        StepperContainerComponent,
        StepperIndicatorComponent,
    ],
})
export class CorporateUiCorporateModule { }
