import { gql } from 'graphql-tag';

export const LOGIN_QUERY = gql`
    mutation LoginUser {
        login(
            input: { clientMutationId: "getAuth", username: "aff_preview", password: "O^pM*ub%)i&KWLd6N!VE9fBu" }
        ) {
            authToken
            user {
                id
                name
            }
        }
    }
`;
