import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AffBtnColorVariantService, FontAwesomeIconService } from '@janet/corporate/ui-corporate';

import { HeaderMenu, IMenuDataNew, MenuEdgesArray } from '../../../types/menu-data.types';

@Component({
    selector: 'janet-nav-bar-desktop',
    templateUrl: './nav-bar-desktop.component.html',
    styleUrls: ['./nav-bar-desktop.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarDesktopComponent implements OnChanges {
    @Input() menus: MenuEdgesArray[] = [];
    @Input() mainMenus: IMenuDataNew[] = [];
    @Input() headerMenu?: HeaderMenu;

    _isButton = false;
    showIcon = false;

    constructor(
        public _affBtnVariantService: AffBtnColorVariantService,
        public _fontAwesomeIconService: FontAwesomeIconService
    ) { }

    ngOnChanges({ headerMenu }: SimpleChanges) {
        if (headerMenu) {
            this._isButton = this.headerMenu?.headerFindAStoreStyle?.ctaType === 'Button';
            this.showIcon = !!this.headerMenu?.headerFindAStoreStyle?.ctaIcon;
        }
    }

    get btnVariantClass(): string {
        return `${this._affBtnVariantService.getColorVariant(
            this.headerMenu?.headerFindAStoreStyle?.ctaVariant
        )} `;
    }

    getFaIcon(iconStr: string | null | undefined): string {
        return this._fontAwesomeIconService.getFaIcon(iconStr);
    }
}
