<div *ngIf="menu.length" class="tertiary-container">
    <div class="d-flex flex-wrap">
        <a
            [href]="child?.link?.url"
            target="{{ child?.link?.target || '' }}"
            class="tertiary-child text-reset"
            *ngFor="let child of menu"
            [ngClass]="{ 'view-all': child.heading === 'View All' }"
            @fadeSlideIn
        >
            <span [ngClass]="{ 'icon-offset': !child.icon }">
                <em *ngIf="child.icon" [ngClass]="child.icon | getHeaderIconClass" class="icon-style"></em>
                <span [innerHTML]="child.heading" *ngIf="child.heading && child.icon; else linkTemplate"></span>
                <ng-template #linkTemplate>
                    <span [innerHTML]="child.link.title"></span>
                </ng-template>
                <fa-icon class="angle-right ms-2" [icon]="angleRight"></fa-icon>
            </span>
        </a>
    </div>
</div>
<div *ngIf="cta.length" class="tertiary-cta-container" [ngClass]="{ 'cta-border': menu.length }">
    <div class="d-flex flex-column">
        <a
            [href]="child?.link?.url"
            target="{{ child?.link?.target || '' }}"
            class="tertiary-cta-child text-reset"
            *ngFor="let child of cta"
            @fadeSlideIn
        >
            <h3 [innerHTML]="child.heading"></h3>
            <p>{{ child.description }}</p>
            <span class="text-reset view-all pe-none">
                <span [innerHTML]="child?.link?.title"></span>
                <fa-icon class="angle-right ms-1" [icon]="angleRight"></fa-icon>
            </span>
        </a>
    </div>
</div>
