import { Component, HostListener, Input } from '@angular/core';
import { MerchantSearchDetails } from '../../types/merchant-search.types';

@Component({
    selector: 'janet-merchant-search-hero-banner',
    templateUrl: './merchant-search-hero-banner.component.html',
})
export class MerchantSearchHeroBannerComponent {
    _moduleData: MerchantSearchDetails;
    getScreenWidth: any;

    constructor() {
        this.getScreenWidth = window.innerWidth;
    }

    @Input()
    set moduleData(data: MerchantSearchDetails) {
        this._moduleData = data;
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
    }

    public get heroBannerContainerClass(): string {
        return (this.getScreenWidth <= 767) ? "merchant-search-hero-text-wrapper px-0" : "container merchant-search-hero-text-wrapper";
    }
}
