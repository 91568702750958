import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
    selector: 'janet-shop-our-partners-header',
    standalone: false,
    templateUrl: './shop-our-partners-header.component.html',
})
export class ShopOurPartnersHeaderComponent {
    _options: any = [];
    _locationsPageDetails: any;
    _locationsData: any;
    _dealerInfoData: any;
    _locationsLanguageLabelsObj: any;

    constructor(public _cdr: ChangeDetectorRef,) { }

    @Input()
    set options(data: any) {
        if (data) {
            this._options = data;
            this._cdr.detectChanges();
        }
    }

    @Input()
    set locationsPageDetails(data: any) {
        this._locationsPageDetails = data;
        this._cdr.detectChanges();
    }

    @Input()
    set locationsData(data: any) {
        this._locationsData = data;
    }

    @Input()
    set dealerInfoData(data: any) {
        this._dealerInfoData = data;
    }

    @Input()
    set locationsLanguageLabelsObj(data: any) {
        this._locationsLanguageLabelsObj = data;
    }
}
