import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

export let fileUploadProp: any = {};

@Component({
    selector: 'ua2-corporate-file-upload, aff-corporate-file-upload',
    templateUrl: './corporate-file-upload.component.html',
})
export class CorporateFileUploadComponent implements OnInit {
    @Input() titleText: string;
    @Input() allowedFileTypes: string;
    @Input() maxFileSize: string;
    @ViewChild('uploadFileRef') uploadFileRef: any;
    @ViewChild('selectedFileRef') selectedFileRef: any;
    @Input() control = new FormControl();

    @Input() buttonDefaultClass =
        'text-decoration-none text-center shadow-none border border-0 aff-file-upload-button-state';
    @Input() buttonCustomClass =
        'px-3 py-25 d-inline-flex flex-row justify-content-center align-items-center aff-file-upload-button-state aff-file-upload-button aff-file-upload-button-properties aff-file-upload-label';
    @Input() buttonHostCustomClass = '';
    @Input() titleDefaultClass = 'aff-file-upload-title';
    @Input() titleCustomClass = '';
    @Input() fileSelectionLabelDefaultClass = 'aff-selected-file-label';
    @Input() fileSelectionLabelCustomClass = '';
    @Input() fileSelectionLabelWrapperDefaultClass = 'aff-file-selection-label-wrapper';
    @Input() fileSelectionLabelWrapperCustomClass = '';
    @Input() fileTypesDefaultClass = 'aff-allowed-file-types';
    @Input() fileTypesCustomClass = '';
    @Input() fileSizeDefaultClass = 'text-end aff-max-size';
    @Input() fileSizeCustomClass = '';
    @Input() deleteFileDefaultClass = 'aff-delete-file';
    @Input() deleteFileCustomClass = '';

    isFileSelected = false;
    selectedFileLabel = 'No file selected';
    @Input() buttonText: string = 'Select File';
    initButtonText: string = 'Select File';
    initFileSelectionLabel = 'No file selected';
    @Input() fileUploadFieldId: any;
    @Output() inputValueChange: EventEmitter<any> = new EventEmitter();

    @Input() set buttonLabel(val: string) {
        this.buttonText = this.initButtonText = val;
    }

    ngOnInit() {
        if (this.control?.value?.toString()?.trim()?.length > 0 || this.fileUploadFieldId in fileUploadProp) {
            this.control.setValue('');
            this.selectedFileLabel = fileUploadProp[this.fileUploadFieldId]?.name || '';
            this.isFileSelected = true;
            this.buttonText = 'Replace File';
        }
    }

    onFileChange(event: any): void {
        const regex = /(\.jpg|\.jpeg|\.png)$/i;
        const filePath = this.control.value;
        const fileSize = event?.target?.files[0].size / 1024 / 1024;
        if (!regex.test(filePath)) {
            this.selectedFileLabel = 'Invalid file type, please check again!';
            this.deleteFile(null, this.selectedFileLabel);
            return;
        }
        if (fileSize > +this.maxFileSize) {
            this.selectedFileLabel = 'File size exceeds limit';
            this.deleteFile(null, this.selectedFileLabel);
            return;
        }
        if (event?.target?.files?.length > 0) {
            this.selectedFileLabel = event?.target?.files[0].name;
            this.isFileSelected = true;
            this.buttonText = 'Replace File';
            this.inputValueChange.emit({ obj: event, type: 'upload' });
            fileUploadProp[this.fileUploadFieldId] = event?.target?.files[0];
        }
    }

    deleteFile(event: Event | any, label?: string): void {
        this.selectedFileLabel = label ?? this.initFileSelectionLabel;
        this.isFileSelected = false;
        this.buttonText = this.initButtonText;
        this.control.setValue('');
        this.inputValueChange.emit({ obj: null, type: 'delete' });
        if (this.fileUploadFieldId in fileUploadProp) {
            delete fileUploadProp[this.fileUploadFieldId];
        }
    }
}
