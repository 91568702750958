<div class="m-0 p-0" *ngIf="popUpData">
    <div class="m-0 pt-3 px-3 pb-3 px-md-4 d-flex justify-content-between exit-site-header">
        <h6 class="m-0 p-0 text-capitalize">{{ popUpData.popUpTitle }}</h6>
        <em class="fas fa-times position-relative" (click)="closeModal()"></em>
    </div>
    <hr class="m-0 mb-3 p-0 bg-grey-alpha-500" />
    <div class="m-0 mb-4 mb-md-10 px-3 px-md-4 exit-site-body">
        <p class="m-0 p-0">
            {{ popUpData.popUpText }}
        </p>
    </div>
    <div
        class="m-0 mb-4 mb-md-10 px-3 px-md-4 d-flex flex-wrap flex-md-row-reverse align-items-center justify-content-center exit-site-footer"
    >
        <ua2-aff-button
            class="display-content"
            [url]="externalUrl"
            [target]="'_blank'"
            [title]="popUpData.popUpContinueButton"
            [buttonLabel]="popUpData.popUpContinueButton"
            [buttonCustomClass]="'m-0 mb-3 mb-md-0 py-25 px-3 py-md-4 px-md-4 exit-site-continue-btn'"
            (click)="closeModal()"
            *ngIf="popUpData.showContinueButton"
            #continueBtn
        ></ua2-aff-button>

        <ua2-aff-button
            class="display-content"
            [title]="popUpData.popUpCancelButton"
            [buttonLabel]="popUpData.popUpCancelButton"
            [buttonCustomClass]="'m-0 me-md-10 py-25 px-3 py-md-4 px-md-4 exit-site-cancel-btn'"
            (click)="onCancelBtnClick()"
            *ngIf="popUpData.showCancelButton"
            #cancelBtn
        ></ua2-aff-button>
    </div>
</div>
