import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[janetInlineStyle]',
})
export class InlineStyleDirective implements OnChanges {
    @Input() janetInlineStyle: Map<string, string>;
    elem: ElementRef = this.el.nativeElement;

    constructor(private renderer: Renderer2, private el: ElementRef) {}
    ngOnChanges(changes: SimpleChanges): void {
        if ('janetInlineStyle' in changes) {
            this.setElementStyle(changes['janetInlineStyle'].currentValue);
        }
    }

    setElementStyle(entries: any): void {
        entries.forEach((value: string, key: string) => {
            this.renderer.setStyle(this.elem, key, value);
        });
    }
}
