import { InjectionToken } from '@angular/core';
import { UnrecoverableStateEvent } from '@angular/service-worker';

export interface ServiceWorkerRefreshInterval {
    THIRTY_SECONDS: number;
    ONE_MINUTE: number;
    FIVE_MINUTES: number;
    TEN_MINUTES: number;
    FIFTEEN_MINUTES: number;
    THIRTY_MINUTES: number;
    ONE_HOUR: number;
    SIX_HOURS: number;
}

export const SERVICE_WORKER_REFRESH_INTERVAL: ServiceWorkerRefreshInterval = {
    THIRTY_SECONDS: 30 * 1000,
    ONE_MINUTE: 60 * 1000,
    FIVE_MINUTES: 5 * 60 * 1000,
    TEN_MINUTES: 10 * 60 * 1000,
    FIFTEEN_MINUTES: 15 * 60 * 1000,
    THIRTY_MINUTES: 30 * 60 * 1000,
    ONE_HOUR: 60 * 60 * 1000,
    SIX_HOURS: 6 * 60 * 60 * 1000,
};

export interface ServiceWorkerUpdateConfig {
    /**
     * The time after a touch event or mouse move event is fired to check for updates.
     * Defaults to FIFTEEN_MINUTES minutes.
     */
    intervalTime: number;

    /**
     * Whether to prompt the user to update.
     * Defaults to false.
     * If true, updatesAvailable$ will receive an event
     * when an update is available. It's up to the app development team to develop a
     * strategy for prompting the user.
     */
    promptUserUpdateAvailable: boolean;

    /**
     * The message to display to the user the
     * service worker is not able to recover
     * from an update.
     * Defaults to:
     *  `(event: UnrecoverableStateEvent) =>
     *                 `An error occurred that we cannot recover from:\n${event.reason}\n\nPlease reload the page.`
     */
    unrecoverableMessageFn: (event: UnrecoverableStateEvent) => string;
}

export const SERVICE_WORKER_UPDATE_CONFIG: InjectionToken<Partial<ServiceWorkerUpdateConfig>> = new InjectionToken<
    Partial<ServiceWorkerUpdateConfig>
>('SERVICE_WORKER_UPDATE_CONFIG');

export const createServiceWorkerUpdateConfig = (
    config: Partial<ServiceWorkerUpdateConfig>
): ServiceWorkerUpdateConfig => {
    return {
        intervalTime: config.intervalTime || SERVICE_WORKER_REFRESH_INTERVAL.FIFTEEN_MINUTES,
        promptUserUpdateAvailable: config.promptUserUpdateAvailable || false,
        unrecoverableMessageFn:
            config.unrecoverableMessageFn ||
            ((event: UnrecoverableStateEvent) =>
                `An error occurred that we cannot recover from:\n${event.reason}\n\nPlease reload the page.`),
    };
};

export interface UpdateAvailableEvent {
    type: string;
    current: {
        hash: string;
        appData?: object;
    };
    available: {
        hash: string;
        appData?: object;
    };
}
