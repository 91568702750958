import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DynamicComponentService {
    rootViewContainer: ViewContainerRef;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentRef: ComponentRef<any>;

    setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addDynamicComponent(component: any) {
        this.componentRef = this.rootViewContainer.createComponent(component);
    }
}
