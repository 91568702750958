<div class="m-0 toggle-container" [class.active]="active" [ngClass]="{ 'pb-3': active && addSpacing }">
    <ng-container *ngIf="isSubMenuExist === 'true' && accordionlist === 'false'; else withoutSubMenu">
        <ng-container *ngIf="isSingleToggle; else normalHeader">
            <div [ngClass]="headerContainerClass" [id]="headerId" (click)="onHeaderClick()">
                <h4 class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between text-start"
                    [ngClass]="classes">
                    {{ title }}
                    <ng-container *ngIf="active && showInfoText">
                        <span (click)="onInfoTextClick($event)" [ngClass]="infoTextClass">{{headerInfoText}}</span>
                    </ng-container>
                </h4>
                <em [ngClass]="getToggleIconClass"></em>
            </div>
            <ng-container *ngIf="!active && showadditionalAcrdnInfo">
                <div [innerHTML]="additionalAcrdnInfo  | sanitizeHtml" [ngClass]="additionalAcrdnInfoClass"></div>
            </ng-container>
        </ng-container>
        <ng-template #normalHeader>
            <h6 class="position-relative text-start menu" [ngClass]="classes" (click)="setActive.emit()">
                {{ title }}
                <em class="fa-solid fa-chevron-down float-end"></em>
            </h6>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="accordionlist === 'true'">
        <h6 class="position-relative text-start menu border-bottom" [ngClass]="classes"
            (click)="setStorelistActive.emit()">
            <div class="col-10">
                <span id="content1" class="card-title-mobile">{{ title }}</span>
                <div class="container-mob-accordin">
                    <span *ngIf="distance !== ''" id="content2" class="card-distance-mobile static-text">{{ distance }}
                        MI</span>
                    <span id="content3" *ngIf="distance !== ''"
                        class="card-distance-bullet-mobile ps-2 pe-2 static-text">{{ bullet }}</span>
                    <span id="content4" class="card-sub-title-mobile flex-grow-text">{{ subTitle }}</span>
                </div>
            </div>

            <div class="col-2">
                <em class="fa-solid fa-circle-chevron-down float-end merchant-stores-icon"></em>
            </div>
        </h6>
    </ng-container>
    <ng-template #withoutSubMenu>
        <ng-container *ngIf="accordionlist === 'false' && isSubMenuExist === 'false'">
            <a class="text-reset text-decoration-none" href="{{ titleUrl }}"
                target="{{ titleUrlTarget ? titleUrlTarget : '' }}">
                <h6 class="position-relative text-start menu" [ngClass]="classes" (click)="setActive.emit()">
                    {{ title }}
                </h6>
            </a>
        </ng-container>
    </ng-template>
    <div class="m-0 toggle-content" [ngClass]="{ 'd-none': isSubMenuExist === 'false' && accordionlist === 'false' }"
        #submenu [style.height.px]="active ? submenu.scrollHeight : 0">
        <ng-content></ng-content>
    </div>
</div>