import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DialogData, FeatureFlagToggleComponent } from './feature-flag-toggle.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export interface FeatureFlagDialogConfig {
    width?: number;
    height?: number;
}

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagToggleService {
    constructor(private _dialog: MatDialog, @Inject(DOCUMENT) private _doc: Document) {}

    /**
     *
     * @param config See https://material.angular.io/components/dialog/api#MatDialogConfig
     */
    init(config?: MatDialogConfig) {
        // Defaults that can be overriden
        let _config: MatDialogConfig = {
            minWidth: 320,
            minHeight: 335,
            disableClose: true,
        };

        if (config) {
            _config = {
                ..._config,
                ...config,
            };
        }

        _config.data = <DialogData>{
            dialogHeight: _config.minHeight,
        };

        this._doc.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.shiftKey && e.ctrlKey && e.key === 'F') {
                this._dialog.open(FeatureFlagToggleComponent, _config);
            }
        });
    }
}
