<h1 mat-dialog-title>Feature Flags</h1>
<div
    mat-dialog-content
    class="mat-typography feature-flag-table-container"
    [style.height.px]="data.dialogHeight - 135"
>
    <table aria-describedby="Feature Flag Table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef>Key</th>
            <td mat-cell *matCellDef="let row">{{ row.key }}</td>
        </ng-container>

        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Enabled</th>
            <td mat-cell *matCellDef="let row">
                <aff-feature-flag-boolean [flagListItem]="row"></aff-feature-flag-boolean>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
    <button mat-button (click)="onResetDefaultValues()" cdkFocusInitial>Reset Default Values</button>
</div>
