@if(_isDisplayNativeForm()){
<div
    class="container native-Forms-container row mb-3 mb-md-4 mx-auto"
    [id]="'nativeFormContainer_' + _moduleData.ddGfFormIds + '_' + _moduleIndex"
    #nativeFormContainer
>
    @if(_isPagination){
    <aff-stepper-container
        class="m-0 mb-xxl-6 p-0"
        [stepperContainerCustomClass]="stepperContainerClasses"
        [formData]="structuredFormData?.gFormsData"
        [pageIndex]="currentPageIndex"
        [stepperIndicatorContainerClasses]="_stepperIndicatorContainerCustomClass"
        [stepperIndicatorClasses]="_stepperIndicatorClasses"
        [stepperTitleClasses]="_stepperTitleClasses"
    ></aff-stepper-container>
    }
    <form
        class="m-0 p-0"
        [id]="'native_form_' + _moduleData.ddGfFormIds + '_' + _moduleIndex"
        [formGroup]="nativeFormArray.at(currentPageIndex)"
        (ngSubmit)="onSubmit()"
        novalidate
    >
        @for (section of formPage?.pageSections; let sectionIndex = $index; track (section.type+'_'+sectionIndex))
        { @if(section?.sectionDisplay || _isSectionDisplay(section)){ @if(section?.sectionAccordion &&
        accordionList[currentPageIndex]['currentAccordionNumber'] > 1 && section?.accordionNumber <=
        accordionList[currentPageIndex]['currentAccordionNumber']){
        <ua2-aff-accordion>
            <ua2-toggle
                accordionlist="false"
                isSubMenuExist="true"
                [isSingleToggle]="accordionSingleToggle"
                [ngClass]="section?.sectionClass"
                [title]="section?.label"
                [active]="accordionToggle[currentPageIndex][section?.accordionNumber - 1]"
                [headerId]="'nativeFormAccordion_' + currentPageIndex + '_' + section?.accordionNumber"
                [showInfoText]="
                    section?.accordionNumber === accordionList[currentPageIndex]['currentAccordionNumber']
                "
                [classes]="section?.sectionTitleClass + sectionAccordionHeaderClass"
                [headerInfoText]="headerInfoText"
                [infoTextClass]="section?.infoTextClass"
                [additionalAcrdnInfo]="getSubTitle(section)"
                [showadditionalAcrdnInfo]="
                    accordionList[currentPageIndex]['currentAccordionNumber'] > 1 &&
                    !accordionToggle[currentPageIndex][section?.accordionNumber - 1]
                "
                [additionalAcrdnInfoClass]="section?.sectionSubTitleClass + additionalAcrdnInfoClass"
                [toggleIconClass]="
                    accordionToggle[currentPageIndex][section?.accordionNumber - 1]
                        ? section?.toggleActiveIconClass
                        : section?.toggleInactiveIconClass
                "
            >
                <ng-container *ngTemplateOutlet="formSection"></ng-container>
            </ua2-toggle>
        </ua2-aff-accordion>
        } @else if((section?.sectionAccordion && accordionList[currentPageIndex]['currentAccordionNumber'] === 1 &&
        section?.accordionNumber === 1) || !section?.sectionAccordion) {
        <aff-corporate-card [ngClass]="section?.sectionClass">
            <ng-container *ngTemplateOutlet="formSection"></ng-container>
        </aff-corporate-card>
        }

        <ng-template #formSection>
            @if(section?.label && (!section?.sectionAccordion) || (section?.sectionAccordion &&
            accordionList[currentPageIndex]['currentAccordionNumber'] === 1 && section?.accordionNumber === 1)){
            <ng-container *ngTemplateOutlet="formSectionTitle; context: { section: section }"></ng-container>
            } @if(section?.description && (!section?.sectionAccordion) || (section?.sectionAccordion &&
            accordionList[currentPageIndex]['currentAccordionNumber'] === 1) ||
            (accordionToggle[currentPageIndex][(section?.accordionNumber-1)])){
            <ng-container *ngTemplateOutlet="formSectionSubTitle; context: { section: section }"></ng-container>

            } @if(section?.sectionFields){
            <div class="row">
                @for (sectionField of section?.sectionFields; let sectionFieldIndex = $index; track
                ('sectionField_'+sectionFieldIndex)) { @if(_isActiveFormControl(sectionField?.formFieldName)){
                @switch (sectionField?.__typename?.toString()?.toLowerCase()) { @case('selectfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: sectionField, type: 'singleselect', section: section }
                    "
                ></ng-container>
                }@case('textfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: sectionField, type: 'text', section: section }
                    "
                ></ng-container>
                }@case('websitefield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: sectionField, type: 'url', section: section }
                    "
                ></ng-container>
                }@case('datefield'){
                <ng-container
                    *ngTemplateOutlet="
                        formCalendarField;
                        context: { field: sectionField, type: 'date', section: section }
                    "
                ></ng-container>
                }@case('numberfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: sectionField, type: 'number', section: section }
                    "
                ></ng-container>
                }@case('emailfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: sectionField, type: 'email', section: section }
                    "
                ></ng-container>
                }@case('phonefield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: sectionField, type: 'tel', section: section }
                    "
                ></ng-container>
                }@case('ssnfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: sectionField, type: 'tel', section: section }
                    "
                ></ng-container>
                }@case('zipfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: sectionField, type: 'tel', section: section }
                    "
                ></ng-container>
                }@case('routingnumberfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: sectionField, type: 'tel', section: section }
                    "
                ></ng-container>
                }@case('checkboxfield'){
                <ng-container
                    *ngTemplateOutlet="formCheckBoxField; context: { field: sectionField, section: section }"
                ></ng-container>
                }@case('textareafield'){
                <ng-container
                    *ngTemplateOutlet="formTextAreaField; context: { field: sectionField }"
                ></ng-container>
                }@case ('radiofield') {
                <ng-container *ngTemplateOutlet="formRadioField; context: { field: sectionField }"></ng-container>
                }@case('fileuploadfield'){
                <ng-container
                    *ngTemplateOutlet="formFileUploadField; context: { field: sectionField }"
                ></ng-container>
                } @case ('captchafield') {
                <ng-template #recpatchaField>
                    <aff-corporate-form-recaptcha
                        [ngClass]="sectionField.formFieldClass"
                        [class]="isCaptchaInvisible() ? ' mt-0' : 'd-block mt-4'"
                        [siteKey]="structuredFormData?.gFormSettings?.recaptcha?.publicKey"
                        [isCaptchaInvisible]="isCaptchaInvisible()"
                        [captchaFieldId]="sectionField.id + '_' + _moduleData.ddGfFormIds + '_' + _moduleIndex"
                        [control]="
                            $any(nativeFormArray.at(currentPageIndex).controls[sectionField?.formFieldName])
                        "
                        [showErrorMessage]="
                            $any(
                                nativeFormArray.at(currentPageIndex).controls[sectionField?.formFieldName]?.touched
                            )
                        "
                        [errorMessages]="sectionField.formFieldErrorMessages"
                        [errorMessageClass]="'text-start text-red m-0 position-absolute form-field-err-msg-fnt'"
                        (inputChange)="handleEvents()"
                        (captchaReadyEventEmitter)="onCurrentCaptchaReady()"
                    >
                    </aff-corporate-form-recaptcha>
                </ng-template>
                } } } }
            </div>
            } @if(section?.subSections && section?.subSections?.length > 0 ){ @for (subSection of
            section?.subSections; let subSectionIndex = $index;track ('subSection_'+subSectionIndex)){
            <hr [ngClass]="section?.sectionDividerClass" />
            @if(subSection?.label){
            <ng-container
                *ngTemplateOutlet="formSubSectionTitle; context: { subSection: subSection }"
            ></ng-container>
            } @if(subSection?.content){
            <ng-container
                *ngTemplateOutlet="formSubSectionSubTitle; context: { subSection: subSection }"
            ></ng-container>
            } @if(subSection?.subSectionFields){
            <div class="row">
                @for (subSectionField of subSection?.subSectionFields; let subSectionFieldIndex = $index; track
                ('subSectionField_'+subSectionFieldIndex)) {
                @if(_isActiveFormControl(subSectionField?.formFieldName)){ @switch
                (subSectionField?.__typename?.toString()?.toLowerCase()) { @case('selectfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: subSectionField, type: 'singleselect', section: subSection }
                    "
                ></ng-container>
                }@case('textfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: subSectionField, type: 'text', section: subSection }
                    "
                ></ng-container>
                }@case('websitefield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: subSectionField, type: 'url', section: subSection }
                    "
                ></ng-container>
                }@case('datefield'){
                <ng-container
                    *ngTemplateOutlet="
                        formCalendarField;
                        context: { field: subSectionField, type: 'date', section: subSection }
                    "
                ></ng-container>
                }@case('numberfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: subSectionField, type: 'number', section: subSection }
                    "
                ></ng-container>
                }@case('emailfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: subSectionField, type: 'email', section: subSection }
                    "
                ></ng-container>
                }@case('phonefield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: subSectionField, type: 'tel', section: subSection }
                    "
                ></ng-container>
                }@case('ssnfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: subSectionField, type: 'tel', section: subSection }
                    "
                ></ng-container>
                }@case('zipfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: subSectionField, type: 'tel', section: subSection }
                    "
                ></ng-container>
                }@case('routingnumberfield'){
                <ng-container
                    *ngTemplateOutlet="
                        formGeneralField;
                        context: { field: subSectionField, type: 'tel', section: section }
                    "
                ></ng-container>
                }@case('checkboxfield'){
                <ng-container
                    *ngTemplateOutlet="formCheckBoxField; context: { field: subSectionField, section: subSection }"
                ></ng-container>
                }@case('textareafield'){
                <ng-container
                    *ngTemplateOutlet="formTextAreaField; context: { field: subSectionField }"
                ></ng-container>
                }@case ('radiofield') {
                <ng-container
                    *ngTemplateOutlet="formRadioField; context: { field: subSectionField }"
                ></ng-container>
                }@case('fileuploadfield'){
                <ng-container
                    *ngTemplateOutlet="formFileUploadField; context: { field: subSectionField }"
                ></ng-container>
                } @case ('captchafield') {
                <ng-template #recpatchaField>
                    <aff-corporate-form-recaptcha
                        [ngClass]="subSectionField.formFieldClass"
                        [class]="isCaptchaInvisible() ? ' mt-0' : 'd-block mt-4'"
                        [siteKey]="structuredFormData?.gFormSettings?.recaptcha?.publicKey"
                        [isCaptchaInvisible]="isCaptchaInvisible()"
                        [captchaFieldId]="subSectionField.id + '_' + _moduleData.ddGfFormIds + '_' + _moduleIndex"
                        [control]="
                            $any(nativeFormArray.at(currentPageIndex).controls[subSectionField?.formFieldName])
                        "
                        [showErrorMessage]="
                            $any(
                                nativeFormArray.at(currentPageIndex).controls[subSectionField?.formFieldName]
                                    ?.touched
                            )
                        "
                        [errorMessages]="subSectionField.formFieldErrorMessages"
                        [errorMessageClass]="'text-start text-red m-0 position-absolute form-field-err-msg-fnt'"
                        (inputChange)="handleEvents()"
                        (captchaReadyEventEmitter)="onCurrentCaptchaReady()"
                    >
                    </aff-corporate-form-recaptcha>
                </ng-template>
                } } } }
            </div>
            } } }
        </ng-template>
        } }
        <ng-container *ngIf="showReCaptchaField$ | async">
            <ng-container [ngTemplateOutlet]="recpatchaField"></ng-container>
        </ng-container>

        @if(_isPostCustomField()){
        <aff-corporate-card [ngClass]="formPage?.pageCustomField?.sectionClass">
            @if(formPage?.pageCustomField?.label){
            <ng-container
                *ngTemplateOutlet="formSectionTitle; context: { section: formPage?.pageCustomField }"
            ></ng-container>
            } @if(formPage?.pageCustomField?.description){
            <ng-container
                *ngTemplateOutlet="formSectionSubTitle; context: { section: formPage?.pageCustomField }"
            ></ng-container>
            }

            <ua2-aff-button
                class="p-0 m-0"
                [buttonLabel]="formPage?.pageCustomField?.customFields?.label"
                [isButtonAnchor]="isButtonAnchor"
                [buttonCustomClass]="btnCustomClass('next', 'add')"
                [buttonType]="'button'"
                (buttonClickEvt)="addSections($event)"
            >
            </ua2-aff-button>
            <p *ngIf="existingSectionInvalid" class="text-danger">{{ invalidErrorMsg }}</p>
        </aff-corporate-card>
        } @if(formPage?.pageConsent){
        <aff-corporate-card [ngClass]="formPage?.pageConsent?.sectionClass">
            @if(formPage?.pageConsent?.label){
            <ng-container
                *ngTemplateOutlet="formSectionTitle; context: { section: formPage?.pageConsent }"
            ></ng-container>
            } @if(formPage?.pageConsent?.description){
            <p
                [innerHTML]="getPageConsentDescription(formPage?.pageConsent) | sanitizeHtml"
                [ngClass]="formPage?.pageConsent?.sectionSubTitleClass"
                class="text-break"
            ></p>
            } @if(getCheckBoxFormFieldType(formPage?.pageConsent?.__typename)){
            <ng-container
                *ngTemplateOutlet="formCheckBoxField; context: { field: formPage?.pageConsent }"
            ></ng-container>
            } @if(formPage?.pageConsent?.description){
            <p
                [innerHTML]="getPageConsentTerms(formPage?.pageConsent) | sanitizeHtml"
                class="consent-terms m-0 p-0 mt-4 mt-md-10 text-break"
            ></p>
            }
        </aff-corporate-card>
        } @if(formPage?.pageButton){
        <aff-corporate-card [ngClass]="buttonCardClass">
            @if(currentPageIndex !== 0 && _isPagination){
            <ua2-aff-button
                class="p-0 m-0"
                [buttonLabel]="formPage?.pageButton?.previousButton?.text"
                [isButtonAnchor]="isButtonAnchor"
                [buttonCustomClass]="btnCustomClass('previous')"
                [buttonType]="getButtonType('previous')"
                (buttonClickEvt)="previousPage($event)"
            ></ua2-aff-button>
            }

            <ua2-aff-button
                class="p-0 m-0"
                [buttonLabel]="formPage?.pageButton?.nextButton?.text"
                [isButtonAnchor]="isButtonAnchor"
                [buttonCustomClass]="btnCustomClass('next')"
                [buttonType]="getButtonType('next')"
                [disabled]="!isCurrentPageValid() || isFormSubmitted()"
                (buttonClickEvt)="nextPage($event)"
            ></ua2-aff-button>
        </aff-corporate-card>
        }
    </form>
</div>

<!------------------------------ Content Templates -------------------------------------------->
<!--Title-->
<ng-template #formSectionTitle let-section="section">
    <h4 [ngClass]="section?.sectionTitleClass" [class.text-start]="section?.sectionAccordion || !_isPagination">
        {{ section?.label }}
    </h4>
</ng-template>

<!--Sub-title-->
<ng-template #formSectionSubTitle let-section="section">
    <p
        [innerHTML]="getSubTitle(section) | sanitizeHtml"
        [ngClass]="section?.sectionSubTitleClass"
        [class.text-start]="section?.sectionAccordion || !_isPagination"
    ></p>
</ng-template>

<!--Sub-Section Title-->
<ng-template #formSubSectionTitle let-subSection="subSection">
    <h5 [ngClass]="subSection?.subSectionTitleClass">{{ subSection?.label }}</h5>
</ng-template>

<!--Sub-Section SubTitle-->
<ng-template #formSubSectionSubTitle let-subSection="subSection">
    <p [innerHTML]="subSection?.content" [ngClass]="subSection?.subSectionSubTitleClass"></p>
</ng-template>

<!--Check Box Field-->
<ng-template #formCheckBoxField let-field="field" let-section="section">
    <aff-corporate-check-box
        [ngClass]="field?.formFieldClass"
        [checkBoxLabel]="
            field?.sectionFieldLabel ? field?.sectionFieldLabel : field?.formFieldOptions[0]?.label ?? ''
        "
        [checkBoxId]="field?.id + '_' + _moduleData.ddGfFormIds + '_' + _moduleIndex"
        [checkBoxContainerCustomClass]="field?.formFieldInputContainerClass"
        [useNgModel]="false"
        [checkBoxLabelCustomClass]="field?.formFieldInputLabelClass"
        [control]="$any(nativeFormArray?.at(currentPageIndex)?.controls[field?.formFieldName])"
        (componentCreated)="populateFormFieldValues($event, section, field, 'componentCreated')"
        (formControlChange)="onCheckBoxValueChange($event, section, field)"
    ></aff-corporate-check-box>
</ng-template>

<!--Genearl Fields-->
<ng-template #formGeneralField let-field="field" let-type="type" let-section="section">
    <aff-corporate-form-field
        [ngClass]="field.formFieldClass"
        [formFieldContainerCustomClass]="getFormFieldContainerClass(field)"
        [formFieldType]="type"
        [formFieldLabel]="formLabel(field)"
        [formFieldPlaceholder]="formLabel(field)"
        [formFieldId]="field?.id + '_' + _moduleData.ddGfFormIds + '_' + _moduleIndex"
        [formFieldCustomClass]="getFormFieldCustomClass(field, section)"
        formFieldLabelCustomClass="form-label"
        [floatingLabelTextClass]="field?.formFieldFloatingLabelTextClass"
        [floatingLabelText]="getFloatingLabelText(field)"
        [singleSelectoptions]="field?.formFieldOptions"
        [isAutoClickSingleSelection]="field?.formFieldSelectedDefault"
        [mask]="field?.formFieldMask"
        [hideInputText]="field?.formFieldInputTextHide"
        [passwordType]="field?.isPasswordType"
        (passwordTypeChange)="onPasswordTypeChange($event, field)"
        [control]="$any(nativeFormArray?.at(currentPageIndex)?.controls[field?.formFieldName])"
        [showErrorMessage]="
            section?.sectionAccordion &&
            accordionList[currentPageIndex]['currentAccordionNumber'] > 1 &&
            !accordionToggle[currentPageIndex][section?.accordionNumber - 1]
                ? false
                : $any(nativeFormArray?.at(currentPageIndex)?.controls[field?.formFieldName]?.touched)
        "
        [errorMessages]="field?.formFieldErrorMessages"
        [errorMessageClass]="'text-start text-red m-0 position-absolute form-field-err-msg-fnt mt-2'"
    >
    </aff-corporate-form-field>
</ng-template>

<!--Text Area Field-->
<ng-template #formTextAreaField let-field="field">
    <aff-corporate-textarea
        [ngClass]="field?.formFieldClass"
        textAreaContainerCustomClass="text-area-container form-floating"
        [textAreaLabel]="formLabel(field)"
        [textAreaPlaceholder]="formLabel(field)"
        [textAreaId]="field?.id + '_' + _moduleData.ddGfFormIds + '_' + _moduleIndex"
        textAreaCustomClass="form-control"
        textAreaLabelCustomClass="form-label"
        [control]="$any(nativeFormArray.at(currentPageIndex).controls[field?.formFieldName])"
        [showErrorMessage]="$any(nativeFormArray.at(currentPageIndex).controls[field?.formFieldName]?.touched)"
        [errorMessages]="field?.formFieldErrorMessages"
        [errorMessageClass]="'text-start text-red m-0 position-absolute form-field-err-msg-fnt mt-2'"
    >
    </aff-corporate-textarea>
</ng-template>

<!--Radio Field-->
<ng-template #formRadioField let-field="field">
    <aff-corporate-radio-button
        [ngClass]="field?.formFieldClass"
        [labelText]="field?.label"
        [name]="field?.formFieldName"
        [radioFieldOptions]="field?.formFieldOptions"
        [floatingLabelTextClass]="field?.formFieldFloatingLabelTextClass"
        [floatingLabelText]="getFloatingLabelText(field)"
        [radioBtnId]="_moduleData.ddGfFormIds + '_' + _moduleIndex"
        [control]="$any(nativeFormArray?.at(currentPageIndex)?.controls[field?.formFieldName])"
    ></aff-corporate-radio-button>
</ng-template>

<!--File Upload Field-->
<ng-template #formFileUploadField let-field="field">
    <aff-corporate-file-upload
        [titleText]="getFloatingLabelText(field)"
        [allowedFileTypes]="field?.allowedExtensions?.join(', ')"
        [fileUploadFieldId]="field?.formFieldName + '_' + _moduleData.ddGfFormIds + '_' + _moduleIndex"
        [maxFileSize]="field?.maxFileSize"
        [control]="$any(nativeFormArray.at(currentPageIndex)?.controls[field?.formFieldName])"
        (inputValueChange)="onFileInputValueChange($event, field?.formFieldName)"
    >
    </aff-corporate-file-upload>
</ng-template>

<!--Calendar Field-->
<ng-template #formCalendarField let-field="field" let-type="type" let-section="section">
    <aff-corporate-date-picker
        [ngClass]="field?.formFieldClass"
        formFieldLabelCustomClass="form-label"
        [formFieldCustomClass]="getFormFieldCustomClass(field, section)"
        [formFieldLabel]="formLabel(field)"
        [formFieldPlaceholder]="formLabel(field)"
        [formFieldId]="field?.id + '_' + _moduleData.ddGfFormIds + '_' + _moduleIndex"
        [control]="$any(nativeFormArray.at(currentPageIndex)?.controls[field?.formFieldName])"
        [showErrorMessage]="
            section?.sectionAccordion &&
            accordionList[currentPageIndex]['currentAccordionNumber'] > 1 &&
            !accordionToggle[currentPageIndex][section?.accordionNumber - 1]
                ? false
                : $any(nativeFormArray?.at(currentPageIndex)?.controls[field?.formFieldName]?.touched)
        "
    >
    </aff-corporate-date-picker>
</ng-template>
}
