import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GOOGLE_MAP_API_KEY, GOOGLE_API_URL } from '@janet/shared/domain';
import { DOCUMENT } from '@angular/common';
import { AddressComponent, LocationData, Result } from '../types/google-api.types';
import { ActivatedRoute } from '@angular/router';
import { getCountryCode } from '../validators/zip-search-results.validator';
import { MerchantFilterService } from './merchant-filter.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GoogleApiService {
    googleApiUrl = GOOGLE_API_URL;
    googleApiKey = GOOGLE_MAP_API_KEY;
    private _stateNameStatusEmitter = new BehaviorSubject<boolean>(false);
    readonly stateNameStatusEmitter$ = this._stateNameStatusEmitter.asObservable();
    stateNameStatus: boolean;

    constructor(
        private http: HttpClient,
        @Inject(DOCUMENT) private document: Document,
        public route: ActivatedRoute,
        private _merchantFilterService: MerchantFilterService
    ) { }

    getGoogleApiResponse(
        zipcode: number | string,
        category?: string | [],
        isOnLoad?: boolean,
        urlStateName?: string
    ) {
        const address = (zipcode as string).trim().length > 0 ? zipcode : '';
        const searchParam = this.route.snapshot.queryParamMap.get('search');
        this.http
            .get(
                `${this.googleApiUrl}?components=postal_code:${address}|country:${getCountryCode(address)}&key=${this.googleApiKey
                }`
            )
            .subscribe(
                (res) => {
                    const response = <LocationData>res;
                    const result = response?.results?.find((object: Result) => object?.address_components);
                    if (result) {
                        if (this.document.defaultView?.sessionStorage && (zipcode as string).trim().length > 0) {
                            this.document.defaultView.sessionStorage.setItem('zipCode', String(zipcode));
                        }
                        let stateName: any = '',
                            shortCode: any = '';
                        if (getCountryCode(address) === 'PR') {
                            stateName = 'puerto rico';
                            shortCode = 'pr';
                        } else {
                            const administrativeAreaLevel1 = result?.address_components?.find(
                                (item: AddressComponent) =>
                                    item?.types
                                        ?.map((type: string) => type.toLowerCase())
                                        .includes('administrative_area_level_1')
                            );

                            stateName = administrativeAreaLevel1?.long_name?.toLowerCase();
                            shortCode = administrativeAreaLevel1?.short_name?.toLowerCase();
                        }

                        this.setOnloadStatus(searchParam, stateName, shortCode, zipcode, category, isOnLoad, urlStateName);
                    } else {
                        this._stateNameStatusEmitter.next(true);
                    }
                },
                (error): void => {
                    console.log(error);
                }
            );
    }

    setOnloadStatus(searchParam: any, stateName: any, shortCode: any, zipcode: number | string, category?: string | [], isOnLoad?: boolean, urlStateName?: string) {
        if (searchParam === null) {
            isOnLoad = false;
        } else {
            const getSessionShCode =
                this.document.defaultView?.sessionStorage?.getItem('stateShortCode');
            if (getSessionShCode === shortCode) {
                this.stateNameStatus = true;
                isOnLoad = true;
                this._stateNameStatusEmitter.next(this.stateNameStatus);
            } else {
                this.stateNameStatus = false;
                isOnLoad = false;
            }
        }
        this.handlePageRedirect(searchParam, stateName, shortCode, zipcode, category, isOnLoad, urlStateName);
    }

    handlePageRedirect(searchParam: any, stateName: any, shortCode: any, zipcode: number | string, category?: string | [], isOnLoad?: boolean, urlStateName?: string) {
        if (stateName && stateName.trim().length > 0) {
            if (this.document.defaultView?.sessionStorage) {
                this.document.defaultView.sessionStorage.setItem('stateName', String(stateName));
                this.document.defaultView.sessionStorage.setItem(
                    'stateShortCode',
                    String(shortCode)
                );
                this._merchantFilterService.sessionStorageSubject(String(stateName));
            }
            if (!isOnLoad) {
                this.redirectToShopPageUrl(zipcode, stateName, category);
            }
            if (isOnLoad && urlStateName) {
                const sessionStateName =
                    this.document?.defaultView?.sessionStorage?.getItem('stateName');

                if (sessionStateName && urlStateName !== sessionStateName?.toLowerCase()) {
                    this.redirectToShopPageUrl(zipcode, stateName, category);
                }
            }
        }
    }

    redirectToShopPageUrl(zipcode: number | string, stateName: string, category?: string | []) {
        const address = (zipcode as string).trim().length > 0 ? zipcode : '';
        const baseUrl = `${location.origin}/shop/${stateName.replace(/\s+/g, '-')}?search=${address}`;
        if (category) {
            if (typeof category === 'string') {
                this.document.location.href = `${baseUrl}&filter=${category.replace(/\s+/g, '-').toLowerCase()}`;
            } else if (Array.isArray(category) && category.length > 0) {
                this.document.location.href = `${baseUrl}&filter=${category
                    .toString()
                    .replace(/\s+/g, '-')
                    .toLowerCase()}`;
            } else {
                this.document.location.href = baseUrl;
            }
        } else {
            this.document.location.href = baseUrl;
        }
    }
}
