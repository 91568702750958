import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { faAngleDown, faAngleRight, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';

import { MenuDataService } from '../../../services/menu-data.service';
import { HeaderMenu, IMenuDataNew, MenuEdgesArray } from '../../../types/menu-data.types';

@Component({
    selector: 'janet-nav-bar',
    templateUrl: './nav-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent implements OnInit, OnDestroy {
    menus: MenuEdgesArray[] = [];
    mainMenus: IMenuDataNew[] = [];
    headerMenu: HeaderMenu;

    angleDown = faAngleDown;
    angleRight = faAngleRight;
    angleUp = faAngleUp;

    menuSubscrption: Subscription;

    constructor(public _menuDataSevice: MenuDataService) { }

    ngOnInit(): void {
        this.getMenuData();
    }

    getMenuData() {
        this.menuSubscrption = this._menuDataSevice._menus$.subscribe((menu) => {
            this.getHeaderMenu(menu.menus.nodes[2].headerMenu);

            const mainMenu = menu.menus.nodes[2].menuItems['edges'];
            const data = mainMenu.map((item: MenuEdgesArray) => {
                return { ...item, selected: false };
            });
            this.mainMenus = this._menuDataSevice.getTree(data, 'id', 'parentId', 'header');

            this.menus = menu?.menus?.nodes[0]?.menuItems['edges'];
        });
    }

    ngOnDestroy(): void {
        this.menuSubscrption.unsubscribe();
    }

    private getHeaderMenu(headerMenu: HeaderMenu): void {
        this.headerMenu = headerMenu;
    }
}
