import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { QueryParams } from '@janet/corporate/domain';

@Component({
    selector: 'janet-merchant-stores',
    templateUrl: './merchant-stores.component.html',
})
export class MerchantStoresComponent {
    @Input() displayListSearchResults: any[] = [];
    @Input() storeListSearchResults: any[] = [];
    @Input() totalRecords: any;
    @Input() zipcode: number;
    @Input() selectedMarkerIndex: number;
    @Input() paginationClicked: boolean;
    currentPage: number;
    selectedMarkerIndexVal: number;
    clickedCard: HTMLElement;
    @ViewChild('highlighter', { static: false }) highlighter: ElementRef<any>;

    @Output() displayListSearchResultsChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedMarkerIndexValEmitter: EventEmitter<number> = new EventEmitter<number>();

    constructor(private queryParams: QueryParams) {}
    getQueryParams() {
        return this.queryParams.getQueryParams();
    }
    getExtendedDisplayListSearchResults(): any[] {
        const extendedResults = [...this.displayListSearchResults];
        while (extendedResults.length < 10) {
            extendedResults.push({}); // Replace {} with the desired placeholder or value
        }
        if (this.paginationClicked) {
            this.clickedCard = document.querySelector('#card_' + this.selectedMarkerIndex) as HTMLElement;
            this.clickedCard && this.highlightCard();
        }
        this.selectedMarkerIndexVal = this.selectedMarkerIndex;
        return extendedResults;
    }

    onCardClick(index: number) {
        this.selectedMarkerIndexVal = index;
        this.selectedMarkerIndexValEmitter.emit(this.selectedMarkerIndexVal);
    }

    highlightCard() {
        if (
            this.clickedCard &&
            this.highlighter.nativeElement &&
            !this.clickedCard.classList.contains('no-border-container')
        ) {
            this.highlighter.nativeElement.setAttribute(
                'style',
                `width:${this.clickedCard.offsetWidth}px;
                    height:${this.clickedCard.offsetHeight}px;
                    top:${this.clickedCard.offsetTop}px;
                    left:${this.clickedCard.offsetLeft}px;
                    display:block !important`
            );
        }
    }

    onStoresContainerClick(event: any) {
        this.clickedCard = event.target.closest('.card-container');
        this.highlightCard();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.highlightCard();
    }

    @Input()
    set storeListPaginationClicked(data: any) {
        this.highlighter?.nativeElement?.setAttribute(
            'style',
            `width:0;
                height:0;
                top:0;
                left:0;
                display:none !important`
        );
    }
}
