import { gql } from 'apollo-angular';
export const LOCATIONS_QUERY = gql`
    query getLocationsData($search: String!, $language: String) {
        locations(where: { search: $search, language: $language }) {
            nodes {
                seo {
                    title
                    metaDesc
                }
                languageCode
                slug
                uri
                translations {
                    languageCode
                    slug
                    uri
                }
                language {
                    code
                    country_flag_url
                    default_locale
                    id
                    language_code
                    native_name
                    translated_name
                    url
                }
                locations {
                    displayType
                    merchantName
                    merchantLogo {
                        link
                        sourceUrl
                        srcSet
                        altText
                    }
                    logoType
                    merchantStat
                    textimageBanner
                    desktopBanner {
                        sourceUrl
                        srcSet
                        altText
                    }
                    mobileBanner {
                        sourceUrl
                        srcSet
                        altText
                    }
                    merchantBanner
                    backgroundColor
                    textColor
                    linkColor
                    showNoCreditNeeded
                    showNoCreditNeededText
                    showBuyOutOptions
                    buyOutOptions
                    earlyBuyOutOption
                    customBannerSideText
                    bannerTitle
                    bannerText
                    bannerSubText
                    creditStatus
                    creditCheckText
                    creditText
                }
            }
        }
        shopCategories(first: 100) {
            nodes {
                ShopCategoryIcon {
                    fieldGroupName
                    icon
                }
                ShopCategoryAPIKey {
                    fieldGroupName
                    apiKey
                }
                name
                id
                slug
                taxonomyName
                shopCategoryId
                termTaxonomyId
                description
            }
        }
        allMerchantFieldsLabel {
            nodes {
                merchantLabels {
                    fieldGroupName
                    labelsList {
                        fieldGroupName
                        textName
                        textPosition
                        textValue
                    }
                }
            }
        }
    }
`;
