<div [ngClass]="[datePickerContainerDefaultClass, datePickerContainerCustomClass]">
    <div class="form-floating">
        <input
            type="text"
            [ngClass]="formFieldClasses"
            [id]="formFieldId"
            [placeholder]="formFieldPlaceholder"
            [formControl]="control"
            [readOnly]="_isReadOnly"
            autocomplete="off"
            (click)="showCalendar = !showCalendar"
        />
        <label
            [for]="formFieldId"
            [ngClass]="[formFieldLabelDefaultClass, formFieldLabelCustomClass]"
            [innerHTML]="formFieldLabel"
        ></label>
        <span
            [ngClass]="[calendarIconDefaultClass, calendarIconCustomClass]"
            [class.aff-calendar-icon-disabled]="showError"
            (click)="showCalendar = !showCalendar"
            ><i class="fa fa-calendar"></i
        ></span>
    </div>
    <div *ngIf="!showError" [ngClass]="[dateFormatDefaultClass, dateFormatCustomClass]">
        <span *ngIf="type === 'date-of-birth'">{{ dateFormat.toLowerCase() }}</span>
        <span *ngIf="type === 'date-range'">{{
            dateFormat.toLowerCase() + " - " + dateFormat.toLowerCase()
        }}</span>
    </div>
    <!-- need to update error messages based on minimum and maximum date range allowed -->
    <div *ngIf="showError" class="aff-date-picker-error">
        <span *ngIf="type === 'date-of-birth' || type === 'pay-day'">Invalid date, use the format yyyy-mm-dd</span>
        <span *ngIf="type === 'date-range'">Invalid date, use the format yyyy-mm/dd - yyyy-mm-dd</span>
    </div>
    <div [style.display]="showCalendar ? 'block' : 'none'">
        <aff-corporate-calendar
            (onDateSelection)="onDateSelection($event)"
            [dateFormat]="dateFormat"
            [showCalendar]="showCalendar"
            [dateEntered]="updatedDate"
            [lifeExpectancy]="lifeExpectancy"
            [calendarType]="type"
            [dateRangeDuration]="dateRangeDuration"
            [paydayMonthsDuration]="paydayMonthsDuration"
            [holidayCalendar]="holidayCalendar"
        ></aff-corporate-calendar>
    </div>
</div>
