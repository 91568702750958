import { Component, Input } from '@angular/core';
import { FooterMenu } from '../../types/menu-data.types';

@Component({
    selector: 'janet-footer-menu-mobile',
    templateUrl: './footer-menu-mobile.component.html',
    styleUrls: ['./footer-menu-mobile.component.scss'],
})
export class FooterMenuMobileComponent {
    menuList: FooterMenu[];

    @Input()
    set menuListItems(value: FooterMenu[]) {
        this.menuList = value;
    }

    trackByFn(index: number, item: any): string {
        return item.node.id;
    }
}
