import { AfterViewInit, Directive, HostListener, Renderer2, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BREAK_POINTS } from '../../constants/breakpoints.constants';
import { StylesHelper } from '../core/classes/styles-helper.class';

@Directive({
    selector: '[janetChat]',
})
export class ChatDirective implements AfterViewInit {
    private isDesktop$: Observable<BreakpointState> = this.breakpointObserver.observe([BREAK_POINTS.xl]);
    public _isDesktop = true;
    icon_container: ElementRef;
    elR: ElementRef = this.el.nativeElement;
    timer: any = null;
    window = window as any;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private _renderer: Renderer2,
        private el: ElementRef
    ) { }

    ngAfterViewInit(): void {
        this.icon_container = this.el.nativeElement.querySelector('fa-icon');
        this.isDesktop$.subscribe((isDesktop: BreakpointState) => {
            this._isDesktop = isDesktop.matches;
            this.placeChatButton();
        });
    }

    /**
     * @method placeChatButton is used to place the chat icon for mobile/desktop view.
     */
    placeChatButton(): void {
        this._renderer.addClass(this.icon_container, 'chat-icon-default-desktop');
        if (this._isDesktop) {
            const isDeskTopRemove: string[] = [
                'chat-button-mobile-width',
                'position-left-default-mobile',
                'position-left-scroll-mobile',
            ];
            const isDeskTopAdd: string[] = ['position-right-default-desktop', 'chat-button-desktop-width'];
            StylesHelper.removeClassFromElement(this._renderer, this.elR, isDeskTopRemove);
            StylesHelper.addClassToElement(this._renderer, this.elR, isDeskTopAdd);
        } else {
            const isMobileRemove: string[] = [
                'chat-button-desktop-width',
                'position-right-default-desktop',
                'position-left-scroll-mobile',
            ];
            const isMobileAdd: string[] = ['chat-button-mobile-width', 'position-left-default-mobile'];
            StylesHelper.removeClassFromElement(this._renderer, this.elR, isMobileRemove);
            StylesHelper.addClassToElement(this._renderer, this.elR, isMobileAdd);
        }
    }

    @HostListener('click')
    onChatButtonClick() {
        if (this.window?.SnapEngage) {
            this.window.SnapEngage.startLink();
        }
    }

    @HostListener('mouseover')
    onMouseOver(): void {
        if (this._isDesktop) {
            this.addMouseOverStyle();
        }
    }

    @HostListener('mouseout')
    onMouseOut(): void {
        if (this._isDesktop) {
            this.addMouseOutStyle();
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e: any) {
        if (this.timer !== null) {
            clearTimeout(this.timer);
        }
        if (this._isDesktop === false) {
            this.onScrollClass();
            this.timer = setTimeout(() => {
                this.onScrollStopClass();
            }, 400);
        }
    }

    /**
     * @method addMouseOverStyle is used to add css class on mouse over event in desktop.
     */
    addMouseOverStyle(): void {
        const isDeskTopRemove: string[] = [
            'position-left-default-mobile',
            'position-left-scroll-mobile',
            'position-right-default-desktop',
        ];
        const isDeskTopRemoveChatIcon: string[] = ['chat-icon-default-desktop'];
        const isDeskTopAdd: string[] = ['position-right-hover-desktop'];
        const isDeskTopAddChatIcon: string[] = ['chat-icon-hover-desktop'];
        StylesHelper.removeClassFromElement(this._renderer, this.icon_container, isDeskTopRemoveChatIcon);
        StylesHelper.removeClassFromElement(this._renderer, this.elR, isDeskTopRemove);
        StylesHelper.addClassToElement(this._renderer, this.icon_container, isDeskTopAddChatIcon);
        StylesHelper.addClassToElement(this._renderer, this.elR, isDeskTopAdd);
    }

    /**
     * @method addMouseOutStyle is used to add css class on mouse out event in desktop.
     */
    addMouseOutStyle(): void {
        const isDeskTopRemove: string[] = [
            'position-left-default-mobile',
            'position-left-scroll-mobile',
            'position-right-hover-desktop',
        ];
        const isDeskTopRemoveChatIcon: string[] = ['chat-icon-hover-desktop'];
        const isDeskTopAdd: string[] = ['position-right-default-desktop'];
        const isDeskTopAddChatIcon: string[] = ['chat-icon-default-desktop'];
        StylesHelper.removeClassFromElement(this._renderer, this.icon_container, isDeskTopRemoveChatIcon);
        StylesHelper.removeClassFromElement(this._renderer, this.elR, isDeskTopRemove);
        StylesHelper.addClassToElement(this._renderer, this.icon_container, isDeskTopAddChatIcon);
        StylesHelper.addClassToElement(this._renderer, this.elR, isDeskTopAdd);
    }

    /**
     * @method onScrollClass is used to add css class on scroll event starts in mobile.
     */
    onScrollClass(): void {
        if (this._isDesktop === false) {
            const isMobileRemove: string[] = ['position-left-default-mobile'];
            const isMobileAdd: string[] = ['position-left-scroll-mobile'];
            StylesHelper.removeClassFromElement(this._renderer, this.elR, isMobileRemove);
            StylesHelper.addClassToElement(this._renderer, this.elR, isMobileAdd);
        }
    }

    /**
     * @method onScrollStopClass is used to add css class on scroll event stops in mobile.
     */
    onScrollStopClass(): void {
        if (this._isDesktop === false) {
            const isMobileRemove: string[] = ['position-left-scroll-mobile'];
            const isMobileAdd: string[] = ['position-left-default-mobile'];
            StylesHelper.removeClassFromElement(this._renderer, this.elR, isMobileRemove);
            StylesHelper.addClassToElement(this._renderer, this.elR, isMobileAdd);
        }
    }
}
