import { Component } from '@angular/core';
import { MenuDataService } from '../../services/menu-data.service';
import { SanitizeUrlService } from '../../core/services/sanitize-url.service';

@Component({
    selector: 'janet-copy-rights',
    templateUrl: './copy-rights.component.html',
    styleUrls: ['./copy-rights.component.scss'],
})
export class CopyRightsComponent {
    constructor(private _menuDataSevice: MenuDataService, private _sanitizeUrlService: SanitizeUrlService) {}
    currentYear: number = new Date().getFullYear();
    copyrights$ = this._menuDataSevice.copyrights$;

    sanatizeURL(url: string) {
        return this._sanitizeUrlService.sanitize(url);
    }
}
