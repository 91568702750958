<ua2-aff-accordion>
    <ua2-toggle
        accordionlist="false"
        *ngFor="let menu of menuList; let i = index; let l = last; trackBy: trackByFn"
        title="{{ menu?.node?.label | uppercase }}"
    >
        <ng-container
            [ngTemplateOutlet]="subMenuList"
            [ngTemplateOutletContext]="{ submenuList: { submenus: menu.children, lastIndex: l } }"
        >
        </ng-container>
    </ua2-toggle>
</ua2-aff-accordion>

<ng-template let-submenuList="submenuList" #subMenuList>
    <janet-footer-sub-menu-list [subMenuList]="submenuList"></janet-footer-sub-menu-list>
</ng-template>
