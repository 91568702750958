import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private _action = new BehaviorSubject<unknown>({});
    readonly action$ = this._action.asObservable();

    // eslint-disable-next-line @typescript-eslint/ban-types
    modalAction(value: object) {
        this._action.next(value);
    }
}
