import { Component, Input } from '@angular/core';
import { DsarFormModule } from '../../types/kitchen-sink.types';

@Component({
    selector: 'janet-dsar-form',
    templateUrl: './dsar-form.component.html',
})
export class DsarFormComponent {
    @Input() public dsarFormData: DsarFormModule;
}
