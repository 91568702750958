export const HeroModuleMock = {
    __typename: 'Page_Contentmodules_ContentModules_Hero',
    fieldGroupName: 'Page_Contentmodules_ContentModules_Hero',
    backgroundImage: {
        __typename: 'MediaItem',
        sourceUrl:
            'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero.jpg',
        srcSet: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero-300x86.jpg 300w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero-1024x293.jpg 1024w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero-768x219.jpg 768w, https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/01/green-living-room-couch-hero.jpg 1400w',
        altText: 'Siva',
    },
    title: 'SIva',
    contentArea: null,
    colorScheme: 'light',
    contentAlignment: 'left',
    showButton: null,
    heroLayoutMobile: 'background',
    button: {
        __typename: 'AcfLink',
        target: '',
        title: 'Google',
        url: 'https://www.linkedin.com/company/americanfirstfinance/',
    },
};
