<section class="icons-wrapper">
    <div class="container row mb-3 mb-md-4 mx-auto">
        <span
            [ngClass]="iconClasses"
            class="text-center mb-4"
            *ngFor="let icon of iconsModuleData.icons; trackBy: trackByFn"
        >
            <ng-container *ngIf="icon.useFaIcon; else useImage">
                <div class="fa-icon-container mt-3 mb-2 p-0 px-12 bg-light d-inline-block text-center">
                    <em [ngClass]="getFaIcon(icon.iconFa)"></em>
                </div>
            </ng-container>
            <ng-template #useImage>
                <img
                    class="mt-3 mb-2 icons-img"
                    [src]="icon.iconImage.mediaItemUrl"
                    [alt]="icon.iconImage.altText"
                    height="120"
                    width="120"
                />
            </ng-template>
            <h5
                class="my-2"
                innerText="{{
                    icon.iconTitle && icon.iconTitle.toString().trim().length > 0 ? icon.iconTitle : ''
                }}"
            ></h5>
            <p
                class="mt-2 mb-1 text-break"
                [innerHTML]="
                    (icon.iconSubText && icon.iconSubText.toString().trim().length > 0 ? icon.iconSubText : '')
                        | sanitizeHtml
                "
            ></p>
        </span>
    </div>
    <div class="container" *ngIf="iconsModuleData.showButton">
        <div class="row">
            <div class="d-grid d-grid d-md-block col-12 mx-auto mb-3 my-md-3 text-center">
                <ua2-aff-button
                    class="display-content"
                    [url]="iconsModuleData?.button?.url"
                    [title]="iconsModuleData?.button?.title"
                    [buttonLabel]="iconsModuleData?.button?.title"
                    [id]="'button'"
                    [target]="iconsModuleData?.button?.target"
                    [buttonCustomClass]="'text-break p-3 p-md-4 d-inline-block'"
                ></ua2-aff-button>
            </div>
        </div>
    </div>
</section>
