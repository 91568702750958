import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

export interface RatingContext {
    /**
     * The star fill percentage, an integer in the `[0, 100]` range.
     */
    fill: number;

    /**
     * Index of the star, starts with `0`.
     */
    index: number;
}

@Component({
    selector: 'ua2-rating',
    templateUrl: './rating.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})
export class RatingComponent implements OnInit, AfterViewInit {
    @ViewChild('ratingGrid') ratingGrid: ElementRef;
    /**
     * The maximal rating that can be given.
     */
    @Input() max: number;

    /**
     * The current rating. Could be a decimal value like `4.5`.
     */
    @Input() rate: number;

    contexts: RatingContext[] = [];

    isMobile$ = this.breakpointObserver.observe([Breakpoints.Handset]);
    isTablet$ = this.breakpointObserver.observe([Breakpoints.Tablet]);
    isDesktop$ = this.breakpointObserver.observe([Breakpoints.Web]);

    constructor(private renderer: Renderer2, private breakpointObserver: BreakpointObserver) { }

    ngOnInit(): void {
        /**
         * Initializes the contexts which are used for displaying the rating icons.
         */
        this.contexts = Array.from({ length: this.max }, (v, k) => ({ fill: 0, index: k }));
        this._updateState(this.rate);
    }

    ngAfterViewInit() {
        this.renderer.setStyle(this.ratingGrid.nativeElement, 'display', 'grid');
        this.renderer.setStyle(this.ratingGrid.nativeElement, 'grid-template-columns', `repeat(${this.max}, 1fr)`);
        this.renderer.setStyle(this.ratingGrid.nativeElement, 'column-gap', '0.625rem');
        this.renderer.setStyle(this.ratingGrid.nativeElement, 'justify-items', 'center');
    }

    private _updateState(nextValue: number) {
        this.contexts.forEach((context, index) => {
            context.fill = Math.round(this.getValueInRange(nextValue - index, 1, 0) * 100);
        });
    }

    getValueInRange(value: number, max: number, min = 0): number {
        return Math.max(Math.min(value, max), min);
    }
}
