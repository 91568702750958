import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ua2-aff-card-header, aff-card-header',
    templateUrl: './aff-card-header.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})
export class AffCardHeaderComponent implements OnInit {
    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'card-header');
    }
}
