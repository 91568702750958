import { Pipe, PipeTransform } from '@angular/core';

/**
 * This is used to parse and display icons for the Header menus
 *
 * @export
 * @class GetHeaderIconClassPipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: 'getHeaderIconClass',
})
export class GetHeaderIconClassPipe implements PipeTransform {
    transform(iconNode: string): string {
        if (iconNode) {
            const icon = JSON.parse(iconNode);
            return `fa-${icon.style} fa-${icon.id}`;
        } else {
            return '';
        }
    }
}
