import { gql } from 'apollo-angular';

export const LANGUAGES_QUERY = gql`
    query Languages {
        languages {
            code
            country_flag_url
            default_locale
            native_name
            translated_name
            url
            id
            language_code
        }
        defaultLanguage {
            code
            country_flag_url
            default_locale
            id
            language_code
            native_name
            translated_name
            url
        }
    }
`;
