import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    constructor(private httpClient: HttpClient) {}

    getDealerInfo(url: string, params: any) {
        return this.httpClient.post(url, params);
    }
}
