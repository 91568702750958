<div [id]="carouselContainer" class="carousel slide" #carouselRef>
    <div class="carousel-inner">
        <ng-content></ng-content>
    </div>

    <ng-container>
        <button
            class="carousel-control-prev d-none"
            type="button"
            [attr.data-bs-target]="'#' + carouselContainer"
            data-bs-slide="prev"
            [id]="'carouselControl_prev' + carouselContainer"
        >
            <span class="carousel-control-prev-icon"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button
            class="carousel-control-next d-none"
            type="button"
            [attr.data-bs-target]="'#' + carouselContainer"
            data-bs-slide="next"
            [id]="'carouselControl_next' + carouselContainer"
        >
            <span class="carousel-control-next-icon"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </ng-container>

    <div class="d-flex justify-content-center" *ngIf="showIndicators">
        <button
            *ngFor="let slide of slides; let index = index; let first = first; let count = count"
            type="button"
            class="btn btn-link shadow-none py-0 border-0 m-0 {{
                carouselContainer + '_sliderButton'
            }} fa-carousel-icon-btn"
            [attr.data-bs-target]="'#' + carouselContainer"
            [attr.data-bs-slide-to]="index"
            [class.active]="slide.id === (activeId | async)"
            [attr.aria-current]="index === (activeId | async)"
            [id]="carouselContainer + '_sliderButton_' + index"
            #carouselIndicator
        >
            <fa-icon
                *ngIf="slide.id === (activeId | async)"
                [icon]="faFilledCircle"
                class="text-secondary"
                [ngClass]="[carouselIconsize]"
            ></fa-icon>
            <fa-icon
                *ngIf="slide.id !== (activeId | async)"
                [icon]="faEmptyCircle"
                class="text-secondary"
                [ngClass]="[carouselIconsize]"
            ></fa-icon>
        </button>
    </div>
</div>
