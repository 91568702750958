export const MerchantStoresMock = {
    __typename: 'MerchantStore_Merchantstores',
    daBrandIds: {
        __typename: 'Brand',
        id: 'cG9zdDo5Mzg3NA==',
        slug: 'test-brand-166560113356',
        brandAttributes: {
            __typename: 'Brand_Brandattributes',
            brandId: '1',
            brandName: 'Conn’s Home Plus Testing',
            brandLogo: null,
            brandLogoWp: {
                __typename: 'MediaItem',
                mediaItemUrl: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/11/amcco.jpg',
            },
            brandBannerImage: null,
            brandBannerImageWp: {
                __typename: 'MediaItem',
                mediaItemUrl: 'https://dev1hcms1.americanfirstfinance.com/wp-content/uploads/2022/11/Image-15.png',
            },
            brandUrl: 'https://www.connstest.com',
            online: true,
            instore: null,
            childItems: 2,
            categories: [
                {
                    __typename: 'MerchantCategory',
                    slug: 'furniture',
                    name: 'Furniture',
                    taxonomyName: 'merchant_category',
                    categoryIcon: {
                        __typename: 'MerchantCategory_Categoryicon',
                        categoryIcon:
                            '{ "style" : "solid", "id" : "couch", "label" : "Couch", "unicode" : "f4b8" }',
                        fieldGroupName: 'categoryIcon',
                    },
                    termTaxonomyId: 2,
                },
            ],
            spuncontent:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum. ',
            topMerchant: null,
            cta: {
                __typename: 'Brand_Brandattributes_Cta',
                ctaContent: 'Approvals from $300 to $5,000*',
                ctaText: 'Apply Now',
                ctaUrl: 'https://www.connstest.com',
                fieldGroupName: 'cta',
                ctaOnlineUrl: 'https://www.connstest.com',
                ctaDisclaimer:
                    '*Product may vary. New customers may be approved for loan amounts up to $3,500 and returning customers up to $5,000. See agreement for details.  Subject to approval. Restrictions apply.',
                ctaHint: 'This location only allows applications in-store.',
                ctaIcon:
                    '{ "style" : "regular", "id" : "diamond-turn-right", "label" : "Diamond turn right", "unicode" : "f5eb" }',
                ctaShowIcon: true,
            },
        },
        contentModules: {
            __typename: 'Brand_Contentmodules',
            contentModules: [
                {
                    __typename: 'Brand_Contentmodules_ContentModules_ContentArea',
                    fieldGroupName: 'Brand_Contentmodules_ContentModules_ContentArea',
                    content:
                        '<div>\n<div>\n<div>\n<h4><strong>What Does Conn&#8217;s Freight Offer?</strong></h4>\n</div>\n</div>\n</div>\n<div>Conn&#8217;s offers furniture for the entire home, inside and out. They stock items from some of the largest name brands at prices you won’t find at regular retail stores. They are your one-stop shop for all your home furnishing needs. For many products, they offer same-day or next-day delivery</div>\n',
                    content2: null,
                    contentWidth: 'full_width',
                    image: null,
                    image2: null,
                    imagePosition: null,
                    imagePosition2: null,
                    showButton: true,
                    showButton2: null,
                    showImage1: null,
                    showImage2: null,
                    button: {
                        __typename: 'AcfLink',
                        target: '',
                        title: 'Button Text',
                        url: 'https://www.google.com',
                    },
                    button2: null,
                },
                {
                    __typename: 'Brand_Contentmodules_ContentModules_ContentArea',
                    fieldGroupName: 'Brand_Contentmodules_ContentModules_ContentArea',
                    content: '<p>qweqewqeqweqwe</p>\n',
                    content2: null,
                    contentWidth: 'full_width',
                    image: null,
                    image2: null,
                    imagePosition: null,
                    imagePosition2: null,
                    showButton: null,
                    showButton2: null,
                    showImage1: null,
                    showImage2: null,
                    button: null,
                    button2: null,
                },
            ],
        },
    },
    daCity: 'Wharton',
    daCompanyDescription: 'NA',
    daLatitude: '40.9039560000',
    daLongitude: '-74.5694420000',
    mapRadiusConfiguration: 1,
    daOperatingHours: [
        {
            __typename: 'MerchantStore_Merchantstores_daOperatingHours',
            createdAt: null,
            days: [
                {
                    __typename: 'MerchantStore_Merchantstores_daOperatingHours_days',
                    day: 'mon',
                    fieldGroupName: 'days',
                },
                {
                    __typename: 'MerchantStore_Merchantstores_daOperatingHours_days',
                    day: 'tue',
                    fieldGroupName: 'days',
                },
                {
                    __typename: 'MerchantStore_Merchantstores_daOperatingHours_days',
                    day: 'wed',
                    fieldGroupName: 'days',
                },
                {
                    __typename: 'MerchantStore_Merchantstores_daOperatingHours_days',
                    day: 'thu',
                    fieldGroupName: 'days',
                },
                {
                    __typename: 'MerchantStore_Merchantstores_daOperatingHours_days',
                    day: 'fri',
                    fieldGroupName: 'days',
                },
                {
                    __typename: 'MerchantStore_Merchantstores_daOperatingHours_days',
                    day: 'sat',
                    fieldGroupName: 'days',
                },
            ],
            endTime: '21:00:00',
            fieldGroupName: 'da_operating_hours',
            id: 'bae06a8c6676c8f2b906a0dbc1de048c',
            startTime: '10:00:00',
        },
        {
            __typename: 'MerchantStore_Merchantstores_daOperatingHours',
            createdAt: null,
            days: [
                {
                    __typename: 'MerchantStore_Merchantstores_daOperatingHours_days',
                    day: 'sun',
                    fieldGroupName: 'days',
                },
            ],
            endTime: '19:00:00',
            fieldGroupName: 'da_operating_hours',
            id: 'b96927ea0c1c978ac5680b702f7af049',
            startTime: '11:00:00',
        },
    ],
    daPlaceName: "Bob's Discount Furniture",
    daPostalCode: '07885-1216',
    daPrimaryNaicsCode: '44911012',
    categoryServiceName: null,
    daBbbBusinessReviewUrl: 'NA',
    daState: 'NJ',
    daStreet: '316 State Route 15 S',
    storePhone: '(123) 456-7890',
    topLevelCategory: 'Furniture',
    categories: null,
};
