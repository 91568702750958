import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { AccordionService } from '../../../services/accordion.service';

@Component({
    selector: 'ua2-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ToggleComponent {
    @Input() active = false;
    @Input() title = 'Accordion Label';
    @Input() titleUrl: null | undefined | string;
    @Input() titleUrlTarget: null | undefined | string;
    @Input() isSubMenuExist = 'true';
    @Input() classes = 'm-0 px-4 py-3';
    @Output() setActive = new EventEmitter();
    @Input() accordionlist = 'true';
    @Input() distance = '';
    @Input() bullet = '';
    @Input() subTitle = '';
    @Input() addSpacing = false;
    @Input() isSingleToggle = false;
    @Input() headerId?: any = '';
    @Input() headerInfoText = '';
    @Input() infoTextClass = '';
    @Input() showInfoText = false;
    @Input() headerContainerClass = 'd-flex flex-row justify-content-between m-0 p-0 menu';
    @Input() additionalAcrdnInfo = '';
    @Input() showadditionalAcrdnInfo = false;
    @Input() additionalAcrdnInfoClass = '';
    @Output() setStorelistActive = new EventEmitter();
    _toggleIconClass = '';


    constructor(private _accordionService: AccordionService) { }

    onHeaderClick() {
        this._accordionService.customAccordionEvent.emit({ id: this.headerId, type: 'header' });
    }
    onInfoTextClick(event: Event) {
        event.stopPropagation();
        this._accordionService.customAccordionEvent.emit({ id: this.headerId, type: 'info' });
    }

    @Input()
    set toggleIconClass(_classes: any) {
        this._toggleIconClass = _classes;
    }

    get getToggleIconClass() {
        return this._toggleIconClass;
    }
}
