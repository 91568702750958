export enum ButtonColor {
    DEFAULT = 'btn',
    PRIMARY = 'btn-primary',
    SECONDARY = 'btn-secondary',
    SUCCESS = 'btn-success',
    DANGER = 'btn-danger',
    WARNING = 'btn-warning',
    INFO = 'btn-info',
    LIGHT = 'btn-light',
    DARK = 'btn-dark',
    LINK = 'btn-link',
}
