export const CategorysListMock = [
    {
        __typename: 'ShopCategory',
        ShopCategoryIcon: {
            __typename: 'ShopCategory_Shopcategoryicon',
            fieldGroupName: 'ShopCategoryIcon',
            icon: '{ "style" : "light", "id" : "washing-machine", "label" : "Washing Machine", "unicode" : "f898" }',
        },
        name: 'Appliances',
        id: 'dGVybToxNg==',
        slug: 'appliances',
        taxonomyName: 'shop_category',
        shopCategoryId: 16,
        termTaxonomyId: 16,
        description: 'Description 1',
        ShopCategoryAPIKey: { apiKey: 'appliances' },
    },
    {
        __typename: 'ShopCategory',
        ShopCategoryIcon: {
            __typename: 'ShopCategory_Shopcategoryicon',
            fieldGroupName: 'ShopCategoryIcon',
            icon: '{ "style" : "light", "id" : "car-wrench", "label" : "Car Wrench", "unicode" : "f5e3" }',
        },
        name: 'Auto Repair',
        id: 'dGVybToxOA==',
        slug: 'auto-repair',
        taxonomyName: 'shop_category',
        shopCategoryId: 18,
        termTaxonomyId: 18,
        description: 'Description 2',
        ShopCategoryAPIKey: { apiKey: 'auto-repair' },
    },
    {
        __typename: 'ShopCategory',
        ShopCategoryIcon: {
            __typename: 'ShopCategory_Shopcategoryicon',
            fieldGroupName: 'ShopCategoryIcon',
            icon: '{ "style" : "light", "id" : "laptop-mobile", "label" : "Laptop Mobile", "unicode" : "f87a" }',
        },
        name: 'Electronics',
        id: 'dGVybToxNw==',
        slug: 'electronics',
        taxonomyName: 'shop_category',
        shopCategoryId: 17,
        termTaxonomyId: 17,
        description: 'Description 3',
        ShopCategoryAPIKey: { apiKey: 'electronics' },
    },
    {
        __typename: 'ShopCategory',
        ShopCategoryIcon: {
            __typename: 'ShopCategory_Shopcategoryicon',
            fieldGroupName: 'ShopCategoryIcon',
            icon: '{ "style" : "light", "id" : "couch", "label" : "Couch", "unicode" : "f4b8" }',
        },
        name: 'Furniture',
        id: 'dGVybToxNA==',
        slug: 'furniture',
        taxonomyName: 'shop_category',
        shopCategoryId: 14,
        termTaxonomyId: 14,
        description: 'Description 4',
        ShopCategoryAPIKey: { apiKey: 'furniture' },
    },
    {
        __typename: 'ShopCategory',
        ShopCategoryIcon: {
            __typename: 'ShopCategory_Shopcategoryicon',
            fieldGroupName: 'ShopCategoryIcon',
            icon: '{ "style" : "light", "id" : "gem", "label" : "Gem", "unicode" : "f3a5" }',
        },
        name: 'Jewelry',
        id: 'dGVybToyMQ==',
        slug: 'jewelry',
        taxonomyName: 'shop_category',
        shopCategoryId: 21,
        termTaxonomyId: 21,
        description: 'Description 5',
        ShopCategoryAPIKey: { apiKey: 'jewelry' },
    },
    {
        __typename: 'ShopCategory',
        ShopCategoryIcon: {
            __typename: 'ShopCategory_Shopcategoryicon',
            fieldGroupName: 'ShopCategoryIcon',
            icon: '{ "style" : "light", "id" : "bed-front", "label" : "Bed Front", "unicode" : "f8f7" }',
        },
        name: 'Mattresses',
        id: 'dGVybToxNQ==',
        slug: 'mattresses',
        taxonomyName: 'shop_category',
        shopCategoryId: 15,
        termTaxonomyId: 15,
        description: 'Description 6',
        ShopCategoryAPIKey: { apiKey: 'mattresses' },
    },
    {
        __typename: 'ShopCategory',
        ShopCategoryIcon: {
            __typename: 'ShopCategory_Shopcategoryicon',
            fieldGroupName: 'ShopCategoryIcon',
            icon: '{ "style" : "light", "id" : "dog", "label" : "Dog", "unicode" : "f6d3" }',
        },
        name: 'Pets',
        id: 'dGVybToyMA==',
        slug: 'pets',
        taxonomyName: 'shop_category',
        shopCategoryId: 20,
        description: 'Description 7',
        ShopCategoryAPIKey: { apiKey: 'pets' },
        termTaxonomyId: 20,
    },
    {
        __typename: 'ShopCategory',
        ShopCategoryIcon: {
            __typename: 'ShopCategory_Shopcategoryicon',
            fieldGroupName: 'ShopCategoryIcon',
            icon: '{ "style" : "light", "id" : "tire", "label" : "Tire", "unicode" : "f631" }',
        },
        name: 'Tire & Wheel',
        id: 'dGVybToxOQ==',
        slug: 'tire-wheel',
        taxonomyName: 'shop_category',
        shopCategoryId: 19,
        termTaxonomyId: 19,
        description: 'Description 8',
        ShopCategoryAPIKey: { apiKey: 'tire-wheel' },
    },
];
