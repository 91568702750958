import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
    selector: 'ua2-aff-slide-toggle',
    templateUrl: './aff-slide-toggle.component.html',
    styleUrls: ['./aff-slide-toggle.component.scss'],
})
export class AffSlideToggleComponent {
    @Input() label: string;
    @Input() checked: boolean;
    @Input() disabled: boolean;
    dialogOptions: MatDialogConfig = {};
    @Output() checkedChange = new EventEmitter<boolean>();

    constructor(public dialog: MatDialog) {}
}
