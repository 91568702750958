import { Component, Input } from '@angular/core';
import { FooterSubmenus } from '../../types/menu-data.types';

@Component({
    selector: 'janet-footer-sub-menu-list',
    templateUrl: './footer-sub-menu-list.component.html',
    styleUrls: ['./footer-sub-menu-list.component.scss'],
})
export class FooterSubMenuListComponent {
    @Input() subMenuList: FooterSubmenus;

    trackByFn(index: number, item: any): string {
        return item.node.id;
    }
}
