<div [ngClass]="categoryIconContainerClasses">
    <span [ngClass]="categoryIconAnchorClasses" on-click="onCategoryClick()">
        <div [ngClass]="categoryIconInnerCircleClasses">
            <em [ngClass]="categoryIconClasses"></em>
        </div>
        <p [ngClass]="categoryIconLabelClasses" #categoryLbl>
            {{ _categoryLabel }}
        </p>
    </span>
</div>
