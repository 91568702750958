import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'ua2-aff-modal',
    templateUrl: './aff-modal.component.html',
    styleUrls: ['./aff-modal.component.scss'],
})
export class AffModalComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modalData: any;
    @ViewChild('modalOpenBtn', { static: false }) modalOpenBtn: ElementRef;
    @ViewChild('modalCloseBtn', { static: false }) modalCloseBtn: ElementRef;
    @ViewChild('modalContentPlaceholder', { read: ViewContainerRef }) modalContentPlaceholder: ViewContainerRef;
    component: any;

    constructor(private _modalService: ModalService) { }

    ngOnInit() {
        this.subscription = this._modalService.action$.subscribe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (affModalData: any) => {
                this.removeComponent();
                this.modalData = affModalData;
                this.modalAction();
            }
        );
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    modalAction(): void {
        if (this.modalData?.showModal) {
            this.addComponent(this.modalData?.component);
            this.modalOpenBtn.nativeElement.click();
        } else {
            this.modalCloseBtn?.nativeElement.click();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addComponent(componentsClass: any[]) {
        // Create component/ append oModal dynamically inside the ng-template
        componentsClass.forEach((componentClass) => {
            this.component = this.modalContentPlaceholder.createComponent(componentClass);
        });
    }

    removeComponent() {
        this.modalContentPlaceholder?.clear();
    }
}
