import { ContentModulesHero } from './../../types/kitchen-sink.types';
import { StylesHelper } from './../../core/classes/styles-helper.class';
import { Observable, Subscription } from 'rxjs';
import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    Renderer2,
    AfterViewInit,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { tap } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AffHeroColorScheme, AffHeroLayout } from './enums/aff-hero.enums';
import { BREAK_POINTS } from '../../../constants/breakpoints.constants';

@Component({
    selector: 'janet-hero-component',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss'],
})
export class HeroComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('heroBody') heroBody: ElementRef;
    @Input() moduleData: ContentModulesHero;
    @ViewChild('heroContainer') heroContainer: ElementRef;
    @ViewChild('heroBodyContainer') heroBodyContainer: ElementRef;

    isExtraLargeScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([BREAK_POINTS.xxl]);
    isMediumScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([BREAK_POINTS.md]);
    isMediumScreen = false;
    isExtraLargeScreen = false;

    public layouts = AffHeroLayout;
    public colorSchemes = AffHeroColorScheme;

    private _subscriptions: Subscription[] = [];

    constructor(private breakpointObserver: BreakpointObserver, private renderer: Renderer2) {}
    ngOnInit(): void {
        const isMediumScreen = this.isMediumScreen$.subscribe((_isMediumScreen: BreakpointState) => {
            this.isMediumScreen = _isMediumScreen.matches;
        });
        if (!this._subscriptions.includes(isMediumScreen)) {
            this._subscriptions.push(isMediumScreen);
        }
    }

    ngAfterViewInit() {
        this.setHeroBodyClasses();
        const isTabScreen = this.isMediumScreen$.subscribe((_isTabScreen: BreakpointState) => {
            this.setHeroTextClasses(_isTabScreen.matches);
            this.isMediumScreen = _isTabScreen.matches;
            this.setHeroContainerClass();
        });
        const isDesktopScreen = this.isExtraLargeScreen$.subscribe((_isDesktopScreen: BreakpointState) => {
            this.isExtraLargeScreen = _isDesktopScreen.matches;
            this.setHeroModuleImageHeight(_isDesktopScreen.matches);
        });
        if (!this._subscriptions.includes(isTabScreen)) {
            this._subscriptions.push(isTabScreen);
        }
        if (!this._subscriptions.includes(isDesktopScreen)) {
            this._subscriptions.push(isDesktopScreen);
        }
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((sub) => sub.unsubscribe());
    }

    get heroClasses() {
        let classes = 'hero-module-background-image';

        if (this.moduleData.heroLayoutMobile === this.layouts.FEATURED) {
            classes += ' py-md-6 py-xxl-5 position-relative overflow-hidden';
        } else if (this.moduleData.heroLayoutMobile === this.layouts.BACKGROUND) {
            classes += ' px-4 py-md-6 py-xxl-5 position-relative overflow-hidden';
        }

        if (this.moduleData.contentAlignment === 'left') {
            classes += ' text-start';
        } else if (this.moduleData.contentAlignment === 'center') {
            classes += ' text-center align-items-center';
        }
        return classes;
    }

    setHeroBodyClasses() {
        const bodyEl = this.heroBody.nativeElement;
        StylesHelper.addClassToElement(this.renderer, bodyEl, ['py-10', 'px-3', 'text-break']);

        if (this.moduleData.contentAlignment === 'center') {
            this.renderer.addClass(bodyEl, 'text-center');
            this.renderer.addClass(bodyEl, 'align-items-center');
        } else if (this.moduleData.contentAlignment === 'left') {
            this.renderer.removeClass(bodyEl, 'text-center');
            this.renderer.removeClass(bodyEl, 'align-items-center');
            const leftDescktopSub = this.isExtraLargeScreen$.subscribe((isDesktop: BreakpointState) => {
                if (isDesktop.matches) {
                    StylesHelper.removeClassFromElement(this.renderer, bodyEl, [
                        'text-center',
                        'align-items-center',
                    ]);
                    StylesHelper.addClassToElement(this.renderer, bodyEl, ['text-start']);
                } else {
                    StylesHelper.addClassToElement(this.renderer, bodyEl, ['text-center', 'align-items-center']);
                }
            });
            this._subscriptions.push(leftDescktopSub);
        }

        const isDesckSubscription = this.isExtraLargeScreen$
            .pipe(
                tap(() => {
                    StylesHelper.removeClassFromElement(this.renderer, this.heroBody.nativeElement, [
                        'col-8',
                        'offset-2',
                        'col-7',
                        'offset-1',
                        'col-12',
                    ]);
                })
            )
            .subscribe((isDesktop: BreakpointState) => {
                if (isDesktop.matches && this.moduleData.contentAlignment === 'center') {
                    StylesHelper.addClassToElement(this.renderer, bodyEl, ['col-12']);
                } else if (isDesktop.matches && this.moduleData.contentAlignment === 'left') {
                    StylesHelper.addClassToElement(this.renderer, bodyEl, ['col-8']);
                } else {
                    StylesHelper.addClassToElement(this.renderer, bodyEl, ['col-12']);
                }
            });

        this._subscriptions.push(isDesckSubscription);
    }

    setHeroTextClasses(val: boolean) {
        const contEl = this.heroContainer.nativeElement;
        const isMediumScreen: boolean = val;
        if (
            this.moduleData.colorScheme === this.colorSchemes.DARK ||
            (this.moduleData.heroLayoutMobile === this.layouts.FEATURED && !isMediumScreen)
        ) {
            StylesHelper.removeClassFromElement(this.renderer, contEl, ['content-text-light']);
            StylesHelper.addClassToElement(this.renderer, contEl, ['content-text-dark']);
        } else if (this.moduleData.colorScheme === this.colorSchemes.LIGHT) {
            StylesHelper.removeClassFromElement(this.renderer, contEl, ['content-text-dark']);
            StylesHelper.addClassToElement(this.renderer, contEl, ['content-text-light']);
        }

        if (this.isMediumScreen && !this.isExtraLargeScreen) {
            StylesHelper.removeClassFromElement(this.renderer, contEl, [
                'hero-layout-background',
                'hero-layout-featured',
            ]);
            StylesHelper.addClassToElement(this.renderer, contEl, ['hero-layout-tablet-background']);
        }
    }

    get setHeroButtonClasses() {
        let classes = '';

        if (
            this.moduleData.colorScheme === this.colorSchemes.DARK ||
            (this.moduleData.heroLayoutMobile === this.layouts.FEATURED && !this.isMediumScreen)
        ) {
            classes += ' heroBtn-dark';
        } else if (this.moduleData.colorScheme === this.colorSchemes.LIGHT) {
            classes += ' heroBtn-light';
        }
        return classes;
    }

    setHeroModuleImageHeight(_isDesktopScreen: boolean): void {
        const contEl = this.heroContainer?.nativeElement;
        if (contEl) {
            if (
                this.isMediumScreen &&
                _isDesktopScreen &&
                this.moduleData.heroLayoutMobile === this.layouts.BACKGROUND
            ) {
                StylesHelper.removeClassFromElement(this.renderer, contEl, [
                    'hero-layout-featured',
                    'hero-layout-tablet-background',
                ]);
                StylesHelper.addClassToElement(this.renderer, contEl, ['hero-layout-background']);
            } else if (
                this.isMediumScreen &&
                _isDesktopScreen &&
                this.moduleData.heroLayoutMobile === this.layouts.FEATURED
            ) {
                StylesHelper.removeClassFromElement(this.renderer, contEl, [
                    'hero-layout-background',
                    'hero-layout-tablet-background',
                ]);
                StylesHelper.addClassToElement(this.renderer, contEl, ['hero-layout-featured']);
            } else if (this.isMediumScreen && !_isDesktopScreen) {
                StylesHelper.removeClassFromElement(this.renderer, contEl, [
                    'hero-layout-background',
                    'hero-layout-featured',
                ]);
                StylesHelper.addClassToElement(this.renderer, contEl, ['hero-layout-tablet-background']);
            } else {
                StylesHelper.removeClassFromElement(this.renderer, contEl, [
                    'hero-layout-background',
                    'hero-layout-featured',
                    'hero-layout-tablet-background',
                ]);
            }
        }
    }

    setHeroContainerClass() {
        const heroBodyContainer = this.heroBodyContainer?.nativeElement;
        if (heroBodyContainer) {
            if (this.isMediumScreen === false && this.moduleData.heroLayoutMobile === this.layouts.BACKGROUND) {
                StylesHelper.addClassToElement(this.renderer, heroBodyContainer, [
                    'd-flex',
                    'justify-content-center',
                    'align-items-center',
                    'hero-body-min-height',
                ]);
            } else {
                StylesHelper.removeClassFromElement(this.renderer, heroBodyContainer, [
                    'd-flex',
                    'justify-content-center',
                    'align-items-center',
                    'hero-body-min-height',
                ]);
            }
        }
    }
}
