import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AffModalComponent } from './aff-modal.component';

@NgModule({
    declarations: [AffModalComponent],
    exports: [AffModalComponent],
    imports: [CommonModule, FontAwesomeModule],
})
export class AffModalModule {}
