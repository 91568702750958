import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as LocalStorageActions from './local-storage.actions';
import { LocalStorageItem, localStoragesFeatureKey } from './local-storage.model';

export function selectLocalStorageId(a: LocalStorageItem): string {
    //In this case this would be optional since primary key is id
    return a.key;
}

export interface localStorageState extends EntityState<LocalStorageItem> { //NOSONAR
    // additional entities state properties
    isLoaded: boolean;
    isLoading: boolean;
    error: string | null;
}

export interface PartialLocalStorageState {
    readonly [localStoragesFeatureKey]: localStorageState;
}

export const localStorageAdapter: EntityAdapter<LocalStorageItem> = createEntityAdapter<LocalStorageItem>({
    selectId: selectLocalStorageId,
});

export const initialLocalStorageState: localStorageState = localStorageAdapter.getInitialState({
    // additional entity state properties
    isLoaded: false,
    isLoading: true,
    error: null,
});

export const localStorageReducer = createReducer(
    initialLocalStorageState,
    on(LocalStorageActions.addLocalStorageItemKeyValue, (state, { localStorageItem }) =>
        localStorageAdapter.setOne(localStorageItem, state)
    ),
    on(LocalStorageActions.setLocalStorageItem, (state, { localStorageItem }) => {
        return localStorageAdapter.setOne(localStorageItem, state);
    }),
    on(LocalStorageActions.addLocalStorageItems, (state, { localStorageItems }) =>
        localStorageAdapter.setMany(localStorageItems, state)
    ),
    on(LocalStorageActions.removeLocalStorageItemsKeys, (state, { ids }) =>
        localStorageAdapter.removeMany(ids, state)
    ),
    on(LocalStorageActions.removeLocalStorageItemKey, (state, { id }) => localStorageAdapter.removeOne(id, state)),
    on(LocalStorageActions.loadLocalStorageItems, (state, { localStorageItems }) =>
        localStorageAdapter.setAll(localStorageItems, {
            ...state,
            isLoaded: true,
            isLoading: false,
        })
    ),
    on(LocalStorageActions.setLocalStorageItems, (state, { localStorageItems }) => {
        return localStorageAdapter.setMany(localStorageItems, state);
    }),
    on(LocalStorageActions.clearLocalStorageItems, (state) =>
        localStorageAdapter.removeAll({ ...state, isLoaded: false })
    ),
    on(LocalStorageActions.localStorageError, (state, { message }) => ({
        ...state,
        error: message,
    }))
    // on(loadApis, (state) => ({ ...state, isLoading: true }))
);
