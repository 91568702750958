<div class="gmap-container" id="googlemap" #googleMapContainer></div>

<div class="d-none">
    <ng-container *ngFor="let infoWindowContent of locationsDetails; let index = index">
        <div class="gmap-info-window-content mb-4" id="iw_{{ index }}">
            <p class="mb-0 mb-xxl-2 info-window-content-title">{{ infoWindowContent?.PDNAM1 }}</p>
            <small class="mb-0 d-block info-window-content-description">{{ infoWindowContent?.PDADR1 }}</small>
            <span *ngIf="infoWindowContent?.CATEGORY"
                class="m-0 mt-xxl-2 d-inline-block info-window-content-description">
                {{ infoWindowContent?.CATEGORY }}
            </span>
            <ua2-aff-button *ngIf="
                    !(
                        infoWindowContent?.DTYPE?.toLowerCase() === 'risa' &&
                        infoWindowContent?.PDST?.toLowerCase() === 'ca'
                    )
                " [url]="infoWindowContent?.TARGETURL + getQueryParams()" target="_blank" [title]="_applyBtnLabel"
                [buttonLabel]="_applyBtnLabel" [affButtonIcon]="getFaIcon()" [showAffButtonIcon]="false"
                [buttonCustomClass]="
                    'py-25 px-3 d-inline-flex flex-row justify-content-center align-items-center aff-button gmap-info-window-button'
                " class="w-100 d-block pt-3 pt-xxl-4" [setStyleAttribute]="isBrandedDealer()"></ua2-aff-button>

            <ua2-aff-button *ngIf="
                    !(
                        infoWindowContent?.DTYPE?.toLowerCase() === 'risa' &&
                        infoWindowContent?.PDST?.toLowerCase() === 'ca'
                    )
                " [url]="getDirectionUrl(infoWindowContent?.LATITUDE, infoWindowContent?.LONGITUDE)" target="_blank"
                [title]="_getDirectionBtnLabel" [buttonLabel]="_getDirectionBtnLabel" [affButtonIcon]="getFaIcon()"
                [showAffButtonIcon]="false" [buttonCustomClass]="
                    'py-0 px-0 d-inline-flex flex-row justify-content-center align-items-center get-direction-apply'
                " class="w-100 d-block pt-3 pt-xxl-4 text-center"></ua2-aff-button>

            <ua2-aff-button *ngIf="
                    infoWindowContent?.DTYPE?.toLowerCase() === 'risa' &&
                    infoWindowContent?.PDST?.toLowerCase() === 'ca'
                " [url]="getDirectionUrl(infoWindowContent?.LATITUDE, infoWindowContent?.LONGITUDE)" target="_blank"
                [title]="_getDirectionBtnLabel" [buttonLabel]="_getDirectionBtnLabel"
                [affButtonIcon]="getFaIcon('fa-diamond-turn-right')" [showAffButtonIcon]="true" [buttonCustomClass]="
                    'py-25 px-3 d-inline-flex flex-row justify-content-center align-items-center aff-button gmap-info-window-button'
                " class="w-100 d-block pt-3 pt-xxl-4" [setStyleAttribute]="isBrandedDealer()"></ua2-aff-button>
        </div>
    </ng-container>
</div>