<div #bannerWrapperContainer class="position-relative d-flex" [ngClass]="[bannerWrapperClasses]">
    <img
        *ngIf="moduleData?.backgroundType === affBackgroundTypes.BACKGROUND_TYPE_IMAGE"
        class="mw-100 position-absolute banner-background-image"
        [alt]="$any(moduleData.backgroundImage?.altText)"
        [src]="$any(moduleData.backgroundImage?.sourceUrl)"
        [srcset]="$any(moduleData.backgroundImage?.srcSet)"
    />
    <ng-container
        [ngTemplateOutlet]="bannerIcon"
        [ngTemplateOutletContext]="{
            className:
                $any(moduleData.bannerTextAlignment) === affBannerTextAlignment.BANNER_TEXT_LEFT &&
                ((moduleData.bannerType === 'shop' && moduleData.searchType !== 'cat_zip') ||
                    moduleData.bannerType === 'simple')
                    ? 'banner-container-level-icon-left'
                    : 'banner-container-level-icon-center'
        }"
    >
    </ng-container>
    <ng-template #bannerIcon let-className="className">
        <span
            class="banner-fa-icon"
            *ngIf="!moduleData?.useAsHero && moduleData.fontAwesomeIcon"
            [ngClass]="[className, bannerIconClasses, colorSchemeTextClasses]"
        >
            <i
                [ngClass]="[
                    getFaIcon($any(moduleData.fontAwesomeIcon)),
                    $any(moduleData.useAsHero) ? 'banner-fa-icon-h1' : ''
                ]"
            ></i>
        </span>
    </ng-template>
    <div class="container">
        <div class="banner-container" [ngClass]="[bannerContainerClasses]">
            <div class="banner-heading-container" [ngClass]="[bannerHeadingContainerClasses]">
                <ng-container
                    [ngTemplateOutlet]="bannerIcon"
                    [ngTemplateOutletContext]="{
                        className:
                            $any(moduleData.bannerTextAlignment) === affBannerTextAlignment.BANNER_TEXT_LEFT &&
                            ((moduleData.bannerType === 'shop' && moduleData.searchType !== 'cat_zip') ||
                                moduleData.bannerType === 'simple')
                                ? 'banner-heading-level-icon-left'
                                : 'banner-heading-level-icon-center'
                    }"
                >
                </ng-container>
                <div
                    class="banner-heading-wrapper"
                    [ngClass]="[bannerHeadingWrapperClasses]"
                    [style.color]="$any(moduleData.colorScheme) || 'currentColor'"
                >
                    <h3
                        class="banner-heading-h3"
                        *ngIf="!moduleData?.useAsHero"
                        [ngClass]="bannerTitleH3Classes + ' ' + colorSchemeTextClasses"
                        [innerHTML]="$any(moduleData.headingText) | sanitizeHtml"
                    ></h3>
                    <h1
                        class="banner-heading-h1"
                        *ngIf="moduleData?.useAsHero"
                        [ngClass]="bannerTitleH1Classes + ' ' + colorSchemeTextClasses"
                        [innerHTML]="$any(moduleData.headingText) | sanitizeHtml"
                    ></h1>

                    <div
                        class="banner-sub-heading"
                        [ngClass]="bannerSubheadingClasses + ' ' + colorSchemeTextClasses"
                        *ngIf="moduleData.subheadingText"
                    >
                        <span
                            *ngIf="moduleData?.subheadingText && $any(moduleData.subheadingText).trim().length > 0"
                            [innerHTML]="$any(moduleData.subheadingText) | sanitizeHtml"
                        ></span>
                    </div>
                </div>
            </div>

            <ua2-aff-search
                [isShopCTA]="moduleData.bannerType === affBannerTypes.BANNER_TYPE_SHOP"
                [buttonText]="
                    moduleData.bannerType === affBannerTypes.BANNER_TYPE_SIMPLE
                        ? moduleData.buttonCta?.title
                        : moduleData.buttonCtaText
                "
                [btnTarget]="$any(moduleData.buttonCta?.target)"
                [affBtnId]="
                    moduleData.bannerType === affBannerTypes.BANNER_TYPE_SHOP ? 'aff-shop-cta' : 'aff-simple-cta'
                "
                [enableBtnDarkTheme]="enableButtonDarkTheme"
                [selectOptions]="selectOptions"
                [searchType]="moduleData.searchType"
                [findStoreURL]="findStoreURL"
                [simpleCtaUrl]="$any(moduleData.buttonCta?.url)"
                [defaultCategory]="{
                    label: $any(moduleData.defaultCategory?.name),
                    value: $any(moduleData.defaultCategory?.slug)
                }"
                [errorMessageClass]="errorMessageClass"
            ></ua2-aff-search>
        </div>
    </div>
</div>
