import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CategoryUtilsService {
    filterCategoryDetails(categoryDetails: any[], filterResults: string[]): any[] {
        const apiKeyResult: any[] = [];

        categoryDetails.forEach((category: any) => {
            if (filterResults.includes(category.slug)) {
                apiKeyResult.push(category.apiKey);
            }
        });

        return apiKeyResult;
    }
}
