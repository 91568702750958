import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { QueryParams } from '@janet/corporate/domain';

@Component({
    selector: 'janet-shop-our-partners-stores-list',
    standalone: false,
    templateUrl: './shop-our-partners-stores-List.component.html',
})
export class ShopOurPartnersStoresListComponent {
    @Input() paginationClicked: boolean;
    @Input() selectedMarkerIndex: number;
    @ViewChild('highlighter', { static: false }) highlighter: ElementRef<any>;
    @Output() selectedMarkerIndexValEmitter: EventEmitter<number> = new EventEmitter<number>();
    selectedMarkerIndexVal: number = 0;
    clickedCard: HTMLElement;
    iShowDTCBannerAfterStore = 5;
    object: any;
    extendedResults: any = [];
    _displayListSearchResults: any[] = [];
    _totalRecords: any;
    public getScreenWidth: any;
    _locationsData: any;
    _dealerInfoData: any;
    _locationsLanguageLabelsObj: any;
    _storeListErrorMessage: any;

    constructor(private queryParams: QueryParams, private _cdr: ChangeDetectorRef) {
        this.getScreenWidth = window.innerWidth;
        this.object = Object;
    }

    @Input()
    set displayListSearchResults(data: any) {
        this._displayListSearchResults = data;
    }

    @Input()
    set totalRecords(data: any) {
        this._totalRecords = data;
    }

    @Input()
    set locationsData(data: any) {
        this._locationsData = data;
    }

    @Input()
    set dealerInfoData(data: any) {
        this._dealerInfoData = data;
    }

    @Input()
    set locationsLanguageLabelsObj(data: any) {
        this._locationsLanguageLabelsObj = data;
    }

    @Input()
    set storeListErrorMessage(data: any) {
        this._storeListErrorMessage = data;
    }

    getExtendedDisplayListSearchResults() {
        this.extendedResults = [];
        this.extendedResults = [...this._displayListSearchResults];
        while (this.extendedResults.length < 10) {
            this.extendedResults.push({});
        }

        if (this.paginationClicked) {
            this.clickedCard = document.querySelector('#card_' + this.selectedMarkerIndex) as HTMLElement;
            this.clickedCard && this.highlightCard();
        }
        this.selectedMarkerIndexVal = this.selectedMarkerIndex;
        return this.extendedResults;
    }

    getQueryParams() {
        return this.queryParams?.getQueryParams();
    }

    getButtonUrl(sUrl: any): any {
        if (
            this._dealerInfoData?.sBrandedCustom &&
            this._dealerInfoData?.sBrandedCustom?.toString()?.trim().length > 0
        ) {
            let sNewUrl = sUrl.replace('https://', '');
            sNewUrl = sNewUrl.substring(sNewUrl.indexOf('/'));
            sUrl = this._dealerInfoData.sBrandedCustom + sNewUrl;
            return sUrl;
        }
        return sUrl;
    }

    onCardClick(index: number) {
        this.selectedMarkerIndexVal = index;
        this.selectedMarkerIndexValEmitter.emit(this.selectedMarkerIndexVal);
    }

    highlightCard() {
        if (
            this.clickedCard &&
            this.highlighter.nativeElement &&
            !this.clickedCard.classList.contains('no-border-container')
        ) {
            this.highlighter.nativeElement.setAttribute(
                'style',
                `width:0;
                height:0;
                top:0;
                left:0;
                display:none !important`
            );
        }
    }

    onStoresContainerClick(event: any) {
        this.clickedCard = event.target.closest('.card-container');
        this.highlightCard();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.getScreenWidth = window.innerWidth;
        this.highlightCard();
    }

    @Input()
    set storeListPaginationClicked(data: any) {
        this.highlighter?.nativeElement?.setAttribute(
            'style',
            `width:0;
            height:0;
            top:0;
            left:0;
            display:none !important`
        );
    }

    isBrandedDealer() {
        let backgroundClr = '#161616';
        let linkClr = '#fff';

        const locations = this._locationsData?.locations;
        const branded = this._dealerInfoData?.bBranded;
        if (locations && branded) {
            backgroundClr = locations?.backgroundColor ?? '#161616';
            linkClr = locations?.linkColor ?? '#fff';
            return {
                setStyleAttr: true,
                attributeName: 'style',
                attributes: `background-color:${backgroundClr} !important;color:${linkClr} !important;`,
            };
        } else {
            backgroundClr = '#161616';
            linkClr = '#fff';
            return {
                setStyleAttr: false,
                attributeName: 'style',
                attributes: `background-color:${backgroundClr} !important;color:${linkClr} !important;`,
            };
        }
    }

    public get applyStoreTextColor() {
        let backgroundClr = '#262626';

        const locations = this._locationsData?.locations;
        const branded = this._dealerInfoData?.bBranded;

        if (locations && branded) {
            backgroundClr = locations.backgroundColor ?? '#262626';
            return backgroundClr;
        } else {
            backgroundClr = '#262626';
            return backgroundClr;
        }
    }
}
