import { InjectionToken } from '@angular/core';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject } from 'rxjs';

export const LaunchDarklyConfigToken = new InjectionToken('LaunchDarklyConfigToken');

export interface LaunchDarklyConfig {
    clientID?: string | null;
    localFeatureFlags?: LaunchDarklyLocalFeatureFlags;
}

/**
 * Key must be lowercase and dash cash
 */
export interface LaunchDarklyLocalFeatureFlags {
    [key: string]: boolean;
}

export enum LaunchDarklyStoreType {
    LAUNCH_DARKLY = 'launch-darkly',
    /**
     * Configured locally, not in Launch Darkly
     */
    LOCAL = 'local',
}
export interface LaunchDarklyFeatureFlagStore {
    /**
     * kebab-case
     */
    key: string;
    store: BehaviorSubject<LDFlagValue>;
    storeType: LaunchDarklyStoreType;

    /**
     * Allow for reset
     */

    defaultValue: LDFlagValue;

    /**
     * default false
     */
    updatedByLaunchDarkly: boolean;
}

export interface FeatureFlagListItem {
    key: string;
    value: LDFlagValue;
}
