<div class="category-filter-wrapper">
    <div
        class="category-filter-header category-filter-header-border px-3 pt-4 pb-3 d-flex flex-row justify-content-between align-items-center position-fixed top-0 w-100"
        #categoryFilterHeader
    >
        <h4 class="m-0">Filter by Category</h4>
        <i class="fa-light fa-xmark category-filter-close-icon" (click)="onClickCloseIcon()"></i>
    </div>
    <div class="category-filter-content pt-37 px-3 text-break" #categoryFilterContent>
        <ng-container *ngFor="let option of _multiSelectOptions; let i = index; let l = last">
            <aff-corporate-check-box
                [checkBoxLabel]="option.label"
                [option]="option"
                [isChecked]="option.checked"
                [checkBoxId]="'formCheckBox_' + (i + 1)"
                (selectedOptionsChange)="onSelectedOptionsChange($event)"
                [checkBoxContainerCustomClass]="
                    getCheckBoxContainerClass(l, option?.value?.toLowerCase(), option?.description)
                "
            ></aff-corporate-check-box>
            <p
                *ngIf="option?.description"
                [innerHTML]="option?.description | sanitizeHtml"
                class="m-0 p-0 ms-8 mt-1 category-filter-misc text-break"
                [class.mb-4]="l"
            ></p>
        </ng-container>
    </div>
    <div
        class="category-filter-footer category-filter-footer-shadow position-fixed bottom-0 w-100 p-8"
        #categoryFilterFooter
    >
        <ua2-aff-button
            class="d-block"
            [buttonLabel]="'Done'"
            [buttonType]="'button'"
            [id]="'category-filter-button'"
            [isButtonAnchor]="isButtonAnchor"
            [buttonCustomClass]="buttonCustomClass"
            (click)="onClickCloseIcon()"
        ></ua2-aff-button>
    </div>
</div>
