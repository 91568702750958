import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AffAccordionComponent } from './aff-accordion.component';
import { ToggleComponent } from './toggle/toggle.component';
import { SanitizeHtmlModule } from '@janet/shared/ui-common';

@NgModule({
    declarations: [AffAccordionComponent, ToggleComponent],
    exports: [AffAccordionComponent, ToggleComponent],
    imports: [CommonModule, FontAwesomeModule, SanitizeHtmlModule]
})
export class AffAccordionModule { }
