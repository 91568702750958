<ng-template>
    <div
        class="aff-mdc-popover-panel mdc-menu-surface mdc-menu-surface--open mat-mdc-elevation-specific"
        [id]="panelId"
        [ngClass]="_classList"
        (keydown)="_handleKeydown($event)"
        [@transformMenu]="_panelAnimationState"
        (@transformMenu.start)="_onAnimationStart($event)"
        (@transformMenu.done)="_onAnimationDone($event)"
        tabindex="-1"
        role="popover"
        [attr.aria-label]="ariaLabel || null"
        [attr.aria-labelledby]="ariaLabelledby || null"
        [attr.aria-describedby]="ariaDescribedby || null"
    >
        <div class="aff-mdc-popover-content mdc-list">
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>
