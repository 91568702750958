import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffBouncyLoaderComponent } from './aff-bouncy-loader.component';

@NgModule({
    declarations: [AffBouncyLoaderComponent],
    imports: [CommonModule],
    exports: [AffBouncyLoaderComponent],
})
export class AffBouncyLoaderModule {}
