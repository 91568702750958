<button
    mat-flat-button
    [color]="color"
    [attr.design]="design"
    [style.width.px]="width"
    [disabled]="disabled"
    (click)="loadAction()"
>
    <span *ngIf="!showLoader">
        <ng-content></ng-content>
    </span>
    <aff-bouncy-loader [size]="size" *ngIf="showLoader"></aff-bouncy-loader>
</button>
