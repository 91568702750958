import { InjectionToken } from '@angular/core';

export const localStoragesFeatureKey = 'localStorage';

export interface LocalStorageItem {
    key: string;
    value: any;
}

export interface LocalStorageState {
    [key: string]: any;
}

export interface LocalStorageConfiguration {
    excludeKeys?: string[];
}

export const defaultKeysExcluded: string[] = [];

/**
 * See https://angular.io/guide/dependency-injection-in-action
 *
 */
export const AFF_LOCAL_STORAGE = new InjectionToken<Storage>('Local Storage', {
    providedIn: 'root',
    factory: () => localStorage,
});

export const localStorageDefaultConfiguration: LocalStorageConfiguration = {
    excludeKeys: [],
};

export const AFF_LOCAL_STORAGE_CONFIGURATION = new InjectionToken<LocalStorageConfiguration>(
    'Local Storage Configuration',
    {
        providedIn: 'root',
        factory: () => {
            return <LocalStorageConfiguration>{
                ...localStorageDefaultConfiguration,
            };
        },
    }
);
