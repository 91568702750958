<div class="shop-our-partners-header container py-10"
    [ngClass]="{'pb-4': (! _dealerInfoData || !_dealerInfoData?.bBranded)}">
    <ng-container
        *ngIf="_dealerInfoData?.bBranded && _dealerInfoData?.sIsLangToggleAllowed?.toString()?.trim()?.toLowerCase() === 'y'">
        <janet-shop-our-partners-lang-toggle></janet-shop-our-partners-lang-toggle>
    </ng-container>
    <janet-shop-our-partners-title [locationsPageDetails]="_locationsPageDetails" [locationsData]="_locationsData"
        [dealerInfoData]="_dealerInfoData"
        [locationsLanguageLabelsObj]="_locationsLanguageLabelsObj"></janet-shop-our-partners-title>
    <!-- Commenting Below Code for future reference -->
    <!-- <janet-shop-our-partners-form [multiSelectOptions]="_options" [locationsPageDetails]="_locationsPageDetails"
        [locationsData]="_locationsData" [dealerInfoData]="_dealerInfoData"></janet-shop-our-partners-form> -->
</div>