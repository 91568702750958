import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { LANGUAGES_QUERY } from '../../queries';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PageDataResolver } from '../../core/resolvers/page-data.resolver';
import { LanguageDataService } from '../../services/language.service';

@Component({
    selector: 'janet-shop-our-partners-lang-toggle',
    standalone: false,
    templateUrl: './shop-our-partners-lang-toggle.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ShopOurPartnersLangToggleComponent implements OnInit {
    languageCodes: any = [];
    languagesList: any = [];
    selectedLanguage: any;
    selectedLanguageData: any;
    languagesToSelectData: any;
    queryParamObj = {};
    defaultLanguage: any;

    constructor(
        private _appollo: Apollo,
        public route: ActivatedRoute,
        public _cdr: ChangeDetectorRef,
        private router: Router,
        private pageDataResolver: PageDataResolver,
        private languageDataService: LanguageDataService
    ) {}

    ngOnInit(): void {
        this.getLanguagesList()
            .pipe(switchMap(() => this.route?.queryParams))
            .subscribe((params: any) => {
                this.queryParamObj = params;
                if (params?.lang?.toString()?.length > 0) {
                    const languageCode =
                        params?.lang?.toString()?.toLowerCase() === 'sp'
                            ? 'es'
                            : params?.lang?.toString()?.toLowerCase();
                    if (this.languageCodes?.includes(languageCode)) {
                        this.selectedLanguage = languageCode;
                        this.getSelectedLanguageData(true);
                    }
                } else {
                    this.getSelectedLanguageData();
                }
            });
    }

    onLangToggle(lang_code: any) {
        this.selectedLanguage = lang_code;
        this.getSelectedLanguageData(true);

        const routeSnapshot = this.router.routerState.snapshot.root;

        const currentUrl = this.router.routerState.snapshot.url;
        const hasLangParam = currentUrl.includes('lang=');

        let newUrl: string;

        if (hasLangParam) {
            newUrl = currentUrl.replace(/(lang=)[^\&]+/, `$1${lang_code}`);
        } else {
            const separator = currentUrl.includes('?') ? '&' : '?';
            newUrl = `${currentUrl}${separator}lang=${lang_code}`;
        }

        const stateSnapshot: RouterStateSnapshot = {
            url: newUrl,
            root: routeSnapshot,
        } as RouterStateSnapshot;

        this.pageDataResolver.resolve(routeSnapshot, stateSnapshot).subscribe(
            (data: any) => {
                this.handlePageData(data);
            },
            (error: any) => {
                console.error('Error resolving data:', error);
            }
        );
    }

    handlePageData(data: any) {
        this.selectedLanguageData = data;
        this.languageDataService.setSelectedLanguageData(this.selectedLanguageData);
        this._cdr.detectChanges();
    }

    getLanguagesList(): Observable<any> {
        this.languagesList = [];
        this.languageCodes = [];
        this.defaultLanguage = null;
        return this._appollo
            .query<any>({
                query: LANGUAGES_QUERY,
            })
            .pipe(
                switchMap((response: any) => {
                    this.languagesList = response?.data?.languages;
                    this.languageCodes = response?.data?.languages.map((language: any) =>
                        language?.code?.toString()?.toLowerCase()
                    );
                    this.defaultLanguage = response?.data?.defaultLanguage;
                    return of(null);
                }),
                catchError((error: any) => {
                    return of(null);
                })
            );
    }

    getSelectedLanguageData(updateLangParam?: any) {
        if (this.languagesList?.length > 0) {
            this.selectedLanguageData = this.languagesList?.find(
                (data: any) => data?.language_code === (this.selectedLanguage ?? this.defaultLanguage?.code)
            );
            this.languagesToSelectData = this.languagesList?.find(
                (data: any) => data?.language_code !== (this.selectedLanguage ?? this.defaultLanguage?.code)
            );

            if (updateLangParam) {
                this.updateQueryParam();
            } else {
                if (this.selectedLanguage) {
                    this.updateQueryParam();
                }
            }
            this._cdr.detectChanges();
        }
    }

    updateQueryParam() {
        let updatedParams = { ...this.queryParamObj };
        if ('lang' in updatedParams) {
            delete updatedParams['lang'];
        }
        const langParam = { lang: this.selectedLanguage };

        if (Object.keys(updatedParams)?.length === 0) {
            this.router.navigate([], {
                queryParams: { ...langParam },
            });
        } else {
            this.router.navigate([], {
                queryParams: { ...updatedParams, ...langParam },
            });
        }
    }
}
