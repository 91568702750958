import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { QueryParams } from '@janet/corporate/domain';

@Component({
    selector: 'janet-shop-our-partners-stores-list-mobile',
    standalone: false,
    templateUrl: './shop-our-partners-stores-list-mobile.component.html',
})
export class ShopOurPartnersStoresListMobileComponent {
    @Input() paginationClicked: boolean;
    clickedCard: HTMLElement;
    @ViewChild('highlighter', { static: false }) highlighter: ElementRef<any>;
    isBorderActive: boolean;
    public getScreenWidth: any;
    object: any;
    iShowDTCBannerAfterStore = 5;
    _displayListSearchResults: any = [];
    _totalRecords: any;
    _googleMapUrl: any;
    _locationsData: any;
    _dealerInfoData: any;
    @ViewChild('affStoreButton', { static: false }) affStoreButton: ElementRef;
    _locationsLanguageLabelsObj: any;
    _storeListErrorMessage: any;

    constructor(private _cdr: ChangeDetectorRef, private queryParams: QueryParams) {
        this.getScreenWidth = window.innerWidth;
        this.object = Object;
    }

    @Input()
    set displayListSearchResults(data: any) {
        this._displayListSearchResults = data;
        this.toggleAccordion();
        this._cdr.detectChanges();
    }

    @Input()
    set totalRecords(data: any) {
        this._totalRecords = data;
        this._cdr.detectChanges();
    }

    @Input()
    set storeListPaginationClicked(data: any) {
        this.toggleAccordion();
        this.highlighter?.nativeElement?.setAttribute(
            'style',
            `width:0;
            height:0;
            top:0;
            left:0;
            display:none !important`
        );
    }

    @Input()
    set googleMapUrl(data: any) {
        this._googleMapUrl = data;
    }

    @Input()
    set locationsData(data: any) {
        this._locationsData = data;
    }

    @Input()
    set dealerInfoData(data: any) {
        this._dealerInfoData = data;
    }

    @Input()
    set locationsLanguageLabelsObj(data: any) {
        this._locationsLanguageLabelsObj = data;
    }

    @Input()
    set storeListErrorMessage(data: any) {
        this._storeListErrorMessage = data;
    }

    toggleAccordion() {
        this._displayListSearchResults.forEach((item: any, i: any) => {
            item.active = false;
        });
        this._cdr.detectChanges();
    }

    getDirectionUrl(lat: string, long: string): string {
        return `${this._googleMapUrl}${lat},${long}`;
    }

    setStoreListActive(index: number) {
        this._displayListSearchResults.forEach((item: any, i: any) => {
            item.active = index !== i || item?.active === true ? false : true;
        });
        this._cdr.detectChanges();

        if (this.isBorderActive) {
            this.isBorderActive = false;
            this.highlighter.nativeElement.setAttribute('style', `display:none`);
        }
        setTimeout(() => {
            this.highlightCard();
        }, 350);
    }

    getQueryParams() {
        return this.queryParams?.getQueryParams();
    }

    getButtonUrl(sUrl: any): any {
        if (
            this._dealerInfoData?.sBrandedCustom &&
            this._dealerInfoData?.sBrandedCustom?.toString()?.trim().length > 0
        ) {
            let sNewUrl = sUrl.replace('https://', '');
            sNewUrl = sNewUrl.substring(sNewUrl.indexOf('/'));
            sUrl = this._dealerInfoData.sBrandedCustom + sNewUrl;
            return sUrl;
        }
        return sUrl;
    }

    getFaIcon(iconStr?: string | null | undefined): string {
        if (iconStr && iconStr !== '') {
            return `fa-light ${iconStr} get-directions-icon`;
        }
        return '';
    }

    onStoresContainerClick(event: any) {
        this.clickedCard = event.target.closest('.card-toggle-container-mobile');
    }

    highlightCard() {
        if (this.clickedCard && this.clickedCard.classList.contains('active') && this.highlighter.nativeElement) {
            if (!this.isBorderActive) {
                this.isBorderActive = true;
                this.highlighter.nativeElement.setAttribute(
                    'style',
                    `width:0;
                    height:0;
                    top:0;
                    left:0;
                    display:none !important`
                );
            }
        } else {
            this.isBorderActive = false;
            this.highlighter.nativeElement.setAttribute('style', `display:none`);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.getScreenWidth = window.innerWidth;
        this.isBorderActive = false;
        this.highlightCard();
    }

    isBrandedDealer() {
        let backgroundClr = '#161616';
        let linkClr = '#fff';

        const locations = this._locationsData?.locations;
        const branded = this._dealerInfoData?.bBranded;
        if (locations && branded) {
            backgroundClr = locations?.backgroundColor ?? '#161616';
            linkClr = locations?.linkColor ?? '#fff';
            return {
                setStyleAttr: true,
                attributeName: 'style',
                attributes: `background-color:${backgroundClr} !important;color:${linkClr} !important;`,
            };
        } else {
            backgroundClr = '#161616';
            linkClr = '#fff';
            return {
                setStyleAttr: false,
                attributeName: 'style',
                attributes: `background-color:${backgroundClr} !important;color:${linkClr} !important;`,
            };
        }
    }
}
