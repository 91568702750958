import { Component, Input } from '@angular/core';
import { IconsModule, Icon } from '../../types/kitchen-sink.types';

@Component({
    selector: 'janet-icons',
    templateUrl: './icons.component.html',
    styleUrls: ['./icons.component.scss'],
})
export class IconsComponent {
    @Input() public iconsModuleData: IconsModule;

    /**
     * @method trackByFn used to improves the Performance of the ngFor if the collection has a large no of items and keeps changing.
     * @param index index of each element.
     * @param item object which comprises title, subtext, url.
     * @returns title of each icon.
     */
    trackByFn(index: number, item: Icon): string {
        return item.iconTitle;
    }

    getFaIcon(iconStr: string): string {
        if (iconStr && iconStr !== '') {
            const iconObj = JSON.parse(iconStr);
            return `fa-${iconObj.style} fa-${iconObj.id} fa-fw fa-size text-secondary`;
        }
        return '';
    }

    get iconClasses(): string {
        let iconClasses = '';
        const subtext = this.iconsModuleData.icons.filter((icon) => icon.iconSubText);
        if (this.iconsModuleData.icons.length === 2) {
            iconClasses = subtext.length > 0 ? ' col-xxl-6 mb-xxl-0 ' : ' col-md-6 mb-md-0 ';
        } else if (this.iconsModuleData.icons.length === 3) {
            iconClasses = subtext.length > 0 ? ' col-xxl-4 mb-xxl-0 ' : ' col-md-4 mb-md-0 ';
        } else if (this.iconsModuleData.icons.length > 3) {
            iconClasses = subtext.length > 0 ? ' col-xxl-3 mb-xxl-0 ' : ' col-md-3 mb-md-0 ';
        } else {
            iconClasses = ' col-12 ';
        }
        return iconClasses;
    }
}
