import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffBouncyLoaderModule } from '../aff-bouncy-loader/aff-bouncy-loader.module';
import { AffBouncyLoaderButtonComponent } from './aff-bouncy-loader-button.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [AffBouncyLoaderButtonComponent],
    imports: [CommonModule, MatButtonModule, AffBouncyLoaderModule],
    exports: [AffBouncyLoaderButtonComponent],
})
export class AffBouncyLoaderButtonModule {}
