<div [ngClass]="[switchContainerDefaultClass, switchContainerCustomClass]">
    <input
        [ngClass]="[switchDefaultClass, switchCustomClass]"
        type="checkbox"
        [id]="switchId"
        [(ngModel)]="isChecked"
        (change)="onChange()"
    />
    <label
        *ngIf="switchLabel && switchLabel.length > 0"
        [ngClass]="[switchLabelDefaultClass, switchLabelCustomClass]"
        [for]="switchId"
        >{{ switchLabel }}</label
    >
</div>
