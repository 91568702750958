import { gql } from 'apollo-angular';
export const TYPE_ID_QUERY = gql`
    query GetTypeId($slug: String!) {
        pageBy(uri: $slug) {
            id
        }
        postBy(slug: $slug) {
            id
        }
    }
`;

export const PAGE_DATA_QUERY = gql`
    query GetPageData($slug: String!) {
        pageBy(uri: $slug) {
            content
            contentTypeName
            id
            uri
            title
            status
            slug
            parentId
            pageId
            seo {
                title
                metaDesc
            }
            contentModules {
                contentModules {
                    ... on Page_Contentmodules_ContentModules_ContentArea {
                        fieldGroupName
                        content
                        content2
                        contentWidth
                        image {
                            sourceUrl
                            srcSet
                            altText
                        }
                        image2 {
                            sourceUrl
                            srcSet
                            altText
                        }
                        imagePosition
                        imagePosition2
                        showButton
                        showButton2
                        showImage1
                        showImage2
                        button {
                            target
                            title
                            url
                        }
                        button2 {
                            target
                            title
                            url
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_Hero {
                        fieldGroupName
                        backgroundImage {
                            sourceUrl
                            srcSet
                            altText
                        }
                        title
                        contentArea
                        colorScheme
                        contentAlignment
                        showButton
                        heroLayoutMobile
                        button {
                            target
                            title
                            url
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_Testimonials {
                        fieldGroupName
                        testimonials {
                            fieldGroupName
                            rating
                            testimonialCustomerName
                            testimonialText
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_Icons {
                        fieldGroupName
                        showButton
                        button {
                            target
                            title
                            url
                        }
                        icons {
                            fieldGroupName
                            iconFa
                            useFaIcon
                            iconImage {
                                altText
                                mediaItemUrl
                            }
                            iconTitle
                            iconSubText
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_FaqSchema {
                        fieldGroupName
                        qaItems {
                            question
                            answer
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_CcpaDsarForm {
                        fieldGroupName
                        otDsarWebForm
                    }
                    ... on Page_Contentmodules_ContentModules_MerchantSignupForm {
                        fieldGroupName
                        merchantSignupForm
                    }
                    ... on Page_Contentmodules_ContentModules_WebsiteForms {
                        fieldGroupName
                        websiteForm
                    }
                    ... on Page_Contentmodules_ContentModules_BannerModule {
                        fieldGroupName
                        bannerType
                        backgroundType
                        backgroundColor
                        backgroundImage {
                            sourceUrl
                            srcSet
                            altText
                        }
                        headingText
                        colorScheme
                        useAsHero
                        buttonCta {
                            target
                            title
                            url
                        }
                        buttonCtaText
                        subheadingText
                        fontAwesomeIcon
                        bannerHeight
                        bannerTextAlignment
                        searchType
                        defaultCategory {
                            name
                            slug
                            taxonomyName
                            __typename
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_ShopCategoryModule {
                        fieldGroupName
                        shopCategoryHeading
                        shopCategorySubheading
                        shopCategoryList {
                            fieldGroupName
                            shopCategory {
                                slug
                                name
                                taxonomyName
                                shopCategoryId
                                termTaxonomyId
                                ShopCategoryIcon {
                                    fieldGroupName
                                    icon
                                }
                                ShopCategoryAPIKey {
                                    fieldGroupName
                                    apiKey
                                }
                            }
                        }
                    }
                    ... on Page_Contentmodules_ContentModules_GlForms {
                        ddGfFormIds
                        fieldGroupName
                    }
                }
            }
        }
        shopCategories(first: 100) {
            nodes {
                ShopCategoryIcon {
                    fieldGroupName
                    icon
                }
                ShopCategoryAPIKey {
                    fieldGroupName
                    apiKey
                }
                name
                id
                slug
                taxonomyName
                shopCategoryId
                termTaxonomyId
                description
            }
        }
    }
`;

export const POST_DATA_QUERY = gql`
    query GetPostData($id: ID!) {
        postBy(id: $id) {
            content
            contentTypeName
            id
            uri
            title
            status
            slug
            parentId
            pageId
        }
    }
`;

export const HOME_PAGE_QUERY = gql`
    {
        nodeByUri(uri: "/") {
            __typename
            ... on ContentType {
                id
                name
            }
            ... on Page {
                id
                title
                slug
            }
        }
    }
`;

export const SHOP_PAGE_QUERY = gql`
    query getShopData($slug: String!) {
        shopBy(uri: $slug) {
            id
            seo {
                title
                metaDesc
            }
            contentModules {
                contentModules {
                    ... on Shop_Contentmodules_ContentModules_BannerModule {
                        fieldGroupName
                        bannerType
                        backgroundType
                        backgroundColor
                        backgroundImage {
                            sourceUrl
                            srcSet
                            altText
                        }
                        headingText
                        colorScheme
                        useAsHero
                        buttonCta {
                            target
                            title
                            url
                        }
                        buttonCtaText
                        subheadingText
                        fontAwesomeIcon
                        bannerHeight
                        bannerTextAlignment
                        searchType
                        defaultCategory {
                            name
                            slug
                            taxonomyName
                            __typename
                        }
                    }
                    ... on Shop_Contentmodules_ContentModules_ContentArea {
                        fieldGroupName
                        content
                        content2
                        contentWidth
                        image {
                            sourceUrl
                            srcSet
                            altText
                        }
                        image2 {
                            sourceUrl
                            srcSet
                            altText
                        }
                        imagePosition
                        imagePosition2
                        showButton
                        showButton2
                        showImage1
                        showImage2
                        button {
                            target
                            title
                            url
                        }
                        button2 {
                            target
                            title
                            url
                        }
                    }
                    ... on Shop_Contentmodules_ContentModules_Hero {
                        fieldGroupName
                        backgroundImage {
                            sourceUrl
                            srcSet
                            altText
                        }
                        title
                        contentArea
                        colorScheme
                        contentAlignment
                        showButton
                        heroLayoutMobile
                        button {
                            target
                            title
                            url
                        }
                    }
                    ... on Shop_Contentmodules_ContentModules_Testimonials {
                        fieldGroupName
                        testimonials {
                            fieldGroupName
                            rating
                            testimonialCustomerName
                            testimonialText
                        }
                    }
                    ... on Shop_Contentmodules_ContentModules_Icons {
                        fieldGroupName
                        showButton
                        button {
                            target
                            title
                            url
                        }
                        icons {
                            fieldGroupName
                            iconFa
                            useFaIcon
                            iconImage {
                                altText
                                mediaItemUrl
                            }
                            iconTitle
                            iconSubText
                        }
                    }
                    ... on Shop_Contentmodules_ContentModules_FaqSchema {
                        fieldGroupName
                        qaItems {
                            question
                            answer
                        }
                    }
                    ... on Shop_Contentmodules_ContentModules_CcpaDsarForm {
                        fieldGroupName
                        otDsarWebForm
                    }
                    ... on Shop_Contentmodules_ContentModules_MerchantSignupForm {
                        fieldGroupName
                        merchantSignupForm
                    }
                    ... on Shop_Contentmodules_ContentModules_WebsiteForms {
                        fieldGroupName
                        websiteForm
                    }
                    ... on Shop_Contentmodules_ContentModules_BannerModule {
                        fieldGroupName
                        bannerType
                        backgroundType
                        backgroundColor
                        backgroundImage {
                            sourceUrl
                            srcSet
                            altText
                        }
                        headingText
                        colorScheme
                        useAsHero
                        buttonCta {
                            target
                            title
                            url
                        }
                        buttonCtaText
                        subheadingText
                        fontAwesomeIcon
                        bannerHeight
                        bannerTextAlignment
                        searchType
                        defaultCategory {
                            name
                            slug
                            taxonomyName
                            __typename
                        }
                    }
                    ... on Shop_Contentmodules_ContentModules_ShopCategoryModule {
                        fieldGroupName
                        shopCategoryHeading
                        shopCategorySubheading
                        shopCategoryList {
                            fieldGroupName
                            shopCategory {
                                slug
                                name
                                taxonomyName
                                shopCategoryId
                                termTaxonomyId
                                ShopCategoryIcon {
                                    fieldGroupName
                                    icon
                                }
                                ShopCategoryAPIKey {
                                    fieldGroupName
                                    apiKey
                                }
                            }
                        }
                    }
                }
            }
        }
        shopCategories(first: 100) {
            nodes {
                ShopCategoryIcon {
                    fieldGroupName
                    icon
                }
                ShopCategoryAPIKey {
                    fieldGroupName
                    apiKey
                }
                name
                id
                slug
                taxonomyName
                shopCategoryId
                termTaxonomyId
                description
            }
        }
    }
`;

export const GFORM_DATA_QUERY = gql`
    query getForm($formIds: [ID]!) {
        gfForms(where: { formIds: $formIds }) {
            nodes {
                title
                databaseId
                formId
                isActive
                cssClass
                description
                pagination {
                    backgroundColor
                    color
                    hasProgressbarOnConfirmation
                    lastPageButton {
                        imageUrl
                        text
                        type
                    }
                    pageNames
                    progressbarCompletionText
                    style
                    type
                }
                submitButton {
                    conditionalLogic {
                        actionType
                        logicType
                        rules {
                            fieldId
                            operator
                            value
                        }
                    }
                    imageUrl
                    layoutGridColumnSpan
                    location
                    text
                    type
                    width
                }
                lastPageButton {
                    imageUrl
                    text
                    type
                }
                formFields(first: 500) {
                    nodes {
                        id
                        inputType
                        databaseId
                        layoutGridColumnSpan
                        pageNumber
                        type
                        visibility
                        layoutSpacerGridColumnSpan
                        displayOnly
                        ... on PageField {
                            id
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            cssClass
                            databaseId
                            displayOnly
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            nextButton {
                                conditionalLogic {
                                    actionType
                                    logicType
                                    rules {
                                        fieldId
                                        operator
                                        value
                                    }
                                }
                                imageUrl
                                text
                                type
                            }
                            pageNumber
                            previousButton {
                                conditionalLogic {
                                    actionType
                                    logicType
                                    rules {
                                        fieldId
                                        operator
                                        value
                                    }
                                }
                                imageUrl
                                text
                                type
                            }
                            type
                            visibility
                        }
                        ... on SectionField {
                            type
                            pageNumber
                            id
                            label
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            visibility
                            cssClass
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            description
                        }
                        ... on TextField {
                            labelPlacement
                            type
                            pageNumber
                            id
                            label
                            errorMessage
                            isRequired
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            maxLength
                            size
                            visibility
                            cssClass
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            description
                            value
                        }
                        ... on SelectField {
                            labelPlacement
                            type
                            pageNumber
                            id
                            label
                            errorMessage
                            isRequired
                            choices {
                                text
                                value
                            }
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            size
                            visibility
                            cssClass
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            description
                            value
                        }
                        ... on PhoneField {
                            labelPlacement
                            type
                            pageNumber
                            id
                            label
                            errorMessage
                            isRequired
                            layoutGridColumnSpan
                            size
                            visibility
                            cssClass
                            description
                            value
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                        }
                        ... on EmailField {
                            labelPlacement
                            type
                            pageNumber
                            id
                            label
                            errorMessage
                            isRequired
                            layoutGridColumnSpan
                            size
                            visibility
                            cssClass
                            description
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                        }
                        ... on DateField {
                            labelPlacement
                            type
                            pageNumber
                            id
                            label
                            errorMessage
                            isRequired
                            layoutGridColumnSpan
                            visibility
                            cssClass
                            description
                            value
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            calendarIconType
                            calendarIconUrl
                            descriptionPlacement
                        }
                        ... on FileUploadField {
                            labelPlacement
                            id
                            type
                            pageNumber
                            id
                            label
                            errorMessage
                            isRequired
                            layoutGridColumnSpan
                            visibility
                            cssClass
                            description
                            fileUploadValues {
                                basePath
                                baseUrl
                                filename
                                url
                            }
                            maxFileSize
                            maxFiles
                            allowedExtensions
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            value
                            databaseId
                            canAcceptMultipleFiles
                            displayOnly
                            values
                            layoutSpacerGridColumnSpan
                        }
                        ... on RadioField {
                            labelPlacement
                            type
                            pageNumber
                            id
                            label
                            errorMessage
                            isRequired
                            layoutGridColumnSpan
                            visibility
                            cssClass
                            choices {
                                text
                                value
                                ... on CheckboxFieldChoice {
                                    isSelected
                                    text
                                    value
                                }
                                ... on ListFieldChoice {
                                    text
                                    value
                                }
                                ... on RadioFieldChoice {
                                    isSelected
                                    text
                                    value
                                }
                            }
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            description
                            value
                        }
                        ... on CheckboxField {
                            labelPlacement
                            type
                            pageNumber
                            id
                            label
                            errorMessage
                            isRequired
                            layoutGridColumnSpan
                            visibility
                            cssClass
                            choices {
                                text
                                value
                                ... on CheckboxFieldChoice {
                                    isSelected
                                    text
                                    value
                                }
                                ... on ListFieldChoice {
                                    text
                                    value
                                }
                                ... on MultiSelectFieldChoice {
                                    isSelected
                                    text
                                    value
                                }
                            }
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            description
                        }
                        ... on NumberField {
                            labelPlacement
                            type
                            pageNumber
                            id
                            label
                            errorMessage
                            isRequired
                            layoutGridColumnSpan
                            size
                            visibility
                            cssClass
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            description
                            value
                        }
                        ... on CaptchaField {
                            labelPlacement
                            id
                            captchaBadgePosition
                            captchaLanguage
                            captchaTheme
                            captchaType
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            cssClass
                            databaseId
                            description
                            displayOnly
                            errorMessage
                            inputType
                            label
                            labelPlacement
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            pageNumber
                            simpleCaptchaBackgroundColor
                            simpleCaptchaFontColor
                            simpleCaptchaSize
                            type
                            value
                            visibility
                        }
                        ... on MultiSelectField {
                            labelPlacement
                            id
                            choices {
                                text
                                value
                                ... on CheckboxFieldChoice {
                                    isSelected
                                    text
                                    value
                                }
                                ... on ListFieldChoice {
                                    text
                                    value
                                }
                            }
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            cssClass
                            description
                            errorMessage
                            isRequired
                            labelPlacement
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            pageNumber
                            size
                            type
                            value
                        }
                        ... on HtmlField {
                            id
                            content
                            cssClass
                            databaseId
                            displayOnly
                            hasMargins
                            inputType
                            label
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            pageNumber
                            type
                            visibility
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                        }
                        ... on ConsentField {
                            id
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            consentValue
                            cssClass
                            databaseId
                            description
                            descriptionPlacement
                            displayOnly
                            errorMessage
                            inputType
                            isRequired
                            label
                            labelPlacement
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            pageNumber
                            personalData {
                                isIdentificationField
                                shouldErase
                                shouldExport
                            }
                            type
                            value
                            visibility
                        }
                        ... on HiddenField {
                            id
                            databaseId
                            defaultValue
                            displayOnly
                            inputName
                            inputType
                            label
                            layoutGridColumnSpan
                            pageNumber
                            personalData {
                                isIdentificationField
                                shouldErase
                                shouldExport
                            }
                            type
                            value
                            visibility
                            layoutSpacerGridColumnSpan
                        }
                        ... on WebsiteField {
                            id
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            cssClass
                            databaseId
                            defaultValue
                            description
                            descriptionPlacement
                            displayOnly
                            errorMessage
                            inputName
                            inputType
                            isRequired
                            label
                            labelPlacement
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            pageNumber
                            placeholder
                            size
                            type
                            value
                            visibility
                        }
                        ... on TextAreaField {
                            id
                            adminLabel
                            cssClass
                            databaseId
                            defaultValue
                            description
                            descriptionPlacement
                            displayOnly
                            errorMessage
                            hasRichTextEditor
                            inputName
                            inputType
                            isRequired
                            label
                            labelPlacement
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            maxLength
                            pageNumber
                            placeholder
                            shouldAllowDuplicates
                            size
                            type
                            value
                            visibility
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                        }
                        ... on PostCustomTextField {
                            id
                            conditionalLogic {
                                actionType
                                logicType
                                rules {
                                    fieldId
                                    operator
                                    value
                                }
                            }
                            autocompleteAttribute
                            cssClass
                            databaseId
                            defaultValue
                            description
                            descriptionPlacement
                            displayOnly
                            errorMessage
                            hasAutocomplete
                            hasInputMask
                            inputName
                            inputType
                            isPasswordInput
                            isRequired
                            label
                            inputMaskValue
                            labelPlacement
                            layoutGridColumnSpan
                            layoutSpacerGridColumnSpan
                            maxLength
                            pageNumber
                            placeholder
                            postMetaFieldName
                            shouldAllowDuplicates
                            size
                            type
                            value
                            visibility
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                    }
                }
            }
        }
        gfSettings {
            recaptcha {
                publicKey
                type
            }
        }
    }
`;

export const GFORM_SUBMIT_DATA_QUERY = gql`
    mutation submitGfForm(
        $input: SubmitGfFormInput!
    ) {
        submitGfForm(input: $input) {
            confirmation {
                type
                message
                pageId
                url
                queryString
            }
            errors {
                id
                message
            }
            entry {
                id
                ... on GfSubmittedEntry {
                    databaseId
                }
                ... on GfDraftEntry {
                    resumeToken
                }
            }
            clientMutationId
            resumeUrl
        }
    }
`;
