<ng-container *ngIf="shopOurPartnersForm">
    <!-- Commenting Below Code for future reference -->
    <!--    <form [formGroup]="shopOurPartnersForm"
        class="d-flex flex-row align-items-center shop-our-partners-form justify-content-center mt-3"
        (ngSubmit)="onSubmit()" novalidate> -->
    <form [formGroup]="shopOurPartnersForm"
        [ngClass]="{'justify-content-md-end' : _dealerInfoData?.bBranded,  'justify-content-xxl-between': !_dealerInfoData?.bBranded}"
        class="d-flex flex-row align-items-center shop-our-partners-form justify-content-end" (ngSubmit)="onSubmit()"
        novalidate>
        <div class="d-flex flex-row-reverse align-items-center search-text-input-wrapper" #searchTextInputWrapper>
            <aff-corporate-form-field [formFieldContainerCustomClass]="formFieldContainerCustomClass"
                [formFieldType]="_dealerInfoData?.bBranded ? 'number' : 'text'"
                [formFieldLabel]="_dealerInfoData?.bBranded ? _locationsLanguageLabelsObj?.searchInputLabel:'Zip or Store Name'"
                [formFieldPlaceholder]="_dealerInfoData?.bBranded ? _locationsLanguageLabelsObj?.searchInputLabel :'Zip or Store Name'"
                formFieldId="shopOurPartnersSearchText" formFieldCustomClass="form-control"
                [formFieldLabelCustomClass]="formFieldLabelCustomClass"
                [control]="$any(shopOurPartnersForm.controls.textSearch)" [errorMessages]="selectErrorMessages"
                [showErrorMessage]="showErrorMessage" class="w-100" (formFieldEvent)="handleEvent($event)"
                (inputChange)="inputChange()">
            </aff-corporate-form-field>
            <button class="border-0 position-absolute d-flex justify-content-center align-items-center"
                [ngClass]="searchButtonClass" type="submit" #storeSearchButton>
                <em class="fa-light fa-magnifying-glass"></em>
            </button>
        </div>

        <aff-corporate-form-field [class.d-none]="_dealerInfoData?.bBranded"
            [formFieldContainerCustomClass]="formFieldCategoryContainerCustomClass" formFieldType="multiselect"
            formFieldLabel="Filter By" formFieldPlaceholder="Filter By" formFieldId="shopOurPartnersCategory"
            formFieldCustomClass="form-control" [formFieldLabelCustomClass]="formFieldCategoryLabelCustomClass"
            [multiSelectOptions]="multiSelectOptions" [control]="$any(shopOurPartnersForm.controls.categorySearch)"
            [errorMessages]="categoryErrorMessages" [showErrorMessage]="showErrorMessage"
            [loaderDisplay]="isLoaderDisplay" (multiSelectedOptionData)="toggleMultiSelectOption($event)">
        </aff-corporate-form-field>

        <ng-container *ngIf="getScreenWidth <= '1439'">
            <slider-button [class.d-none]="_dealerInfoData?.bBranded" (sliderButtonClicked)="togglePanel()"
                [count]="getCategoriesCount()" [sliderButtonContainerClass]="sliderButtonContainerClass"
                [sliderButtonIconClass]="sliderButtonIconClass"
                [sliderButtonBadgeClass]="sliderButtonBadgeClass"></slider-button>

            <slide-panel [showPanel]="_showPanel">
                <janet-category-filter [class.d-none]="_dealerInfoData?.bBranded" (togglePanel)="togglePanel()"
                    (toggleCheckBox)="toggleCheckBox($event)" [multiSelectOptions]="multiSelectOptions"
                    [showPanel]="_showPanel"></janet-category-filter>
            </slide-panel>
        </ng-container>
    </form>
</ng-container>