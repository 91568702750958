import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureFlagListItem, LaunchDarklyStoreService } from '@janet/shared/domain';

export interface DialogData {
    dialogHeight: number;
}

@Component({
    selector: 'aff-feature-flag-value',
    templateUrl: './feature-flag-toggle.component.html',
    styleUrls: ['./feature-flag-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FeatureFlagToggleComponent implements OnInit {
    @HostBinding('class') hostClasses = 'aff-feature-flag-value';

    displayedColumns: string[] = ['key', 'value'];

    dataSource: FeatureFlagListItem[] = [];
    constructor(
        public featureFlagStore: LaunchDarklyStoreService,
        private _cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit() {
        this.featureFlagStore.allFeatureFlags$.subscribe((data: FeatureFlagListItem[]) => {
            this.dataSource = [...data];
            this._cd.detectChanges();
        });
    }

    onResetDefaultValues() {
        this.featureFlagStore.resetToDefaultValues();
    }
}
