<div>
    <janet-nav-bar
        *ngIf="!locationsBaseModuleData?.locations?.hideHeaderFooter"
        [ngStyle]="{ visibility: display ? 'visible' : 'hidden' }"
    ></janet-nav-bar>
    <div
        class="mt-xxl-0"
        [ngClass]="{
            'mt-0': locationsBaseModuleData?.locations?.hideHeaderFooter,
            'mt-14': !locationsBaseModuleData?.locations?.hideHeaderFooter
        }"
    >
        <router-outlet></router-outlet>
    </div>
    <ng-container *ngIf="_chatScriptService._isChatInterfaceInitialised">
        <janet-chat janetChat></janet-chat>
    </ng-container>
</div>
