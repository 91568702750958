import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FEATURE_FLAG_API, OFeatureFlagState } from './o-feature-flag.model';

@Injectable({
    providedIn: 'root',
})
export class OFeatureFlagService {
    constructor(private http: HttpClient, @Inject(FEATURE_FLAG_API) public api: string) {}

    getOFeatureFlagState(): Observable<{
        success: boolean;
        result: OFeatureFlagState;
    }> {
        return this.http.get<{ success: boolean; result: OFeatureFlagState }>(
            `${this.api}/api/fetch-feature-flags`
        );
    }
}
