import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FontAwesomeIconService {

    getFaIcon(iconStr: (string | null | undefined)): string {
        if (iconStr) {
            const iconObj = JSON.parse(iconStr);
            return `fa-${iconObj.style} fa-${iconObj.id}`;
        }
        return '';
    }
}
