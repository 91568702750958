import { Inject, Injectable } from '@angular/core';
import { initialize, LDClient, LDFlagValue, LDOptions, LDUser } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Subject } from 'rxjs';
import { kebabCase } from '../../util';
import { createFeatureFlatStoreItem } from './launch-darkly.fns';
import {
    FeatureFlagListItem,
    LaunchDarklyConfig,
    LaunchDarklyConfigToken,
    LaunchDarklyFeatureFlagStore,
    LaunchDarklyLocalFeatureFlags,
    LaunchDarklyStoreType,
} from './launch-darkly.models';

/**
 * A feature flag from launch darkly is not retrieved until
 * the user requests it. Then the store monitors for updates
 * to the requested key.
 *
 * A feature flag provided locally is immediately configured
 * in the store before the user requests it. It needs to be
 * provided by the Injection Token config.
 *
 * A non-existent feature flag will default to false.
 */
@Injectable({
    providedIn: 'root',
})
export class LaunchDarklyStoreService {
    /**
     * Used to verify if user params have already initialized Launch Darkly
     * @private
     */
    private _user: string | null = null;

    /**
     * Launch Darkly Client instance after initialized with user.
     * @private
     */
    private _ldClient: LDClient | null = null;

    /**
     * Wrap Launch Darkly feature flag in individual BehaviorSubject to support
     * metrics in the Launch Darkly platform. If we get all feature flags at once ( .getAll() ),
     * then the user will be logged on all feature flags in the project whether used or not.
     * @private
     */
    private _store: Map<string, LaunchDarklyFeatureFlagStore> = new Map();

    /**
     * Error if user is not a JSON object
     */
    errorEvent$: Subject<any> = new Subject<any>();

    allFeatureFlags$: BehaviorSubject<FeatureFlagListItem[]> = new BehaviorSubject<FeatureFlagListItem[]>([]);

    /**
     * For testing only
     */
    get store() {
        return this._store;
    }

    constructor(@Inject(LaunchDarklyConfigToken) private _config: LaunchDarklyConfig) {
        this._showClientIDWarn();
        if (this._config.localFeatureFlags && Object.keys(this._config.localFeatureFlags).length > 0) {
            this._addLocalKeys(this._config.localFeatureFlags);
        }
    }

    /**
     * Get Feature Flag from store. If flag does not exist in store, add it and
     * get value from Launch Darkly.
     * @param featureFlagKey - kebab-case
     * @param defaultValue
     */
    selectFeature$(featureFlagKey: string, defaultValue?: LDFlagValue): BehaviorSubject<LDFlagValue> {
        featureFlagKey = kebabCase(featureFlagKey);
        defaultValue = defaultValue !== undefined ? defaultValue : false;

        // Ensure only feature flags a user requests are added to the store.
        if (!this._store.has(featureFlagKey)) {
            /**
             * If the feature flag is not already configured in the store
             * as provided by the Injection Token config,
             * assume it's a Launch Darkly feature flag and configure
             * the flag for Launch Darkly
             */
            if (this._ldClient) {
                this._store.set(
                    featureFlagKey,
                    createFeatureFlatStoreItem(
                        featureFlagKey,
                        LaunchDarklyStoreType.LAUNCH_DARKLY,
                        this._ldClient ? this._ldClient.variation(featureFlagKey, defaultValue) : defaultValue
                    )
                );
            } else {
                this._store.set(
                    featureFlagKey,
                    createFeatureFlatStoreItem(
                        featureFlagKey,
                        LaunchDarklyStoreType.LAUNCH_DARKLY,
                        defaultValue // defaults to false if not provided
                    )
                );
            }
        }

        const store: LaunchDarklyFeatureFlagStore | undefined = this._store.get(featureFlagKey);

        return store ? store.store : defaultValue;
    }

    private _updateFeatureFlagsFromLaunchDarkly(): void {
        Array.from(this._store.keys()).forEach((featureFlagKey: string) => {
            if (this._store.has(featureFlagKey) && this._ldClient) {
                const _storeItem: LaunchDarklyFeatureFlagStore | undefined = this._store.get(featureFlagKey);
                const _currentValue = _storeItem ? _storeItem.store.getValue() : false;

                const _newValue = this._ldClient.variation(featureFlagKey, _currentValue);

                // Will override local key state
                if (_storeItem) {
                    _storeItem.store.next(_newValue);
                }

                if (_newValue !== _currentValue && _storeItem) {
                    _storeItem.updatedByLaunchDarkly = true;
                }
            }
        });

        this._publishAllFeatureFlags();
    }

    /**
     * Will only be invoked on startup
     * @private
     */
    private _addLocalKeys(localFlags: LaunchDarklyLocalFeatureFlags) {
        Object.keys(localFlags).forEach((featureFlagKey: string) => {
            this._store.set(
                featureFlagKey,
                createFeatureFlatStoreItem(featureFlagKey, LaunchDarklyStoreType.LOCAL, localFlags[featureFlagKey])
            );
        });

        this._publishAllFeatureFlags();
    }

    /**
     * Called from application with user information and options.
     *
     * See https://docs.launchdarkly.com/sdk/client-side/javascript#getting-started
     *
     * Feature flag targeting and rollouts are determined by the user viewing the page.
     * You must pass a user context to the SDK during initialization before requesting
     * any feature flags with variation. If you fail to pass a valid user context to the
     * SDK during initialization, you will receive a 400 error.
     * @param user: LDUser - defaults to {}
     * @param options: LDOptions - optional, see Launch Darkly docs
     */
    initFeatureFlags(user: LDUser = {}, options?: LDOptions) {
        // Don't re-load if user or user params have not changed.
        try {
            const _user = JSON.stringify(user);
            if (_user === this._user) {
                return;
            }

            this._user = _user;
        } catch (e: any) {
            this.errorEvent$.next(e);
        }

        if (this._config.clientID && this._config.clientID.length) {
            this._ldClient = initialize(this._config.clientID, user, options);

            if (this._ldClient) {
                // Get flags on load
                this._ldClient.on('ready', this._updateFeatureFlagsFromLaunchDarkly.bind(this));

                // Listen for changes
                this._ldClient.on('change', this._updateFeatureFlagsFromLaunchDarkly.bind(this));
            }
        } else {
            this._showClientIDWarn();
        }
    }

    toggleFeature(featureFlagKey: string, value: boolean) {
        const _storeItem: LaunchDarklyFeatureFlagStore | undefined = this._store.get(featureFlagKey);

        if (_storeItem) {
            // Will override local key state
            _storeItem.store.next(value);
        }
    }

    resetToDefaultValues() {
        Array.from(this._store.keys()).forEach((featureFlagKey: string) => {
            const _storeItem: LaunchDarklyFeatureFlagStore | undefined = this._store.get(featureFlagKey);
            if (_storeItem) {
                // Will override local key state
                _storeItem.store.next(_storeItem.defaultValue);
            }
        });

        this._publishAllFeatureFlags();
    }

    /**
     * Use for toggling local feature flags.
     * @private
     */
    private _publishAllFeatureFlags() {
        const allFeatureFlags = Array.from(this.store.values()).map((flagStore: LaunchDarklyFeatureFlagStore) => {
            return {
                key: flagStore.key,
                value: flagStore.store.getValue(),
            };
        });

        this.allFeatureFlags$.next(allFeatureFlags);
    }

    private _showClientIDWarn() {
        if (!(this._config.clientID && this._config.clientID.length > 0)) {
            console.warn('Launch Darkly client id not provided. Provide Launch Darkly client id in app module:');
            console.warn(`
         providers: [
                ...
                {
                    provide: LaunchDarklyClientIDToken,
                    useValue: environment.launchDarklyConfig,
                },
                ...
            ],
        `);
        }
    }
}
