<section *ngIf="copyrights$ | async as copyrights" class="copyrights-wrapper w-100">
    <!-- Copyright -->
    <div class="copyrights text-center p-3 bg-dark text-light d-flex justify-content-center flex-wrap">
        <span> © {{ currentYear }} American First Finance. All Rights Reserved.</span>
        <ul class="d-flex list-unstyled flex-wrap justify-content-center m-0 px-md-3">
            <li *ngFor="let item of copyrights.menuItems.edges; let i = index">
                <a
                    class="text-reset"
                    target="{{ item?.node?.target ? item?.node?.target : '' }}"
                    [href]="sanatizeURL(item.node.url)"
                    >{{ item.node.label }}</a
                >
                <span class="p-2" *ngIf="copyrights.menuItems.edges.length - 1 !== i">|</span>
            </li>
        </ul>
    </div>
    <!-- Copyright -->
</section>
