import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, distinctUntilKeyChanged } from 'rxjs/operators';
import {
    defaultSlickCarouselSettings,
    AFFSlickCarouselSettings,
    TranslateTrackParams,
} from './slick-carousel.model';

export interface UpdateSettings {
    breakpoint: number | null;
    settings: AFFSlickCarouselSettings;
}

@Injectable()
export class SlickCarouselStore {
    private _onDestroy$: Subject<boolean> = new Subject();
    private _originalSettings: AFFSlickCarouselSettings = {
        ...defaultSlickCarouselSettings,
    };

    set originalSettings(s: Partial<AFFSlickCarouselSettings>) {
        this._originalSettings = {
            ...this._originalSettings,
            ...s,
        };
    }

    get originalSettings(): AFFSlickCarouselSettings {
        return this._originalSettings;
    }

    updateSettings: Subject<UpdateSettings> = new Subject<UpdateSettings>();

    state$: BehaviorSubject<AFFSlickCarouselSettings> = new BehaviorSubject<AFFSlickCarouselSettings>({
        ...defaultSlickCarouselSettings,
    });

    speed$: Observable<number> = this.state$.pipe(map((state: AFFSlickCarouselSettings) => state.speed));

    arrows$: Observable<boolean> = this.state$.pipe(map((state: AFFSlickCarouselSettings) => state.arrows));

    dots$: Observable<boolean> = this.state$.pipe(map((state: AFFSlickCarouselSettings) => state.dots));

    slidesToScroll$: Observable<number> = this.state$.pipe(
        map((state: AFFSlickCarouselSettings) => state.slidesToScroll)
    );

    draggable$: Observable<boolean> = this.state$.pipe(map((state: AFFSlickCarouselSettings) => state.draggable));

    translateTrackParams$: Observable<TranslateTrackParams> = this.state$.pipe(
        map((state: AFFSlickCarouselSettings) => {
            return {
                slidesToScroll: state.slidesToScroll,
                slidesToShow: state.slidesToShow,
                touchThreshold: state.touchThreshold,
            };
        })
    );

    get infinite() {
        return this.state$.value.infinite;
    }

    get draggable() {
        return this.state$.value.draggable;
    }

    get speed() {
        return this.state$.value.speed;
    }

    constructor() {
        this.updateSettings
            .pipe(takeUntil(this._onDestroy$), distinctUntilKeyChanged('breakpoint'))
            .subscribe((u: UpdateSettings) => {
                console.log(u);
                this.next(u.settings);
            });
    }

    next(state: Partial<AFFSlickCarouselSettings>): void {
        this.state$.next({
            ...this.state$.value,
            ...state,
        });
    }

    destroy() {
        this._onDestroy$.next(true);
    }
}
