import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class NearByLocationAPIService {
    nearByLocationsURL = environment.nearByLocationsURL;

    constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document) { }

    getNearByLocations(
        latitude: any,
        longitude: any,
        distance: any,
        dealerSlug?: any,
        dealerNumber?: any,
    ): Observable<any> {

        let url = `${this.nearByLocationsURL}${latitude}/${longitude}/${distance}`;

        if (dealerSlug && dealerNumber) {
            url = `${url}/${dealerSlug}/${dealerNumber}`;
        } else {
            url = dealerSlug ? `${url}/${dealerSlug}?version=2` : `${url}?version=2`;
        }

        return this.http.get(url);
    }

}