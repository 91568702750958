import { Component, Input } from '@angular/core';

@Component({
    selector: 'ua2-bouncy-loader',
    templateUrl: './bouncy-loader.component.html',
    styleUrls: ['./bouncy-loader.component.scss'],
})
export class BouncyLoaderComponent {
    @Input() size: number = 13;

    items: any[] = Array(3)
        .fill(0)
        .map((x, i) => i);
}
