import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuDataService } from '../../services/menu-data.service';
import { Nodes, FooterMenu, MenuEdgesArray } from '../../types/menu-data.types';

@Component({
    selector: 'janet-footer-menu-container',
    templateUrl: './footer-menu-container.component.html',
    styleUrls: [],
})
export class FooterMenuContainerComponent implements OnInit, OnDestroy {
    public mainFooterMenuObject$: Subscription;
    public footerMenus: FooterMenu[];

    constructor(private _menuDataSevice: MenuDataService) { }

    ngOnInit(): void {
        this.getFooterMenuData();
    }

    /**
     * @method getFooterMenuData Subscribes to the customerServiceInformation$.
     * @description Used to create the the footer menu object structure using getTree method from menu service.
     * @description Assign the created object to  footerMenus properties.
     */
    getFooterMenuData(): void {
        this.mainFooterMenuObject$ = this._menuDataSevice.customerServiceInformation$.subscribe(
            (footerMenuObject: Nodes) => {
                const footerMenuItems: MenuEdgesArray[] = footerMenuObject.menuItems.edges;
                const data = footerMenuItems.map((item: MenuEdgesArray) => {
                    return { ...item, selected: false };
                });
                this.footerMenus = this._menuDataSevice.getTree(data, 'id', 'parentId');
            }
        );
    }

    ngOnDestroy(): void {
        this.mainFooterMenuObject$?.unsubscribe();
    }
}
