import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SOPInputValueEmitterService {
    private _sopSearchInputValueSubject = new BehaviorSubject<any>(null);
    readonly sopSearchInputValueSubject$ = this._sopSearchInputValueSubject.asObservable();

    private _isSOPLoaderDisplay: any = new BehaviorSubject<unknown>(false);
    readonly isSOPLoaderDisplay$: any = this._isSOPLoaderDisplay.asObservable();

    private _isSOPBodyContentDisplay: any = new BehaviorSubject<any>(false);
    readonly isSOPBodyContentDisplay$: any = this._isSOPBodyContentDisplay.asObservable();

    // eslint-disable-next-line @typescript-eslint/ban-types
    emitSOPSearchInputValue(value: any) {
        this._sopSearchInputValueSubject.next(value);
    }

    soploaderDisplay(value: boolean) {
        this._isSOPLoaderDisplay.next(value);
    }

    sopBodyContentDisplay(value: boolean) {
        this._isSOPBodyContentDisplay.next(value);
    }
}
