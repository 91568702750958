import { gql } from 'apollo-angular';

export const REDIRECTS_QUERY = gql`
    query GetRedirects {
        redirection {
            redirects {
                code
                groupId
                groupName
                matchType
                origin
                target
                type
            }
        }
    }
`;
