import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ua2-corporate-switch, aff-corporate-switch',
  templateUrl: './corporate-switch.component.html',
})
export class CorporateSwitchComponent {
  @Input() switchLabel?: string = '';
  @Input() isChecked?: boolean = false;
  @Input() switchId?: string = '';
  @Input() switchContainerDefaultClass?: string = '';
  @Input() switchContainerCustomClass?: string = 'form-check form-switch text-start corporate-switch-container';
  @Input() switchDefaultClass?: string = 'shadow-none';
  @Input() switchCustomClass?: string = 'form-check-input';
  @Input() switchLabelDefaultClass?: string = '';
  @Input() switchLabelCustomClass?: string = 'form-check-label';
  @Output() selectedOptionsChange = new EventEmitter();

  onChange() {
    this.selectedOptionsChange.emit(this.isChecked);
  }
}
