import { AfterViewInit, Component, EventEmitter, Input, Output, booleanAttribute } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'ua2-corporate-check-box, aff-corporate-check-box',
    templateUrl: './corporate-check-box.component.html',
})
export class CorporateCheckBoxComponent implements AfterViewInit {
    checkBoxType = 'checkbox';
    @Input() checkBoxLabel?: string = '';
    @Input() isChecked?: boolean = false;
    @Input() checkBoxId?: string = '';
    @Input() checkBoxContainerDefaultClass?: string = '';
    @Input() checkBoxContainerCustomClass?: string =
        'form-check text-start d-flex align-items-center check-box-container mt-3';
    @Input() checkBoxDefaultClass?: string = 'shadow-none';
    @Input() checkBoxCustomClass?: string = 'form-check-input me-2';
    @Input() checkBoxLabelDefaultClass?: string = '';
    @Input() checkBoxLabelCustomClass?: string = 'form-check-label form-check-box-label';
    @Input() isDisabled = false;
    @Input({ transform: booleanAttribute }) useNgModel = true;
    @Output() selectedOptionsChange = new EventEmitter();
    @Output() formControlChange = new EventEmitter();
    selected = {} as Selected;
    @Input() control = new FormControl();
    @Output() componentCreated: EventEmitter<any> = new EventEmitter<any>();

    ngAfterViewInit() {
        this.componentCreated.emit(this.control?.value);
    }

    @Input()
    set option(value: any) {
        this.selected = value;
    }

    onChange() {
        this.selected.checked = this.isChecked;
        this.selectedOptionsChange.emit(this.selected);
    }

    onFormControlChange() {
        this.formControlChange.emit(this.control?.value);
    }
}

export interface Selected {
    [key: string]: string | boolean | undefined;
    checked: boolean | undefined;
    label: string;
    value: string;
}
