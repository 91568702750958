import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
    Input,
    ChangeDetectorRef,
    Inject,
    OnDestroy,
    PLATFORM_ID,
    EventEmitter,
    Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SOPInputValueEmitterService } from '../../services/sop-input-value-emitter.service';
import { SOPSearchResultsService } from '../../services/sop-search-results.service';
import { Subscription } from 'rxjs';
import { GeoLocationService } from '../../services/geo-location.service';
import { NearByLocationAPIService } from '../../services/near-by-location-api.service';

@Component({
    selector: 'janet-shop-our-partners-form',
    standalone: false,
    templateUrl: './shop-our-partners-form.component.html',
})
export class ShopOurPartnersFormComponent implements OnInit, OnDestroy {
    shopOurPartnersForm: any;
    _multiSelectOptions: any = [];
    public getScreenWidth: any;
    _formFieldContainerCustomClass =
        'corporate-form-field-container form-floating shop-our-partners-search-form-field';
    _formFieldLabelCustomClass = 'form-label px-3';
    selectErrorMessages = {
        required: 'Field is required',
        minlength: 'Minimum 3 characters required',
    };
    categoryErrorMessages = {
        required: 'Field is required',
    };
    showErrorMessage = false;
    isLoaderDisplay = false;
    _searchButtonClass = 'zip-search-button-in-active';
    _sliderButtonContainerDefaultClass = 'text-center rounded-circle';
    _sliderButtonIconClass = 'fa-light fa-sliders text-center';
    _sliderButtonBadgeClass =
        'position-relative rounded-circle d-flex justify-content-center align-items-center text-center';
    sliderButtonContainerClass = this._sliderButtonContainerDefaultClass + ' slider-button-container-show';
    sliderButtonIconClass = this._sliderButtonIconClass + ' slider-button-icon-show';
    sliderButtonBadgeClass = this._sliderButtonBadgeClass + ' slider-button-count-badge-show';
    _formFieldCategoryContainerCustomClass =
        'corporate-form-field-container form-floating shop-our-partners-search-category-form-field d-none d-xxl-block';
    _formFieldCategoryLabelCustomClass = 'form-label';
    showPanel = false;
    _showPanel = '';
    selectedOptionsList: any = [];
    @ViewChild('searchTextInputWrapper') searchTextInputWrapper: ElementRef;
    searchParamValue: any = null;
    filterParamValue: any = null;
    private queryParamSubscription: Subscription;
    categoryList: any = [];
    sopLoaderDisplaysubscription: Subscription;
    _locationsPageDetails: any;
    _locationsData: any;
    _dealerInfoData: any;
    _browser = false;
    invalidChars = ['-', '+', 'e'];
    _searchButtonVisible = false;
    @ViewChild('storeSearchButton', { static: false }) storeSearchButton: ElementRef<any>;
    _locationsLanguageLabelsObj: any;
    _storeListErrorMessage = '';
    @Output() storeListErrMsg: EventEmitter<any> = new EventEmitter();
    isFilterParamFunctionInvoked = false;
    filterQueryParam: any = null;

    constructor(
        public formBuilder: FormBuilder,
        public _cdr: ChangeDetectorRef,
        public route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        public _sopInputValueEmitterService: SOPInputValueEmitterService,
        private _sopSearchResultsService: SOPSearchResultsService,
        @Inject(PLATFORM_ID) private _platformId: any,
        private geoLocationService: GeoLocationService,
        private _nearByLocationAPIService: NearByLocationAPIService
    ) {
        this.getScreenWidth = window.innerWidth;
        if (isPlatformBrowser(this._platformId)) {
            this._browser = true;
        }
    }

    ngOnInit(): void {
        this.selectedOptionsList = [];
        this.searchParamValue = null;
        this.filterParamValue = null;
        this.categoryList = [];
        this.filterQueryParam = null;

        this.sopLoaderDisplaysubscription = this._sopInputValueEmitterService.isSOPLoaderDisplay$.subscribe(
            (data: boolean) => {
                this.isLoaderDisplay = data;
                this.formFieldContainerCustomClass = data;
                this.formFieldLabelCustomClass = data;
                this.formFieldCategoryContainerCustomClass = data;
                this.formFieldCategoryLabelCustomClass = data;
            }
        );

        this.queryParamSubscription = this.route?.queryParams?.subscribe((params) => {
            const searchParam = params?.search;
            const filterParam = params?.filter;
            this.filterQueryParam = filterParam;
            if (searchParam) {
                this.searchParamValue = searchParam;
            }
            if (filterParam) {
                let filteredOption: any = [];

                filterParam
                    ?.toString()
                    ?.trim()
                    ?.split(',')
                    .forEach((element: any) => {
                        if (this._multiSelectOptions && this._multiSelectOptions?.length > 0) {
                            const filteredVal: any = this._multiSelectOptions.find((option: any) => {
                                return (
                                    element.toString().toLowerCase() ===
                                        option?.apiKey?.toString().toLowerCase() ||
                                    element.toString().toLowerCase() === option?.value?.toString().toLowerCase() ||
                                    element.toString().toLowerCase() === option?.label?.toString().toLowerCase()
                                );
                            });
                            if (filteredVal) {
                                filteredOption.push(filteredVal.apiKey);
                            }
                            this.isFilterParamFunctionInvoked = true;
                        }
                    });
                this.filterParamValue = filteredOption?.toString()?.trim();
                this._cdr.detectChanges();
            }
        });

        this.shopOurPartnersForm = this.formBuilder.group({
            textSearch: [this.searchParamValue ? this.searchParamValue : ''],
            categorySearch: [this.categoryList],
        });

        if (this._locationsLanguageLabelsObj && this._locationsLanguageLabelsObj?.storeListDefaultErrMsg) {
            //NOSONAR
            this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg1}. ${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg2}`;
        }
    }

    ngOnDestroy(): void {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }

        if (this.sopLoaderDisplaysubscription) {
            this.sopLoaderDisplaysubscription.unsubscribe();
        }
    }

    @Input()
    set multiSelectOptions(data: any) {
        this._multiSelectOptions = data.map((obj: any) => ({ ...obj, checked: false }));
        if (this.filterQueryParam && !this.isFilterParamFunctionInvoked) {
            let filteredOption: any = [];

            this.filterQueryParam
                ?.toString()
                ?.trim()
                ?.split(',')
                .forEach((element: any) => {
                    if (this._multiSelectOptions && this._multiSelectOptions?.length > 0) {
                        const filteredVal: any = this._multiSelectOptions.find((option: any) => {
                            return (
                                element.toString().toLowerCase() === option?.apiKey?.toString().toLowerCase() ||
                                element.toString().toLowerCase() === option?.value?.toString().toLowerCase() ||
                                element.toString().toLowerCase() === option?.label?.toString().toLowerCase()
                            );
                        });
                        if (filteredVal) {
                            filteredOption.push(filteredVal.apiKey);
                        }
                        this.isFilterParamFunctionInvoked = true;
                    }
                });
            this.filterParamValue = filteredOption?.toString()?.trim();
            this._cdr.detectChanges();
            this.autoFillCategory();
        }
    }

    get multiSelectOptions(): any {
        return this._multiSelectOptions;
    }

    @Input()
    set locationsPageDetails(data: any) {
        this._locationsPageDetails = data;
        this._cdr.detectChanges();
    }

    @Input()
    set locationsData(data: any) {
        this._locationsData = data;
    }

    @Input()
    set dealerInfoData(data: any) {
        this._dealerInfoData = data;
    }

    @Input()
    set locationsLanguageLabelsObj(data: any) {
        this._locationsLanguageLabelsObj = data;
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
    }

    set formFieldContainerCustomClass(isDisplay: boolean) {
        const defaultClass = 'corporate-form-field-container form-floating shop-our-partners-search-form-field';
        if (isDisplay) {
            this._formFieldContainerCustomClass = `${defaultClass} pointer-none form-field-disabled`;
        } else {
            this._formFieldContainerCustomClass = `${defaultClass}`;
        }
        this._cdr.detectChanges();
    }

    get formFieldContainerCustomClass(): any {
        return this._formFieldContainerCustomClass;
    }

    set formFieldLabelCustomClass(isDisplay: boolean) {
        const defaultClass = 'form-label px-3';
        if (isDisplay) {
            this._formFieldLabelCustomClass = `${defaultClass} form-field-disabled`;
        } else {
            this._formFieldLabelCustomClass = `${defaultClass}`;
        }
        this._cdr.detectChanges();
    }

    get formFieldLabelCustomClass(): any {
        return this._formFieldLabelCustomClass;
    }

    set formFieldCategoryContainerCustomClass(isDisplay: boolean) {
        const defaultClass =
            'corporate-form-field-container form-floating shop-our-partners-search-category-form-field d-none d-xxl-block';
        if (isDisplay) {
            this._formFieldCategoryContainerCustomClass = `${defaultClass} pointer-none form-field-disabled`;
        } else {
            this._formFieldCategoryContainerCustomClass = `${defaultClass}`;
        }
        this._cdr.detectChanges();
    }

    get formFieldCategoryContainerCustomClass(): any {
        return this._formFieldCategoryContainerCustomClass;
    }

    set formFieldCategoryLabelCustomClass(isDisplay: boolean) {
        const defaultClass = 'form-label';
        if (isDisplay) {
            this._formFieldCategoryLabelCustomClass = `${defaultClass} form-field-disabled`;
        } else {
            this._formFieldCategoryLabelCustomClass = `${defaultClass}`;
        }
        this._cdr.detectChanges();
    }

    get formFieldCategoryLabelCustomClass(): any {
        return this._formFieldCategoryLabelCustomClass;
    }

    public get searchButtonClass(): string {
        if (this.isLoaderDisplay) {
            this._searchButtonVisible = false;
            this.setSearchButtonColor();
            return 'zip-search-button-in-active';
        }
        if (this._searchButtonClass === 'zip-search-button-in-active') {
            this._searchButtonVisible = false;
        }
        this.setSearchButtonColor();
        return `${this._searchButtonClass}`;
    }

    handleEvent(object: any): void {
        if (object.event === 'focusin') {
            this._searchButtonClass = 'zip-search-button-active';
            this._searchButtonVisible = true;
            this.setSearchButtonColor();

            if (this.getScreenWidth <= 767) {
                this.sliderButtonContainerClass = `${this._sliderButtonContainerDefaultClass} slider-button-container-hide`;
                this.sliderButtonIconClass = `${this._sliderButtonIconClass} slider-button-icon-hide`;
                this.sliderButtonBadgeClass = `${this._sliderButtonBadgeClass} slider-button-count-badge-hide`;
            } else {
                this.sliderButtonContainerClass = `${this._sliderButtonContainerDefaultClass} slider-button-container-show`;
                this.sliderButtonIconClass = `${this._sliderButtonIconClass} slider-button-icon-show`;
                this.sliderButtonBadgeClass = `${this._sliderButtonBadgeClass} slider-button-count-badge-show`;
            }
        }
        if (object.event === 'focusout') {
            this.sliderButtonContainerClass = `${this._sliderButtonContainerDefaultClass} slider-button-container-show`;
            this.sliderButtonIconClass = `${this._sliderButtonIconClass} slider-button-icon-show`;
            this.sliderButtonBadgeClass = `${this._sliderButtonBadgeClass} slider-button-count-badge-show`;

            if (this.getScreenWidth >= 768) {
                setTimeout(() => {
                    this._searchButtonVisible = false;
                    this._searchButtonClass = 'zip-search-button-in-active';
                    this.setSearchButtonColor();
                    this._cdr.detectChanges();
                }, 300);
            } else {
                this._searchButtonVisible = false;
                this._searchButtonClass = 'zip-search-button-in-active';
                this.setSearchButtonColor();
            }
        }
        if (object.event === 'input') {
            if (this._dealerInfoData?.bBranded) {
                this.onBrandDealerInputChange(object.data);
            } else {
                this.onInputChange(object.data);
            }
        }

        if (object.event === 'keydown') {
            if (this._dealerInfoData?.bBranded) {
                this.onBrandDealerInputKeyDown(object.data);
            }
        }
    }

    onInputChange(event: any): void {
        const value = event.target.value;
        if (value.toString().trim().length >= 5 && !isNaN(value.slice(0, 5))) {
            const zipCode = event.target.value.replace(/[e+-]/gi, '');
            event.target.value = zipCode.slice(0, 5);
            this.shopOurPartnersForm.patchValue({
                textSearch: zipCode.slice(0, 5),
            });
        }
    }

    onBrandDealerInputChange(event: any): void {
        const value = event.target.value.replace(/[e+-]/gi, '');
        event.target.value = value.slice(0, 5);
    }

    onBrandDealerInputKeyDown(event: KeyboardEvent): void {
        if (this.invalidChars.includes(event.key)) {
            event.preventDefault();
        }
    }

    togglePanel() {
        if (this.isLoaderDisplay) {
            return;
        }
        this.showPanel = !this.showPanel;
        this._showPanel = this.showPanel.toString();
        if (this.getScreenWidth <= 1439) {
            this.onSelectCategories();
        }
    }

    toggleCheckBox(data: any) {
        const options: any = this._multiSelectOptions.map((obj: any) => obj);
        this._multiSelectOptions = options;
    }

    toggleMultiSelectOption(data: any) {
        if (this.getScreenWidth >= 1440) {
            this.onSelectCategories();
        }
    }

    inputChange() {
        if (
            this.getScreenWidth < 768 &&
            this.shopOurPartnersForm?.get('textSearch')?.value?.toString()?.trim()?.length > 0
        ) {
            //this.onZipSearchBtnClick('inputChange');
        }
    }

    onSelectCategories() {
        const selectedOptionsList: any = this._multiSelectOptions
            .filter((item: any) => item?.checked)
            .map((item: any) => item?.apiKey);

        if (
            this.selectedOptionsList.length !== selectedOptionsList.length ||
            this.selectedOptionsList.some((value: any, index: any) => value !== selectedOptionsList[index])
        ) {
            this.selectedOptionsList = selectedOptionsList;
            this.onSubmit();
        }
    }

    getCategoriesCount() {
        return this._multiSelectOptions.filter((item: any) => item.checked).length;
    }

    autoFillCategory(): void {
        if (!this.filterParamValue || this.filterParamValue.length <= 0) {
            return;
        }

        const filterParamValue = this.filterParamValue?.toString()?.split(',');

        if (filterParamValue && Array.isArray(filterParamValue) && filterParamValue.length > 0) {
            this._multiSelectOptions = this._multiSelectOptions.map((obj: any) => {
                const isSelected = filterParamValue.some((value: any) => {
                    return (
                        value.toString()?.trim()?.toLowerCase() ===
                            obj?.label?.toString()?.trim()?.toLowerCase() ||
                        value.toString()?.trim()?.toLowerCase() ===
                            obj?.value?.toString()?.trim()?.toLowerCase() ||
                        value.toString()?.trim()?.toLowerCase() === obj?.apiKey?.toString()?.trim()?.toLowerCase()
                    );
                });
                obj.checked = isSelected;
                if (obj.checked) {
                    this.categoryList.push(obj.label);
                    this.selectedOptionsList.push(obj.apiKey);
                }
                return obj;
            });

            if (this.shopOurPartnersForm) {
                this.shopOurPartnersForm.patchValue({
                    categorySearch: this.categoryList.join(','),
                });
                this._cdr.detectChanges();
            }
        }
    }

    setSearchButtonColor() {
        //NOSONAR
        let backgroundClr = 'transparent';
        let linkClr = 'transparent';

        if (this._searchButtonVisible) {
            const locations = this._locationsData?.locations;
            if (locations && this._dealerInfoData?.bBranded) {
                backgroundClr = locations.backgroundColor ?? '#262626';
                linkClr = locations.linkColor ?? '#fff';
            } else {
                backgroundClr = '#262626';
                linkClr = '#fff';
            }
        }

        if (this.storeSearchButton?.nativeElement) {
            this.storeSearchButton.nativeElement.setAttribute(
                'style',
                `background-color:${backgroundClr};color:${linkClr};`
            );
        }
    }

    updateQueryParams(textSearch: any, categorySearch: any) {
        if (textSearch && textSearch?.toString()?.trim()?.length > 0) {
            const queryParams: any = { search: textSearch };
            if (categorySearch && categorySearch.length > 0) {
                queryParams['filter'] = categorySearch.toString();
            }
            this.router.navigate([], { queryParams });
        } else {
            if (categorySearch && categorySearch.length > 0) {
                const queryParams: any = { filter: categorySearch.toString() };
                this.router.navigate([], { queryParams });
            } else {
                this.router.navigate([], {});
            }
        }
    }

    async onSubmit() {
        //NOSONAR
        let textSearch = this.shopOurPartnersForm?.get('textSearch')?.value;
        const categorySearch = this.selectedOptionsList?.toString();

        if (!this._dealerInfoData?.bBranded && (!textSearch || textSearch?.toString()?.trim()?.length < 3)) {
            this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListMinCharErrMsg}`;
            this.updateQueryParams(textSearch, categorySearch);
            const response = {
                aResults: [],
            };
            this._sopInputValueEmitterService.emitSOPSearchInputValue(response);
            this.storeListErrMsg.emit(this._storeListErrorMessage);
            return;
        } else if (this._dealerInfoData?.bBranded && (!textSearch || textSearch?.toString()?.trim()?.length < 5)) {
            if (!this.searchParamValue) {
                const location = await this.getLatLangDetails();
                if (location?.latitude && location?.longitude) {
                    const zipCode = await this.getZipCode(location?.latitude, location?.longitude);
                    if (
                        this._locationsLanguageLabelsObj &&
                        this._locationsLanguageLabelsObj?.storeListBrandErrMsg
                    ) {
                        //NOSONAR
                        this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListBrandErrMsg} ${
                            zipCode ? zipCode?.trim() : ''
                        }`;
                    }
                    let latitude: any = location?.latitude;
                    let longitude: any = location?.longitude;
                    let distance = '';
                    let dealerSlug = '';
                    let dealerNumber = '';
                    if (this._dealerInfoData) {
                        distance =
                            this._dealerInfoData?.fDistance &&
                            this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0
                                ? parseInt(this._dealerInfoData?.fDistance).toString()
                                : '3000';
                        dealerSlug =
                            this._dealerInfoData?.sHashId &&
                            this._dealerInfoData?.sHashId?.toString()?.trim()?.length > 0
                                ? this._dealerInfoData?.sHashId?.toString()
                                : 'd';
                        dealerNumber =
                            this._dealerInfoData?.sDealer &&
                            this._dealerInfoData?.sDealer?.toString()?.trim()?.length > 0
                                ? this._dealerInfoData?.sDealer?.toString()
                                : '';
                    }

                    this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                    this.getNearByLocationsData(
                        { latitude: latitude, longitude: longitude },
                        distance,
                        dealerSlug,
                        dealerNumber
                    );
                } else {
                    let latitude: any = '';
                    let longitude: any = '';
                    let distance = '';
                    let dealerSlug = '';
                    let dealerNumber = '';
                    if (this._dealerInfoData) {
                        latitude =
                            this._dealerInfoData?.fDefaultLat &&
                            this._dealerInfoData?.fDefaultLat?.toString()?.trim()?.length > 0
                                ? parseFloat(this._dealerInfoData?.fDefaultLat).toString()
                                : 39.111689;
                        longitude =
                            this._dealerInfoData?.fDefaultLon &&
                            this._dealerInfoData?.fDefaultLon?.toString()?.trim()?.length > 0
                                ? parseFloat(this._dealerInfoData?.fDefaultLon).toString()
                                : -97.119141;
                        distance =
                            this._dealerInfoData?.fDistance &&
                            this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0
                                ? parseInt(this._dealerInfoData?.fDistance).toString()
                                : '3000';
                        dealerSlug =
                            this._dealerInfoData?.sHashId &&
                            this._dealerInfoData?.sHashId?.toString()?.trim()?.length > 0
                                ? this._dealerInfoData?.sHashId?.toString()
                                : 'd';
                        dealerNumber =
                            this._dealerInfoData?.sDealer &&
                            this._dealerInfoData?.sDealer?.toString()?.trim()?.length > 0
                                ? this._dealerInfoData?.sDealer?.toString()
                                : '';
                    }

                    this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                    this.getNearByLocationsData(
                        { latitude: latitude, longitude: longitude },
                        distance,
                        dealerSlug,
                        dealerNumber
                    );
                }
            }

            if (!isNaN(textSearch) && textSearch.trim() !== '') {
                const dealerSlug = this.getDealerSlug();
                const distance =
                    this._dealerInfoData?.fDistance &&
                    this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0
                        ? parseInt(this._dealerInfoData?.fDistance).toString()
                        : '50';
                this.getSOPSearchResults(null, '', { latitude: '0', longitude: '0' }, dealerSlug, distance, '');
            }
        }

        this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg1} ${
            textSearch ? textSearch?.trim() : ''
        }. ${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg2}`;

        if (this._locationsPageDetails?.isDealerLocationPage) {
            if (this._locationsPageDetails.isBrandDealer) {
                if (this._browser) {
                    textSearch = textSearch?.toString()?.trim().slice(0, 5);
                    if (
                        this._locationsLanguageLabelsObj &&
                        this._locationsLanguageLabelsObj?.storeListBrandErrMsg
                    ) {
                        //NOSONAR
                        this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListBrandErrMsg} ${
                            textSearch ? textSearch?.trim() : ''
                        }`;
                    }
                    this._sopInputValueEmitterService.soploaderDisplay(true);
                    const location = await this.getLatLangDetails();
                    if (
                        !location?.latitude &&
                        !location?.longitude &&
                        textSearch &&
                        this.searchParamValue &&
                        this.searchParamValue?.toString()?.length > 0
                    ) {
                        this.updateQueryParams(textSearch, categorySearch);
                        if (
                            textSearch?.toString()?.trim()?.length === 5 &&
                            !isNaN(textSearch?.toString()?.trim())
                        ) {
                            let distance = '';
                            let dealerSlug = '';
                            let dealerNumber = '';
                            if (this._dealerInfoData) {
                                distance =
                                    this._dealerInfoData?.fDistance &&
                                    this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0
                                        ? parseInt(this._dealerInfoData?.fDistance).toString()
                                        : '3000';
                                dealerSlug =
                                    this._dealerInfoData?.sHashId &&
                                    this._dealerInfoData?.sHashId?.toString()?.trim()?.length > 0
                                        ? this._dealerInfoData?.sHashId?.toString()
                                        : 'd';
                                dealerNumber =
                                    this._dealerInfoData?.sDealer &&
                                    this._dealerInfoData?.sDealer?.toString()?.trim()?.length > 0
                                        ? this._dealerInfoData?.sDealer?.toString()
                                        : '';
                            }
                            this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                            this.getSOPSearchResults(
                                textSearch,
                                categorySearch,
                                { latitude: '0', longitude: '0' },
                                distance,
                                dealerSlug,
                                dealerNumber
                            );
                        } else {
                            const response = {
                                aResults: [],
                            };
                            this._sopInputValueEmitterService.emitSOPSearchInputValue(response);
                        }
                    } else if (
                        !location?.latitude &&
                        !location?.longitude &&
                        textSearch &&
                        (!this.searchParamValue || this.searchParamValue?.toString()?.length <= 0)
                    ) {
                        this.updateQueryParams(textSearch, categorySearch);
                        if (
                            textSearch?.toString()?.trim()?.length === 5 &&
                            !isNaN(textSearch?.toString()?.trim())
                        ) {
                            let latitude: any = '';
                            let longitude: any = '';
                            let distance = '';
                            let dealerSlug = '';
                            let dealerNumber = '';
                            if (this._dealerInfoData) {
                                latitude =
                                    this._dealerInfoData?.fDefaultLat &&
                                    this._dealerInfoData?.fDefaultLat?.toString()?.trim()?.length > 0
                                        ? parseFloat(this._dealerInfoData?.fDefaultLat).toString()
                                        : 39.111689;
                                longitude =
                                    this._dealerInfoData?.fDefaultLon &&
                                    this._dealerInfoData?.fDefaultLon?.toString()?.trim()?.length > 0
                                        ? parseFloat(this._dealerInfoData?.fDefaultLon).toString()
                                        : -97.119141;
                                distance =
                                    this._dealerInfoData?.fDistance &&
                                    this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0
                                        ? parseInt(this._dealerInfoData?.fDistance).toString()
                                        : '3000';
                                dealerSlug =
                                    this._dealerInfoData?.sHashId &&
                                    this._dealerInfoData?.sHashId?.toString()?.trim()?.length > 0
                                        ? this._dealerInfoData?.sHashId?.toString()
                                        : 'd';
                                dealerNumber =
                                    this._dealerInfoData?.sDealer &&
                                    this._dealerInfoData?.sDealer?.toString()?.trim()?.length > 0
                                        ? this._dealerInfoData?.sDealer?.toString()
                                        : '';
                            }
                            this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                            this.getSOPSearchResults(
                                textSearch,
                                categorySearch,
                                { latitude: latitude, longitude: longitude },
                                distance,
                                dealerSlug,
                                dealerNumber
                            );
                        } else {
                            const response = {
                                aResults: [],
                            };
                            this._sopInputValueEmitterService.emitSOPSearchInputValue(response);
                        }
                    } else if (
                        location?.latitude &&
                        location?.longitude &&
                        textSearch &&
                        (!this.searchParamValue ||
                            this.searchParamValue?.toString()?.length <= 0 ||
                            (this.searchParamValue && this.searchParamValue?.toString()?.length > 0))
                    ) {
                        this.updateQueryParams(textSearch, categorySearch);
                        if (
                            textSearch?.toString()?.trim()?.length === 5 &&
                            !isNaN(textSearch?.toString()?.trim())
                        ) {
                            let latitude = location?.latitude;
                            let longitude = location?.longitude;
                            let distance = '';
                            let dealerSlug = '';
                            let dealerNumber = '';
                            if (this._dealerInfoData) {
                                distance =
                                    this._dealerInfoData?.fDistance &&
                                    this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0
                                        ? parseInt(this._dealerInfoData?.fDistance).toString()
                                        : '3000';
                                dealerSlug =
                                    this._dealerInfoData?.sHashId &&
                                    this._dealerInfoData?.sHashId?.toString()?.trim()?.length > 0
                                        ? this._dealerInfoData?.sHashId?.toString()
                                        : 'd';
                                dealerNumber =
                                    this._dealerInfoData?.sDealer &&
                                    this._dealerInfoData?.sDealer?.toString()?.trim()?.length > 0
                                        ? this._dealerInfoData?.sDealer?.toString()
                                        : '';
                            }
                            this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                            this.getSOPSearchResults(
                                textSearch,
                                categorySearch,
                                { latitude: latitude, longitude: longitude },
                                distance,
                                dealerSlug,
                                dealerNumber
                            );
                        } else {
                            const response = {
                                aResults: [],
                            };
                            this._sopInputValueEmitterService.emitSOPSearchInputValue(response);
                        }
                    } else {
                        this._sopInputValueEmitterService.soploaderDisplay(false);
                    }
                }
            } else {
                if (this._browser) {
                    this._sopInputValueEmitterService.soploaderDisplay(true);
                    this.updateQueryParams(textSearch, categorySearch);
                    if (textSearch && textSearch?.toString()?.trim()?.length >= 3) {
                        let dealerSlug = this.getDealerSlug();
                        let distance =
                            this._dealerInfoData?.fDistance &&
                            this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0
                                ? parseInt(this._dealerInfoData?.fDistance).toString()
                                : '50';
                        this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                        this.getSOPSearchResults(
                            textSearch,
                            categorySearch,
                            { latitude: '0', longitude: '0' },
                            distance,
                            dealerSlug
                        );
                    } else {
                        const response = {
                            aResults: [],
                        };
                        this._sopInputValueEmitterService.emitSOPSearchInputValue(response);
                    }
                }
            }
        } else {
            this._sopInputValueEmitterService.soploaderDisplay(true);
            this.updateQueryParams(textSearch, categorySearch);
            if (textSearch && textSearch?.toString()?.trim()?.length >= 3) {
                let dealerSlug = this.getDealerSlug();
                let distance =
                    this._dealerInfoData?.fDistance &&
                    this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0
                        ? parseInt(this._dealerInfoData?.fDistance).toString()
                        : '50';
                this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                this.getSOPSearchResults(
                    textSearch,
                    categorySearch,
                    { latitude: '0', longitude: '0' },
                    distance,
                    dealerSlug
                );
            } else {
                const response = {
                    aResults: [],
                };
                this._sopInputValueEmitterService.emitSOPSearchInputValue(response);
            }
        }

        this.storeListErrMsg.emit(this._storeListErrorMessage);
    }

    async getLatLangDetails() {
        try {
            const location = await this.geoLocationService.getGeoLocation();
            return location;
        } catch (error) {
            return { latitude: null, longitude: null };
        }
    }

    async getZipCode(lat: any, lang: any) {
        try {
            const zipCode = await this.geoLocationService.getPostalCode(lat, lang);
            return zipCode;
        } catch (error) {
            return '';
        }
    }

    getDealerSlug() {
        let dealerSlug = null;
        const pathname = window?.location?.pathname;
        if (pathname) {
            const pathComponents: any = pathname.split('/').filter((component) => component.trim() !== '');
            if (pathComponents && pathComponents.length >= 2 && pathComponents[0] === 'locations') {
                dealerSlug = pathComponents[1];
            }
        }
        return dealerSlug;
    }

    getSOPSearchResults(
        textSearch: any,
        categorySearch: any,
        location: any,
        distance: any,
        dealerSlug: any,
        dealerNumber?: any
    ) {
        this._sopSearchResultsService
            .getSOPSearchResults(textSearch, categorySearch, location, distance, dealerSlug, dealerNumber)
            .subscribe(
                (response: any) => {
                    if (
                        response &&
                        ((response?.aResults && Array.isArray(response?.aResults)) || Array.isArray(response))
                    ) {
                        this._sopInputValueEmitterService.emitSOPSearchInputValue(response);
                    }
                },
                (error: any) => {
                    console.error('SOP Search API Error');
                    this._sopInputValueEmitterService.soploaderDisplay(false);
                }
            );
    }

    getNearByLocationsData(location: any, distance: any, dealerSlug: any, dealerNumber?: any) {
        this._nearByLocationAPIService
            .getNearByLocations(location?.latitude, location?.longitude, distance, dealerSlug, dealerNumber)
            .subscribe(
                (response: any) => {
                    if (
                        response &&
                        ((response?.aResults && Array.isArray(response?.aResults)) || Array.isArray(response))
                    ) {
                        this._sopInputValueEmitterService.emitSOPSearchInputValue(response);
                    }
                },
                (error: any) => {
                    console.error('Near By Locations API Error');
                    this._sopInputValueEmitterService.soploaderDisplay(false);
                },
                () => {
                    this._sopInputValueEmitterService.soploaderDisplay(false);
                }
            );
    }
}
