import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { nuxtEmit } from './nuxt.actions';
import { selectNuxtOn } from './nuxt.selectors';

@Injectable({
    providedIn: 'root',
})
export class NuxtFacade {
    constructor(private store: Store) {}

    $on<T>(event: string): Observable<T> {
        return this.store.select(selectNuxtOn(event));
    }

    $emit(event: string, value: any) {
        this.store.dispatch(nuxtEmit(event, value));
    }
}
