<div class="merchant-stores-content-container m-0 p-0 ms-3 ms-md-0">
    <div
        class="row merchant-store-lists-cont mb-3 mb-md-0 me-md-2 position-relative"
        (click)="onStoresContainerClick($event)"
    >
        <div class="store-card-highlighter" #highlighter></div>
        <div *ngIf="totalRecords === 0" class="col-12 warning-no-data mt-3">
            <p class="mb-0">Sorry, but there aren’t any stores that match your search.</p>
        </div>
        <div
            class="col-12 col-xxl-6 card-container ps-0 pe-0"
            *ngFor="let merchantStoreList of getExtendedDisplayListSearchResults(); let i = index"
            [ngClass]="{
                'highlighted-block': selectedMarkerIndexVal === i && paginationClicked,
                'no-border-container': !merchantStoreList.PDNAM1
            }"
            [id]="'card_' + i"
            (click)="onCardClick(i)"
        >
            <div class="card card-wrap border-0 rounded-0">
                <div
                    class="card-body col-md-8"
                    [ngClass]="{
                        'p-3': !(
                            merchantStoreList?.DTYPE?.toLowerCase() === 'risa' &&
                            merchantStoreList?.PDST?.toLowerCase() === 'ca'
                        ),
                        'p-3 pe-40':
                            merchantStoreList?.DTYPE?.toLowerCase() === 'risa' &&
                            merchantStoreList?.PDST?.toLowerCase() === 'ca'
                    }"
                >
                    <h5 class="card-title">{{ merchantStoreList.PDNAM1 }}</h5>
                    <p class="card-text m-0">{{ merchantStoreList.PDADR1 }}</p>
                    <p class="card-text mt-2">{{ merchantStoreList.CATEGORY }}</p>
                </div>
                <div
                    class="merchant-stores-button ps-0 pe-36 top-20"
                    *ngIf="
                        merchantStoreList.PDNAM1 &&
                        !(
                            merchantStoreList?.DTYPE?.toLowerCase() === 'risa' &&
                            merchantStoreList?.PDST?.toLowerCase() === 'ca'
                        )
                    "
                >
                    <ua2-aff-button
                        class="display-content"
                        [url]="merchantStoreList.TARGETURL + getQueryParams()"
                        [title]=""
                        [buttonLabel]="'Apply'"
                        [target]="'_blank'"
                        [id]=""
                        [buttonCustomClass]="
                            'store-apply-button px-3 py-25 d-inline-flex flex-row justify-content-center align-items-center aff-button-state aff-button-properties'
                        "
                    ></ua2-aff-button>
                    <p class="miles m-0" *ngIf="merchantStoreList?.DISTANCE !== undefined">
                        {{ merchantStoreList?.DISTANCE }} MI
                    </p>
                </div>
                <div
                    class="merchant-stores-button-apply ps-0 pe-36 top-20"
                    *ngIf="
                        merchantStoreList.PDNAM1 &&
                        merchantStoreList?.DTYPE?.toLowerCase() === 'risa' &&
                        merchantStoreList?.PDST?.toLowerCase() === 'ca'
                    "
                >
                    <div class="apply-store-section">
                        <p class="apply-to-store m-0">Apply In Store</p>
                        <p class="miles m-0" *ngIf="merchantStoreList?.DISTANCE !== undefined">
                            {{ merchantStoreList?.DISTANCE }} MI
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
