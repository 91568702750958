import { Injectable, RendererFactory2, Renderer2 } from '@angular/core';
import {
    ONE_TRUST_COOKIE_SCRIPT,
    ONE_TRUST_DATA_DOMAIN_CONSET,
    ONE_TRUST_DATA_DOMAIN_SCRIPT,
} from '@janet/shared/domain';
import { environment } from '../../../environments/environment';
import { ScriptService } from './script.service';

@Injectable({ providedIn: 'root' })
export class CookieComplianceService {
    private _renderer: Renderer2;
    /** Adding  One Trust Cookie Compliance scripts dynamically  based on env*/
    constructor(private _scriptService: ScriptService, private rendererFactory: RendererFactory2) {
        this._renderer = rendererFactory.createRenderer(null, null);
    }

    addCookieComplianceScript(): void {
        const env = environment.production ? '' : '-test';
        const attributes = [{ name: 'data-domain-script', value: ONE_TRUST_DATA_DOMAIN_SCRIPT + env }];
        this._scriptService.loadJsScript(
            this._renderer,
            ONE_TRUST_COOKIE_SCRIPT,
            'text/javascript',
            '',
            attributes
        );

        this._scriptService.loadJsScript(
            this._renderer,
            `${ONE_TRUST_DATA_DOMAIN_CONSET}/${ONE_TRUST_COOKIE_SCRIPT}-${env}/OtAutoBlock.js}`,
            'text/javascript',
            ''
        );
        this._scriptService.loadJsScript(this._renderer, '', 'text/javascript', `function OptanonWrapper() {}`);
    }
}
