import { GTMscriptService } from './core/services/gtm-script.service';
import { CookieComplianceService } from './core/services/cookie-compliance.service';
import {
    AfterViewInit,
    Component,
    HostListener,
    Inject,
    ViewContainerRef,
    PLATFORM_ID,
    Renderer2,
    TransferState,
    OnInit,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DynamicComponentService, AffModalComponent, ModalService } from '@janet/corporate/ui-corporate';
import { ExitSiteConfirmationComponent } from './components/exit-site-confirmation/exit-site-confirmation.component';
import { ChatScriptService } from './core/services/chat-script.service';
import { FeatureFlagToggleService } from '@janet/shared/ui-common';
import { environment } from '../environments/environment';
import {
    SOP_BASE_URL_PARAMS,
    SOP_DEALER_INFO_STATE_KEY,
    SOP_LOCATION_DATA_STATE_KEY,
} from '../constants/state-keys';

@Component({
    selector: 'janet-root',
    templateUrl: './app.component.html',
    styleUrls: [],
})
export class AppComponent implements OnInit, AfterViewInit {
    display = true;
    dealerInfo: any = null;
    locationsBaseModuleData: any = null;
    constructor(
        public _chatScriptService: ChatScriptService,
        private _cookieCompliance: CookieComplianceService,
        private featureFlagToggleService: FeatureFlagToggleService,
        private _gtmScriptService: GTMscriptService,
        @Inject(DynamicComponentService)
        private dynamicComponentService: DynamicComponentService,
        @Inject(ViewContainerRef) private viewContainerRef: ViewContainerRef,
        private modalService: ModalService,
        @Inject(PLATFORM_ID) private platformId: any,
        private renderer: Renderer2,
        private _transferState: TransferState
    ) {
        if (!isPlatformBrowser(this.platformId)) {
            this.display = false;
        }
        if (this.display && !environment.production) {
            this.featureFlagToggleService.init();
        }
    }
    title = 'corporate-website';

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        let element = event.target as HTMLElement;
        const tagsToCheck = ['EM', 'SPAN', 'H3', 'P', 'DIV', 'H6'];

        if (tagsToCheck.includes(element.tagName)) {
            element = element.closest('a') as HTMLElement;
        }
        if (element && element.tagName.toLocaleLowerCase() === 'a') {
            const href = element.getAttribute('href');

            if (
                !href?.includes('americanfirstfinance.com') &&
                href?.includes('http') &&
                (!this.dealerInfo ||
                    !this.dealerInfo?.sBrandedCustom ||
                    this.dealerInfo?.sBrandedCustom?.toString()?.trim()?.length === 0 ||
                    (this.dealerInfo?.sBrandedCustom?.toString()?.trim()?.length > 0 &&
                        !href?.includes(this.dealerInfo?.sBrandedCustom)))
            ) {
                //NOSONAR

                const modelObj = {
                    component: [ExitSiteConfirmationComponent],
                    showModal: true,
                    exterenalUrl: href,
                };
                this.modalService.modalAction(modelObj);
                event?.preventDefault();
            }
        }
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            let dealerInfo = this._transferState?.get(SOP_DEALER_INFO_STATE_KEY, null);
            const locationData = this._transferState?.get(SOP_LOCATION_DATA_STATE_KEY, null);
            this.locationsBaseModuleData = locationData?.data?.locations?.nodes?.[0] || null;

            if (dealerInfo && dealerInfo?.bBranded) {
                //NOSONAR
                this.dealerInfo = dealerInfo;
            } else {
                this.dealerInfo = null;
            }

            const PARAM = this._transferState?.get(SOP_BASE_URL_PARAMS, null);
        }
    }

    ngAfterViewInit(): void {
        this.dynamicComponentService.setRootViewContainerRef(this.viewContainerRef);
        this.dynamicComponentService.addDynamicComponent(AffModalComponent);
        if (this.display && navigator.userAgent.indexOf('Chrome-Lighthouse') < 0) {
            this._initScripts();
        }
        if (isPlatformBrowser(this.platformId)) {
            this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
            this.renderer.setProperty(document.body, 'scrollTop', 0);
        }
    }

    private _initScripts() {
        setTimeout(() => {
            this._chatScriptService.initiateSnapEngageChatScript();
            this._cookieCompliance.addCookieComplianceScript();
        }, 2000);
        this._gtmScriptService.trackPageNavigation();
    }
}
