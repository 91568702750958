<div>
    <janet-nav-bar *ngIf="(!dealerInfo || dealerInfo?.bBrandedHeaderFooter)"
        [ngStyle]="{ visibility: display ? 'visible' : 'hidden' }"></janet-nav-bar>
    <div class="mt-xxl-0"
        [ngClass]="{'mt-0': !dealerInfo?.bBrandedHeaderFooter, 'mt-14': (!dealerInfo || dealerInfo?.bBrandedHeaderFooter) }">
        <router-outlet></router-outlet>
    </div>
    <ng-container *ngIf="_chatScriptService._isChatInterfaceInitialised">
        <janet-chat janetChat></janet-chat>
    </ng-container>
</div>