import { AffFullWidthColorScheme } from './../components/full-width-content/enums/aff-fullWidth.enums';
export const HERO_FIELD_GROUP_NAME = 'Contentmodules_ContentModules_Hero';
export const CONTENT_AREA_FIELD_GROUP_NAME = 'Contentmodules_ContentModules_ContentArea';
export const TESTIMONIAL_FIELD_GROUP_NAME = 'Contentmodules_ContentModules_Testimonials';
export const ICONS_FIELD_GROUP_NAME = 'Contentmodules_ContentModules_Icons';
export const DASR_FORM_FIELD_GROUP_NAME = 'Contentmodules_ContentModules_CcpaDsarForm';
export const MERCHANT_SIGNUP_FORM_FIELD_GROUP_NAME = 'Contentmodules_ContentModules_MerchantSignupForm';
export const FAQ_SCHEMA_FIELS_GROUP_NAME = 'Contentmodules_ContentModules_FaqSchema';
export const Website_FORM_FIELD_GROUP_NAME = 'Contentmodules_ContentModules_WebsiteForms';
export const BANNER_MODULE_GROUP_NAME = 'Contentmodules_ContentModules_BannerModule';
export const SHOP_CATEGORY_MODULE_GROUP_NAME = 'Contentmodules_ContentModules_ShopCategoryModule';
export const NATIVE_FORMS_MODULE_GROUP_NAME = 'Contentmodules_ContentModules_GlForms';

export type Button = {
    __typename: string;
    target: string;
    title: string;
    url: string;
};

export type DsarFormModule = {
    otDsarWebForm: any;
    __typename: string;
    fieldGroupName: string;
};

export type MerchantSignUpFormModule = {
    merchantSignupForm: any;
    __typename: string;
    fieldGroupName: string;
};

export type WebsiteFormModule = {
    websiteForm: any;
    __typename: string;
    fieldGroupName: string;
};

export type ContentModulesHero = {
    __typename: string;
    backgroundColor: string;
    backgroundImage: {
        altText: string;
        sourceUrl: string;
        srcSet: string;
    };
    colorScheme: string;
    contentAlignment: string;
    contentArea: string;
    fieldGroupName: string;
    showButton: boolean;
    title: string;
    button: Button;
    overlay: boolean;
    heroLayoutMobile: string;
};

export type ContentModulesBanner = {
    __typename: string;
    backgroundColor: string;
    backgroundType: string;
    buttonCtaText: string;
    subheadingText: string;
    headingText: string;
    bannerType: string;
    buttonCta: {
        target: string;
        title: string;
        url: string;
    };
    fontAwesomeIcon: string;
    bannerHeight: string;
    backgroundImage: {
        altText: string;
        sourceUrl: string;
        srcSet: string;
        __typename: string;
    };
    colorScheme: string;
    fieldGroupName: string;
    bannerTextAlignment: string;
    searchType: string;
    useAsHero: any;
    defaultCategory: {
        name: string;
        slug: string;
        taxonomyName: string;
        __typename: string;
    };
};

export type ContentModulesTestimonials = {
    __typename: string;
    fieldGroupName: string;
    colorScheme: string;
    testimonials: Testimonial[];
};

export type Testimonial = {
    __typename: string;
    fieldGroupName: string;
    colorScheme: string;
    rating: any;
    testimonialCustomerName: string;
    testimonialText: string;
};

export type ContentModulesContentArea = {
    __typename: string;
    button: {
        target: string;
        title: string;
        url: string;
    };
    button2: {
        target: string;
        title: string;
        url: string;
    };
    content: string;
    content2: string;
    contentWidth: string;
    fieldGroupName: string;
    image: {
        sourceUrl: string;
        srcSet: string;
        altText: string;
    };
    image2: {
        sourceUrl: string;
        altText: string;
        srcSet: string;
    };
    imagePosition: string;
    imagePosition2: string;
    showButton: boolean;
    showButton2: boolean;
    title: string;
    showImage1: boolean;
    showImage2: boolean;
    heroLayoutMobile: string;
    contentAlignment: string;
    colorScheme: AffFullWidthColorScheme;
    backgroundImage: {
        title: string;
        sourceUrl: string;
    };
};

export type ContentModules =
    | ContentModulesContentArea
    | ContentModulesTestimonials
    | ContentModulesHero
    | IconsModuleData
    | DsarFormModule
    | MerchantSignUpFormModule
    | WebsiteFormModule
    | ContentModulesBanner;

export type Edge = {
    cursor: string;
    node: {
        contentModules: {
            contentModules: Array<any>;
        };
        id: string;
        slug: string;
        status: string;
        template: any;
        title: string;
        uri: string;
    };
};

export type KitchenSinkPage = {
    pages: {
        edges: Edge[];
    };
};

export type KitchenSinkResponse = {
    data: KitchenSinkPage;
    loading: boolean;
    networkStatus: number;
};

export interface IconsModuleData {
    __typename: string;
    fieldGroupName: string;
    showButton: boolean;
    button: Button;
    icons: Icons;
}

export type Icons = Icon[];

export type Icon = {
    __typename: string;
    fieldGroupName: string;
    iconImage: IconImage;
    iconTitle: string;
    iconFa: string;
    useFaIcon: boolean;
    iconSubText: string | undefined | null;
};

export type IconImage = {
    altText: string;
    mediaItemUrl: string;
};

export type IconsModule = IconsModuleData;

export interface IMenuData {
    __typename?: string;
    node?: INode;
    selected?: boolean;
    children?: INode[];
}

export interface INode {
    __typename: string;
    label?: string;
    order?: number;
    url?: string;
    menuIcons?: IMenuIcons;
    parentId?: string;
    id?: string;
    children?: INode[];
    node?: INode;
    target?: string | null;
    _isSubMenu?: boolean | null;
}

export interface IMenuIcons {
    __typename?: string;
    icon?: string;
}

export interface FaqSchema {
    question: string;
    answer: string;
}

export interface MerchantStores {
    PDNO: string;
    PDNAME: string;
    PDNAM1: string;
    PDADR1: string;
    PDADR2: string;
    PDCITY: string;
    PDST: string;
    PDZIP: string;
    LATITUDE: string;
    LONGITUDE: string;
    PDLOC: string;
    SRCLAT: string;
    SRCLON: string;
    SRCZIP: string;
    UAVERSION: string;
    DISTANCE: string;
    DTYPE: string;
    TARGETURL: string;
    CATEGORY: string;
    PRODSOLD1: string;
    sIsLangToggleAllowed: string;
}
export interface MerchantStoreLists {
    name: string;
    address: string;
    link: string;
    category: string;
}
