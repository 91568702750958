import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { GeoLocationService } from '../../services/geo-location.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NearByLocationAPIService } from '../../services/near-by-location-api.service';
import { SOPSearchResultsService } from '../../services/sop-search-results.service';
import { SOPInputValueEmitterService } from '../../services/sop-input-value-emitter.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'janet-shop-our-partners-container',
    standalone: false,
    templateUrl: './shop-our-partners-container.component.html',
})
export class ShopOurPartnersContainerComponent implements OnInit, OnDestroy {

    options = [];
    merchantShopLists = [];
    merchantShopTotalRecords = 0;
    merchantShopRadius = 50;
    _locationsPageDetails: any;
    _locationsData: any;
    _dealerInfoData: any;
    _browser = false;
    searchParamValue: any = null;
    filterParamValue: any = null;
    sopSearchInputValueSubject: Subscription;
    private queryParamSubscription: Subscription;
    _locationsLanguageLabelsObj: any;
    _storeListErrorMessage = "";

    constructor(
        private _cdr: ChangeDetectorRef,
        private geoLocationService: GeoLocationService,
        @Inject(PLATFORM_ID) private _platformId: any,
        public route: ActivatedRoute,
        private router: Router,
        private _nearByLocationAPIService: NearByLocationAPIService,
        private _sopSearchResultsService: SOPSearchResultsService,
        private _sopInputValueEmitterService: SOPInputValueEmitterService
    ) {
        if (isPlatformBrowser(this._platformId)) {
            this._browser = true;
        }
    }

    ngOnInit(): void {
        this.initializeComponentProperties();

        this.queryParamSubscription = this.route?.queryParams?.subscribe((params) => {
            const searchParam = params?.search?.trim();
            const filterParam = params?.filter;
            if (searchParam) {
                this.searchParamValue = searchParam;
                this._cdr.detectChanges();
            }
            if (filterParam && filterParam?.toString()?.trim()?.length > 0) {
                let filteredOption: any = [];

                filterParam?.toString()?.trim()?.split(',').forEach((element: any) => {
                    const filteredVal: any = this.options.find((option: any) => {
                        return ((element.toString().toLowerCase() === option?.apiKey?.toString().toLowerCase()) || (element.toString().toLowerCase() === option?.value?.toString().toLowerCase()) || (element.toString().toLowerCase() === option?.label?.toString().toLowerCase()));
                    });
                    if (filteredVal) {
                        filteredOption.push(filteredVal.apiKey);
                    }
                });
                this.filterParamValue = filteredOption?.toString()?.trim();
            }
        });

        if (this._locationsPageDetails) {
            this.manipulateLocationsPage();
        }

        this.sopSearchInputValueSubject = this._sopInputValueEmitterService.sopSearchInputValueSubject$.subscribe((response: any) => {
            if (response && ((response?.aResults && Array.isArray(response?.aResults)) || Array.isArray(response))) {
                this.merchantShopLists = response?.aResults ? response?.aResults : response;
                this.merchantShopTotalRecords = response?.aResults ? response?.aResults?.length : response.length;
                this._cdr.detectChanges();
                this._sopInputValueEmitterService.soploaderDisplay(false);
            }
        });

        if (this._locationsLanguageLabelsObj && this._locationsLanguageLabelsObj?.storeListDefaultErrMsg) {//NOSONAR
            this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg1}. ${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg2}`;
        }
    }

    ngOnDestroy(): void {
        if (this.sopSearchInputValueSubject) {
            this.sopSearchInputValueSubject.unsubscribe();
        }
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    initializeComponentProperties() {
        this.searchParamValue = null;
        this.filterParamValue = null;
    }

    @Input()
    set locationsPageDetails(data: any) {
        this._locationsPageDetails = data;
        this._cdr.detectChanges();
    }

    @Input()
    set locationsData(data: any) {
        this._locationsData = data;
    }

    @Input()
    set dealerInfoData(data: any) {
        this._dealerInfoData = data;
    }

    @Input()
    set categoriesData(data: any) {
        this.populateSelectOptionValue(data);
    }

    @Input()
    set locationsLanguageLabelsObj(data: any) {
        this._locationsLanguageLabelsObj = data;
    }

    populateSelectOptionValue(data: any) {
        this.options = data.map((item: any) => {
            const option: any = {};
            [option.label, option.value, option.description, option.apiKey] = [
                item.name,
                item.slug,
                item?.description,
                item?.ShopCategoryAPIKey?.apiKey,
            ];
            return option;
        });
        this._cdr.detectChanges()
    }

    async manipulateLocationsPage() {//NOSONAR
        this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg1} ${this.searchParamValue ? this.searchParamValue?.trim() : ''}. ${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg2}`;


        if (this._locationsPageDetails.isDealerLocationPage) {
            if (this._locationsPageDetails.isBrandDealer) {
                if (this._locationsLanguageLabelsObj && this._locationsLanguageLabelsObj?.storeListBrandErrMsg) {//NOSONAR
                    this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListBrandErrMsg} ${this.searchParamValue ? this.searchParamValue?.trim() : ''}`;
                }

                this._sopInputValueEmitterService.soploaderDisplay(true);
                if (this._browser) {
                    const location = await this.getLatLangDetails();
                    if ((!location?.latitude && !location?.longitude) && this.searchParamValue && this.searchParamValue?.toString()?.length > 0) {
                        if (this.searchParamValue?.toString()?.length >= 3) {
                            let distance = '';
                            let dealerSlug = '';
                            let dealerNumber = '';

                            if (this._dealerInfoData) {
                                distance = (this._dealerInfoData?.fDistance && this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0) ? parseInt(this._dealerInfoData?.fDistance).toString() : '3000';
                                dealerSlug = (this._dealerInfoData?.sHashId && this._dealerInfoData?.sHashId?.toString()?.trim()?.length > 0) ? this._dealerInfoData?.sHashId?.toString() : 'd';
                                dealerNumber = (this._dealerInfoData?.sDealer && this._dealerInfoData?.sDealer?.toString()?.trim()?.length > 0) ? this._dealerInfoData?.sDealer?.toString() : '';
                            }
                            this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                            this.getSOPSearchResults(this.searchParamValue, null, { latitude: '0', longitude: '0' }, distance, dealerSlug, dealerNumber);
                        }
                        else {
                            this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                            this._sopInputValueEmitterService.soploaderDisplay(false);
                        }
                    } else if ((!location?.latitude && !location?.longitude) && (!this.searchParamValue || this.searchParamValue?.toString()?.length <= 0)) {
                        let latitude: any = '';
                        let longitude: any = '';
                        let distance = '';
                        let dealerSlug = '';
                        let dealerNumber = '';
                        if (this._dealerInfoData) {
                            latitude = (this._dealerInfoData?.fDefaultLat && this._dealerInfoData?.fDefaultLat?.toString()?.trim()?.length > 0) ? parseFloat(this._dealerInfoData?.fDefaultLat).toString() : 39.111689;
                            longitude = (this._dealerInfoData?.fDefaultLon && this._dealerInfoData?.fDefaultLon?.toString()?.trim()?.length > 0) ? parseFloat(this._dealerInfoData?.fDefaultLon).toString() : -97.119141;
                            distance = (this._dealerInfoData?.fDistance && this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0) ? parseInt(this._dealerInfoData?.fDistance).toString() : '3000';
                            dealerSlug = (this._dealerInfoData?.sHashId && this._dealerInfoData?.sHashId?.toString()?.trim()?.length > 0) ? this._dealerInfoData?.sHashId?.toString() : 'd';
                            dealerNumber = (this._dealerInfoData?.sDealer && this._dealerInfoData?.sDealer?.toString()?.trim()?.length > 0) ? this._dealerInfoData?.sDealer?.toString() : '';
                        }
                        this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                        this.getNearByLocationsData({ latitude: latitude, longitude: longitude }, distance, dealerSlug, dealerNumber);
                    } else if ((location?.latitude && location?.longitude) && (!this.searchParamValue || this.searchParamValue?.toString()?.length <= 0)) {
                        const zipCode = await this.getZipCode(location?.latitude, location?.longitude);
                        if (this._locationsLanguageLabelsObj && this._locationsLanguageLabelsObj?.storeListBrandErrMsg) {//NOSONAR
                            this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListBrandErrMsg} ${zipCode ? zipCode?.trim() : ''}`;
                        }
                        let latitude = location?.latitude;
                        let longitude = location?.longitude;
                        let distance = '';
                        let dealerSlug = '';
                        let dealerNumber = '';
                        if (this._dealerInfoData) {
                            distance = (this._dealerInfoData?.fDistance && this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0) ? parseInt(this._dealerInfoData?.fDistance).toString() : '3000';
                            dealerSlug = (this._dealerInfoData?.sHashId && this._dealerInfoData?.sHashId?.toString()?.trim()?.length > 0) ? this._dealerInfoData?.sHashId?.toString() : 'd';
                            dealerNumber = (this._dealerInfoData?.sDealer && this._dealerInfoData?.sDealer?.toString()?.trim()?.length > 0) ? this._dealerInfoData?.sDealer?.toString() : '';
                        }
                        this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                        this.getNearByLocationsData({ latitude: latitude, longitude: longitude }, distance, dealerSlug, dealerNumber);
                    } else if ((location?.latitude && location?.longitude) && (this.searchParamValue && this.searchParamValue?.toString()?.length > 0)) {
                        const zipCode = await this.getZipCode(location?.latitude, location?.longitude);
                        if (this._locationsLanguageLabelsObj && this._locationsLanguageLabelsObj?.storeListBrandErrMsg) {//NOSONAR
                            this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListBrandErrMsg} ${zipCode ? zipCode?.trim() : ''}`;
                        }
                        let latitude = location?.latitude;
                        let longitude = location?.longitude;
                        let distance = '';
                        let dealerSlug = '';
                        let dealerNumber = '';
                        if (this._dealerInfoData) {
                            distance = (this._dealerInfoData?.fDistance && this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0) ? parseInt(this._dealerInfoData?.fDistance).toString() : '3000';
                            dealerSlug = (this._dealerInfoData?.sHashId && this._dealerInfoData?.sHashId?.toString()?.trim()?.length > 0) ? this._dealerInfoData?.sHashId?.toString() : 'd';
                            dealerNumber = (this._dealerInfoData?.sDealer && this._dealerInfoData?.sDealer?.toString()?.trim()?.length > 0) ? this._dealerInfoData?.sDealer?.toString() : '';
                        }
                        if (this.searchParamValue?.toString()?.length >= 3) {
                            dealerNumber = `${dealerNumber}?search=${this.searchParamValue}`;
                            const filterValue = this.filterParamValue?.toString()?.trim();
                            if (filterValue) {
                                dealerNumber += `&filter=${filterValue}`;
                            }
                        }
                        this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                        if (this.searchParamValue?.toString()?.length === 5) {
                            if (this._locationsLanguageLabelsObj && this._locationsLanguageLabelsObj?.storeListBrandErrMsg) {//NOSONAR
                                this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListBrandErrMsg} ${this.searchParamValue ? this.searchParamValue?.trim() : ''}`;
                            }
                            const dlnmb = (this._dealerInfoData?.sDealer && this._dealerInfoData?.sDealer?.toString()?.trim()?.length > 0) ? this._dealerInfoData?.sDealer?.toString() : '';
                            this.getSOPSearchResults(this.searchParamValue, null, { latitude: '0', longitude: '0' }, distance, dealerSlug, dlnmb);
                        } else {
                            this.getNearByLocationsData({ latitude: latitude, longitude: longitude }, distance, dealerSlug, dealerNumber);
                        }

                    } else {
                        this._sopInputValueEmitterService.soploaderDisplay(false);
                    }
                }
            } else {
                this._sopInputValueEmitterService.soploaderDisplay(true);
                if (this._browser) {
                    if (this.searchParamValue && this.searchParamValue?.toString()?.length > 0) {
                        if (this.searchParamValue?.toString()?.length >= 3) {
                            let dealerSlug = this.getDealerSlug();
                            let distance = (this._dealerInfoData?.fDistance && this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0) ? parseInt(this._dealerInfoData?.fDistance).toString() : '50';

                            this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                            this.getSOPSearchResults(this.searchParamValue, this.filterParamValue, { latitude: '0', longitude: '0' }, distance, dealerSlug);
                        }
                        else {
                            this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListMinCharErrMsg}`;
                            this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                            this._sopInputValueEmitterService.soploaderDisplay(false);
                        }
                    } else {
                        const location = await this.getLatLangDetails();
                        if (location?.latitude && location?.longitude) {
                            const zipCode = await this.getZipCode(location?.latitude, location?.longitude);
                            this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg1} ${zipCode ? zipCode?.trim() : ''}. ${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg2}`;
                            let dealerSlug = this.getDealerSlug();
                            let distance = (this._dealerInfoData?.fDistance && this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0) ? parseInt(this._dealerInfoData?.fDistance).toString() : '50';

                            this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                            this.getNearByLocationsData(location, distance, dealerSlug);
                        }
                        else {
                            this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListLocationDisabledErrMsg}`;
                            this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                            this._sopInputValueEmitterService.soploaderDisplay(false);
                            this._cdr.detectChanges();
                        }
                    }
                }
            }
        } else {
            this._sopInputValueEmitterService.soploaderDisplay(true);
            if (this._browser) {
                if (this.searchParamValue && this.searchParamValue?.toString()?.length > 0) {
                    if (this.searchParamValue?.toString()?.length >= 3) {
                        let dealerSlug = this.getDealerSlug();
                        let distance = (this._dealerInfoData?.fDistance && this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0) ? parseInt(this._dealerInfoData?.fDistance).toString() : '50';

                        this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                        this.getSOPSearchResults(this.searchParamValue, this.filterParamValue, { latitude: '0', longitude: '0' }, distance, dealerSlug);
                    }
                    else {
                        this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListMinCharErrMsg}`;
                        this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                        this._sopInputValueEmitterService.soploaderDisplay(false);
                    }
                } else {
                    const location = await this.getLatLangDetails();
                    if (location?.latitude && location?.longitude) {
                        const zipCode = await this.getZipCode(location?.latitude, location?.longitude);
                        this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg1} ${zipCode ? zipCode?.trim() : ''}. ${this._locationsLanguageLabelsObj?.storeListDefaultErrMsg2}`;

                        let dealerSlug = this.getDealerSlug();
                        let distance = (this._dealerInfoData?.fDistance && this._dealerInfoData?.fDistance?.toString()?.trim()?.length > 0) ? parseInt(this._dealerInfoData?.fDistance).toString() : '50';

                        this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                        this.getNearByLocationsData(location, distance, dealerSlug);
                    }
                    else {
                        this._storeListErrorMessage = `${this._locationsLanguageLabelsObj?.storeListLocationDisabledErrMsg}`;
                        this._sopInputValueEmitterService.sopBodyContentDisplay(true);
                        this._sopInputValueEmitterService.soploaderDisplay(false);
                        this._cdr.detectChanges();
                    }
                }
            }
        }
    }



    async getLatLangDetails() {
        try {
            const location = await this.geoLocationService.getGeoLocation();
            return location;
        } catch (error) {
            return { latitude: null, longitude: null };
        }
    }

    async getZipCode(lat: any, lang: any) {
        try {
            const zipCode = await this.geoLocationService.getPostalCode(lat, lang);
            return zipCode;
        } catch (error) {
            return '';
        }
    }

    getDealerSlug() {
        let dealerSlug = null;
        const pathname = window?.location?.pathname;
        if (pathname) {
            const pathComponents: any = pathname.split('/').filter(component => component.trim() !== '');
            if (pathComponents && pathComponents.length >= 2 && pathComponents[0] === "locations") {
                dealerSlug = pathComponents[1];
            }
        }
        return dealerSlug;
    }

    getNearByLocationsData(location: any, distance: any, dealerSlug: any, dealerNumber?: any) {
        this._nearByLocationAPIService.getNearByLocations(location?.latitude, location?.longitude, distance, dealerSlug, dealerNumber).subscribe(
            (response: any) => {
                if (response && ((response?.aResults && Array.isArray(response?.aResults)) || Array.isArray(response))) {
                    this.merchantShopLists = response?.aResults ? response?.aResults : response;
                    this.merchantShopTotalRecords = response?.aResults ? response?.aResults?.length : response.length;
                    this._cdr.detectChanges();
                }
            },
            (error: any) => {
                console.error('Near By Locations API Error');
                this._sopInputValueEmitterService.soploaderDisplay(false);
            },
            () => {
                this._sopInputValueEmitterService.soploaderDisplay(false);
            }
        );
    }

    getSOPSearchResults(searchParamValue: any, filterValue: any, location: any, distance: any, dealerSlug: any, dealerNumber?: any) {
        this._sopSearchResultsService.getSOPSearchResults(searchParamValue, filterValue, location, distance, dealerSlug, dealerNumber).subscribe(
            (response: any) => {
                if (response && ((response?.aResults && Array.isArray(response?.aResults)) || Array.isArray(response))) {
                    this.merchantShopLists = response?.aResults ? response?.aResults : response;
                    this.merchantShopTotalRecords = response?.aResults ? response?.aResults?.length : response.length;
                    this._cdr.detectChanges();
                }
            },
            (error: any) => {
                console.error('SOP Search API Error');
                this._sopInputValueEmitterService.soploaderDisplay(false);
            },
            () => {
                this._sopInputValueEmitterService.soploaderDisplay(false);
            }
        );
    }

}
