import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { AffBtnColorVariantService, FontAwesomeIconService } from '@janet/corporate/ui-corporate';

import { HeaderMenu, IMenuDataNew, MenuEdgesArray } from '../../../types/menu-data.types';

@Component({
    selector: 'janet-nav-bar-mobile',
    templateUrl: './nav-bar-mobile.component.html',
    styleUrls: ['./nav-bar-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarMobileComponent implements OnChanges {
    @Input() menus: MenuEdgesArray[] = [];
    @Input() mainMenus: IMenuDataNew[] = [];
    @Input() headerMenu?: HeaderMenu;
    angleRight = faAngleRight;
    classes = 'm-0 px-4 py-6 px-md-5 px-xl-21';
    _isButton = false;
    showIcon = false;

    constructor(
        private _affBtnVariantService: AffBtnColorVariantService,
        private _fontAwesomeIconService: FontAwesomeIconService
    ) {}

    ngOnChanges({ headerMenu }: SimpleChanges) {
        if (headerMenu) {
            this.getHeaderMenu(this.headerMenu);
        }
    }

    trackByFn(index: number, item: any): string {
        return item.node.id;
    }

    hasSubHeader(menu: any): boolean {
        if (menu?.children) {
            if (menu?.children[0]?._isSubMenu === true) {
                if (menu?.children[0]?.children && menu?.children[0]?.children[0]?.children) {
                    return true;
                }
                return false;
            }
            return false;
        }
        return false;
    }

    preventEvent(event: Event) {
        event?.preventDefault();
    }

    linkHandler(event: Event): void {
        event?.stopPropagation();
    }

    getHeaderMenu(headerMenu?: HeaderMenu): void {
        this._isButton = headerMenu?.headerFindAStoreStyle?.ctaType === 'Button' ? true : false;
        this.showIcon = headerMenu?.headerFindAStoreStyle?.ctaIcon ? true : false;
    }

    get btnVariantClass(): string {
        return `${this._affBtnVariantService.getColorVariant(
            this.headerMenu?.headerFindAStoreStyle?.ctaVariant
        )} `;
    }

    getFaIcon(iconStr: string | null | undefined): string {
        return this._fontAwesomeIconService.getFaIcon(iconStr);
    }

    hasChildren(children?: MenuEdgesArray[]): boolean {
        return !!(children && children.length);
    }
}
