import { Pipe, PipeTransform } from '@angular/core';

/**
 * This adds the loading="lazy" attribute to all incoming images
 *
 * @export
 * @class AddLazyLoadingPipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: 'addLazyLoading',
})
export class AddLazyLoadingPipe implements PipeTransform {
    transform(value: string): string {
        const regexp = /<img/g;
        return regexp.test(value) ? value.replace(regexp, '<img loading="lazy"') : value;
    }
}
