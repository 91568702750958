import { Injectable } from '@angular/core';
import * as OFeatureFlagActions from './o-feature-flag.actions';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { concatMap, switchMap } from 'rxjs/operators';
import { OFeatureFlagService } from './o-feature-flag.service';
import { OFeatureFlagState } from './o-feature-flag.model';
import { EMPTY, from } from 'rxjs';

@Injectable()
export class OFeatureFlagEffects implements OnInitEffects {
    onInitOFeatureFlagEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OFeatureFlagActions.onInitOFeatureFlagEffect),
            switchMap(() =>
                this.oFeatureFlagService.getOFeatureFlagState().pipe(
                    concatMap((response: { success: boolean; result: OFeatureFlagState }) => {
                        if (response.success) {
                            return from([OFeatureFlagActions.setFeatureList({ payload: response.result })]);
                        } else {
                            return EMPTY;
                        }
                    })
                )
            )
        )
    );

    constructor(private actions$: Actions, private oFeatureFlagService: OFeatureFlagService) {}

    ngrxOnInitEffects(): Action {
        return OFeatureFlagActions.onInitOFeatureFlagEffect();
    }
}
