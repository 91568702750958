<form
    class="d-flex flex-column flex-xxl-row justify-content-center zip-search-form"
    [formGroup]="zipSearchForm"
    (ngSubmit)="onSubmit()"
    novalidate
>
    <aff-corporate-form-field
        formFieldContainerCustomClass="corporate-form-field-container form-floating"
        formFieldType="number"
        formFieldLabel="Your Zip Code"
        formFieldPlaceholder="Your Zip Code"
        formFieldId="zipcode"
        formFieldCustomClass="form-control"
        formFieldLabelCustomClass="form-label"
        [control]="$any(zipSearchForm.controls.zipcode)"
        [errorMessages]="errorMessages"
        [errorMessageClass]="'text-start text-red m-0 position-absolute zip-err-msg-font mt-2'"
        (formFieldEvent)="handleEvent($event)"
    >
    </aff-corporate-form-field>

    <ua2-aff-button
        [buttonLabel]="'Go'"
        [buttonType]="'submit'"
        [isButtonAnchor]="isButtonAnchor"
        [buttonCustomClass]="buttonCustomClass"
        [disabled]="isdisabled || !zipSearchForm.valid"
    ></ua2-aff-button>
</form>
