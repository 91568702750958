import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { QueryParams } from '@janet/corporate/domain';

@Component({
    selector: 'janet-merchant-stores-mobile',
    templateUrl: './merchant-stores-mobile.component.html',
})
export class MerchantStoresMobileComponent {
    @Input() showAccordion: boolean;
    @Input() totalRecords: number;
    @Input() paginationClicked: boolean;
    @Input() googleMapUrl: string;
    _displayListSearchResults: any = [];
    clickedCard: HTMLElement;
    isBorderActive: boolean;
    @ViewChild('highlighter', { static: false }) highlighter: ElementRef<any>;

    constructor(private _cdr: ChangeDetectorRef, private queryParams: QueryParams) { }

    @Input()
    set displayListSearchResults(data: any) {
        this._displayListSearchResults = data;
        this.toggleAccordion();
    }

    @Input()
    set storeListPaginationClicked(data: any) {
        this.toggleAccordion();
        this.highlighter?.nativeElement?.setAttribute(
            'style',
            `width:0;
                    height:0;
                    top:0;
                    left:0;
                    display:none !important`
        );
    }

    getQueryParams() {
        return this.queryParams.getQueryParams();
    }

    getDirectionUrl(lat: string, long: string): string {
        return `${this.googleMapUrl}${lat},${long}`;
    }

    getFaIcon(iconStr?: string | null | undefined): string {
        if (iconStr && iconStr !== '') {
            return `fa-light ${iconStr} get-directions-icon`;
        }
        return '';
    }

    setStoreListActive(index: number) {
        this._displayListSearchResults.forEach((item: any, i: any) => {
            item.active = index !== i || item?.active === true ? false : true;
        });
        this._cdr.detectChanges();

        if (this.isBorderActive) {
            this.isBorderActive = false;
            this.highlighter.nativeElement.setAttribute('style', `display:none`);
        }
        setTimeout(() => {
            this.highlightCard();
        }, 350);
    }

    toggleAccordion() {
        this._displayListSearchResults.forEach((item: any, i: any) => {
            item.active = false;
        });
        this._cdr.detectChanges();
    }

    highlightCard() {
        if (this.clickedCard && this.clickedCard.classList.contains('active') && this.highlighter.nativeElement) {
            if (!this.isBorderActive) {
                this.isBorderActive = true;
                this.highlighter.nativeElement.setAttribute(
                    'style',
                    `width:${this.clickedCard.offsetWidth}px;
                            height:${this.clickedCard.offsetHeight}px;
                            top:${this.clickedCard.offsetTop}px;
                            left:${this.clickedCard.offsetLeft}px;
                            display:block !important`
                );
            }
        } else {
            this.isBorderActive = false;
            this.highlighter.nativeElement.setAttribute('style', `display:none`);
        }
    }

    onStoresContainerClick(event: any) {
        this.clickedCard = event.target.closest('.card-toggle-container-mobile');
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.isBorderActive = false;
        this.highlightCard();
    }
}
