import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagToggleComponent } from './feature-flag-toggle.component';
import { FeatureFlagToggleService } from './feature-flag-toggle.service';
import { FeatureFlagValueModule } from './feature-flag-value/feature-flag-value.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    declarations: [FeatureFlagToggleComponent],
    imports: [CommonModule, FeatureFlagValueModule, MatDialogModule, MatButtonModule, MatTableModule],
    exports: [FeatureFlagToggleComponent],
    providers: [FeatureFlagToggleService],
})
export class FeatureFlagToggleModule {}
