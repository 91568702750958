import { Observable, of } from 'rxjs';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpContext,
    HttpContextToken,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

export const CUSTOMER_TOKEN_NAME = 'customerToken';
export const APPLICATION_AUTH_TOKEN_NAME = 'applicationToken';

const CACHE_IT = new HttpContextToken<boolean>(() => false);

export function cacheIt() {
    return new HttpContext().set(CACHE_IT, true);
}

@Injectable()
export class GeneralInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = sessionStorage.getItem(APPLICATION_AUTH_TOKEN_NAME) || '';
        const customerToken: string = sessionStorage.getItem(CUSTOMER_TOKEN_NAME) || '';
        let clone;
        if (req.headers.get("skip")) {
            clone = req.clone({
                headers: req.headers.delete('skip'),
            });
        } else {
            clone = req.clone({
                headers: req.context.get(CACHE_IT)
                    ? req.headers.set('Authorization', `Bearer ${customerToken}`)
                    : req.headers.set('Authorization', `Bearer ${token}`),
            });
        }
        return next.handle(clone).pipe(
            map((resp: any): any => {
                // on Response
                if (resp instanceof HttpResponse) {
                    // Do whatever you want with the response.
                    return resp;
                }
            }),
            catchError((error): Observable<any> => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        sessionStorage.clear();
                    }
                }
                return of('401 error response.');
            })
        );
    }
}
