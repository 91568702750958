<div
    class="mb-3 mb-xxl-5 position-relative overflow-hidden text-center d-flex flex-column justify-content-center merchant-search-hero-wrapper merchant-search-hero-height"
>
    <img
        class="mw-100 position-absolute d-block start-0 merchant-search-hero-image"
        [src]="_moduleData.backgroundImage.sourceUrl"
        [srcset]="_moduleData.backgroundImage.srcSet"
        [alt]="_moduleData.backgroundImage.altText"
    />
    <div [ngClass]="heroBannerContainerClass">
        <div class="row me-0 ms-0">
            <div class="px-3 py-10 text-break text-center col-12">
                <h1 class="m-0 merchant-search-hero-header-text">Stores in {{ _moduleData.fullStateName }}</h1>
                <div class="mt-3">
                    <div class="m-0 p-0">
                        <p class="m-0 merchant-search-hero-content-text">
                            {{ _moduleData.bannerText }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
