import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormFieldErrorMessages } from '../corporate-form-field/corporate-form-field.component';

@Component({
    selector: 'ua2-corporate-textarea, aff-corporate-textarea',
    templateUrl: './corporate-textarea.component.html',
})
export class CorporateTextareaComponent {
    @Input() textAreaLabel?: string = 'Comments';
    @Input() textAreaId?: string = '';
    @Input() rows?: string | number = 10;
    @Input() cols?: string | number = 112;
    @Input() textAreaPlaceholder?: string = '';
    @Input() control = new FormControl();
    @Input() isReadonly = false;
    @Input() isDisabled = false;
    @Input() textAreaContainerDefaultClass?: string = 'form-floating';
    @Input() textAreaContainerCustomClass?: string = 'text-area-container';
    @Input() textAreaLabelDefaultClass?: string = '';
    @Input() textAreaLabelCustomClass?: string = 'form-label';
    @Input() textAreaDefaultClass?: string = 'shadow-none';
    @Input() textAreaCustomClass?: string = 'form-control';
    @Input() errorMessages: FormFieldErrorMessages = {};
    @Input() errorMessageClass?: string = 'text-start text-red m-0';
    @Output() textAreaValueChange = new EventEmitter();
    value = '';
    _showErrorMessage: any = true;

    @Input()
    set textAreaValue(value: any) {
        this.value = value;
    }

    @Input()
    set showErrorMessage(value: any) {
        this._showErrorMessage = value;
    }

    onValueChange(event: Event): void {
        const value = (event?.target as any)?.value;
        this.value = value;
        this.textAreaValueChange.emit(this.value);
    }

    get textAreaFieldClasses(): string {
        let classes = `${this.textAreaDefaultClass} ${this.textAreaCustomClass}`;

        if (this._showErrorMessage) {
            classes += ' error-message';
        }
        return classes.trim();
    }
}
