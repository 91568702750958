import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, Renderer2 } from '@angular/core';

import { HeaderMenu, IMenuDataNew, MenuEdgesArray } from '../../../types/menu-data.types';

@Component({
    selector: 'janet-nav-bar-mobile-wrapper',
    templateUrl: './nav-bar-mobile-wrapper.component.html',
    styleUrls: ['./nav-bar-mobile-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarMobileWrapperComponent {
    isActive = false;
    @Input() menus: MenuEdgesArray[] = [];
    @Input() mainMenus: IMenuDataNew[] = [];
    @Input() headerMenu?: HeaderMenu;
    scrollTop = 0;
    constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {}

    toggle() {
        this.isActive = !this.isActive;

        if (this.isActive) {
            //Getting Scrolled Distance
            this.scrollTop =
                window.scrollY ||
                (<any>(this.document.documentElement || this.document.body.parentNode || this.document.body))
                    .scrollTop;
            this.renderer.addClass(this.document.body, 'body-overflow');
            return;
        }
        this.renderer.removeClass(this.document.body, 'body-overflow');
        const scrollBehaviour: any = 'instant';
        const scrollPropertiesObj: ScrollToOptions = {
            top: this.scrollTop,
            left: 0,
            behavior: scrollBehaviour,
        };
        // Scrolling back to the top instantly without transition.
        window.scrollTo(scrollPropertiesObj);
    }
}
