import { map } from 'rxjs/operators';
import { Inject, Injectable, Injector, PLATFORM_ID, TransferState } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { PageDataService } from '../../services/page-data.service';
import { DOCUMENT, isPlatformServer, isPlatformBrowser } from '@angular/common';
import { SOP_BASE_URL_STATE_KEY } from '../../../constants/state-keys';
import brandInfo from '../../../../brand-info.json';

@Injectable({
    providedIn: 'root',
})
export class PageDataResolver {
    baseUrl: any = '';
    brandInfo: any = brandInfo;

    constructor(
        private _pageDataService: PageDataService,
        @Inject(DOCUMENT) private document: Document,
        private injector: Injector,
        @Inject(PLATFORM_ID) private platformId: any,
        private _transferState: TransferState
    ) {
        this.baseUrl = '';
        if (isPlatformServer(this.platformId)) {
            try {
                const host = this.injector?.get('host', null); //NOSONAR

                if (host) {
                    this.baseUrl = host;

                    this._transferState?.set(SOP_BASE_URL_STATE_KEY, this.baseUrl);
                    const baseUrl = this._transferState?.get(SOP_BASE_URL_STATE_KEY, null);
                    console.log('sop page base url server (PageDataResolver) --> ' + baseUrl);
                } else {
                    console.log('Host provider not available during server-side rendering.');
                }
            } catch (error) {
                console.log('Error retrieving host from injector');
            }
        }

        if (isPlatformBrowser(this.platformId)) {
            const vanityUrls = this.brandInfo.map((item: any) => {
                return {
                    url: item.URI,
                    pdno: item.PDNO,
                };
            });
            const vanityURL = vanityUrls.find(
                (urlData: { url: string }) => urlData.url === window.location.origin
            );

            if (vanityURL) {
                const fullPath = `${vanityURL.url}/locations/d/${vanityURL.pdno}/`;
                const currentPathSegments = window.location.pathname.split('/');
                const currentPDNO = currentPathSegments[currentPathSegments.length - 2];

                if (currentPDNO !== vanityURL.pdno) {
                    const newUrl = fullPath + window.location.search;
                    console.log('Browser redirecting to -->' + newUrl);
                    window.location.href = newUrl;
                }
            }

            const baseUrl = this._transferState?.get(SOP_BASE_URL_STATE_KEY, null);
            if (baseUrl) {
                console.log('sop page base url browser (PageDataResolver) --> ' + baseUrl);
            }
        }
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let baseUrl = '';
        if (this.baseUrl) {
            baseUrl = this.baseUrl;
        }

        let slug = state.url.replace('/', '');
        slug = slug.replace(/\.$/, '');
        if (slug === '') {
            slug = '/';
        }

        //Recdirect for external URL's apart from angular routes
        if (slug.includes('www.')) {
            const externalURL = slug.replace(/www./g, '');
            window.location.href = `https://${externalURL.replace(/\/$/, '')}`;
            return EMPTY;
        }

        return this._pageDataService.getPageData(slug, baseUrl).pipe(map((response: any) => response?.data));
    }
}
