import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Location } from '@angular/common';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
    .then((module) => enableDebugTools(module.injector.get(ApplicationRef).components[0]))
    .catch((err) => console.error(err));

/**To preserve trailing slash**/
const __stripTrailingSlash = (Location as any).stripTrailingSlash;
Location.stripTrailingSlash = function (url) {
    if (!url.endsWith('/') && !url.includes('?')) {
        url = url + '/';
    }

    const queryString$ = url.match(/([^?]+)(.*)/);
    if (queryString$ && queryString$[2].length > 0) {
        return /[^\\/]\/$/.test(queryString$[1])
            ? queryString$[1] + '.' + queryString$[2]
            : __stripTrailingSlash(url);
    }
    return /[^\\/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);
};
