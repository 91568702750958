import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffBouncyLoaderModule } from '../aff-bouncy-loader/aff-bouncy-loader.module';
import { AffBouncyFlatLoaderButtonComponent } from './aff-bouncy-flat-loader-button.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [AffBouncyFlatLoaderButtonComponent],
    imports: [CommonModule, MatButtonModule, AffBouncyLoaderModule],
    exports: [AffBouncyFlatLoaderButtonComponent],
})
export class AffBouncyFlatLoaderButtonModule {}
