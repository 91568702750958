import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'ua2-coporate-category-icon, coporate-category-icon',
  templateUrl: './coporate-category-icon.component.html'
})
export class CoporateCategoryIconComponent {
  @Input() url = '';
  @Input() categoryIcon = '';
  @Input() categoryIconContainerDefaultClass?: string = '';
  @Input() categoryIconContainerCustomClass?: string = 'px-12 px-md-35 mb-8 mb-xxl-0 category-icon-container-class';
  @Input() categoryIconAnchorDefaultClass?: string = '';
  @Input() categoryIconAnchorCustomClass?: string = 'd-inline-block text-decoration-none text-reset text-center category-icon-anchor category-icon-anchor-text';
  @Input() categoryIconDefaultClass?: string = '';
  @Input() categoryIconCustomClass?: string = 'category-icon';
  @Input() categoryIconLabelDefaultClass?: string = 'm-0';
  @Input() categoryIconLabelCustomClass?: string = 'category-icon-label';
  @Input() categoryIconInnerCircleClass?: string = 'category-icon-inner-circle d-flex align-items-center justify-content-center mb-2 mb-xxl-3';
  @Output() selectedCategory: EventEmitter<any> = new EventEmitter();
  _categoryLabel = '';

  get categoryIconContainerClasses(): string {
    const classes = `${this.categoryIconContainerDefaultClass} ${this.categoryIconContainerCustomClass}`;
    return classes.trim();
  }

  get categoryIconAnchorClasses(): string {
    const classes = `${this.categoryIconAnchorDefaultClass} ${this.categoryIconAnchorCustomClass}`;
    return classes.trim();
  }

  get categoryIconClasses(): string {
    const classes = `${this.categoryIcon} ${this.categoryIconDefaultClass} ${this.categoryIconCustomClass}`;
    return classes.trim();
  }

  get categoryIconLabelClasses(): string {
    const classes = `${this.categoryIconLabelDefaultClass} ${this.categoryIconLabelCustomClass}`;
    return classes.trim();
  }

  get categoryIconInnerCircleClasses(): string {
    const classes = `${this.categoryIconInnerCircleClass}`;
    return classes.trim();
  }

  onCategoryClick(): void {
    this.selectedCategory.emit();
  }

  @Input()
  set categoryLabel(label: any) {
    this._categoryLabel = label;
  }
}
