import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OFeatureFlagState } from './o-feature-flag.model';
import * as OFeatureFlagReducer from './o-feature-flag.reducer';

export const selectOFeatureFlagState = createFeatureSelector<OFeatureFlagState>(
    OFeatureFlagReducer.oFeatureFlagsFeatureKey
);

/**
 * Return feature flag value
 *
 * @example
 * const val = this.store.select(selectFeatureFlagByKey('INFR-2710', false ));
 *
 * @param key - key of feature flag
 * @param defaultValue - value if api did not return feature flag key
 */
export const selectFeatureFlagByKey = (key: string, defaultValue?: boolean) => {
    return createSelector(selectOFeatureFlagState, (state: OFeatureFlagState) => {
        if (state[key] !== undefined && state[key] !== null) {
            return state[key];
        } else if (defaultValue !== undefined && defaultValue !== null) {
            return defaultValue;
        } else {
            return false;
        }
    });
};
