import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
    selector: 'janet-shop-our-partners-title',
    standalone: false,
    templateUrl: './shop-our-partners-title.component.html',
})
export class ShopOurPartnersTitleComponent {
    _locationsPageDetails: any;
    _locationsData: any;
    _dealerInfoData: any;
    _locationsLanguageLabelsObj: any;

    constructor(public _cdr: ChangeDetectorRef,) { }

    @Input()
    set locationsPageDetails(data: any) {
        this._locationsPageDetails = data;
        this._cdr.detectChanges();
    }

    @Input()
    set locationsData(data: any) {
        this._locationsData = data;
    }

    @Input()
    set dealerInfoData(data: any) {
        this._dealerInfoData = data;
    }

    @Input()
    set locationsLanguageLabelsObj(data: any) {
        this._locationsLanguageLabelsObj = data;
    }

    get computedMaxHeight(): number {
        const logoType = this._locationsData?.locations?.logoType?.toString()?.trim()?.toLowerCase();
        switch (logoType) {
            case 'h':
                return 100;
            case 'v':
                return 160;
            default:
                return 100;
        }
    }

}
