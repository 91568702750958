import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCommonModule, MatRippleModule } from '@angular/material/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { AffPopover } from './popover';
import { AffPopoverItem } from './popover-item';
import { AffPopoverContent } from './popover-content';
import { AFF_POPOVER_SCROLL_STRATEGY_FACTORY_PROVIDER, AffPopoverTrigger } from './popover-trigger';

@NgModule({
    imports: [CommonModule, MatRippleModule, MatCommonModule, OverlayModule],
    exports: [
        CdkScrollableModule,
        AffPopover,
        MatCommonModule,
        AffPopoverItem,
        AffPopoverContent,
        AffPopoverTrigger,
    ],
    declarations: [AffPopover, AffPopoverItem, AffPopoverContent, AffPopoverTrigger],
    providers: [AFF_POPOVER_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class AffPopoverModule {}
