import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { AbstractCustomShadowdomHtmlDirective } from './abstract-custom-shadowdom-html.directive';

/**
 * Add html string content inside a DOM element keeping the styles
 * of that content isolated from the app styles.
 */
@Directive({
    selector: '[affCustomShadowdomHtml]',
})
export class CustomShadowdomHtmlDirective extends AbstractCustomShadowdomHtmlDirective {
    /**
     * Input value is html string such as
     * <p><strong>some content</strong> is in this paragraph</p>
     * @param html
     */
    @Input()
    set affCustomShadowdomHtml(html: string) {
        this._htmlContent = html;
    }

    /**
     * Optional element wrapper for the content of the ShadowDom.
     * An element is required for the dom to render, defaults to 'div'
     *
     * @param elementTag
     */
    @Input()
    override set rootElementWrapper(elementTag: string) {
        this._rootElementWrapper = elementTag;
    }

    /**
     * Add styles to ShadowDom
     * @param styles
     */
    @Input()
    set shadowDomStyles(styles: string) {
        this._styles = styles;
    }

    constructor(protected override elementRef: ElementRef, @Inject(DOCUMENT) protected override doc: Document) {
        super(elementRef, doc);
    }

    protected htmlParsers(html: string): string {
        // no parsers for the basic directive
        return html;
    }
}
