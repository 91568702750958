import { LaunchDarklyLocalFeatureFlags } from '@janet/shared/domain';

/**
 * Keys need to be kebab-case to be compatible
 * with Launch Darkly in the future.
 *
 * Values should be "false" unless you want a feature
 * to be release to production.
 */
export const localFeatureFlags: LaunchDarklyLocalFeatureFlags = {
    ['merchant-search-feature']: false,
};
