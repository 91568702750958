import {
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    Inject,
} from '@angular/core';
import { GOOGLE_MAP_API_KEY, GOOGLE_MAPS_PLACE_URL } from '@janet/shared/domain';
import { MerchantSearchResultsService } from '../../services/merchant-search-results.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MerchantFilterService } from '../../services/merchant-filter.service';
import { DOCUMENT } from '@angular/common';
import { CategoryUtilsService } from '../../services/category.service';
import { MerchantLocationData } from './merchant-location-data';
@Component({
    selector: 'janet-merchant-location',
    templateUrl: './merchant-location.component.html',
})
export class MerchantLocationComponent implements OnInit, OnDestroy {
    mapApiKey: string = GOOGLE_MAP_API_KEY;
    mapUrl: string = GOOGLE_MAPS_PLACE_URL;
    @Output() zipCodeValue: any = new EventEmitter();

    _merchantSearchStoreList: any;
    _merchantSearchNoOfRecords: number;
    _merchantSearchRadius: number;

    previousStoreData: any[] = [];
    displayData: any[] = [];
    nextStoreData: any[] = [];
    zipcode: number;
    totalRecords: any;
    selectedMarkerIndex: number;
    currentPage = 1;
    displayListSearchResultsChange: any;
    showAccordion = true;
    clickAction: string;
    newCurrentPage: number;
    additionalPage: number;
    paginationClicked = false;
    subscription: Subscription;
    categoriesList = '';
    searchValue: any;
    startIndex: number;
    endIndex: number;
    public getScreenWidth: any;
    isDataUpdated = false;
    storeIndex: number | null;
    apiKeyResult: any;
    apiKeyParam: any;
    filters: any;
    totalDataLength: number;
    LoaderDisplaysubscription: Subscription;
    isLoaderDisplay = false;
    _clearMarker = { clear: false };
    _storeListMobileDisplayClass = 'px-0';
    sessionSubscription: Subscription;
    storeListPaginationClicked = { clicked: false };

    constructor(
        public _merchantSearchResults: MerchantSearchResultsService,
        private route: ActivatedRoute,
        private _merchantFilterService: MerchantFilterService,
        private router: Router,
        private _cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private categoryUtilsService: CategoryUtilsService
    ) {
        this.getScreenWidth = window.innerWidth;
    }

    @Input()
    set merchantSearchStoreList(data: any) {
        this._merchantSearchStoreList = data;
    }

    @Input()
    set merchantSearchNoOfRecords(data: any) {
        this._merchantSearchNoOfRecords = data;
    }

    @Input()
    set merchantSearchRadius(data: any) {
        this._merchantSearchRadius = data;
    }

    ngOnInit() {
        this.LoaderDisplaysubscription = this._merchantFilterService.isLoaderDisplay$.subscribe(
            (data: boolean) => {
                this.isLoaderDisplay = data;
                this.storeListMobileDisplayClass = data;
                this.clearMarker();
            }
        );

        this.subscription = this._merchantFilterService.filteredData$.subscribe((data: any) => {
            if (data?.fRadius) {
                this._merchantSearchRadius = data.fRadius;
            }

            if (data?.aResults) {
                if (data?.urlStateName && data?.urlStateName?.trim().length > 0) {
                    const sessionStateName = this.document?.defaultView?.sessionStorage?.getItem('stateName');

                    if (
                        sessionStateName &&
                        data?.urlStateName?.toLowerCase() === sessionStateName?.toLowerCase()
                    ) {
                        this._merchantFilterService.loaderDisplay(false);
                    } else {
                        this.sessionStorageSubscription(data);
                    }
                } else {
                    this._merchantFilterService.loaderDisplay(false);
                }

                this._merchantSearchStoreList = [];
                this._merchantSearchNoOfRecords = 0;
                this.merchantSearchStoreList = data.aResults;
                this.displayData = [];
                this._cdr.detectChanges();
                this.nextStoreData = [];
                this.previousStoreData = [];
                this._merchantSearchNoOfRecords = data.iNumberOfRecords;
                this.categoriesList = data.categories;
                this.searchValue = data.searchText;
                // Update the searchValue in the browser URL
                const queryParams: any = { search: this.searchValue.replace(/\s+/g, '-') };
                if (this.categoriesList && this.categoriesList.length > 0) {
                    queryParams['filter'] = this.categoriesList.toString();
                }

                this.router.navigate([], { queryParams });

                this.isDataUpdated = true;
                this.initializeData();
            }
        });

        this.route.queryParams.subscribe((params) => {
            const searchValue = params['search'];
            if (searchValue) {
                this.zipcode = searchValue.replace('-', ' ');
                this.zipCodeValue.emit(this.zipcode);
            }
            this.categoriesList = params['filter'];
            this.initializeData();
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.LoaderDisplaysubscription) {
            this.LoaderDisplaysubscription.unsubscribe();
        }

        if (this.sessionSubscription) {
            this.sessionSubscription.unsubscribe();
        }
    }

    // method to set the initial values
    initializeData(): void {
        this.displayData = this._merchantSearchStoreList.slice(0, 10); // Assign the first 10 results to displayData
        this._cdr.detectChanges();
        this.nextStoreData = this._merchantSearchStoreList.slice(10, 20); // Assign the remaining results to storeData
        const records = { record: this._merchantSearchNoOfRecords };
        this.totalRecords = { ...records };
        this._cdr.detectChanges();
        MerchantLocationData.initialMerchantData = {
            numberOfRecords: this.totalRecords,
            aResults: [this.displayData, this.nextStoreData],
        };

        this.startIndex = 1;
        this.endIndex = 0;
        this.paginationClicked = false;
    }

    handleDisplayListSearchResultsChange(event: any) {
        // Handle the event emitted from the child component
        this.displayData = event;
        this._cdr.detectChanges();
    }

    onMarkerSelected(index: number): void {
        this.selectedMarkerIndex = index;
        this.paginationClicked = true;
    }

    handlePaginationUpdate(event: { page: number; action: string }) {
        // Handle the pagination update here
        this.currentPage = event.page;
        this.clickAction = event.action;
        this.updateDataOnClick();
        const clicked = { clicked: true };
        this.storeListPaginationClicked = { ...clicked };
    }

    updateDataOnClick() {
        this.paginationClicked = false;

        // Make an API call to fetch the oModal for the current page
        // Use the appropriate parameters like page number and limit
        const zipCode = this.zipcode; // Replace with the desired zip code
        const limit = 20;

        const totalRecords = MerchantLocationData.initialMerchantData.numberOfRecords;
        const totalResults = MerchantLocationData.initialMerchantData.aResults.reduce(
            (initialValue: any, currentValue: any) => initialValue + currentValue.length,
            0
        );

        if (this.clickAction == 'previous') {
            this.newCurrentPage = this.currentPage - 1;
            this.displayData = MerchantLocationData.initialMerchantData.aResults[this.newCurrentPage];
            this._cdr.detectChanges();
        } else {
            this.newCurrentPage = this.currentPage;
            this.totalDataLength = MerchantLocationData.initialMerchantData.aResults.length;
            if (totalRecords === totalResults || this.newCurrentPage < this.totalDataLength) {
                const getPage = this.newCurrentPage - 1;
                this.displayData = MerchantLocationData.initialMerchantData.aResults[getPage];
                this._cdr.detectChanges();
            } else {
                this.displayData = this.nextStoreData;
                this._cdr.detectChanges();
            }
        }

        const storedCategoryDetails = this.document?.defaultView?.sessionStorage?.getItem('categoryDetails');

        if (this.categoriesList) {
            const filterResults = this.categoriesList?.toString()?.split(',');

            this.apiKeyResult = this.processAndFilterCategoryDetails(storedCategoryDetails, filterResults);

            this.apiKeyParam = this.apiKeyResult.map((apiKey: any) => apiKey).join(',');
        }

        if (totalRecords !== totalResults && this.clickAction !== 'previous') {
            this.merchantSearchResultsSubscription(zipCode, limit);
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
    }

    public get merchantLocationContainer(): string {
        let containerClass = '';
        if (this.getScreenWidth <= 1439) {
            containerClass = 'row mx-auto my-0 pt-0 pb-0 px-0 pt-md-0 pt-xxl-00 mt-3 mt-md-4';
        }

        if (this.getScreenWidth >= 1440) {
            containerClass = 'container row mx-auto my-0 pt-0 pb-0 px-0 pt-md-0 pt-xxl-00 mt-3 mt-md-4';
        }

        if (this.getScreenWidth >= 768) {
            containerClass = containerClass + ' position-relative';
        }

        if (this.totalRecords?.record === 0 && this.getScreenWidth < 768) {
            containerClass += ' px-3';
        }

        return containerClass;
    }

    onSelectedMarkerIndexValEmitted(index: number | null) {
        this.storeIndex = index;
    }
    processAndFilterCategoryDetails(
        storedCategoryDetails: string | null | undefined,
        filterResults: string[]
    ): any[] {
        if (typeof storedCategoryDetails === 'string' && storedCategoryDetails) {
            const parsedCategoryDetails = JSON.parse(storedCategoryDetails);
            return this.categoryUtilsService.filterCategoryDetails(parsedCategoryDetails, filterResults);
        }

        return [];
    }

    get paginationDisplayClass() {
        if (this.isLoaderDisplay) {
            if (this.getScreenWidth >= 768) {
                return 'invisible';
            }
            if (this.getScreenWidth < 768) {
                return 'd-none';
            }
        }
        return '';
    }

    clearMarker() {
        if (this.isLoaderDisplay) {
            const clearMarker = { clear: true };
            this._clearMarker = { ...clearMarker }; // This creates a new object reference
        }
    }

    set storeListMobileDisplayClass(display: boolean) {
        const defaultClass = 'px-0';
        if (display) {
            this._storeListMobileDisplayClass = `${defaultClass} d-none`;
        } else {
            this._storeListMobileDisplayClass = `${defaultClass} d-block d-sm-block d-md-none d-lg-none`;
        }
        this._cdr.detectChanges();
    }

    get storeListMobileDisplayClass(): any {
        return this._storeListMobileDisplayClass;
    }

    sessionStorageSubscription(data: any) {
        this.sessionSubscription = this._merchantFilterService.sessionStorageSubject$.subscribe(
            (stateName: any) => {
                if (
                    stateName &&
                    stateName?.toString().trim().length > 0 &&
                    data?.urlStateName?.toLowerCase() === stateName?.toLowerCase()
                ) {
                    this._merchantFilterService.loaderDisplay(false);
                    this._merchantFilterService.sessionStorageSubject('');
                }
            }
        );
    }

    merchantSearchResultsSubscription(zipCode: any, limit: any) {
        // Call your API service method to fetch the updated data
        this._merchantSearchResults
            .getSearchResults(
                zipCode,
                this.newCurrentPage,
                limit,
                this._merchantSearchRadius,
                this.apiKeyParam ?? ''
            )
            .subscribe(
                (searchResults) => {
                    this.nextStoreData = [];
                    // Handle the search results here
                    this.nextStoreData = searchResults.aResults;

                    const firstData = this.nextStoreData.slice(
                        0,
                        this.nextStoreData.length > 10 ? 10 : this.nextStoreData.length
                    );

                    MerchantLocationData.initialMerchantData.aResults.push(firstData);
                    if (this.nextStoreData.length > 10) {
                        const secondData = this.nextStoreData.slice(
                            10,
                            this.nextStoreData.length > 20 ? 20 : this.nextStoreData.length
                        );
                        MerchantLocationData.initialMerchantData.aResults.push(secondData);
                    }

                    this.showAccordion = false;
                },
                (error) => {
                    // Handle errors here
                    console.error(error);
                }
            );
    }
}
