import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlidePanelService {
  private _sliderScrollPosition = new Subject<any>();
  readonly sliderScrollPosition$ = this._sliderScrollPosition.asObservable();

  emitSliderScrollPosition(position: string): void {
    this._sliderScrollPosition.next(position);
  }
}
