import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffCardComponent } from './aff-card.component';
import { AffCardBodyComponent } from './aff-card-body/aff-card-body.component';
import { AffCardFooterComponent } from './aff-card-footer/aff-card-footer.component';
import { AffCardHeaderComponent } from './aff-card-header/aff-card-header.component';

@NgModule({
    declarations: [AffCardComponent, AffCardBodyComponent, AffCardFooterComponent, AffCardHeaderComponent],
    exports: [AffCardComponent, AffCardHeaderComponent, AffCardBodyComponent, AffCardFooterComponent],
    imports: [CommonModule],
})
export class AffCardModule {}
