import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MerchantSignUpFormModule } from '../../types/kitchen-sink.types';

@Component({
    selector: 'janet-merchant-form',
    templateUrl: './merchant-sign-up-form.component.html',
})
export class MerchantSignUpComponent {
    @Input() merchantSignUpFormData: MerchantSignUpFormModule;

    merchantSignupForm: string;

    constructor(private route: ActivatedRoute) {}
    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            const tag = document.createElement('div');
            tag.innerHTML = this.merchantSignUpFormData.merchantSignupForm;
            const iframe = tag.firstElementChild as HTMLIFrameElement;
            iframe.src = `${iframe.src}?${new URLSearchParams(params).toString()}`;
            this.merchantSignupForm = iframe.outerHTML.toString();
        });
    }
}
